import axios from "axios";
import REACT_LOCALHOST from "../../host";
import { authHeader } from '../../helpers';
const phannhomAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-service-group-vien-phi`,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-service-group-vien-phi`, obj, {"headers":authHeader()});
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-service-group-vien-phi`, obj);
    },
    delete: (ServiceGroupID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-service-group-vien-phi/${ServiceGroupID}/${EmployeeID}`);
    },

    getServiceModule: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-service-module`,obj);
    },
    getServiceGroupBHYT: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-service-group-bhyt`,obj);
    },
};

export default phannhomAPI;
