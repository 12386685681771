import React, { useEffect, useState } from 'react';
import { setDataKhoaPhong } from '../../../../slices/dataTienIch';
import { Form, Input, Button, Modal, Select, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import loginAPI from "../../../../services/loginApi";
import '../khoaphong.scss';

const DetailKhoaPhong = (props) => {
    const { open, setOpen, handleCreate, handleUpdate, listServiceModule, listKhoaphong_ck } = props;
    const { khoaphong } = useSelector((state) => state.dataTienIch);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [userlogin, setUserLogin] = useState();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
            throw new Error(err);
        }
    };

    const handleSubmit = async (e) => {
        Swal.fire({
            title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
            confirmButtonText: "Đồng ý",
            showCancelButton: true,
            cancelButtonText: "Hủy",
            customClass: {
                title: "fs-5 text-dark",
                confirmButton: "bg-primary shadow-none",
                cancelButton: "bg-warning shadow-none",
            },
        }).then((result) => {
            if (result?.isConfirmed) {
                if (khoaphong?.DepartmentID) {
                    var upkhoaphong = {
                        DepartmentID: e?.DepartmentID,
                        DepartmentCode: e?.DepartmentCode,
                        DepartmentName: e?.DepartmentName,
                        ServiceModuleCode: e?.ServiceModuleCode,
                        Hide: 0,
                        MaKhoa_CK: e?.MaKhoa_CK,
                        Note: e?.Note,
                        EmployeeID_CCHN: e?.EmployeeID_CCHN,
                        MAKHOA_BH: e?.MAKHOA_BH,
                        STT: e?.STT,
                        EmployeeID: userlogin.RowID
                    }
                    handleUpdate(upkhoaphong);
                    dispatch(setDataKhoaPhong());
                    form.resetFields();
                    setOpen(false);
                } else {
                    var KhoaPhong = {
                        DepartmentID: e?.DepartmentID,
                        DepartmentCode: e?.DepartmentCode,
                        DepartmentName: e?.DepartmentName,
                        ServiceModuleCode: e?.ServiceModuleCode,
                        Hide: 0,
                        MaKhoa_CK: e?.MaKhoa_CK,
                        Note: e?.Note === undefined ? " " : e?.Note,
                        EmployeeID_CCHN: e?.EmployeeID_CCHN === undefined ? " " : e?.EmployeeID_CCHN,
                        MAKHOA_BH: e?.MAKHOA_BH === undefined ? " " : e?.MAKHOA_BH,
                        STT: e?.STT === undefined ? 1 : e?.STT,
                        EmployeeID: userlogin.RowID
                    }
                    handleCreate(KhoaPhong);
                    form.resetFields();
                    setOpen(false);
                }
            }
        });
    };

    useEffect(() => {
        getAllUser();
        form.setFieldsValue({
            DepartmentID: khoaphong?.DepartmentID,
            DepartmentCode: khoaphong?.DepartmentCode,
            DepartmentName: khoaphong?.DepartmentName,
            ServiceModuleCode: khoaphong?.ServiceModuleCode,
            Hide: khoaphong?.Hide,
            MaKhoa_CK: khoaphong?.MaKhoa_CK,
            Note: khoaphong?.Note,
            EmployeeID_CCHN: khoaphong?.EmployeeID_CCHN,
            MAKHOA_BH: khoaphong?.MAKHOA_BH,
            STT: khoaphong?.STT
        });
    }, [form, khoaphong]);

    return (
        <Modal
            className="DetailKhoaPhong"
            title={<div className="vienphi-danhmuc-add">Khai báo khoa phòng</div>}
            centered
            open={open}
            okButtonProps={{
                style: {
                    display: "none",
                },
            }}
            cancelButtonProps={{
                style: {
                    display: "none",
                },
            }}
            onCancel={() => setOpen(false)}
            width={"80vw"}
            closable={false}
            forceRender
            maskClosable={false}
        >
            <div className="text-center">
                <Form form={form} onFinish={handleSubmit} >
                    <div className="row">
                        <Form.Item
                            hidden={true}
                            label="Phòng thực hiện (ID):"
                            name="DepartmentID"
                        >
                            <Input className="form-control form-control-sm" />
                        </Form.Item>
                        <Form.Item
                            label={<div className='form-input-label labelname'>Mã khoa phòng:</div>}
                            name="DepartmentCode"
                            rules={[{ required: true, message: 'Vui lòng nhập mã khoa!' }]}
                            className="col-md-4 m-0"
                        >
                            <Input className="form-control form-control-sm" />
                        </Form.Item>
                        <Form.Item
                            label={<div className="form-input-label labelname">Tên phòng thực hiện:</div>}
                            className="col-md-8 m-0"
                            name="DepartmentName"
                            rules={[{ required: true, message: 'Vui lòng nhập tên phòng thực hiện!' }]}
                        >
                            <Input className="form-control inputdm" />
                        </Form.Item>
                    </div>
                    <div className="row">
                        <Form.Item
                            className="col-md-4 m-0"
                            label={<div className='form-input-label labelname'>Mã BS CCHN:</div>}
                            name="EmployeeID_CCHN"
                        >
                            <Input className="form-control form-control-sm" />
                        </Form.Item>
                        <Form.Item
                            label={<div className='form-input-label labelname'>Nhóm dịch vụ:</div>}
                            className="col-md-4 m-0"
                            name="ServiceModuleCode"
                            rules={[{ required: true, message: 'Vui lòng nhập nhóm dịch vụ!' }]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="label"
                                notFoundContent={"Chưa có dữ liệu"}
                                placeholder="Chọn nhóm dịch vụ"
                                options={listServiceModule.map((item) => ({
                                    value: item.ServiceModuleCode,
                                    label: item.ServiceModuleName,
                                }))}
                            />
                        </Form.Item>

                        <Form.Item
                            className="col-md-4 m-0"
                            label={<div className='form-input-label labelname'>Mã khoa BHYT:</div>}
                            name="MaKhoa_CK"
                            rules={[{ required: true, message: 'Vui lòng chọn mã khoa BHYT!' }]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="label"
                                notFoundContent={"Chưa có dữ liệu"}
                                placeholder="Chọn"
                                options={listKhoaphong_ck.map((item) => ({
                                    value: item.MaKhoa,
                                    label: `${item.MaKhoa} - ${item.TenKhoa}`,
                                }))}
                            />
                        </Form.Item>
                    </div>
                    <div className="row">
                        <Form.Item
                            className="col-md-4 m-0"
                            label={<div className='form-input-label labelname'>Mã khoa QĐ 130:</div>}
                            name="MAKHOA_BH"
                        >
                            <Input className="form-control form-control-sm" />
                        </Form.Item>
                        <Form.Item

                            label={<div className='form-input-label labelname'>Ghi chú:</div>}
                            className="col-md-5 m-0"
                            name="Note"
                        >
                            <Input.TextArea className="form-control" rows={1} />
                        </Form.Item>

                        <Form.Item
                            className="col-md-3 m-0"
                            label={<div className='form-input-label labelname'>STT:</div>}
                            name="STT"
                        >
                            <InputNumber className="form-control form-control-sm" />
                        </Form.Item>
                    </div>
                    <div className="text-center">
                        <hr />
                        <Button type="primary" htmlType="submit" className="mx-2 vienphi-danhmuc-btn">
                            <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                        </Button>
                        <Button
                            onClick={() => {
                                form.resetFields();
                                setOpen(false);
                            }}
                            danger className="mx-2"
                        >
                            <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};
export default DetailKhoaPhong;
