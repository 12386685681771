import axios from "axios";
import REACT_LOCALHOST from "../../host";
//import { authHeader } from '../../helpers';
const nhominduocAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-group-printer-duoc`,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-group-printer-duoc`, obj);
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-group-printer-duoc`, obj);
    },
    delete: (GroupPrinterID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-group-printer-duoc/${GroupPrinterID}/${EmployeeID}`);
    },
};

export default nhominduocAPI;