
import { Table, Button, Dropdown, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
    faEdit,
    faTrashCan,
    faPenToSquare,
    faPlus,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import "../soluongfilm.scss";
import DetailDonViTinh from "../Detail";

function KhaiBaoSoLuongFilm() {

     const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
   
    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const columns = [
        {
            title: "Nhóm DVKT",
            dataIndex: "Unit_ID",
            fixed: "left",
            render: (Unit_ID) => <div style={{ width: "200px" }}> {Unit_ID} </div>,
            filters: [
                {
                  text: 'Joe',
                  value: 'Joe',
                },
                {
                  text: 'Category 1',
                  value: 'Category 1',
                },
                {
                  text: 'Category 2',
                  value: 'Category 2',
                },
              ],
        },
        {
            title: "Loại DVKT",
            dataIndex: "Unit_Name",
            render: (Unit_Name) => <div style={{ width: "500px" }}> {Unit_Name} </div>,
        },
        {
            title: "Mã DVKT",
            dataIndex: "STT",
            render: (STT) => <div style={{ width: "50px", textAlign: 'center' }}> {STT} </div>,
        },
        {
            title: "Tên DVKT",
            dataIndex: "Unit_Name",
            render: (Unit_Name) => <div style={{ width: "500px" }}> {Unit_Name} </div>,
        },
        {
            title: "Số lượng Film/KQ",
            dataIndex: "",
            align: "center",
            render: (Unit_Name) => <div style={{ width: "500px" }}> {Unit_Name} </div>,
        },
        {
            title: "Thao tác",
            dataIndex: "",
            align: "center",
            fixed: "right",
            render: (record) => (
                <div className="d-flex justify-content-center">
                    <Dropdown
                        menu={{
                            items,
                           
                        }}
                        placement="left"
                        arrow={{
                            pointAtCenter: true,
                        }}
                    >
                        <Button
                            className="bg-light vienphi-danhmuc-icon-modify"
                            onClick={() => {
                                setActiveModify(!activeModify);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: "10px" }}
                                className="text-dark"
                            />
                        </Button>
                    </Dropdown>
                </div>
            ),
        },
    ];

    return (
        <>

            <div className="text-muted">
                <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                    <div className="d-flex align-items-center ">
                        <div className="vienphi-danhmuc-title mx-2"><FontAwesomeIcon icon={faBars} /> Khai Báo số lượng film X-Quang</div>
                    </div>
                    <div className="text-end">
                        <Button className="form-btn bg" onClick={handleDataCreate}>
                            <FontAwesomeIcon icon={faPlus} className="mx-1" />
                            Thêm mới
                        </Button>
                    </div>
                </div>

                <DetailDonViTinh
                    open={open} setOpen={setOpen}
                    key="DetailDonViTinh"
                />
                <hr className="w-100 my-1" />
                <div className="m-1 danhmuc">
                    <Table
                        dataSource={''}
                        columns={columns}
                        scroll={{ x: true, y: "100vh" }}
                        size="small"
                        pagination={false}
                        bordered={true}
                    />
                </div>
            </div>

        </>
    );
}

export default KhaiBaoSoLuongFilm;
