import axios from "axios";
import REACT_LOCALHOST from "../../host";
import { authHeader } from '../../helpers';
const donvitinhAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-group-printer-vien-phi`,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-group-printer-vien-phi`, obj, {"headers":authHeader()});
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-group-printer-vien-phi`, obj);
    },
    delete: (GroupPrinterID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-group-printer-vien-phi/${GroupPrinterID}/${EmployeeID}`);
    }
};

export default donvitinhAPI;
