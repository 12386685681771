import React from "react";
import  "./notfound.scss";
import { Link } from 'react-router-dom';
const Unauthorized = () => {
    return (
        <div className="notfound">
            <h1>Bạn không có quyền truy cập vào trang này !</h1>
            <div className="trangchu"> <Link to="/" > Về trang chủ</Link></div>
        </div>
    );
};

export default Unauthorized;