import axios from "axios";
import REACT_LOCALHOST from "../../host";
import { authHeader } from '../../helpers';
const donvitinhAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-unitOfmeasure-duoc`,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-unitOfmeasure-duoc`, obj);
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-unitOfmeasure-duoc`, obj);
    },
    delete: (UnitID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-unitOfmeasure-duoc/${UnitID}/${EmployeeID}`);
    },
};

export default donvitinhAPI;