import axios from "axios";
import REACT_LOCALHOST from "../../host";
import { authHeader } from '../../helpers';
const duongdungAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-usage-duoc`,obj);
    },
    getAllBHYT: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-usage-bhyt-duoc `,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-med-usage-duoc`, obj);
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-med-usage-duoc`, obj);
    },
    delete: (UsageID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-med-usage-duoc/${UsageID}/${EmployeeID}`);
    },
};

export default duongdungAPI;