import { Button, Form, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DataGrid, GridColumn } from 'rc-easyui';
import moment from 'moment';
import "./timkiem.scss";
import tiepnhanAPI from "../../../services/tiepnhan/tiepnhanAPI";
import { setDataSearchTiepNhan,setDataTiepNhan } from "../../../slices/dataTiepNhan";
import { setDataDatHen } from "../../../slices/dataDatHen";
function Timkiem(props) {
    const { setActiveKey } = props;
    const [ListSearch, setListSearch] = useState([]);
    const [ListGioiTinh, setListGioiTinh] = useState([]);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const getAllGioiTinh = async () => {
        try {
            const data = await tiepnhanAPI.getAllPatientGender();
            setListGioiTinh(data.data);
        } catch (err) { }
    };
    const handleSubmit = async (e) => {
        try {
            const data = await tiepnhanAPI.searchPatients({
                PatientCode: e?.PatientCode === undefined ? "" : e?.PatientCode,
                PatientName: e?.PatientName === undefined ? "" : e?.PatientName,
                PatientBirthday: e?.PatientBirthday === undefined ? "" : e?.PatientBirthday,
                PatientMobile: e?.PatientMobile === undefined ? "" : e?.PatientMobile,
                CC_Serial: e?.CC_Serial === undefined ? "" : e?.CC_Serial
            });
            setListSearch(data.data);
        } catch (err) {}
    }

    const switchToTab2 = () => {
        setActiveKey("CongKham"); 
    };
    const handleRowEditEnd = async (event) => {
        dispatch(setDataSearchTiepNhan(event));
        dispatch(setDataDatHen(null));
        dispatch(setDataTiepNhan(null));
        
        switchToTab2();
    }
    useEffect(() => {
        getAllGioiTinh();
    }, [form]);
    const getFooterData = () => {
        /* return [
             { PatientName: "Tổng cộng:", PatientGenderID: ListDatHen.length }
         ]*/
    }
    return (
        <>
            <div className="m-1 tn-from">
                <Form onFinish={handleSubmit} form={form}>
                    <div className="tiepnhan-row">
                        <div className="tiepnhan-timkiem-form">
                            <div className="mr-tn">
                                <div className="row">
                                    <Form.Item
                                        label={<div className="form-input-label">Mã BN :</div>}
                                        className="w-15 m-0"
                                        name="PatientCode"
                                    >
                                        <Input className="form-control" />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="form-input-label">Họ và tên :</div>}
                                        className="w-25 m-0"
                                        name="PatientName"
                                    >
                                        <Input className="form-control" />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="form-input-label">N.Sinh(Tuổi) :</div>}
                                        className="w-15 m-0"
                                        name="PatientBirthday"
                                    >
                                        <Input className="form-control" />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="form-input-label">Điện thoại :</div>}
                                        className="w-15 m-0"
                                        name="PatientMobile"
                                    >
                                        <Input className="form-control" />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="form-input-label">CMND :</div>}
                                        className="w-15 m-0"
                                        name="CC_Serial"
                                    >
                                        <Input className="form-control" />
                                    </Form.Item>
                                    <Form.Item className="w-10 m-0 d-flex align-items-end">
                                        <Button htmlType="submit" className="form-btn">
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            <div className="mx-2"> Tìm kiếm</div>
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                <div className="my-2 tiepnhan-tk-table">
                    <DataGrid
                        data={ListSearch}
                        style={{ height: '80vh' }}
                        columnResizing
                        dblclickToEdit
                        editMode="row"
                        onRowDblClick={(row) => handleRowEditEnd(row)}
                        showFooter
                        footerData={getFooterData()}
                        inert

                    >
                        <GridColumn field="PatientCode" title="Mã BN" width={150} resizable />
                        <GridColumn field="PatientName" title="Họ tên" width={300} resizable />
                        <GridColumn field="PatientGenderID" title="Giới tính" width={80} resizable
                            render={({ row }) => {
                                const category = ListGioiTinh.find((item) => item.PatientGenderID === row.PatientGenderID);
                                return category ? category.PatientGenderName : null;
                            }}
                        />
                        <GridColumn field="PatientBirthday" title="Ngày sinh" width={100} resizable
                            render={({ row }) => (
                                <span>{moment(row.PatientBirthday).format('DD/MM/YYYY')}</span>
                            )}
                        />
                        <GridColumn field="Age" title="Tuổi" width={100} resizable />
                        <GridColumn field="CC_Serial" title="CCCD" width={400} resizable />


                    </DataGrid>
                </div>
            </div>
        </>
    );
}

export default Timkiem;
