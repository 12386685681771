import { Modal, Tabs, Button, Form, Input, Checkbox, Table, Dropdown, Radio, Select } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCalendarCheck,
  faFileExcel,
  faPenToSquare,
  faReply,
  faSave,
  faFile,
  faAngleRight,
  faAngleLeft,
  faEdit,
  faTrashCan,
  faEye,
  faArrowsRotate,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import "./hosobenhanyhct.scss";
function FormKhamBenh() {
  const onDropdownChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };
  const list = [
    {
      value: '1',
      label: 'Jack',
    },
    {
      value: '2',
      label: 'Lưu Văn Tèo',
    },
    {
      value: '3',
      label: 'Nguyễn Việt Hùng',
    },
  ];
  const dataSource = [
    {
      key: '1',
      name: 'Mike',
      age: 32,
      address: '10 Downing Street',
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      address: '10 Downing Street',
    },
  ];

  const columns = [
    {
      title: 'Mã',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tên',
      dataIndex: 'age',
      key: 'age',
    }
  ];
  return (
    <div className="FromKhamBenhYHCT">
      <div className="w-40">
        <div className="row formrow">
          <Form.Item
            label={<div className="form-input-label lablename-input">1. C.Đoán vv YHCT :</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Select
              className="form-control input-select"
              showSearch
              optionFilterProp="children"
              placeholder={"select a country"}
              onChange={onDropdownChange}
              filterOption={(input, option) => {
                return (
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );

              }}
            >
              {list.map((item) => (
                <Select.Option
                  title={item.label}
                  key={item.label}
                  id={item.value}
                  value={item.value}
                >
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="row formrow">
          <Form.Item
            label={<div className="form-input-label lablename-input">2.C.Đoán vv YHHĐ :</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Select
              className="form-control input-select"
              showSearch
              optionFilterProp="children"
              placeholder={"select a country"}
              onChange={onDropdownChange}
              filterOption={(input, option) => {
                return (
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );

              }}
            >
              {list.map((item) => (
                <Select.Option
                  title={item.label}
                  key={item.label}
                  id={item.value}
                  value={item.value}
                >
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="row formrow">
          <Form.Item
            label={<div className="form-input-label lablename-input">ICD YHCT kèm theo:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Select
              className="form-control input-select"
              showSearch
              optionFilterProp="children"
              placeholder={"select a country"}
              onChange={onDropdownChange}
              filterOption={(input, option) => {
                return (
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );

              }}
            >
              {list.map((item) => (
                <Select.Option
                  title={item.label}
                  key={item.label}
                  id={item.value}
                  value={item.value}
                >
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="tableICDKT">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
        <div className="row formrow">
          <Form.Item
            label={<div className="form-input-label lablename-input title">II. LÝ DO VÀO VIỆN:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input.TextArea rows={1} className="form-control" />
          </Form.Item>
        </div>
        <div className="row formrowauto">
          <Form.Item
            label={<div className="form-input-label lablename-input"><span className="title">III. HỎI BỆNH:</span><br /> 1. Bệnh sử: <br />2. Tiền sử: </div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input.TextArea rows={3} className="form-control" />
          </Form.Item>
        </div>
        <div className="row formrowin">
          <Form.Item
            label={<div className="form-input-label lablename-input">+ Bản thân:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row formrowin">
          <Form.Item
            label={<div className="form-input-label lablename-input">+ Gia đình:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="khambenhitem">
          <div className="khambenhitem-1">
            <div className="title">IV. KHÁM BỆNH</div>
            <div className="title-tt">1. Toàn thân:</div>
            <div className="row">
              <Form.Item
                label={''}
                className="col-md-12 m-0"
                name="ServiceGroup_ID"
              >
                <Input.TextArea rows={3} className="form-control" />
              </Form.Item>
            </div>
          </div>
          <div className="khambenhitem-2">
            <div className="row formrowkb">
              <Form.Item
                label={<div className="namelable">Mạch(l/p):</div>}
                className="col-md-12 m-0"
                name="ServiceGroup_ID"
              >
                <Input className="form-control" />
              </Form.Item>
            </div>
            <div className="row formrowkb">
              <Form.Item
                label={<div className="namelable">N.Độ(°C):</div>}
                className="col-md-12 m-0"
                name="ServiceGroup_ID"
              >
                <Input className="form-control" />
              </Form.Item>
            </div>
            <div className="row formrowkb">
              <Form.Item
                label={<div className="namelable">HA(mmHg):</div>}
                className="col-md-12 m-0"
                name="ServiceGroup_ID"
              >
                <Input className="form-control" />
              </Form.Item>
            </div>
            <div className="row formrowkb">
              <Form.Item
                label={<div className="namelable">N.Thở(l/p):</div>}
                className="col-md-12 m-0"
                name="ServiceGroup_ID"
              >
                <Input className="form-control" />
              </Form.Item>
            </div>
            <div className="row formrowkb">
              <Form.Item
                label={<div className="namelable">C.Nặng(kg):</div>}
                className="col-md-12 m-0"
                name="ServiceGroup_ID"
              >
                <Input className="form-control" />
              </Form.Item>
            </div>
            <div className="row formrowkb">
              <Form.Item
                label={<div className="namelable">C.Cao(cm):</div>}
                className="col-md-12 m-0"
                name="ServiceGroup_ID"
              >
                <Input className="form-control" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="cacbophan">
          <div className="title-tt">2. Các bộ phận khác:</div>
          <div className="row">
            <Form.Item
              label={''}
              className="col-md-12 m-0"
              name="ServiceGroup_ID"
            >
              <Input.TextArea rows={5} className="form-control" />
            </Form.Item>
          </div>
        </div>
        <div className="cacbophan">
          <div className="title-tt">3. Tóm tắt kết quả CLS:</div>
          <div className="row">
            <Form.Item
              label={''}
              className="col-md-12 m-0"
              name="ServiceGroup_ID"
            >
              <Input.TextArea rows={5} className="form-control" />
            </Form.Item>
          </div>
        </div>


      </div>
      <div className="w-60">
        <div className="row formrow-s">
          <Form.Item
            label={<div className="form-input-label lablename-input">Mẫu HS:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Select
              className="form-control input-select"
              showSearch
              optionFilterProp="children"
              placeholder={"select a country"}
              onChange={onDropdownChange}
              filterOption={(input, option) => {
                return (
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );

              }}
            >
              {list.map((item) => (
                <Select.Option
                  title={item.label}
                  key={item.label}
                  id={item.value}
                  value={item.value}
                >
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablename-input">4. C.Đ ban đầu:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablename-input">Xử trí:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="title">Y HỌC CỔ TRUYỀN</div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">I. Vọng chẩn:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">II. Văn chẩn:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">II. Vấn chẩn:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="thietchan">IV. Thiết chẩn</div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">1. Xúc chẩn:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">2. M tay trái:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">M tay phải:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">V. TT tứ chẩn:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct-iv">VI. Biện chứng luận trị:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="thietchan">VII. Chẩn đoán:</div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">- Bệnh danh:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">- Bát cương:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">- Ng nhân:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">- Tạng phủ:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">- Kinh mạch:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">- Định vị bệnh:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">- Pháp ĐTrị:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="row iteminput">
          <Form.Item
            label={<div className="form-input-label lablenameyhct">- Ph.Dược:</div>}
            className="col-md-12 m-0"
            name="ServiceGroup_ID"
          >
            <Input className="form-control" />
          </Form.Item>
        </div>
        <div className="item-pp">
          <div className="title-1">- Ph.Pháp điều trị không dùng thuốc:</div>
          <div className="row iteminput">
            <Form.Item
              label={''}
              className="col-md-12 m-0"
              name="ServiceGroup_ID"
            >
              <Input className="form-control" />
            </Form.Item>
          </div>
        </div>
        <div className="item-pp">
          <div className="title-2">- Hướng điều trị / Chăm sóc:</div>
          <div className="row texarea">
            <Form.Item
              label={''}
              className="col-md-12 m-0"
              name="ServiceGroup_ID"
            >
             <Input.TextArea rows={5} className="form-control" />
            </Form.Item>
          </div>
        </div>


      </div>
    </div>
  );
}

export default FormKhamBenh;
