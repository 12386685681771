import axios from "axios";
import REACT_LOCALHOST from "../host";
//import { authHeader } from '../helpers';
const nguoigioithieuAPI = {
    fetchNguoiGioiThieu: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-introducer`, obj);
    },
    addupNguoiGioiThieu: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-update-med-introducer`, obj);
    },
   
    deleteNguoiGioiThieu: (RowID, EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-med-introducer/${RowID}/${EmployeeID}`);
    },
};

export default nguoigioithieuAPI;

