import { Modal, Button, Form, Input, Select, Checkbox, InputNumber } from "antd";
import "./style.scss";
import "../../danhmuc.scss";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { errDialog, errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import loginAPI from "../../../../../services/loginApi";
import { setDataDichVuKyThuat } from "../../../../../slices/dataVienPhi";
import dvktAPI from "../../../../../services/vienphi/danhmuc/dvktAPI";

function DetailDVKT(props) {
    const { open, setOpen, handleUpdate, reLoad, NhomVienPhi, LoaiVienPhi, DonViTinh, NhomIn, KhoaPhong, KhoaBHYT, NhomBHYT } = props;
    const { dichvukythuat } = useSelector((state) => state.dataVienPhi);
    const [userlogin, setUserLogin] = useState();
    const [CheckHide, setcheckAnHien] = useState(false);
    const [IsSampleTransfer, setcheckGuiMau] = useState(false);
    const [IsAssure, setIsAssure] = useState(false);
    const [IsWarningNote, setIsWarningNote] = useState(false);
    const [HandPoint_App, setHandPoint_App] = useState(false);
    const [IsResultCLS, setIsResultCLS] = useState(false);
    const [IsPTTT_XM5, setIsPTTT_XM5] = useState(false);
    const [IsEKip_xml, setIsEKip_xml] = useState(false);
    const [HandPoint, setHandPoint] = useState(false);
    const [IsItemAttach, setIsItemAttach] = useState(false);

    const [filteredCategories, setFilteredCategories] = useState([]);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const handleProvinceChange = (value) => {
        form.setFieldsValue({ ServiceGroupID: value });
        const filtered = LoaiVienPhi.filter((item) => item.ServiceGroupID === value);
        setFilteredCategories(filtered);
    };

    const onChangeGuiMau = (e) => {
        setcheckGuiMau(e.target.checked)
    };
    const onChangeCamDoan = (e) => {
        setIsAssure(e.target.checked)
    };
    const onChangeHoiChuan = (e) => {
        setIsWarningNote(e.target.checked)
    };
    const onChangeHenTaiKham = (e) => {
        setHandPoint_App(e.target.checked)
    };
    const onChangeKQCLS = (e) => {
        setIsResultCLS(e.target.checked)
    };
    const onChangePTTT = (e) => {
        setIsPTTT_XM5(e.target.checked)
    };

    const onChangeEkip = (e) => {
        setIsEKip_xml(e.target.checked)
    };
    const onChangeChiDinhTruoc = (e) => {
        setHandPoint(e.target.checked)
    };
    const onChangeThemThuoc = (e) => {
        setIsItemAttach(e.target.checked)
    };
    const onChangeAnHien = (e) => {
        setcheckAnHien(e.target.checked)
    };

    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleCreate = async (obj) => {
        const data = await dvktAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);
        }
        else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }

    };
    const handleSubmit = async (e) => {
        if (e?.ServiceName === undefined) { errDialog(); } else {
            Swal.fire({
                title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
                confirmButtonText: "Đồng ý",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                customClass: {
                    title: "fs-5 text-dark",
                    confirmButton: "shadow-none",
                    cancelButton: "shadow-none",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    if (dichvukythuat?.ServiceID) {
                        var arrayEdit = {
                            ServiceID: e?.ServiceID,
                            ServiceCode_PK: e?.ServiceCode_PK,
                            ServiceCode_AX: e?.ServiceCode_AX,
                            ServiceName: e?.ServiceName,
                            ServiceName_PK: e?.ServiceName_PK,
                            ServiceName_AX: e?.ServiceName_AX,
                            DepartmentCode: e?.DepartmentCode,
                            Hide: e?.Hide,
                            ServiceCategoryID: e?.ServiceCategoryID,
                            STT: e?.STT,
                            UnitID: e?.UnitID,
                            IsSampleTransfer: e?.IsSampleTransfer,
                            Note: e?.Note,
                            IsAssure: e?.IsAssure,
                            GroupPrinterID: e?.GroupPrinterID,
                            MaNhomTT_BH: e?.MaNhomTT_BH,
                            MaKhoa_BH: e?.MaKhoa_BH,
                            HandPoint: e?.HandPoint,
                            HandPoint_App: e?.HandPoint_App,
                            IsItemAttach: e?.IsItemAttach,
                            IsWarningNote: e?.IsWarningNote,
                            IsResultCLS: e?.IsResultCLS,
                            IsPTTT_XM5: e?.IsPTTT_XM5,
                            MachineID: e?.MachineID,
                            TimeFinished: e?.TimeFinished,
                            Rate_BHYT: e?.Rate_BHYT,
                            VAT: e?.VAT,
                            IsEKip_xml: e?.IsEKip_xml,
                            EmployeeID: userlogin.RowID,

                        }
                        handleUpdate(arrayEdit);
                        dispatch(setDataDichVuKyThuat());
                        form.resetFields();
                        setOpen(false);
                    } else {
                        var arrayAdd = {
                            ServiceCode_PK: e?.ServiceCode_PK,
                            ServiceCode_AX: e?.ServiceCode_AX === undefined? " " : e?.ServiceCode_AX,
                            ServiceName: e?.ServiceName,
                            ServiceName_PK: e?.ServiceName_PK,
                            ServiceName_AX: e?.ServiceName_AX === undefined? " " : e?.ServiceName_AX,
                            DepartmentCode: e?.DepartmentCode === undefined? " " : e?.DepartmentCode,
                            Hide: CheckHide === false ? 0 : 1,
                            ServiceCategoryID: e?.ServiceCategoryID,
                            STT: e?.STT === undefined ? 1 : e?.STT,
                            UnitID: e?.UnitID === undefined? 0 : e?.UnitID,
                            IsSampleTransfer: IsSampleTransfer === false ? 0 : 1,
                            Note: e?.Note === undefined? " " : e?.Note,
                            IsAssure: IsAssure === false ? 0 : 1,
                            GroupPrinterID: e?.GroupPrinterID === undefined? 0 : e?.GroupPrinterID,
                            MaNhomTT_BH: e?.MaNhomTT_BH === undefined? 0 : e?.MaNhomTT_BH,
                            MaKhoa_BH: e?.MaKhoa_BH === undefined? " " : e?.MaKhoa_BH ,
                            HandPoint: HandPoint === false ? 0 : 1,
                            HandPoint_App: HandPoint_App === false ? 0 : 1,
                            IsItemAttach: IsItemAttach === false ? 0 : 1,
                            IsWarningNote: IsWarningNote === false ? 0 : 1,
                            IsResultCLS: IsResultCLS === false ? 0 : 1,
                            IsPTTT_XM5: IsPTTT_XM5 === false ? 0 : 1,
                            MachineID: e?.MachineID === undefined? 0 : e?.MachineID,
                            TimeFinished: e?.TimeFinished === undefined? 0 : e?.TimeFinished ,
                            Rate_BHYT: e?.Rate_BHYT === undefined? 0 : e?.Rate_BHYT,
                            VAT: e?.VAT === undefined? 0 : e?.VAT ,
                            IsEKip_xml: IsEKip_xml === false ? 0 : 1,
                            EmployeeID: userlogin.RowID,

                        }
                        handleCreate(arrayAdd);

                    }
                }
            });
        }

    }

    useEffect(() => {
        getAllUser();
        if (dichvukythuat) {
            let DepartmentCodeList = dichvukythuat?.DepartmentCode ===' ' ? null : dichvukythuat?.DepartmentCode?.split(",");
            const category = LoaiVienPhi.find(item => item.ServiceCategoryID === dichvukythuat?.ServiceCategoryID);
            if (category) {
                const filtered = LoaiVienPhi.filter(item => item.ServiceGroupID === category.ServiceGroupID);
                setFilteredCategories(filtered);

            }
            form.setFieldsValue({
                ServiceID: dichvukythuat?.ServiceID,
                ServiceCode_PK: dichvukythuat?.ServiceCode_PK,
                ServiceCode_AX: dichvukythuat?.ServiceCode_AX,
                ServiceName: dichvukythuat?.ServiceName,
                ServiceName_PK: dichvukythuat?.ServiceName_PK,
                ServiceName_AX: dichvukythuat?.ServiceName_AX,
                DepartmentCode: DepartmentCodeList?.map((item) => {
                    return item;
                }),
               // DepartmentCode: dichvukythuat?.DepartmentCode,
              
                Hide: dichvukythuat?.Hide,
                ServiceCategoryID: dichvukythuat?.ServiceCategoryID,
                STT: dichvukythuat?.STT,
                UnitID: dichvukythuat?.UnitID,
                IsSampleTransfer: dichvukythuat?.IsSampleTransfer,
                Note: dichvukythuat?.Note,
                IsAssure: dichvukythuat?.IsAssure,
                GroupPrinterID: dichvukythuat?.GroupPrinterID,
                MaNhomTT_BH: dichvukythuat?.MaNhomTT_BH,
                MaKhoa_BH: dichvukythuat?.MaKhoa_BH,
                HandPoint: dichvukythuat?.HandPoint,
                HandPoint_App: dichvukythuat?.HandPoint_App,
                IsItemAttach: dichvukythuat?.IsItemAttach,
                IsWarningNote: dichvukythuat?.IsWarningNote,
                IsResultCLS: dichvukythuat?.IsResultCLS,
                IsPTTT_XM5: dichvukythuat?.IsPTTT_XM5,
                MachineID: dichvukythuat?.MachineID,
                TimeFinished: dichvukythuat?.TimeFinished,
                Rate_BHYT: dichvukythuat?.Rate_BHYT,
                VAT: dichvukythuat?.VAT,
                IsEKip_xml: dichvukythuat?.IsEKip_xml,
                EmployeeID: dichvukythuat?.EmployeeID,
                ServiceGroupID: category ? category.ServiceGroupID : undefined,
            });
        }
    }, [dichvukythuat, form,LoaiVienPhi]);

    return (
        <>
            <Modal
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo dịch vụ kỹ thuật
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={"100vw"}
                closable={false}
                forceRender
                maskClosable={false}
                className="DetailDVKT"
            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form}>
                        <div>
                            <div className="row dmdot dmvp-1 ">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input"></div>}
                                    className="col-md-4 m-0 "
                                    name="ServiceID"
                                    hidden={true}

                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-s">Nhóm viện phí :</div>}
                                    className="col-md-6 m-0"
                                    name="ServiceGroupID"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng nhập nhóm viện phí !",
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        onChange={handleProvinceChange}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={NhomVienPhi.map((item) => ({
                                            value: item.ServiceGroupID,
                                            label: item.ServiceGroupName
                                        }))}

                                    />


                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-s">Loại viện phí :</div>}
                                    className="col-md-4 m-0"
                                    name="ServiceCategoryID"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng nhập loại viện phí !",
                                        },
                                    ]}
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        placeholder="Chọn"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return (
                                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );

                                        }}
                                        options={
                                            filteredCategories.map((item) => ({
                                                value: item.ServiceCategoryID,
                                                label: item.ServiceCategoryName
                                            }))
                                        }
                                    >
                                        {/*loainhom()*/}

                                    </Select>

                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã dịch vụ :</div>}
                                    className="col-md-2 m-0 "
                                    name="ServiceCode_PK"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Mã dịch vụ!",
                                        },
                                    ]}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row dmdot dmvp-1">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên dịch vụ :</div>}
                                    className="col-md-6 m-0"
                                    name="ServiceName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng nhập tên !",
                                        },
                                    ]}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên DVKT thu gọn :</div>}
                                    className="col-md-6 m-0"
                                    name="ServiceName_PK"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng nhập tên thu gọn !",
                                        },
                                    ]}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row vienphi-form-row dmdot dmvp-1">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã DVKT AX :</div>}
                                    className="col-md-3 m-0 m-0"
                                    name="ServiceCode_AX"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên DVKT AX :</div>}
                                    className="col-md-9 m-0"
                                    name="ServiceName_AX"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row vienphi-form-row dmdot dmvp-1">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã KPBHYT :</div>}
                                    className="col-md-3 m-0 m-0"
                                    name="MaKhoa_BH"
                                >
                                    <Select
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={KhoaBHYT.map((item) => ({
                                            value: item.MaKhoa,
                                            label: item.TenKhoa
                                        }))}

                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-s">Phòng thực hiện :</div>}
                                    className="col-md-9 m-0"
                                    name="DepartmentCode"
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ textAlign: 'left' }}
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"

                                       
                                        options={KhoaPhong.map((item) => ({
                                            value: item.DepartmentID,
                                            label: item.DepartmentName
                                        }))}
                                    />

                                    {/*<Select
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={KhoaPhong.map((item) => ({
                                            value: item.DepartmentCode,
                                            label: item.DepartmentName
                                        }))}

                                    />*/}
                                </Form.Item>

                            </div>
                            <div className="row dmdot dmvp-1">
                                <Form.Item
                                    label={<div className="form-input-label lablename-s">ĐVT :</div>}
                                    className="col-md-3 m-0"
                                    name="UnitID"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng nhập ĐVT !",
                                        },
                                    ]}

                                >
                                    <Select
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={DonViTinh.map((item) => ({
                                            value: item.UnitID,
                                            label: item.UnitName
                                        }))}

                                    />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <div className="form-input-label lablename lablename-9324_A">M.N BH 9324_AX :</div>
                                    }
                                    className="col-md-5 m-0"
                                    name="MaNhomTT_BH"
                                >
                                    <Select
                                        showSearch
                                        className="w-100"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").includes(input)
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                    (optionB?.label ?? "").toLowerCase()
                                                )
                                        }
                                        options={NhomBHYT.map((item) => ({
                                            label: item.ServiceGroupBHYTName,
                                            value: item.ServiceGroupBHYTID,
                                        }))}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-s">Nhóm in :</div>}
                                    className="col-md-4 m-0"
                                    name="GroupPrinterID"
                                >
                                    <Select
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={NhomIn.map((item) => ({
                                            value: item.GroupPrinterID,
                                            label: item.GroupPrinterName
                                        }))}

                                    />
                                </Form.Item>

                            </div>
                            <div className="row vienphi-form-row dmdot dmvp-1">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">STT :</div>}
                                    className="col-md-3 m-0"
                                    name="STT"
                                >
                                    <InputNumber className="form-control inputdm" />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Máy Th.Hiện :</div>}
                                    className="col-md-5 m-0"
                                    name="MachineID"

                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tỷ lệ BHYT :</div>}
                                    className="col-md-4 m-0"
                                    name="Rate_BHYT"

                                >
                                    <InputNumber className="form-control inputdm" />
                                </Form.Item>


                            </div>
                            <div className="row vienphi-form-row dmdot dmvp-1">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Th.Gian H.Thành :</div>}
                                    className="col-md-3 m-0"
                                    name="TimeFinished"
                                >
                                    <InputNumber className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">VAT Xuất hóa đơn :</div>}
                                    className="col-md-3 m-0 "
                                    name="VAT"
                                >
                                    <InputNumber className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Gửi mẫu :</div>}
                                    className="text-center col-md-1 m-0"
                                    name="IsSampleTransfer" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeGuiMau} value={IsSampleTransfer} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Giấy CĐ :</div>}
                                    className="text-center w-8 m-0"
                                    name="IsAssure" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeCamDoan} value={IsAssure} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Hội chuẩn :</div>}
                                    className="text-center w-10 m-0"
                                    name="IsWarningNote" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeHoiChuan} value={IsWarningNote} />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">C.Định hẹn TK :</div>}
                                    className="text-center w-10 m-0"
                                    name="HandPoint_App" valuePropName="checked"

                                >
                                    <Checkbox onChange={onChangeHenTaiKham} value={HandPoint_App} />
                                </Form.Item>{" "}

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">K.Quả CLS :</div>}
                                    className="text-center w-8 m-0"
                                    name="IsResultCLS" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeKQCLS} value={IsResultCLS} />
                                </Form.Item>{" "}



                            </div>
                            <div className="row vienphi-form-row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">PTTT :</div>}
                                    className="text-center w-8 m-0"
                                    name="IsPTTT_XM5" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangePTTT} value={IsPTTT_XM5} />
                                </Form.Item>{" "}
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ekip_xml3 :</div>}
                                    className="text-center w-10 m-0"
                                    name="IsEKip_xml" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeEkip} value={IsEKip_xml} />
                                </Form.Item>{" "}
                                <Form.Item
                                    label={
                                        <div className="form-input-label lablename-input">
                                            Thêm thuốc :
                                        </div>
                                    }
                                    className="text-center w-8"
                                    name="IsItemAttach" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeThemThuoc} value={IsItemAttach} />
                                </Form.Item>{" "}
                                <Form.Item
                                    label={
                                        <div className="form-input-label lablename-input cdvltl">
                                            C.Định trước (VLTL,RHM) :
                                        </div>
                                    }
                                    className="text-center col-md-2"
                                    name="HandPoint" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeChiDinhTruoc} value={HandPoint} />
                                </Form.Item>{" "}


                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Khóa :</div>}
                                    className="text-center w-8 m-0 pa"
                                    name="Hide" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeAnHien} value={CheckHide} />
                                </Form.Item>

                            </div>
                            <div className="row vienphi-form-row-last dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ghi chú :</div>}
                                    name="Note"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                        </div>
                        <Form.Item className="text-center">
                            <hr />
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default DetailDVKT;
