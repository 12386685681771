import { Table, Button, Form, Input, Radio } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowsRotate
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import "./danhsachleft.scss";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from 'moment';
import { useDispatch } from "react-redux";
import khambenhAPI from '../../../../services/khambenh/khambenhAPI'
import { setDataLayMotBenhNhan } from "../../../../slices/dataKhamBenh";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);


function Danhsachleft(props) {
    const { setStatusDistable, StatusDistable, StatusDMLeft, setStatusDMLeft } = props;
    const [ListDSBenhNhan, setListDSBenhNhan] = useState([]);
    const [TuNgay, setTuNgay] = useState(null);
    const [DenNgay, setDenNgay] = useState(null);
    const [Status, setStatus] = useState(0);
    const [Search, setSearch] = useState('');
    const dispatch = useDispatch();
    const savedRoom = JSON.parse(sessionStorage.getItem("selectedRoomBacSi"));

    const getAllBenhNhan = async () => {
        try {
            const data = await khambenhAPI.getAllDSBNKham({
                DateFrom: TuNgay === null ? moment(new Date()).format('YYYY-MM-DD') : dayjs(TuNgay).utc().format('YYYY-MM-DD'),
                DateTo: DenNgay === null ? moment(new Date()).format('YYYY-MM-DD') : dayjs(DenNgay).utc().format('YYYY-MM-DD'),
                Status: Status,
                DepartmentID_TH: savedRoom.DepartmentID,
                EmployeeID_TH_Doctor: savedRoom.RowID
            });
            setListDSBenhNhan(data.data);
        } catch (err) { }
    };
    const removeVietnameseTones = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
    const FilterCongKham = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setSearch(searchValue);
        if (searchValue === "") {
            getAllBenhNhan();
            return;
        }
        const filterBySearch = ListDSBenhNhan.filter((item) =>
            removeVietnameseTones(item.PatientName || "").toLowerCase().includes(removeVietnameseTones(searchValue)) ||
            item.PatientCode.includes(e.target.value)
        );
        setListDSBenhNhan(filterBySearch);

    };

    const handleChange = (e) => {
        setStatus(e.target.value);
    };
    const handleDoubleClick = (record) => {
        dispatch(setDataLayMotBenhNhan(record));
        setStatusDistable(false);
        setStatusDMLeft(true);

    };
    useEffect(() => {
        Promise.all([getAllBenhNhan()])

    }, [TuNgay, DenNgay, Status])


    const column0 = [
        {
            title: "STT",
            dataIndex: "STT",
            render: (STT) => <div style={{ width: "50px" }}> {STT} </div>,
        },
        {
            title: "Mã BN",
            dataIndex: "PatientCode",
            render: (PatientCode) => <div style={{ width: "90px" }}> {PatientCode} </div>,
        },
        {
            title: "Họ và tên",
            dataIndex: "PatientName",
            render: (PatientName) => <div style={{ width: "200px" }}> {PatientName} </div>,
        },
        {
            title: "Khoa/Phòng",
            dataIndex: "DepartmentName",
            render: (DepartmentName) => <div style={{ width: "200px" }}> {DepartmentName} </div>,
        },
        {
            title: "Giới tính",
            dataIndex: "PatientGenderName",
            align: "center",
            render: (PatientGenderName) => <div style={{ width: "100px" }}> {PatientGenderName} </div>,
        },
        {
            title: "Năm sinh",
            dataIndex: "PatientBirthyear",
            render: (PatientBirthyear) => <div style={{ width: "80px" }}> {PatientBirthyear} </div>,
        },
        {
            title: "Khám",
            dataIndex: "ServiceName",
            render: (ServiceName) => <div style={{ width: "200px" }}> {ServiceName} </div>,
        },
        {
            title: "Ngày",
            dataIndex: "DateInto",
            render: (DateInto) => <div style={{ width: "80px" }}> {dayjs(DateInto).utc().format('DD/MM/YYYY')} </div>,
        },

        {
            title: "Đối tượng",
            dataIndex: "ObjectName",
            align: "center",
            render: (ObjectName) => <div style={{ width: "100px" }}> {ObjectName} </div>,
        },
    ];
    const column1 = [
        {
            title: "",
            dataIndex: "",
            fixed: "left",
            // render: (TDV) => <div style={{ width: "200px" }}> {TDV} </div>,
        },
        {
            title: "Ngày khám",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "ICD-10",
            dataIndex: "MADVKT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "CD ICD10",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "CĐ Sơ bộ",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "STT",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "P.Thực hiện",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "Ghi chú",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "Th.Hiện",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "TT",
            dataIndex: "MADVKT",
            align: "center",
        }
    ];
    return (
        <div className="fixed dsbenhnhan-left">
            <div className="text-muted">
                <Form className="mx-1 search-khambenh">
                    <div className="form-row">
                        <Radio.Group onChange={handleChange} value={Status} disabled={StatusDMLeft}
                            className="d-flex align-items-center justify-content-around">
                            <Radio value={0} className="form-input-label">
                                Chờ khám
                            </Radio>
                            <Radio value={1} className="form-input-label">
                                Đã khám
                            </Radio>
                            <Radio value={2} className="form-input-label">
                                Kết thúc
                            </Radio>
                        </Radio.Group>
                    </div>
                    <div className="form-row chon-ngay">
                        <div className="lable-date">Ngày:</div>
                        <div className="date-input" >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker disabled={StatusDMLeft}
                                    value={TuNgay === null ? dayjs(new Date()) : dayjs(TuNgay)}
                                    format="DD/MM/YYYY"
                                    onChange={(newValue) => setTuNgay(newValue)}
                                    slotProps={{ textField: { fullWidth: true } }}

                                />
                            </LocalizationProvider>
                            <div className="khoangcach">~</div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker disabled={StatusDMLeft}
                                    value={DenNgay === null ? dayjs(new Date()) : dayjs(DenNgay)}
                                    format="DD/MM/YYYY"
                                    onChange={(newValue) => setDenNgay(newValue)}
                                    slotProps={{ textField: { fullWidth: true } }}

                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="form-row searh-input">
                        <Form.Item className="txt-input">
                            <Input type="search" className="form-control"
                                value={Search} disabled={StatusDMLeft}
                                onChange={(e) => FilterCongKham(e)}
                            />
                        </Form.Item>
                        <Form.Item className="btn-submit">
                            <Button className="danhsachleft-btn bg m-0" disabled={StatusDMLeft}>
                                <FontAwesomeIcon icon={faArrowsRotate} />
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
                <div className="m-1" style={StatusDMLeft ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                    <Table
                        disabled={StatusDMLeft}
                        columns={column0}
                        dataSource={ListDSBenhNhan}
                        size="small"
                        pagination={false}
                        bordered
                        footer={() => 'Tổng :  ' + ListDSBenhNhan.length}
                        className="custom-table"
                        scroll={{ x: "max-content", y: 285 }}
                        rowClassName={() => 'custom-row'}
                        locale={{
                            emptyText: (
                                <div style={{ display: "inline-block", fontSize: '12px', width: '100%', alignItems: "center", justifyContent: "center" }}>
                                    Không có dữ liệu
                                </div>
                            ),
                        }}
                        tableLayout="fixed"
                        onRow={(record) => ({
                            onDoubleClick: () => handleDoubleClick(record),
                        })}


                    />
                </div>
                <div className="fw-bold color-text mx-2">Lịch sử khám bệnh</div>
                <div className="m-1">
                    {/* <Table
                        columns={column1}
                        dataSource={ds}
                        scroll={{ x: true, y: 190 }}
                        size="small"
                        bordered={true}
                        pagination={false}
                    />*/}
                </div>
            </div>
        </div>

    );
}

export default Danhsachleft;
