import { createSlice } from "@reduxjs/toolkit";

export const dataKhamBenhSlice = createSlice({
    name: "dataKhamBenh",
    initialState: {
        rowbenhnhan:null,
    },
    reducers: {
        setDataLayMotBenhNhan: (state, action) => {
            state.rowbenhnhan = action.payload;
        },
    },
});
export const {
    setDataLayMotBenhNhan
} = dataKhamBenhSlice.actions;
export default dataKhamBenhSlice.reducer;