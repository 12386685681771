import CTScanner from "../pages/Canlamsan/CTScanner";
import Dientim from "../pages/Canlamsan/Dientim";
import Domatdoxuong from "../pages/Canlamsan/Domatdoxuong";
import MRI from "../pages/Canlamsan/MRI";
import Noisoi from "../pages/Canlamsan/Noisoi";
import Canlamsan from "../pages/Canlamsan/Sieuam";
import Xquang from "../pages/Canlamsan/XQuang";
import MauMoTa from "../pages/Canlamsan/DanhMuc/MauMoTa";
import KhaiBaoSoLuongFilm from "../pages/Canlamsan/DanhMuc/SoLuongFilm/List";

import Duoc from "../pages/Duoc";
import KeToaBanLe from "../pages/Duoc/NhaThuoc/KeToaBanLe";
import KeToaVaXuatPhieuThu from "../pages/Duoc/NhaThuoc/KeToaVaXuatPhieuThu";
import NhanHoanTraToaNhaThuoc from "../pages/Duoc/NhaThuoc/NhanHoanTraToaNhaThuoc";
import DonViTinhDuoc from "../pages/Duoc/DanhMuc/DonViTinh/List";
import DuongDung from "../pages/Duoc/DanhMuc/DuongDung/List";
import CachDung from "../pages/Duoc/DanhMuc/CachDung/List";
import DanhMucThuocThauBHYT from "../pages/Duoc/DanhMuc/DanhMucThuocThauBHYT/List";
import HangSanXuat from "../pages/Duoc/DanhMuc/HangSanXuat/List";
import KhaiBaoNhomIn from "../pages/Duoc/DanhMuc/KhaiBaoNhomIn/List";
import Kho from "../pages/Duoc/DanhMuc/Kho/List";
import NhaCungCap from "../pages/Duoc/DanhMuc/NhaCungCap/List";
import NuocSanXuat from "../pages/Duoc/DanhMuc/NuocSanXuat/List";
import PhanLoai from "../pages/Duoc/DanhMuc/PhanLoai/List";
import PhanNhom from "../pages/Duoc/DanhMuc/PhanNhom/List";
import ThuocVTYT from "../pages/Duoc/DanhMuc/ThuocVTYT/List";
import ToaMau from "../pages/Duoc/DanhMuc/ToaMau/List";
import NhapKho from "../pages/Duoc/QuanLyKho/NhapKho";
import HoanTraKho from "../pages/Duoc/QuanLyKho/HoanTraKho";
import HoanTraNhaCungCap from "../pages/Duoc/QuanLyKho/HoanTraNhaCungCap";
import XuatHaoPhiKhoaPhong from "../pages/Duoc/QuanLyKho/XuatHaoPhiKhoaPhong";
import DieuChinhSoLuongKho from "../pages/Duoc/QuanLyKho/DieuChinhSoLuongKho";
import XemTonKhaDung from "../pages/Duoc/QuanLyKho/XemTonKhaDung";
import DuTruPhieuLinhThuoc from "../pages/Duoc/QuanLyKho/DuTruPhieuLinhThuoc";
import DuTruDuyetPhieuLinh from "../pages/Duoc/QuanLyKho/DuTruDuyetPhieuLinh";
import CapNhatPhieuNhapKho from "../pages/Duoc/CapNhatPhieuNhap/CapNhatPhieuNhapKho";
import CapNhatLoHanDung from "../pages/Duoc/CapNhatPhieuNhap/CapNhatLoHanDung";
import DuyetToaThuoc from "../pages/Duoc/DuyetCapPhatThuoc/DuyetToaThuoc";
import DanhDauDonThuoc from "../pages/Duoc/DuyetCapPhatThuoc/DanhDauDonThuoc";
import DanhSachToaThuocDaDuyet from "../pages/Duoc/DuyetCapPhatThuoc/DanhSachToaThuocDaDuyet";


import Home from "../pages/Home";
import HSBANgoaitruKB from "../pages/KhambenhHSBA/HSBANgoaitruKB";
import Phieukhambenh from "../pages/KhambenhHSBA/Phieukhambenh";
import LoiDan from "../pages/KhambenhHSBA/LoiDan/List";
import ListTrieuChungBenh from "../pages/KhambenhHSBA/TrieuChungBenh/List";
import ListXuatThuocTuTrucVTTH from "../pages/KhambenhHSBA/XuatThuocTuTruc_VTTH/List";
import HoSoBenhAnYHCT from "../pages/KhambenhHSBA/HoSoBenhAnYHCT";
import DieuChinhHSBANgoaiTru from "../pages/KhambenhHSBA/DieuChinhHSBANgoaiTru/List";

import Khamsuckhoe from "../pages/KhamSucKhoe";
import KhaiBaoHopDongGoiKham from "../pages/KhamSucKhoe/KhaiBaoHopDongGoiKham";
import InTraKetQuaHSKSK from "../pages/KhamSucKhoe/InTraKetQuaHSKSK";
import KhaiBaoChucVuNhanVien from "../pages/KhamSucKhoe/DanhMuc/KhaiBaoChucVuNhanVien/List";
import KhaiBaoPhongBan from "../pages/KhamSucKhoe/DanhMuc/KhaiBaoPhongBan/List";
import KhaiBaoGoiKhamSK from "../pages/KhamSucKhoe/DanhMuc/KhaiBaoGoiKhamSK";
import DSNhanVienCLS from "../pages/KhamSucKhoe/BaoCao/DSNhanVienCLS";
import DSNhanVienKSKTongQuat from "../pages/KhamSucKhoe/BaoCao/DSNhanVienKSKTongQuat";
import DSNhanVienChuaDuyet from "../pages/KhamSucKhoe/BaoCao/DSNhanVienChuaDuyet";

import LoginPage from "../pages/Login";
import Phauthuatthuthuat from "../pages/Phauthuatthuthuat";

import KhaiBaoTTPTTT from "../pages/Phauthuatthuthuat/DanhMuc/KhaiBaoTTPTTT";
import KhaiBaoEkipPTTT from "../pages/Phauthuatthuthuat/DanhMuc/KhaiBaoEkipPTTT/List";

import Khaibaouser from "../pages/Taikhoan/Khaibaouser/List";
import Phanquyen from "../pages/Taikhoan/Phanquyen";
import Tiepnhan from "../pages/Tiepnhan";
import VLTL_YHCT from "../pages/VLTL_YHCT";
import VLTL from "../pages/VLTL_YHCT/VLTL";
import KhaibaoDVKTbatbuoccoketquakhiKQđieutrituvong from "../pages/Vienphi/Danhmuc/KhaibaoDVKTbatbuoccoketquakhiKQđieutrituvong/List";
import KhaibaoDVKTkhongchophepchidinhdongthoi from "../pages/Vienphi/Danhmuc/KhaibaoDVKTkhongchophepchidinhdongthoi/List";
import KhaibaoDVKTthuchienphauthuatxuatXML5 from "../pages/Vienphi/Danhmuc/KhaibaoDVKTthuchienphauthuatxuatXML5/List";
import Khaibaodichvucanhcaovietghichutrongchidinh from "../pages/Vienphi/Danhmuc/Khaibaodichvucanhcaovietghichutrongchidinh/List";
import Khaibaodichvukythuat from "../pages/Vienphi/Danhmuc/Khaibaodichvukythuat/List";
import Khaibaodonvitinh from "../pages/Vienphi/Danhmuc/Khaibaodonvitinh/List";
import Khaibaogiadichvukythuat from "../pages/Vienphi/Danhmuc/Khaibaogiadichvukythuat/List";
import KhaibaogioihanthoigianchidinhDVKT from "../pages/Vienphi/Danhmuc/KhaibaogioihanthoigianchiđinhVKT/List";
import GoiDVKTVienPhi from "../pages/Vienphi/Danhmuc/Khaibaogoidichvukythuat/List";
import Khaibaoloaivienphi from "../pages/Vienphi/Danhmuc/Khaibaoloaivienphi/List";
import KhaibaonhomchuyenkhoaBYT from "../pages/Vienphi/Danhmuc/KhaibaonhomchuyenkhoaBYT/List";
import Khaibaonhomin from "../pages/Vienphi/Danhmuc/Khaibaonhomin/List";
import Khaibaonhomvienphipk from "../pages/Vienphi/Danhmuc/KhaibaonhomvienphiPk/List";
import Khaibaoquyenso from "../pages/Vienphi/Danhmuc/Khaibaoquyenso/List";
import MapdanhmucDVKTvoimaythuchiencanlamsan from "../pages/Vienphi/Danhmuc/MapdanhmucDVKTvoimaythuchiencanlamsan/List";
import Nhomvienphihethong from "../pages/Vienphi/Danhmuc/Nhomvienphihethong/List";
import Nhantrathebhyt from "../pages/Vienphi/NhantratheBHYT";
import Phieuthutamung from "../pages/Vienphi/Phieuthutamung";
import Phieuthutien from "../pages/Vienphi/Phieuthutien";
import XetNghiem from "../pages/XetNghiem";
import KhaiBaoKetQuaXetNghiem from "../pages/XetNghiem/DanhMuc/KhaiBaoKetQuaXetNghiem/List";
import KhaiBaoTenThongSo from "../pages/XetNghiem/DanhMuc/KhaiBaoTenThongSo/List";
import KhaiBaoDonViDo from "../pages/XetNghiem/DanhMuc/KhaiBaoDonViDo/List";
import KhaiBaoOngDungMauXN from "../pages/XetNghiem/DanhMuc/KhaiBaoOngDungMauXN/List";
import KhaiBaoHinhThucTraKetQua from "../pages/XetNghiem/DanhMuc/KhaiBaoHinhThucTraKetQua";
import KhaiBaoBoThongSoXNDVKT from "../pages/XetNghiem/DanhMuc/KhaiBaoBoThongSoXNDVKT";
import MapOngDungMauDVKT from "../pages/XetNghiem/DanhMuc/MapOngDungMauDVKT";

import TiemChung from "../pages/TiemChung/TiemChung";
import KhaiBaoTiemChung from "../pages/TiemChung/KhaiBaoTiemChung";

import KhoaPhong from "../pages/TienIch/KhoaPhong/List";
import NguoiGioiThieu from "../pages/TienIch/NguoiGioiThieu/List";
// import NotFound from "../pages/NotFound";
const PageWeb = [
    //Home
    {
        path: "/",
        page: Home,
        isMainLayout: true,
       
        roles: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35, 36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70, 71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116, 117]
    },
    /* {
         path: "/",
         page: LoginPage,
         isMainLayout: false,
     },*/
    //Tiếp nhận
    {
        path: "/tiepnhan",
        page: Tiepnhan,
        isMainLayout: true,
        roles:[2]
    },
    //Khám bệnh
   {
        path: "/khambenh/phieukhambenh",
        page: Phieukhambenh,
        isMainLayout: true,
        roles:[5]
    },
    {
        path: "/khambenh/HSBANgoaitruKB",
        page: HSBANgoaitruKB,
        isMainLayout: true,
        roles:[6]
    },
    {
        path: "/khambenh/ngoaitruYHCT",
        page: HoSoBenhAnYHCT,
        isMainLayout: true,
        roles:[10]
    },

    {
        path: "/khambenh/dmloidan",
        page: LoiDan,
        isMainLayout: true,
        roles:[15]
    },
    {
        path: "/khambenh/dmtrieuchungbenh",
        page: ListTrieuChungBenh,
        isMainLayout: true,
        roles:[14]
    },
    {
        path: "/khambenh/xuatthuoctutrucvtth",
        page: ListXuatThuocTuTrucVTTH,
        isMainLayout: true,
        roles:[13]
    },
    {
        path: "/khambenh/dieuchinhhsbangoaitru",
        page: DieuChinhHSBANgoaiTru,
        isMainLayout: true,
        roles:[12]
    },
    
    

    //Viện phí
    {
        path: "/vienphi/phieuthutien",
        page: Phieuthutien,
        isMainLayout: true,
        roles:[17]
    },
    {
        path: "/vienphi/phieuthutamung",
        page: Phieuthutamung,
        isMainLayout: true,
        roles:[18]
        
    },
    {
        path: "/vienphi/nhantrathebhyt",
        page: Nhantrathebhyt,
        isMainLayout: true,
        roles:[19]
    },
    {
        path: "/vienphi/dmnhomvienphihethong",
        page: Nhomvienphihethong,
        isMainLayout: true,
        roles:[20]
    },
    {
        path: "/vienphi/dmkhaibaonhomchuyenkhoaBYT",
        page: KhaibaonhomchuyenkhoaBYT,
        isMainLayout: true,
        roles:[20]
    },
    {
        path: "/vienphi/dmkhaibaonhomvienphipk",
        page: Khaibaonhomvienphipk,
        isMainLayout: true,
        roles:[21]
    },
    {
        path: "/vienphi/dmkhaibaonhomin",
        page: Khaibaonhomin,
        isMainLayout: true,
        roles:[22]
    },
    {
        path: "/vienphi/dmkhaibaoloaivienphi",
        page: Khaibaoloaivienphi,
        isMainLayout: true,
        roles:[23]
    },
    {
        path: "/vienphi/dmkhaibaodichvukythuat",
        page: Khaibaodichvukythuat,
        isMainLayout: true,
        roles:[25]
    },
    {
        path: "/vienphi/dmkhaibaogiadichvukythuat",
        page: Khaibaogiadichvukythuat,
        isMainLayout: true,
        roles:[26]
    },
    {
        path: "/vienphi/dmkhaibaogoidichvukythuat",
        page: GoiDVKTVienPhi,
        isMainLayout: true,
        roles:[27]
    },
    {
        path: "/vienphi/dmkhaibaodonvitinh",
        page: Khaibaodonvitinh,
        isMainLayout: true,
        roles:[24]
    },
    {
        path: "/vienphi/dmkhaibaoquyenso",
        page: Khaibaoquyenso,
        isMainLayout: true,
        roles:[28]
    },
    {
        path: "/vienphi/dmkhaibaogioihanthoigianchidinhDVKT",
        page: KhaibaogioihanthoigianchidinhDVKT,
        isMainLayout: true,
        roles:[29]
    },
    {
        path: "/vienphi/dmkhaibaoDVKTkhongchophepchidinhdongthoi",
        page: KhaibaoDVKTkhongchophepchidinhdongthoi,
        isMainLayout: true,
        roles:[30]
    },
    {
        path: "/vienphi/dmkhaibaodichvucanhcaovietghichutrongchidinh",
        page: Khaibaodichvucanhcaovietghichutrongchidinh,
        isMainLayout: true,
        roles:[31]
    },
    {
        path: "/vienphi/dmkhaibaoDVKTbatbuoccoketquakhiKQđieutrituvong",
        page: KhaibaoDVKTbatbuoccoketquakhiKQđieutrituvong,
        isMainLayout: true,
        roles:[32]
    },
    {
        path: "/vienphi/dmmapdanhmucDVKTvoimaythuchiencanlamsan",
        page: MapdanhmucDVKTvoimaythuchiencanlamsan,
        isMainLayout: true,
        roles:[33]
    },
    {
        path: "/vienphi/dmkhaibaoDVKTthuchienphauthuatxuatXML5",
        page: KhaibaoDVKTthuchienphauthuatxuatXML5,
        isMainLayout: true,
        roles:[34]
    },
    //Phẩu thuật thủ thuật
    {
        path: "/pttt",
        page: Phauthuatthuthuat,
        isMainLayout: true,
        roles:[36]
    },
    {
        path: "/pttt/khaibaomaupttt",
        page: KhaiBaoTTPTTT,
        isMainLayout: true,
        roles:[37]
    },
    {
        path: "/pttt/khaibaoekippttt",
        page: KhaiBaoEkipPTTT,
        isMainLayout: true,
        roles:[38]
    },
    

    //Cận lâm sàn
    {
        path: "/canlamsan/sieuam",
        page: Canlamsan,
        isMainLayout: true,
        roles:[40]
    },
    {
        path: "/canlamsan/noisoi",
        page: Noisoi,
        isMainLayout: true,
        roles:[41]
    },
    {
        path: "/canlamsan/xquang",
        page: Xquang,
        isMainLayout: true,
        roles:[42]
    },
    {
        path: "/canlamsan/mri",
        page: MRI,
        isMainLayout: true,
        roles:[44]
    },
    {
        path: "/canlamsan/dientimhohapky",
        page: Dientim,
        isMainLayout: true,
        roles:[45]

    },
    {
        path: "/canlamsan/ctscanner",
        page: CTScanner,
        isMainLayout: true,
        roles:[43]
    },
    {
        path: "/canlamsan/domatdoxuong",
        page: Domatdoxuong,
        isMainLayout: true,
        roles:[46]
    },
    {
        path: "/canlamsan/kbmaumota",
        page: MauMoTa,
        isMainLayout: true,
        roles:[47]
    },
    {
        path: "/canlamsan/kbsoluongfilm",
        page: KhaiBaoSoLuongFilm,
        isMainLayout: true,
        roles:[48]
    },
    
    
    //Xét nghiệm
    {
        path: "/xetnghiem",
        page: XetNghiem,
        isMainLayout: true,
        roles:[50]
    },
    {
        path: "/xetnghiem/dmkbtentrakq",
        page: KhaiBaoKetQuaXetNghiem,
        isMainLayout: true,
        roles:[54]
    },
    {
        path: "/xetnghiem/dmkbtenthongso",
        page: KhaiBaoTenThongSo,
        isMainLayout: true,
        roles:[55]
    },
    {
        path: "/xetnghiem/dmkbtendonvido",
        page: KhaiBaoDonViDo,
        isMainLayout: true,
        roles:[56]
    },
    {
        path: "/xetnghiem/dmkbhinhthuctrakq",
        page: KhaiBaoHinhThucTraKetQua,
        isMainLayout: true,
        roles:[57]
    },
    {
        path: "/xetnghiem/dmkbbothongsotrakqdvkt",
        page: KhaiBaoBoThongSoXNDVKT,
        isMainLayout: true,
        roles:[58]
    },
    {
        path: "/xetnghiem/dmkbongdungmauxn",
        page: KhaiBaoOngDungMauXN,
        isMainLayout: true,
        roles:[59]
    },
    {
        path: "/xetnghiem/dmmapongdungmaudvkt",
        page: MapOngDungMauDVKT,
        isMainLayout: true,
        roles:[60]
    },
    //VLTL_YHCT
    {
        path: "/vltl-yhct/chamcuu",
        page: VLTL_YHCT,
        isMainLayout: true,
        roles:[67]
    },
    {
        path: "/vltl-yhct/thuchien",
        page: VLTL,
        isMainLayout: true,
        roles:[66]
    },
    //Khám sức khỏe
    {
        path: "/khamsuckhoe",
        page: Khamsuckhoe,
        isMainLayout: true,
        //roles:[55]
    },
    //Khám sức khỏe
    {
        path: "/khamsuckhoe/khaibaoHDgoikham",
        page: KhaiBaoHopDongGoiKham,
        isMainLayout: true,
        roles:[69]
    },
    {
        path: "/khamsuckhoe/intrakqhsksk",
        page: InTraKetQuaHSKSK,
        isMainLayout: true,
        roles:[71]
    },
    {
        path: "/khamsuckhoe/dmkbchucvunhanvien",
        page: KhaiBaoChucVuNhanVien,
        isMainLayout: true,
        roles:[72]
    },
    {
        path: "/khamsuckhoe/dmkbphongbancty",
        page: KhaiBaoPhongBan,
        isMainLayout: true,
        roles:[73]
    },
    {
        path: "/khamsuckhoe/dmkbgoikhamskcty",
        page: KhaiBaoGoiKhamSK,
        isMainLayout: true,
        roles:[74]
    },
    {
        path: "/khamsuckhoe/bcdsnhanviencdthemcls",
        page: DSNhanVienCLS,
        isMainLayout: true,
        roles:[76]
    },
    {
        path: "/khamsuckhoe/bcdsnhanviendakhamsk",
        page: DSNhanVienKSKTongQuat,
        isMainLayout: true,
        roles:[77]
    },
    {
        path: "/khamsuckhoe/bcdsnhanvienchuaduyethskham",
        page: DSNhanVienChuaDuyet,
        isMainLayout: true,
        roles:[78]
    },

    
    

    
    //Dược
    {
        path: "/duoc",
        page: Duoc,
        isMainLayout: true,
        //roles:[55]
    },
    {
        path: "/duoc/nhathuoc/ketoabanle",
        page: KeToaBanLe,
        isMainLayout: true,
        roles:[82]
    },
    {
        path: "/duoc/nhathuoc/ketoavaxuatphieuthu",
        page: KeToaVaXuatPhieuThu,
        isMainLayout: true,
        roles:[84]
    },
    {
        path: "/duoc/nhathuoc/nhanhoantratoabanlenhathuoc",
        page: NhanHoanTraToaNhaThuoc,
        isMainLayout: true,
        roles:[85]
    },
    {
        path: "/duoc/danhmuc/donvitinh",
        page: DonViTinhDuoc,
        isMainLayout: true,
        roles:[99]
    },
    {
        path: "/duoc/danhmuc/duongdung",
        page: DuongDung,
        isMainLayout: true,
        roles:[100]
    },
    {
        path: "/duoc/danhmuc/cachdung",
        page: CachDung,
        isMainLayout: true,
        roles:[101]
    },
    {
        path: "/duoc/danhmuc/danhmucthuocthau",
        page: DanhMucThuocThauBHYT,
        isMainLayout: true,
        roles:[110]
    },
    {
        path: "/duoc/danhmuc/hangsanxuat",
        page: HangSanXuat,
        isMainLayout: true,
        roles:[102]
    },
    {
        path: "/duoc/danhmuc/khaibaonhomin",
        page: KhaiBaoNhomIn,
        isMainLayout: true,
        roles:[107]
    },
    {
        path: "/duoc/danhmuc/kho",
        page: Kho,
        isMainLayout: true,
        roles:[108]
    },
    {
        path: "/duoc/danhmuc/nhacungcap",
        page: NhaCungCap,
        isMainLayout: true,
        roles:[104]
    },
    {
        path: "/duoc/danhmuc/nuocsanxuat",
        page: NuocSanXuat,
        isMainLayout: true,
        roles:[103]
    },
    {
        path: "/duoc/danhmuc/phanloai",
        page: PhanLoai,
        isMainLayout: true,
        roles:[106]
    },
    {
        path: "/duoc/danhmuc/phannhom",
        page: PhanNhom,
        isMainLayout: true,
        roles:[105]
    },//dfgdgfdg
     {
        path: "/duoc/danhmuc/thuocvattuyte",
        page: ThuocVTYT,
        isMainLayout: true,
        roles:[109]
    },
    {
        path: "/duoc/danhmuc/toamau",
        page: ToaMau,
        isMainLayout: true,
        roles:[111]
    },
    {
        path: "/duoc/quanlykho/nhapkho",
        page: NhapKho,
        isMainLayout: true,
        roles:[89]
    },
    {
        path: "/duoc/quanlykho/hoantrakho",
        page: HoanTraKho,
        isMainLayout: true,
        roles:[90]
    },
 
    {
        path: "/duoc/quanlykho/hoantranhacungcap",
        page: HoanTraNhaCungCap,
        isMainLayout: true,
        roles:[91]
    },
    {
        path: "/duoc/quanlykho/xuathaophikhoaphong",
        page: XuatHaoPhiKhoaPhong,
        isMainLayout: true,
        roles:[94]
    },
    {
        path: "/duoc/quanlykho/dieuchinhsoluongkho",
        page: DieuChinhSoLuongKho,
        isMainLayout: true,
        roles:[95]
    },
    {
        path: "/duoc/quanlykho/xemtonkhadung",
        page: XemTonKhaDung,
        isMainLayout: true,
        roles:[96]
    },
    {
        path: "/duoc/quanlykho/dutrulapphieulinhthuoc",
        page: DuTruPhieuLinhThuoc,
        isMainLayout: true,
        roles:[92]
    },
    {
        path: "/duoc/quanlykho/dutruduyetcapphieulinh",
        page: DuTruDuyetPhieuLinh,
        isMainLayout: true,
        roles:[93]
    },
    {
        path: "/duoc/capnhatphieunhap/phieunhapkho",
        page: CapNhatPhieuNhapKho,
        isMainLayout: true,
        roles:[97]
    },
    {
        path: "/duoc/capnhatphieunhap/lohandung",
        page: CapNhatLoHanDung,
        isMainLayout: true,
        roles:[98]
    },
 
    {
        path: "/duoc/duyetcapphatthuoc/duyettoathuocBHYTDV",
        page: DuyetToaThuoc,
        isMainLayout: true,
        roles:[86]
    },
    {
        path: "/duoc/duyetcapphatthuoc/danhdaudonthuocphat",
        page: DanhDauDonThuoc,
        isMainLayout: true,
        roles:[87]
    },
    {
        path: "/duoc/duyetcapphatthuoc/danhsachtoathuocdaduyet",
        page: DanhSachToaThuocDaDuyet,
        isMainLayout: true,
        roles:[88]
    },
 
    //Tiem chung
    {
        path: "/tiemchung/tiemchungvaccine",
        page: TiemChung,
        isMainLayout: true,
        roles:[63]
    },
    {
        path: "/tiemchung/kblichtiemchung",
        page: KhaiBaoTiemChung,
        isMainLayout: true,
        roles:[64]
    },
    //tien ich
    {
        path: "/tienich/khoaphong",
        page: KhoaPhong,
        isMainLayout: true,
        roles:[116]
    },
    {
        path: "/tienich/nguoigioithieu",
        page: NguoiGioiThieu,
        isMainLayout: true,
        roles:[117]
    },
    
    //Tài khoản
    {
        path: "/taikhoan/khaibaouser",
        page: Khaibaouser,
        isMainLayout: true,
        roles:[113]
    },
    {
        path: "/taikhoan/phanquyen",
        page: Phanquyen,
        isMainLayout: true,
        roles:[114]
    },
    // {
    //     path: "*",
    //     page: NotFound,
    //     isMainLayout: true,
    // }
    
];
export default PageWeb;
