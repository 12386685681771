import { Table, Button, Dropdown, Checkbox, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import {
    faEdit,
    faTrashCan,
    faEye,
    faPenToSquare,
    faPrint,
    faSpinner,
    faFloppyDisk,
    faCopy,
    faPaste
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import "../../khamsuckhoe.scss";
import ds from "../../../../util/data";

function KhaiBaoGoiKhamSK() {
    const [open, setOpen] = useState(false);
    const [ShowInput, setShowInput] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const [select, setSelect] = useState({
        selectedRowKeys: [],
        loading: false,
    });
    const items = [
        {
            key: "1",
            label: "Xem",
            icon: <FontAwesomeIcon icon={faEye} />,
        },
        {
            key: "2",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "3",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const data = [{
        key: '1',
        name: 'John Brown',
        age: 32,
        chosen: true,
        address: 'New York No. 1 Lake Park',
    }, {
        key: '2',
        name: 'Jim Green',
        age: 42,
        chosen: false,
        address: 'London No. 1 Lake Park',
    }, {
        key: '3',
        name: 'Joe Black',
        age: 32,
        chosen: true,
        address: 'Sidney No. 1 Lake Park',
    }, {
        key: '4',
        name: 'Disabled User',
        age: 99,
        chosen: true,
        address: 'Sidney No. 1 Lake Park',
    }];

    const columns = [
        {
            title: "Mã nhóm",
            dataIndex: "key",
            fixed: "left",
            render: (key) => <div style={{ width: "84px" }}> {key} </div>,
        },
        {
            title: "Tên nhóm",
            dataIndex: "name",
            render: (name) => <div style={{ width: "242px" }}> {name} </div>,
        },
        //Table.SELECTION_COLUMN,
        {
            title: "STT",
            dataIndex: "MADVKT",
            render: (record) => (
                <div style={{ width: "42px", textAlign: 'center' }}>
                    <Checkbox />
                </div>
            ),
        },
        {
            title: "Thao tác",
            dataIndex: "",
            align: "center",
            fixed: "right",
            render: (record) => (
                <div className="d-flex justify-content-center" style={{ width: "30px" }}>
                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="left"
                        arrow={{
                            pointAtCenter: true,
                        }}
                    >
                        <Button
                            className="bg-light vienphi-danhmuc-icon-modify"
                            onClick={() => {
                                setActiveModify(!activeModify);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: "10px" }}
                                className="text-dark"
                            />
                        </Button>
                    </Dropdown>
                </div>
            ),
        },
    ];

    const { selectedRowKeys, loading } = select;

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setSelect({
                ...select,
                selectedRowKeys: selectedRowKeys
            });
        }
    };
    const columns1 = [
        {
            title: "Tên",
            dataIndex: "name",
            fixed: "left",
            render: (name) => <div style={{ width: "372px" }}> {name} </div>,
        },
        {
            title: "STT",
            dataIndex: "key",
            render: (key) => <div style={{ width: "39px" }}> {key} </div>,
        },
        //Table.SELECTION_COLUMN,
        {
            title: "STT",
            dataIndex: "MADVKT",
            render: (record) => (
                <div style={{ width: "188px", textAlign: 'center' }}>
                    <Checkbox />
                </div>
            ),
        },
        {
            title: "Thao tác",
            dataIndex: "",
            align: "center",
            fixed: "right",
            render: (record) => (
                <div className="d-flex justify-content-center" style={{ width: "30px" }}>
                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="left"
                        arrow={{
                            pointAtCenter: true,
                        }}
                    >
                        <Button
                            className="bg-light vienphi-danhmuc-icon-modify"
                            onClick={() => {
                                setActiveModify(!activeModify);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: "10px" }}
                                className="text-dark"
                            />
                        </Button>
                    </Dropdown>
                </div>
            ),
        },
    ];

    return (
        <div className="KhaiBaoGoiKhamSK" >
            <div className="title-khaibao">Khai báo gói khám sức khoẻ</div>
            <div className="khaibaogoikhamsk">
                <div className="khaibaogoikhamsk-left">
                    <div className="top-khaibaogoikhamsk">
                        <div className="title">Danh sách gói</div>
                        <ul className="btn-danhsachgoi">
                            <li className="checkanhien">
                                <div><Checkbox /></div>
                                <div className="dsan">DS Ẩn</div>
                            </li>

                            <li><Button><FontAwesomeIcon
                                icon={faSpinner}
                                style={{ fontSize: "15px" }}
                                className="text-dark"
                            /></Button></li>
                            <li><Button><FontAwesomeIcon
                                icon={faPrint}
                                style={{ fontSize: "15px" }}
                                className="text-dark"
                            /></Button></li>
                            <li><Button onClick={() => setShowInput(true)}>
                                <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    style={{ fontSize: "15px" }}
                                    className="text-dark" />
                            </Button>
                            </li>
                        </ul>
                    </div>
                    <div className="tablegoikham">
                        <ul className="headertable">
                            <li>Mã gói</li>
                            <li>Tên gói</li>
                            <li>Ẩn</li>
                            <li>Xoá</li>
                        </ul>
                        {ShowInput === false ? null :
                            <div className="inputhide">
                                <Input className="form-control" />
                            </div>
                        }

                        <Table
                            columns={columns}
                            dataSource={data}
                            // loading={loading}
                            scroll={{ x: true, y: '53vh' }}
                            size="small"
                            bordered={true}
                            pagination={false}
                            showHeader={false}
                            loading={loading}
                            summary={() => (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>This is a summary content</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            )}
                        />
                    </div>
                </div>
                <div className="khaibaogoikhamsk-right">
                    <div className="top-khaibaogoikhamsk">
                        <div className="title">Chi tiết dịch vụ trong gói</div>
                        <ul className="btn-danhsachgoi">
                           

                            <li><Button><FontAwesomeIcon
                                icon={faFloppyDisk}
                                style={{ fontSize: "15px" }}
                                className="text-dark"
                            /> Lưu </Button></li>
                            <li><Button><FontAwesomeIcon
                                icon={faPrint}
                                style={{ fontSize: "15px" }}
                                className="text-dark"
                            /> In</Button></li>
                            <li><Button onClick={() => setShowInput(true)}>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{ fontSize: "15px" }}
                                    className="text-dark" /> Coppy
                            </Button>
                            </li>
                            <li><Button onClick={() => setShowInput(true)}>
                                <FontAwesomeIcon
                                    icon={faPaste}
                                    style={{ fontSize: "15px" }}
                                    className="text-dark" /> Paste
                            </Button>
                            </li>

                           
                        </ul>
                    </div>
                    <div className="tablegoikham">
                        <ul className="headertablechitiet">
                            <li>Tên DVKT</li>
                            <li>STT</li>
                            <li>Giá dịch vụ</li>
                            <li>Xoá</li>
                        </ul>
                        {ShowInput === false ? null :
                            <div className="inputhide">
                                <Input className="form-control" />
                            </div>
                        }

                        <Table
                            columns={columns1}
                            dataSource={data}
                            // loading={loading}
                            scroll={{ x: true, y: '53vh' }}
                            size="small"
                            bordered={true}
                            pagination={false}
                            showHeader={false}
                            loading={loading}
                            summary={() => (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>This is a summary content</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            )}
                        />
                    </div>

                </div>
            </div>
        </div >

    );
}

export default KhaiBaoGoiKhamSK;
