import { Modal, Button, Form, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { errDialog, errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import loginAPI from "../../../../../services/loginApi";
import cachdungAPI from "../../../../../services/duoc/danhmuc/cachdungAPI";
import { setDataCachDung } from "../../../../../slices/dataDMDuoc";
import "../cachdung.scss";
function DetailCachDung(props) {
    const { open, setOpen, handleUpdate, reLoad } = props;
    const { cachdung } = useSelector((state) => state.dataDMDuoc);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [userlogin, setUserLogin] = useState();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleCreate = async (obj) => {
        const data = await cachdungAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);

        }
        else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }

    };
    const handleSubmit = async (e) => {
        if (e?.Name_VT === undefined || e?.Name_Full === undefined) { errDialog(); } else {
            Swal.fire({
                title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
                confirmButtonText: "Đồng ý",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                customClass: {
                    title: "fs-5 text-dark",
                    confirmButton: "shadow-none",
                    cancelButton: "shadow-none",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    if (cachdung?.RowID) {
                        var cachdungEdit = {
                            RowID: e?.RowID,
                            Name_VT: e?.Name_VT,
                            Name_Full: e?.Name_Full,
                            EmployeeID: userlogin.RowID,
                        }
                        handleUpdate(cachdungEdit);
                        dispatch(setDataCachDung());
                        form.resetFields();
                        setOpen(false);
                    } else {
                        var arraycachdung = {
                            RowID: e?.RowID,
                            Name_VT: e?.Name_VT,
                            Name_Full: e?.Name_Full,
                            EmployeeID: userlogin.RowID,
                        }
                        handleCreate(arraycachdung);
                    }
                }
            });
        }

    }
    useEffect(() => {
        getAllUser();
        form.setFieldsValue({
            RowID: cachdung?.RowID,
            Name_VT: cachdung?.Name_VT,
            Name_Full: cachdung?.Name_Full,
        });
    }, [cachdung, form]);
    return (
        <>
            <Modal className="DetailCachDung"
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo cách dùng
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={600}
                closable={false}
                forceRender
                maskClosable={false}
            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form}>
                        <div className="scroll">
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input"></div>}
                                    className="col-md-8 m-0"
                                    name="RowID"
                                    hidden={true}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label namecachdung">Tên cách dùng :</div>}
                                    className="col-md-12 m-0"
                                     name="Name_Full"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label namecachdung">Tên viết tắt :</div>}
                                    className="col-md-12 m-0"
                                     name="Name_VT"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                
                            </div>
                        </div>
                        <Form.Item className="text-center">
                            <hr />
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default DetailCachDung;
