import { Modal } from "antd";
import { DataGrid, GridColumn } from 'rc-easyui';
import { useState, useEffect } from "react";
import tiepnhanAPI from "../../../services/tiepnhan/tiepnhanAPI";
import { setDataTiepNhan, setDataSinhHieuTiepNhan } from "../../../slices/dataTiepNhan";

import { setDataDatHen } from "../../../slices/dataDatHen";
import { setDataSearchTiepNhan } from "../../../slices/dataTiepNhan";

import { useDispatch } from "react-redux";
function Thongtindangkykham(props) {
    const { isModalOpen, setIsModalOpen } = props;
    const [ListTiepNhan, setListTiepNhan] = useState([]);
    const [ListDoiTuong, setListDoiTuong] = useState([]);
    const [ListGioiTinh, setListGioiTinh] = useState([]);
    const dispatch = useDispatch();
    const getAllTiepNhan = async () => {
        try {
            const data = await tiepnhanAPI.getAllPatientTiepNhan();
            setListTiepNhan(data.data);
        } catch (err) {
           // throw new Error(err);
        }
    };
    const getAllDoiTuong = async () => {
        try {
            const data = await tiepnhanAPI.getAllObject();
            setListDoiTuong(data.data);
        } catch (err) { }
    };
    const getAllGioiTinh = async () => {
        try {
            const data = await tiepnhanAPI.getAllPatientGender();
            setListGioiTinh(data.data);
        } catch (err) {}
    };
    const handleRowEditEnd = async (event) => {
        setIsModalOpen(false);
        const data = await tiepnhanAPI.showBenhNhan(event.PatientReceiveID, event.PatientCode);
        const sinhhieudata = await tiepnhanAPI.getSurvivesignReferenceCode({ PatientReceiveID: event.PatientReceiveID, ReferenceCode: '' });
        dispatch(setDataTiepNhan(data.data));
        dispatch(setDataSinhHieuTiepNhan(sinhhieudata.data));
        dispatch(setDataDatHen(null));
        dispatch(setDataSearchTiepNhan(null));

    }
    useEffect(() => {
        getAllTiepNhan();
        getAllDoiTuong();
        getAllGioiTinh();

    }, [ListTiepNhan]);
    const getFooterData = () => {
        return [
            { PatientCode: "Tổng cộng:", PatientName: ListTiepNhan.length }
        ]
    }
    return (
        <Modal className="popupdskham"
            open={isModalOpen}
            centered
            okButtonProps={{
                style: {
                    display: "none",
                },
            }}
            cancelButtonProps={{
                style: {
                    display: "none",
                },
            }}
            onCancel={() => setIsModalOpen(false)}
            width={1200}
        >
            <div className="dskhambenh">
                <div className=" mx-1 tiepnhan-form-right">
                    <div className="tiepnhan-title">THÔNG TIN ĐĂNG KÝ KHÁM</div>
                    <div className="tiepnhan-table-dskham">
                        <DataGrid
                            data={ListTiepNhan}
                            style={{ height: '80vh' }}
                            filterable
                            columnResizing
                            dblclickToEdit
                            editMode="row"
                            onRowDblClick={(row) => handleRowEditEnd(row)}
                            showFooter
                            footerData={getFooterData()}

                        >
                            <GridColumn field="PatientCode" title="Mã BN" align="center" width={120} resizable />
                            <GridColumn field="PatientName" title="Tên BN" width={200} resizable />
                            <GridColumn field="ObjectID" title="Đối tượng" width={200} resizable
                                render={({ row }) => {
                                    const category = ListDoiTuong.find((item) => item.ObjectID === row.ObjectID);
                                    return category ? category.ObjectName : null;
                                }}
                            />
                            <GridColumn field="PatientBirthyear" title="Năm sinh" width={150} resizable />

                            <GridColumn field="PatientGenderID" title="Giới tính" width={150} resizable
                                render={({ row }) => {
                                    const category = ListGioiTinh.find((item) => item.PatientGenderID === row.PatientGenderID);
                                    return category ? category.PatientGenderName : null;
                                }}
                            />
                            <GridColumn field="PatientMobile" title="Điện thoại" width={150} resizable />

                        </DataGrid>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default Thongtindangkykham;
