import { Modal, Tabs, Button, Form, Input, Checkbox, Table, Dropdown, Radio, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faMagnifyingGlass,
    faPrint,
    faList,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { useState } from "react";
import "../dsnhanvien.scss";
function DSNhanVienKSKTongQuat() {
    const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const onDropdownChange = (value) => {
        console.log(`selected ${value}`);
    };

    const columns = [
        {
            title: "Tên nhân viên",
            dataIndex: "TDV",
            fixed: "left",
            render: (TDV) => <div style={{ width: "150px" }}> {TDV} </div>,
        },
        {
            title: "Tên dịch vụ",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "500px" }}> {TDVVT} </div>,
        },
        {
            title: "Gía tiền",
            dataIndex: "MADVKT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        }
    ];
    const list = [
        {
            value: '1',
            label: 'Jack',
        },
        {
            value: '2',
            label: 'Lưu Văn Tèo',
        },
        {
            value: '3',
            label: 'Nguyễn Việt Hùng',
        },
    ];
    return (

        <div className="DSNhanVienCLS">
            <div className="headerDS">
                <div className="vienphi-danhmuc-title mx-2"><FontAwesomeIcon icon={faList} /> DS nhân viên ksk tổng quát</div>
                <div className="actiondsnhanvien">
                    <div className="row">
                        <Form.Item
                            label={<div className="name-dsnhanvien">Công ty:</div>}
                            className="col-md-4 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Select
                                className="form-control input-select"
                                showSearch
                                optionFilterProp="children"
                                placeholder={"select a country"}
                                onChange={onDropdownChange}
                                filterOption={(input, option) => {
                                    return (
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );

                                }}
                            >
                                {list.map((item) => (
                                    <Select.Option
                                        title={item.label}
                                        key={item.label}
                                        id={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={<div className="name-dsnhanvien">Hợp đồng:</div>}
                            className="col-md-4 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Select
                                className="form-control input-select"
                                showSearch
                                optionFilterProp="children"
                                placeholder={"select a country"}
                                onChange={onDropdownChange}
                                filterOption={(input, option) => {
                                    return (
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );

                                }}
                            >
                                {list.map((item) => (
                                    <Select.Option
                                        title={item.label}
                                        key={item.label}
                                        id={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <div className="col-md-4">
                            <Button className="form-btn bg" onClick={handleDataCreate}>
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="mx-1" />
                                Tìm
                            </Button>
                            <Button className="form-btn bg" onClick={handleDataCreate}>
                                <FontAwesomeIcon icon={faPrint} className="mx-1" />
                                In
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
            <div className="m-1 danhmuc danhmuc_nhomvpht">
                <Table
                    columns={columns}
                    dataSource={''}
                    // loading={loading}
                    scroll={{ x: true }}
                    size="small"
                    pagination={{ pageSize: 20 }}
                />
            </div>
        </div>

    );
}

export default DSNhanVienKSKTongQuat;
