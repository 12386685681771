import axios from "axios";
import REACT_LOCALHOST from "../host";
import { authHeader } from '../helpers';
const tiepnhanAPI = {
    getAllCareer: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-career`,obj);
    },
    getAllEthnic: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-ethnic`,obj);
    },
    getAllNationality: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-nationality`,obj);
    },
    getAllProvincial: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-provincial`,obj);
    },
    getAllDistrict: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-district`,obj);
    },
    getAllWard: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-ward`,obj);
    },
    getAllPatienttype: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-patienttype`,obj);
    },
    getAllObject: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-object`,obj ).catch(err => console.log(err) );
    },
    getAllPatientGender: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-patient-gender`,obj);
    },

    getAllPatientTiepNhan: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-patient-tiep-nhan`,obj);
    },
    getChiDinh: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-chi-dinh`, obj, {"headers":authHeader()});
    },

    AddUpdatePatients: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-update-med-patients`, obj, {"headers":authHeader()});
    },

    AddUpdatePatientsReceive: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-update-med-patient-receive`, obj, {"headers":authHeader()});
    },

    showBenhNhan: (PatientReceiveID,PatientCode) => {
        return axios.get(`${REACT_LOCALHOST}/api/view-med-patient-receive/${PatientReceiveID}/${PatientCode}`);
    },

    getSurvivesignRowID: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-survivesign-rowid`, obj);
    },
    getSurvivesignReferenceCode: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-survivesign-referencecode`, obj);
    },
    AddUpdateSurvivesign: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-update-med-survivesign`, obj);
    },
    AddUpdateChiDinh: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-update-chi-dinh`, obj);
    },
    getChiDinhPatientReceiveid: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-chi-dinh-patient-receiveid`, obj);
    },

    deleteSurvivesignRowID: (RowID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-med-survivesign-rowid/${RowID}/${EmployeeID}`);
    },
    deleteSurvivesignReceive: (PatientReceiveID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-med-survivesign-patientReceiveid/${PatientReceiveID}/${EmployeeID}`);
    },

    deleteChiDinh: (SuggestedID,PatientReceiveID,PatientCode,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-chi-dinh-patient-receiveid/${SuggestedID}/${PatientReceiveID}/${PatientCode}/${EmployeeID}`);
    },
    searchPatients: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/search-patients-tiep-nhan`,obj);
    },
    getIntroducer: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-introducer`,obj);
    },
    getNgChamSoc: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-nguoi-cham-soc`,obj);
    },
    getKSKTN: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-dm-ksk`,obj);
    },
    getDoiTuongKhamBenh: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-doi-tuong-kham-benh`,obj);
    },
    getLoaiKhamBenh: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-loai-kham-benh`,obj);
    },
    getThongKeKhoaPhong: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/tiep-nhan-thong-ke-benh-nhan-theo-phong`,obj);
    },
    getInSTT: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/tiep-nhan-in-stt`,obj);
    },
    getInDanNhan: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/tiep-nhan-in-dan-nhan`,obj);
    },
    getInChiDinh: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/tiep-nhan-in-chi-dinh`,obj);
    },
    getDoiTuongUuTien: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-doi-tuong-uu-tien`,obj);
    },
    getTaiNanTT: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-tai-nan-thuong-tich`,obj);
    },



};

export default tiepnhanAPI;
