import { Button, Form, Input, Checkbox, Select, Radio } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileExcel,
    faPenToSquare,
    faSave,
    faAngleRight,
    faAngleLeft,
    faForwardStep,
    faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
} from "suneditor/src/plugins";

import TextArea from "antd/es/input/TextArea";
import { TreeGrid, GridColumn } from 'rc-easyui';
import 'rc-easyui/dist/themes/material-teal/easyui.css';
import 'rc-easyui/dist/themes/icon.css';
import 'rc-easyui/dist/themes/react.css';
import './maumota.scss';
function MauMoTa() {
    //khaibao
    const [click, setClick] = useState(false);
    //truyền props

    const items1 = [
        {
            label: <div className="form-input-label ">Tiếp</div>,
            key: "1",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faForwardStep} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label ">Lưu</div>,
            key: "2",
            icon: <FontAwesomeIcon icon={faSave} />,
        },
        {
            label: <div className="form-input-label ">F4 - Sửa</div>,
            key: "3",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faPenToSquare} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label ">Hủy</div>,
            key: "4",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFileExcel} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label ">F5 - In KQ</div>,
            key: "5",
            icon: (
                <div className="">
                    <FontAwesomeIcon icon={faPrint} />
                </div>
            ),
        },
    ];
    const getData = [
        {
            id: 1,
            name: "C",
            size: "",
            date: "02/19/2010",
            children: [
                {
                    id: 2,
                    name: "Program Files",
                    size: "120 MB",
                    date: "03/20/2010",
                    children: [
                        {
                            id: 21,
                            name: "Java",
                            size: "",
                            date: "01/13/2010",
                            state: "closed",
                            children: [
                                {
                                    id: 211,
                                    name: "java.exe",
                                    size: "142 KB",
                                    date: "01/13/2010"
                                },
                                {
                                    id: 212,
                                    name: "jawt.dll",
                                    size: "5 KB",
                                    date: "01/13/2010"
                                }
                            ]
                        },
                        {
                            id: 22,
                            name: "MySQL",
                            size: "",
                            date: "01/13/2010",
                            state: "closed",
                            children: [
                                {
                                    id: 221,
                                    name: "my.ini",
                                    size: "10 KB",
                                    date: "02/26/2009"
                                },
                                {
                                    id: 222,
                                    name: "my-huge.ini",
                                    size: "5 KB",
                                    date: "02/26/2009"
                                },
                                {
                                    id: 223,
                                    name: "my-large.ini",
                                    size: "5 KB",
                                    date: "02/26/2009"
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 3,
                    name: "eclipse",
                    size: "",
                    date: "01/20/2010",
                    children: [
                        {
                            id: 31,
                            name: "eclipse.exe",
                            size: "56 KB",
                            date: "05/19/2009"
                        },
                        {
                            id: 32,
                            name: "eclipse.ini",
                            size: "1 KB",
                            date: "04/20/2010"
                        },
                        {
                            id: 33,
                            name: "notice.html",
                            size: "7 KB",
                            date: "03/17/2005"
                        }
                    ]
                }
            ]
        }
    ];

    const list = [
        {
            value: '1',
            label: 'Jack',
        },
        {
            value: '2',
            label: 'Lưu Văn Tèo',
        },
        {
            value: '3',
            label: 'Nguyễn Việt Hùng',
        },
    ];
    const onDropdownChange = (value) => {
        console.log(`selected ${value}`);
    };

    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };


    return (
        <>
            <div className="MauMoTaCLS">
                <div className="MauMoTaBtn">
                    <div className="div-shadow v3">
                        <div className="d-flex justify-content-end align-items-center p-1">
                            {items1.map((item) => (
                                <Button className="form-btn bg mx-1 px-1" key={item.key}>
                                    {item.icon}
                                    <div className="mx-1 fw-bold">{item.label}</div>
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="MauMoTaCLSBottom">
                    <div className="MauMoTaCLS-left">
                        <div className="title">Danh sách tường trình</div>
                        <div>
                            <TreeGrid
                                style={{ height: '87vh' }}
                                data={getData}
                                idField="id"
                                treeField="name"
                            >
                                <GridColumn field="name" width={250} title="Tên mẫu mô tả"></GridColumn>
                                <GridColumn field="size" width={250} title="Tên DVKT"></GridColumn>
                                <GridColumn field="date" width={150} title="Áp dụng"></GridColumn>
                            </TreeGrid>


                        </div>
                    </div>
                    <div className="MauMoTaCLS-right">
                        <div className="rowitem">
                            <div className="col1">Áp dụng cho:</div>
                            <div className="col2">
                                <Radio.Group onChange={onChange} value={value}>
                                    <Radio value={1}>Nam</Radio>
                                    <Radio value={2}>Nữ</Radio>
                                    <Radio value={3}>Cả 2</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="rowitem">
                            <div className="col1">Dịch vụ:</div>
                            <div className="col2 cate">
                                <Select
                                    className="form-control input-select"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder={"select a country"}
                                    onChange={onDropdownChange}
                                    filterOption={(input, option) => {
                                        return (
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        );

                                    }}
                                >
                                    {list.map((item) => (
                                        <Select.Option
                                            title={item.label}
                                            key={item.label}
                                            id={item.value}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="rowitem">
                            <div className="col1">Tên mẫu mô tả:</div>
                            <div className="col2 cate">
                                <Input className="form-control" />
                            </div>
                        </div>
                        <div className="rowitem">
                            <div className="rowitem-col">
                                <div className="rowitem-col1 bacsy">Bác sỹ:</div>
                                <div className="rowitem-col2 bacsy">
                                    <Input className="form-control" />
                                </div>
                            </div>
                            <div className="rowitem-col">
                                <div className="rowitem-col1">Mẫu mô tả:</div>
                                <div className="rowitem-col2">
                                    <Input className="form-control" />
                                </div>
                            </div>
                            <div className="rowitem-col">
                                <div className="rowitem-col1">In giấy:</div>
                                <div className="rowitem-col2">
                                    <Input className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="rowitem">
                            <div className="col1">Nội dung:</div>
                            <div>
                                <SunEditor
                                    autoFocus={true}
                                    lang="en"
                                    setDefaultStyle="height:255px"
                                    setOptions={{
                                        showPathLabel: false,

                                        placeholder: "Enter your text here!!!",
                                        plugins: [
                                            align,
                                            font,
                                            fontColor,
                                            fontSize,
                                            formatBlock,
                                            hiliteColor,
                                            horizontalRule,
                                            lineHeight,
                                            list,
                                            paragraphStyle,
                                            table,
                                            template,
                                            textStyle,
                                            image,
                                            link,
                                        ],
                                        buttonList: [
                                            ["font", "fontSize", "formatBlock"],
                                            ["bold", "underline", "italic"],
                                            ["fontColor", "hiliteColor"],
                                            ["removeFormat"],

                                            ["outdent", "indent"],
                                            [
                                                "align",
                                                "horizontalRule",
                                                "list",
                                                "lineHeight",
                                            ],
                                            ["table"]
                                        ],
                                        formats: [
                                            "p",
                                            "div",
                                            "h1",
                                            "h2",
                                            "h3",
                                            "h4",
                                            "h5",
                                            "h6",
                                        ],
                                        font: [
                                            "Arial",
                                            "Calibri",
                                            "Comic Sans",
                                            "Courier",
                                            "Garamond",
                                            "Georgia",
                                            "Impact",
                                            "Lucida Console",
                                            "Palatino Linotype",
                                            "Segoe UI",
                                            "Tahoma",
                                            "Times New Roman",
                                            "Trebuchet MS",
                                        ],
                                    }}
                                // defaultValue={richTextEditorHtml}
                                // onChange={handleEditorChange}
                                />
                            </div>
                        </div>
                        <div className="rowitem">
                            <div className="col1">Kết luận:</div>
                            <div className="col2 cate">
                                <TextArea
                                    className="form-control"
                                    autoSize={{ minRows: 5, maxRows: 5 }}
                                />
                            </div>
                        </div>
                        <div className="rowitem">
                            <div className="col1">Đề nghị:</div>
                            <div className="col2 cate">
                                <TextArea
                                    className="form-control"
                                    autoSize={{ minRows: 5, maxRows: 5 }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default MauMoTa;
