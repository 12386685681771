import axios from "axios";
import REACT_LOCALHOST from "../../host";
import { authHeader } from '../../helpers';
const dvktAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-services-vien-phi`,obj);
    },
    getAllNhomVP: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-service-group-vien-phi`,obj);
    },
    getAllLoaiVP: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-service-category-vien-phi`,obj);
    },
    getAllPhong: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-department`,obj);
    },
    getAllDVT: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-unitOfmeasure-vien-phi`,obj);
    },
    getAllNhomIn: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-group-printer-vien-phi`,obj);
    },

    getAllKhoaBHYT: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-department-ck`,obj);
    },
    getAllNhomBHYT: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-service-group-bhyt`,obj);
    },


    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-med-services-vien-phi`, obj, {"headers":authHeader()});
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-med-services-vien-phi`, obj);
    },
    delete: (ServiceID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-med-services-vien-phi/${ServiceID}/${EmployeeID}`);
    }
};

export default dvktAPI;
