import "./dathen.scss";
import { Modal, Button, Form, Input, InputNumber, Checkbox, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import dathenimg from "../../assets/image/dat-hen.jpg";
function DatHen(props) {
    const { open, setOpen, reLoad } = props;

    return (
        <>
            <Modal className="DatHenOnline"
                title={
                    <div className="vienphi-danhmuc-add">
                        Đăng ký Đặt hẹn
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={1000}
                closable={false}
                forceRender
                maskClosable={false}

            >
                <div className="text-center">
                    <div className="row">
                        <div className="col-md-5"><img src={dathenimg} className="imgdathen" alt="logoweb" /></div>
                        <div className="col-md-7">
                            <Form>
                                <div className="row">
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Họ tên</div>}
                                        className="col-md-12 m-0 " name="PatientName"
                                    >
                                        <Input className="form-control inputdm" />
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Giới tính</div>}
                                        className="col-md-6 m-0 " name="PatientGenderID"
                                    >
                                        <Select />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Ngày sinh</div>}
                                        className="col-md-6 m-0 " name="PatientBirthday"
                                    >
                                        <Input className="form-control inputdm" type="date" />
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Điện thoại</div>}
                                        className="col-md-6 m-0 " name="PatientMobile"
                                    >
                                        <Input className="form-control inputdm" />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Dân tộc</div>}
                                        className="col-md-6 m-0 " name="EThnicID"
                                    >
                                         <Select />
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Nghề nghiệp</div>}
                                        className="col-md-6 m-0 " name="CareerID"
                                    >
                                         <Select />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Quốc tịch</div>}
                                        className="col-md-6 m-0 " name="NationalityID"
                                    >
                                        <Select />
                                    </Form.Item>
                                </div>
                                <div className="row">

                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Ngày khám</div>}
                                        className="col-md-12 m-0 " name="App_Date"
                                    >
                                        <Input className="form-control inputdm" type="date" />
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Địa chỉ</div>}
                                        className="col-md-12 m-0 " name="Address_"
                                    >
                                        <Input className="form-control inputdm" />
                                    </Form.Item>

                                </div>
                                <div className="row">
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Tỉnh thành</div>}
                                        className="col-md-12 m-0 " name="ProvincialID"
                                    >
                                         <Select />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Quận/Huyện</div>}
                                        className="col-md-12 m-0 " name="DistrictID"
                                    >
                                        <Select />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Phường/Xã</div>}
                                        className="col-md-12 m-0 " name="WardID"
                                    >
                                        <Select />
                                    </Form.Item>
                                </div>
                               
                                <div className="row">
                                    <Form.Item
                                        label={<div className="form-input-label name-dathen">Lý do khám</div>}
                                        className="col-md-12 m-0 " name="Reason"
                                    >
                                       
                                        <TextArea
                                                className="form-control"
                                                autoSize={{
                                                    minRows: 3,
                                                    maxRows: 5,
                                                }}
                                            />
                                    </Form.Item>

                                </div>
                                <Form.Item className="text-end">
                                    <Button
                                        htmlType="submit"
                                        className="mx-2 vienphi-danhmuc-btn"
                                    >
                                        <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Đăng ký
                                    </Button>
                                    <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                        <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    );
}

export default DatHen;
