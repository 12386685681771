import { Input,Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faXmarkCircle
} from "@fortawesome/free-solid-svg-icons";
import "./phieukham.scss";
import { useSelector } from "react-redux";
import { useState, useRef, useEffect, useMemo } from "react";
import tiepnhanAPI from "../../../../services/tiepnhan/tiepnhanAPI";
function ChiDinh(props) {
    const { } = props;
    const { rowbenhnhan } = useSelector((state) => state.dataKhamBenh);

    const [ListChiDinh, setChiDinh] = useState([]);
    const [FiterChiDinh, setFiterChiDinh] = useState([]);
    const [SearchCongKham, setSearchCongKham] = useState("");
    const [FilterArr, setFilterArr] = useState([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const [CheckGiaDV, setCheckGiaDV] = useState(false);

    const [isOpenDT, setIsOpenDT] = useState(false);

    const tableRefICD10 = useRef(null);
    const refChiDinh = useRef(null);
    const rowRefs = useRef([]);
    let lastKeyDownTime = useRef(0);


    const [isTop, setIsTop] = useState(false);
    const searchRef = useRef(null);


    const dropdownRef = useRef(null);

    const removeVietnameseTones = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const getAllChiDinh = async () => {
        try {
            const data = await tiepnhanAPI.getChiDinh();
            setChiDinh(data.data);
        } catch (err) { }
    };

    const handleSearchChange = (value) => {
        if (value === '') {
            setFiterChiDinh([]);
        } else {
            const filterBySearch = ListChiDinh.filter((item) =>
                removeVietnameseTones(item.ServiceName || "").toLowerCase().includes(removeVietnameseTones(value))
            );
            setFiterChiDinh(filterBySearch);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchCongKham(value);
        handleSearchChange(value);
    };

    const toggleSelection = (option) => {
        setSearchCongKham(option.ICD10_Name);
        setIsOpenDT(false);
    };
    const isHandlingKey = useRef(false);
    const handleKeyDownChiDinh = (e) => {
        if (isHandlingKey.current) return;
        isHandlingKey.current = true;

        setTimeout(() => {
            isHandlingKey.current = false;
        }, 50);

        const now = Date.now();
        if (now - lastKeyDownTime.current < 150) return;
        lastKeyDownTime.current = now;
        if (!FilterArr || FilterArr.length === 0) return;

        if (e.key === "Enter") {
            e.preventDefault();
            if (selectedRowIndex !== -1) {
                setSearchCongKham(FilterArr[selectedRowIndex].ICD10_Name);
                setTimeout(() => {
                    if (refChiDinh.current) {
                        refChiDinh.current.focus();
                    }
                }, 100);
                setIsOpenDT(false);
                setSelectedRowIndex(-1);

            }
            return;

        }
        setSelectedRowIndex((prevIndex) => {
            let newIndex = prevIndex;
            if (e.key === "ArrowUp") {
                newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            } else if (e.key === "ArrowDown") {
                newIndex = prevIndex < FilterArr.length - 1 ? prevIndex + 1 : prevIndex;
            }
            return newIndex;
        });
    };

    const checkgiaDVKT = (e) => {
        setCheckGiaDV(e.target.checked);
    }
    useEffect(() => {
        getAllChiDinh();
        if (selectedRowIndex >= 0 && rowRefs.current[selectedRowIndex]) {
            setTimeout(() => {
                rowRefs.current[selectedRowIndex]?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                });
            }, 50);
        }

        if (!isOpenDT || !searchRef.current) return;

        const rect = searchRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        const tableHeight = searchRef.current.offsetHeight || 200; // Giả sử 200px nếu chưa có dữ liệu

        setIsTop(spaceBelow < tableHeight);


        document.addEventListener("keydown", handleKeyDownChiDinh);
        return () => {
            document.removeEventListener("keydown", handleKeyDownChiDinh);
        };
    }, [selectedRowIndex, isOpenDT]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpenDT(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const filteredData = useMemo(() => {
        return (FiterChiDinh.length > 0 ? FiterChiDinh : ListChiDinh)
            .filter(row =>
                row?.ObjectID === (CheckGiaDV ? 2 : (rowbenhnhan === null ? '' : rowbenhnhan.ObjectID))
                &&
                (SearchCongKham === "" || row?.ServiceName?.toLowerCase().includes(SearchCongKham.toLowerCase()))

            );
    }, [FiterChiDinh, ListChiDinh, SearchCongKham, CheckGiaDV]);

    useEffect(() => {
        setFilterArr(filteredData);
        setSelectedRowIndex((prevIndex) =>
            prevIndex >= 0 && prevIndex < filteredData.length ? prevIndex : (filteredData.length > 0 ? 0 : -1)
        );
    }, [filteredData]);

    const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));


    return (
        <>
            <div className="chi-dinh-search" ref={dropdownRef}>
                <div className="input-icon">
                    <Input
                        ref={refChiDinh}
                        type="text"
                        className="w-full inputsearch outline-none"
                        placeholder="Chọn"
                        value={SearchCongKham}
                        onChange={handleInputChange}
                        onClick={() => setIsOpenDT(!isOpenDT)}
                        onKeyDown={handleKeyDownChiDinh}
                    />
                    <FontAwesomeIcon className="iconsearch" icon={faAngleDown} />

                </div>
                <div className="check-huy-cd">
                    <div className="check-dvkt">
                        {rowbenhnhan === null ? <input type="checkbox" className="checkgiadv" value={CheckGiaDV} checked={false} disabled onChange={checkgiaDVKT} />
                            :
                            rowbenhnhan.ObjectID === 2 ?
                                <input type="checkbox" className="checkgiadv" value={CheckGiaDV} checked={true} disabled />
                                :
                                <input type="checkbox" className="checkgiadv" value={CheckGiaDV} checked={CheckGiaDV} onChange={checkgiaDVKT} />

                        }

                        <span>Giá DV</span>
                    </div>
                    <Button 
                        className="color-icon-edit red bnthuy"
                        onClick={() => console.log()}
                    >
                        <FontAwesomeIcon icon={faXmarkCircle} /> Huỷ
                    </Button>
                </div>

                {isOpenDT && (
                    <div className={`absolute w-full table-chidinhkb ${isTop ? "top" : ""}`} ref={searchRef}>
                        <table

                            onKeyDown={handleKeyDownChiDinh} tabIndex={0} ref={tableRefICD10} onFocus={() => setSelectedRowIndex(-1)} >
                            <thead>
                                <tr>
                                    <td className="tendvkt">Tên DVKT</td>
                                    <td className="doituong">Đối tượng</td>
                                    <td className="dongia">Đơn giá</td>
                                    <td className="goikham">Gói khám</td>
                                </tr>
                            </thead>
                            <tbody>
                                {FilterArr.map((option, index) => {
                                    const goikham = option?.ServicePackageID === -1 ? null : <input type="checkbox" disabled checked />;
                                    return (<tr
                                        key={index}
                                        ref={(el) => (rowRefs.current[index] = el)}
                                        className={index === selectedRowIndex ? "active" : ""}
                                        onClick={() => { setSelectedRowIndex(index); toggleSelection(option) }}
                                        tabIndex={0}
                                    >

                                        <td className="tendvkt">{option?.ServiceName}</td>
                                        <td className="doituong">{option?.ObjectName}</td>
                                        <td className="dongia">{priceSplitter(option?.UnitPrice || 0)}</td>
                                        <td className="goikham">{goikham}</td>
                                    </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

        </>
    );
}
export default ChiDinh;
