import { Button, Form, Input, Checkbox, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { DataGrid, GridColumn, Tabs, TabPanel } from 'rc-easyui';
import 'rc-easyui/dist/themes/material-teal/easyui.css';
import 'rc-easyui/dist/themes/icon.css';
import 'rc-easyui/dist/themes/react.css';
import {
    faFileExcel,
    faPenToSquare,
    faSave,
    faAngleRight,
    faAngleLeft,
    faForwardStep,
    faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
} from "suneditor/src/plugins";
import "./sieuam.scss";
import TextArea from "antd/es/input/TextArea";
import Danhsachleft from "./Danhsachleft";
import imguser from "../../../assets/image/user.png";
import Recording from "./Danhsachleft/Doctraketquasieuam/Recording";
import WebcamCapture from "../WebcamPrt";
function Sieuam() {
    //khaibao
    const [click, setClick] = useState(false);
    //truyền props
    const onChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const items1 = [
        {
            label: <div className="form-input-label ">Tiếp</div>,
            key: "1",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faForwardStep} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label ">Lưu</div>,
            key: "2",
            icon: <FontAwesomeIcon icon={faSave} />,
        },
        {
            label: <div className="form-input-label ">F4 - Sửa</div>,
            key: "3",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faPenToSquare} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label ">Hủy</div>,
            key: "4",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFileExcel} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label ">F5 - In KQ</div>,
            key: "5",
            icon: (
                <div className="">
                    <FontAwesomeIcon icon={faPrint} />
                </div>
            ),
        },
    ];
    const datadm = [
        { "code": "FI-SW-01", "name": "Koi", "unitcost": 10.00, "status": "P", "listprice": 36.50, "attr": "Large", "itemid": "EST-1" },
        { "code": "K9-DL-01", "name": "Dalmation", "unitcost": 12.00, "status": "P", "listprice": 18.50, "attr": "Spotted Adult Female", "itemid": "EST-10" },
        { "code": "RP-SN-01", "name": "Rattlesnake", "unitcost": 12.00, "status": "P", "listprice": 38.50, "attr": "Venomless", "itemid": "EST-11" },
        { "code": "RP-SN-01", "name": "Rattlesnake", "unitcost": 12.00, "status": "P", "listprice": 26.50, "attr": "Rattleless", "itemid": "EST-12" },
        { "code": "RP-LI-02", "name": "Iguana", "unitcost": 12.00, "status": "P", "listprice": 35.50, "attr": "Green Adult", "itemid": "EST-13" },
        { "code": "FL-DSH-01", "name": "Manx", "unitcost": 12.00, "status": "P", "listprice": 158.50, "attr": "Tailless", "itemid": "EST-14" },
        { "code": "FL-DSH-01", "name": "Manx", "unitcost": 12.00, "status": "P", "listprice": 83.50, "attr": "With tail", "itemid": "EST-15" },
        { "code": "FL-DLH-02", "name": "Persian", "unitcost": 12.00, "status": "P", "listprice": 23.50, "attr": "Adult Female", "itemid": "EST-16" },
        { "code": "FL-DLH-02", "name": "Persian", "unitcost": 12.00, "status": "P", "listprice": 89.50, "attr": "Adult Male", "itemid": "EST-17" },
        { "code": "AV-CB-01", "name": "Amazon Parrot", "unitcost": 92.00, "status": "P", "listprice": 63.50, "attr": "Adult Male", "itemid": "EST-18" }
    ];
    return (
        <div className="CLSSieuAm">
            <div className="div-shadow v3">
                <div className="d-flex justify-content-end align-items-center p-1">
                    <div className="check">
                        <Form.Item
                            className="m-0 p-0"
                            label={<div className="form-input-label checka4">In toa A4</div>}
                        >
                            <Checkbox />
                        </Form.Item>
                    </div>
                    {items1.map((item) => (
                        <Button className="form-btn bg mx-1 px-1" key={item.key}>
                            {item.icon}
                            <div className="mx-1 fw-bold">{item.label}</div>
                        </Button>
                    ))}
                </div>
            </div>
            <div className="CLSSieuAm-main">
                <div className="CLSSieuAm-left">
                    <div className="thongtin">
                        <div className="img"><img src={imguser} width={60} /></div>
                        <div className="profile">
                            <div className="item-tt">
                                <div className="namel">Họ tên:</div>
                                <Input className="form-control" />
                            </div>
                            <div className="item-tt">
                                <div className="item-cot">
                                    <div className="namell">N.Sinh:</div>
                                    <Input className="form-control" />
                                </div>
                                <div className="item-cot">
                                    <div className="namell">G.Tính:</div>
                                    <Input className="form-control" />
                                </div>
                                <div className="item-cot">
                                    <div className="">-</div>
                                    <Input className="form-control" />
                                </div>

                            </div>
                            <div className="item-tt">
                                <div className="item-cot">
                                    <div className="nameNN">N.Nghiệp:</div>
                                    <Input className="form-control" />
                                </div>
                                <div className="item-cot">
                                    <div className="doituong"> Giới tính</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row-item">
                        <span>Đ/c:</span>
                        <Input className="form-control" />
                    </div>
                    <div className="row-item">
                        <span className="st">Số thẻ:</span>
                        <Input className="form-control" />
                        <span className="g">-</span>
                        <Input className="form-control" />
                    </div>
                    <div className="row-item">
                        <span>KCB BĐ:</span>
                        <Input className="form-control" />
                    </div>
                    <div className="title">Lịch sử thực hiện</div>
                    <div className="tabledm">
                        <DataGrid data={datadm} style={{ height: '50vh' }}>
                            <GridColumn field="itemid" title="STT" width={50}></GridColumn>
                            <GridColumn field="name" title="Họ tên" width={250}></GridColumn>
                            <GridColumn field="listprice" title="Năm sinh" width={100}></GridColumn>
                            <GridColumn field="unitcost" title="Giới tính" width={100}></GridColumn>
                            <GridColumn field="attr" title="Mã BN" width={100} ></GridColumn>
                            <GridColumn field="status" title="Ngày" width={100}></GridColumn>
                        </DataGrid>
                    </div>
                    <div className="camera"><WebcamCapture /></div>

                </div>
                <div className="CLSSieuAm-right">
                    <div className="actiontop">
                        <div className="actiontop-left">
                            <div className="itemR">
                                <div className="row">
                                    <div className="col-md-4 colItem">
                                        <div className="colItem-name">BS CĐ:</div>
                                        <Select
                                            className="txtInput"
                                            showSearch
                                            placeholder="Select a person"
                                            optionFilterProp="label"
                                            onChange={onChange}
                                            onSearch={onSearch}
                                            options={[
                                                {
                                                    value: 'jack',
                                                    label: 'Jack',
                                                },
                                                {
                                                    value: 'lucy',
                                                    label: 'Lucy',
                                                },
                                                {
                                                    value: 'tom',
                                                    label: 'Tom',
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-md-4 colItem">
                                        <div className="colItem-name">Ngày CĐ:</div>
                                        <Input className="form-control txtInput" type="date" />
                                    </div>
                                    <div className="col-md-4 colItem">
                                        <div className="colItem-name">Ngày KQ:</div>
                                        <Input className="form-control txtInput" type="date" />
                                    </div>
                                </div>
                            </div>
                            <div className="itemR">
                                <div className="dbbenh">DB bệnh:</div>
                                <TextArea
                                    className="form-control txtInput1"
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                />

                            </div>
                            <div className="itemR">
                                <div className="dbbenh">CĐ sơ bộ:</div>
                                <Input className="form-control txtInput1" />
                            </div>
                            <div className="itemR">
                                <div className="dbbenh">Chỉ định:</div>
                                <Input className="form-control txtInput1" />
                            </div>
                        </div>
                        <div className="actiontop-right">
                            <div className="rowItemR">
                                <div className="colname">BS trả KQ:</div>
                                <Select
                                    className="txtInputR"
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="label"
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    options={[
                                        {
                                            value: 'jack',
                                            label: 'Jack',
                                        },
                                        {
                                            value: 'lucy',
                                            label: 'Lucy',
                                        },
                                        {
                                            value: 'tom',
                                            label: 'Tom',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="rowItemR">
                                <div className="colname">KTV 1:</div>
                                <Select
                                    className="txtInputR"
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="label"
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    options={[
                                        {
                                            value: 'jack',
                                            label: 'Jack',
                                        },
                                        {
                                            value: 'lucy',
                                            label: 'Lucy',
                                        },
                                        {
                                            value: 'tom',
                                            label: 'Tom',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="rowItemR">
                                <div className="colname">KTV 2:</div>
                                <Select
                                    className="txtInputR"
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="label"
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    options={[
                                        {
                                            value: 'jack',
                                            label: 'Jack',
                                        },
                                        {
                                            value: 'lucy',
                                            label: 'Lucy',
                                        },
                                        {
                                            value: 'tom',
                                            label: 'Tom',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="rowItemR">
                                <div className="colname">Máy SA:</div>
                                <Select
                                    className="txtInputR"
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="label"
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    options={[
                                        {
                                            value: 'jack',
                                            label: 'Jack',
                                        },
                                        {
                                            value: 'lucy',
                                            label: 'Lucy',
                                        },
                                        {
                                            value: 'tom',
                                            label: 'Tom',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="rowItemR">
                                <div className="colname">M.Mô tả:</div>
                                <Select
                                    className="txtInputR"
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="label"
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    options={[
                                        {
                                            value: 'jack',
                                            label: 'Jack',
                                        },
                                        {
                                            value: 'lucy',
                                            label: 'Lucy',
                                        },
                                        {
                                            value: 'tom',
                                            label: 'Tom',
                                        },
                                    ]}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="tabs">
                        <Tabs style={{ height: 250 }}>
                            <TabPanel title="MÔ TẢ - NỘI DUNG">
                                <p>Tab Panel1</p>
                            </TabPanel>
                            <TabPanel iconCls="icon-menu" title="DANH SÁCH CHỜ">
                                <p>Tab Panel2</p>
                            </TabPanel>
                            <TabPanel title="Help" closable iconCls="icon-help">
                                <p>This is the help content.</p>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sieuam;
