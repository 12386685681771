import { Modal, Button, Form, Input, Checkbox, Select,InputNumber } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { errDialog ,errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import loginAPI from "../../../../../services/loginApi";
import { setDataLoaiVienPhi } from "../../../../../slices/dataVienPhi";
import loaivienphiAPI from "../../../../../services/vienphi/danhmuc/loaivienphiAPI";

import "../../danhmuc.scss";

function DetailLoaiVienPhi(props) {
    const { open, setOpen, handleUpdate, listServiceGroup,reLoad } = props;
    const { loaivienphi } = useSelector((state) => state.dataVienPhi);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [userlogin, setUserLogin] = useState();
    const [checkAnHien, setcheckAnHien] = useState(false);
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleCreate = async (obj) => {
        const data = await loaivienphiAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);
            
        }
         else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }

    };
    const handleSubmit = async (e) => {
        if (e?.ServiceCategoryName === undefined || e?.ServiceGroupID === undefined) { errDialog(); } else {
            Swal.fire({
                title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
                confirmButtonText: "Đồng ý",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                customClass: {
                    title: "fs-5 text-dark",
                    confirmButton: "shadow-none",
                    cancelButton: "shadow-none",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    if (loaivienphi?.ServiceCategoryID) {
                        var upVienPhi = {
                            ServiceCategoryID: e?.ServiceCategoryID,
                            ServiceCategoryName: e?.ServiceCategoryName,
                            ServiceGroupID: e?.ServiceGroupID,
                            STT: e?.STT,
                            Hide: e?.Hide,
                            EmployeeID: userlogin.RowID,

                        }
                        handleUpdate(upVienPhi);
                        dispatch(setDataLoaiVienPhi());
                        form.resetFields();
                        setOpen(false);
                    } else {
                        var LoaiVienPhi = {
                            ServiceCategoryName: e?.ServiceCategoryName,
                            ServiceGroupID: e?.ServiceGroupID,
                            STT: e?.STT === undefined ? 1: e?.STT,
                            Hide: checkAnHien === false ? 0 : 1,
                            EmployeeID: userlogin.RowID,
                        }
                        handleCreate(LoaiVienPhi);
                    }
                }
            });
        }

    }
    const onChange = (e) => {
        setcheckAnHien(e.target.checked)
    };
    useEffect(() => {
        getAllUser();
        form.setFieldsValue({
            ServiceCategoryID: loaivienphi?.ServiceCategoryID,
            ServiceCategoryName: loaivienphi?.ServiceCategoryName,
            ServiceGroupID: loaivienphi?.ServiceGroupID,
            STT: loaivienphi?.STT,
            Hide: loaivienphi?.Hide


        });
    }, [loaivienphi,form]);
    return (
        <>
            <Modal className="DetailLoaiVienPhi"
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo nhóm loại
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={1000}
                closable={false}
                forceRender
                maskClosable={false}
            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form}>
                        <div className="scroll">
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input"></div>}
                                    className="col-md-6 m-0"
                                    name="ServiceCategoryID"
                                    hidden={true}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên loại :</div>}
                                    className="col-md-4 m-0"
                                    name="ServiceCategoryName"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Nhóm :</div>}
                                    className="col-md-4 m-0"
                                    name="ServiceGroupID"
                                >
                                    <Select
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={listServiceGroup.map((item) => ({
                                            value: item.ServiceGroupID,
                                            label: item.ServiceGroupName
                                        }))}

                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">STT :</div>}
                                    className="col-md-2 m-0"
                                    name="STT"
                                >
                                    <InputNumber className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ẩn/Hiện:</div>}
                                    className="col-md-2 m-0"
                                    name="Hide" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChange} value={checkAnHien} />
                                </Form.Item>

                            </div>
                        </div>
                        <Form.Item className="text-center">
                            <hr />
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                Thoát <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" />
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default DetailLoaiVienPhi;
