
import { Button, Dropdown, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
    faEdit,
    faTrashCan,
    faPenToSquare,
    faPlus,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { DataGrid, GridColumn, ComboBox } from 'rc-easyui';
import { useState, useEffect } from "react";
import { errorInfo, successInfo } from "../../../../../components/Dialog/Dialog";
import loaivienphiAPI from "../../../../../services/vienphi/danhmuc/loaivienphiAPI";
import phannhomAPI from "../../../../../services/vienphi/danhmuc/phannhomAPI";
import { setDataLoaiVienPhi } from "../../../../../slices/dataVienPhi";
import loginAPI from "../../../../../services/loginApi";
import "../../danhmuc.scss";
import DetailLoaiVienPhi from "../Detail";
import LoadPage from "../../../../../loadpage";

function Khaibaoloaivienphi() {
    const [HideCheck, setHideCheck] = useState(false);
    const [loadpage, setLoadPage] = useState(false);
    const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const [filters, setFilters] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [listLoaiVienPhi, setlistLoaiVienPhi] = useState([]);
    const [listServiceGroup, setlistServiceGroup] = useState([]);
    const dispatch = useDispatch();
    const [userlogin, setUserLogin] = useState();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataLoaiVienPhi(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleDelete(record.ServiceCategoryID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };
    const getAllServiceGroup = async () => {
        try {
            const data = await phannhomAPI.getAll('ServiceGroupID');
            setlistServiceGroup(data.data);
        } catch (err) { }
    };
    const getAllLoaiVienPhi = async () => {
        try {
            const data = await loaivienphiAPI.getAll({ 'ServiceCategoryID': 0, 'Hide': HideCheck === false ? 0 : 1 });
            setlistLoaiVienPhi(data.data);
            setFilteredData(data.data)

        } catch (err) {}
    };
    const handleUpdate = async (obj) => {
        const data = await loaivienphiAPI.update(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllLoaiVienPhi();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (ServiceCategoryID, EmployeeID) => {
        const data = await loaivienphiAPI.delete(ServiceCategoryID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllLoaiVienPhi();
        } else {
            errorInfo(data.data.Content);
        }
    };

    const handleToggleHideCheckbox = async (record) => {
        const newHideValue = record.Hide === false ? true : false;
        const updatedRecord = { ...record, Hide: newHideValue };
        try {
            await handleUpdate(updatedRecord);
            successInfo("Cập nhật trạng thái thành công!");
            getAllLoaiVienPhi();
        } catch (err) {
            errorInfo("Cập nhật trạng thái thất bại!");

        }
    };
    useEffect(() => {
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        const getAllLoaiVienPhi = async () => {
            const data = await loaivienphiAPI.getAll({ 'ServiceCategoryID': 0, 'Hide': HideCheck === false ? 0 : 1 });
            setlistLoaiVienPhi(data.data);
            setFilteredData(data.data)
        };

        getAllLoaiVienPhi();
        getAllUser();
        getAllServiceGroup();
    }, [HideCheck]);

    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const renderComboBox = ({
        data,
        value,
        onChange,
        style = { width: 150 },
        editable = false,
    }) => {
        const enhancedData = [{ value: null, text: "All" }, ...data];

        return (
            <ComboBox
                data={enhancedData}
                value={value}
                onChange={onChange}
                style={style}
                editable={editable}
            />
        );
    };
    const status = [
        { value: null, text: "All" },
        { value: "true", text: "1" },
        { value: "false", text: "0" }
    ]
    if (loadpage === true) {
        return (
            <>
                <div className="text-muted StyleLoaiVienPhi">
                    <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                        <div className="d-flex align-items-center ">
                            <div className="vienphi-danhmuc-title mx-2"><FontAwesomeIcon icon={faBars} /> Phân loại</div>
                        </div>
                        <div className="text-end">
                            <div className="nhomin-act-check">
                                <div className="col-n"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                                <div className="col-n">
                                    <Button className="form-btn bg" onClick={handleDataCreate}>
                                        <FontAwesomeIcon icon={faPlus} className="mx-1" />
                                        Thêm mới
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DetailLoaiVienPhi
                        open={open} setOpen={setOpen}
                        reLoad={getAllLoaiVienPhi}
                        handleUpdate={handleUpdate}
                        listServiceGroup={listServiceGroup}
                        key="DetailLoaiVienPhi"
                    />
                    <div className="m-1 danhmuc">
                        <DataGrid
                            data={filteredData}
                            style={{ height: '90vh' }}
                            filterable
                            columnResizing
                        >
                            <GridColumn field="ServiceCategoryID" title="Mã loại" filterable resizable width={100} />
                            <GridColumn field="ServiceCategoryName" title="Tên loại" filterable width={250} resizable />

                            <GridColumn field="ServiceGroupID" title="Nhóm" filterable width={250} resizable
                                filter={() =>
                                    renderComboBox({
                                        data: listServiceGroup.map((item) => ({
                                            value: item.ServiceGroupID,
                                            text: item.ServiceGroupName,
                                        })),
                                        value: filters.ServiceGroupID || '',
                                        onChange: (value) => {
                                            const filterValue = value || null;
                                            const filteredData = listLoaiVienPhi.filter((row) => {

                                                return filterValue ? row.ServiceGroupID === filterValue : true;
                                            });
                                            setFilteredData(filteredData);
                                            setFilters((prev) => ({ ...prev, ServiceGroupID: filterValue }));
                                        },
                                        onReset: () => {
                                            setFilteredData(listLoaiVienPhi);
                                            setFilters((prev) => ({ ...prev, ServiceGroupID: '' }));
                                        },
                                        style: { width: '100%' },
                                    })
                                }

                                render={({ row }) => {
                                    const nhom = listServiceGroup.find((item) => item.ServiceGroupID === row.ServiceGroupID);
                                    return nhom ? nhom.ServiceGroupName : null;
                                }}

                            />
                            <GridColumn field="STT" title="STT" filterable width={100} resizable />
                            <GridColumn field="Hide" title="Ẩn/Hiện" filterable width={50} resizable
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => (
                                    <Checkbox
                                        checked={row.Hide === true}
                                        onChange={() => handleToggleHideCheckbox(row)}
                                    />
                                )}
                            />
                            <GridColumn
                                field="actions"
                                title="Thao tác"
                                align="center"
                                width={150}
                                resizable
                                filterable={false}
                                render={({ row }) => (
                                    <div className="d-flex justify-content-center align-center">
                                        <Dropdown
                                            menu={{
                                                items,
                                                onClick: (e) => handleThaoTac(e, row),
                                            }}
                                            placement="left"
                                            arrow={{
                                                pointAtCenter: true,
                                            }}
                                        >
                                            <Button
                                                className="bg-light vienphi-danhmuc-icon-modify"
                                                onClick={() => {
                                                    setActiveModify(!activeModify);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    style={{ fontSize: "10px" }}
                                                    className="text-dark"
                                                />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </DataGrid>

                    </div>
                </div>

            </>
        );
    } else { return <LoadPage /> }
}


export default Khaibaoloaivienphi;
