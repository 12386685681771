import axios from "axios";
import REACT_LOCALHOST from "../host";
import { authHeader } from '../helpers';
const dathenAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-patient-app-online`,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-patient-app-online`, obj);
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-patient-app-online`, obj);
    },
    delete: (RowID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-patient-app-online/${RowID}/${EmployeeID}`);
    },
};

export default dathenAPI;