import React, { forwardRef, useEffect, useRef, useState } from "react";
import JsBarcode from "jsbarcode";
import "./printtiepnhan.scss";
import tiepnhanAPI from "../../../services/tiepnhan/tiepnhanAPI";
import employeeAPI from "../../../services/employeeAPI";
import logo from "../../../assets/image/logo.png";
import moment from 'moment';
const PrintChiDinh = forwardRef((props, ref) => {
    const { InListChiDinh, PatientGender } = props;
    const [inChiDinh, setInChiDinh] = useState([]);
    const [TenBacSi, setTenBacSi] = useState([]);

    const barcodeRef = useRef();
    useEffect(() => {
        const getInChiDinh = async () => {
            try {
                const data = await tiepnhanAPI.getInChiDinh({ 'PatientReceiveID': InListChiDinh?.PatientReceiveID, 'ReferenceCode': InListChiDinh?.ReferenceCode });
                setInChiDinh(data.data);
            } catch (err) { }
        };
        const getTenBacSi = async () => {
            try {
                const data = await employeeAPI.getAllRowID({ 'RowID': InListChiDinh?.EmployeeID });
                setTenBacSi(data.data);
            } catch (err) { }
        };
        getInChiDinh();
        getTenBacSi();

        JsBarcode(barcodeRef.current, InListChiDinh !== null ? InListChiDinh.PatientCode : null, {
            format: "CODE128",
            displayValue: true,
            fontSize: 14,
            lineColor: "#000",
            width: 1.5,
            height: 30,
        });
    }, [ref, PatientGender, InListChiDinh]);

    const category = PatientGender.find((item) => item.PatientGenderID === (InListChiDinh !== null ? InListChiDinh.PatientGenderID : 0));
    const huyetap = inChiDinh.length > 0 ? inChiDinh[0]?.BloodPressure.split(",") : '';
    const Ngay = inChiDinh.length > 0 ? moment(inChiDinh[0]?.WorkDate).format('DD') : '';
    const Thang = inChiDinh.length > 0 ? moment(inChiDinh[0]?.WorkDate).format('MM') : '';
    const Nam = inChiDinh.length > 0 ? moment(inChiDinh[0]?.WorkDate).format('YYYY') : '';


    return (
        <div className="inphieu-chi-dinh" ref={ref}>
            <div className="header-cty">
                <div className="img"><img src={logo} className="imglogo" alt="logoweb" /></div>
                <div className="thongtin">
                    <h1>CÔNG TY TNHH WINSOFT VIỆT NAM </h1>
                    <div>Địa chỉ: Công Viên PM Quang Trung, Quận 12, HCM</div>
                    <div>Điện thoại: 0989 365 065</div>
                    <div>Email: winsoft.hotro@gmail.com</div>

                </div>
            </div>
            <div className="header">
                <div className="header-left">
                </div>
                <div className="header-content">
                    <h1>PHIẾU CHỈ ĐỊNH</h1>
                    <div className="doituong">{InListChiDinh !== null ? (InListChiDinh?.ObjectID === 1 ? "BHYT" : " Dịch Vụ") : ''}</div>
                </div>
                <div className="header-right">
                    <canvas ref={barcodeRef} className="barcode" ></canvas>
                </div>
            </div>
            <div className="thongtin-todieutri">
                <ul>
                    <li>
                        <div className="hoten"> Họ tên: {InListChiDinh !== null ? InListChiDinh?.PatientName : ''}</div>
                        <div className="tuoi">Năm sinh:  {InListChiDinh !== null ? InListChiDinh?.PatientBirthyear : ''}</div>
                        <div className="gioitinh">Giới tính:  {category ? category?.PatientGenderName : null}</div>
                    </li>
                    <li>Địa chỉ: {InListChiDinh !== null ? InListChiDinh.Address_ : ''}</li>
                    <li>
                        <div className="hoten"> Khoa/Phòng: {inChiDinh.length > 0 ? inChiDinh[0]?.DepartmentName : ''}</div>
                        <div className="gioitinh">Số BHYT: {inChiDinh.length > 0 ? inChiDinh[0]?.so_BHYT : ''}</div>

                    </li>
                    <li>Chuẩn đoán: {inChiDinh.length > 0 ? inChiDinh[0]?.so_BHYT : ''}</li>
                    <li className="sinhhieu">
                        <span>Mạch: {inChiDinh.length > 0 ? inChiDinh[0]?.Pulse : ''} (lần/phút) </span>
                        <span>Nhiệt độ: {inChiDinh.length > 0 ? inChiDinh[0]?.Temperature : ''} ℃</span>
                        <span>Huyết áp: {huyetap[0]}/{huyetap[1]} mmHg</span>
                        <span>Nặng: {inChiDinh.length > 0 ? inChiDinh[0]?.Weight : ''} Kg</span>
                    </li>
                </ul>
            </div>
            <div className="ketquatodieutri">
                <table>
                    <thead>
                        <tr>
                            <td className="stt">STT</td>
                            <td className="yeucau">Yêu Cầu</td>
                            <td className="soluong">SL</td>
                        </tr>
                    </thead>
                    <tbody>
                        {(Array.isArray(inChiDinh) ? inChiDinh : []).map((item, index) => (
                            <tr key={index}>
                                <td>{item.STT}</td>
                                <td className="tendvkt">{item.ServiceName}</td>
                                <td>{item.Quantity}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            <div className="footerbs">
                <div className="footerbs-c">
                    <div className="ngay">Ngày {Ngay} tháng {Thang} năm {Nam}</div>
                    <div className="bs">BÁC SĨ CHỈ ĐỊNH</div>
                    <div className="tenbs">{TenBacSi.EmployeeName}</div>
                </div>
            </div>
            


        </div>
    );
})

export default PrintChiDinh;
