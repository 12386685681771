import { Modal, Button, Form, Input, Select, Checkbox, InputNumber } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import './thuocVTYT.scss'
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errDialog, errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import loginAPI from "../../../../../services/loginApi";
import { setDataThuocVTYT } from "../../../../../slices/dataDMDuoc";

import thuocvtytAPI from "../../../../../services/duoc/danhmuc/thuocvtytAPI";
function DetailThuocVTYT(props) {
    const { open, setOpen, handleUpdate, reLoad, listNhomThuoc, listLoaiThuoc,
        listDuongDung, listDonViTinh, listNhomIn, listNuocSanXuat, listHangSanXuat, listKho } = props;
    const { thuocvtyt } = useSelector((state) => state.dataDMDuoc);
    const [userlogin, setUserLogin] = useState();
    const [checkAnHien, setcheckAnHien] = useState(false);

    const [checkIsBHYT, setcheckIsBHYT] = useState(false);
    const [checkIsService, setIsService] = useState(false);
    const [checkConvertedMedi, setcheckConvertedMedi] = useState(false);
    const [checkIsKeDonBS, setcheckIsKeDonBS] = useState(false);
    const [NhomKho, setNhomKho] = useState(null);



    const [secondCity, setSecondCity] = useState('');

    const handleProvinceChange = (value) => {
        setSecondCity(value);
    };


    const onChangeAnHien = (e) => {
        setcheckAnHien(e.target.checked)
    };
    const onChangeIsBHYT = (e) => {
        setcheckIsBHYT(e.target.checked)
    };
    const onChangeIsService = (e) => {
        setIsService(e.target.checked)
    };
    const onChangeConvertedMedi = (e) => {
        setcheckConvertedMedi(e.target.checked)
    };
    const onChangeIsKeDonBS = (e) => {
        setcheckIsKeDonBS(e.target.checked)
    };


    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    const handleCreate = async (obj) => {
        const data = await thuocvtytAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);

        }
        else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }

    };
    const handleSubmit = async (e) => {
        if (e?.ItemName === undefined) { errDialog(); } else {
            Swal.fire({
                title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
                confirmButtonText: "Đồng ý",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                customClass: {
                    title: "fs-5 text-dark",
                    confirmButton: "shadow-none",
                    cancelButton: "shadow-none",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    if (thuocvtyt?.ItemID) {
                        var arrayEdit = {
                            ItemID: e?.ItemID,
                            ItemCode_PK: e?.ItemCode_PK,
                            ItemName: e?.ItemName,
                            ItemContent: e?.ItemContent,
                            Active: e?.Active,
                            Active_Code: e?.Active_Code,
                            UsageID: e?.UsageID,
                            UnitID: e?.UnitID,
                            UnitID_Packed: e?.UnitID_Packed,

                            SODK: e?.SODK,
                            QUYCACH: e?.QUYCACH,
                            DosageForms: e?.DosageForms,
                            ServiceCategoryID: e?.ServiceCategoryID,
                            Hide: e?.Hide,
                            UnitPrice: e?.UnitPrice,
                            UnitPrice_VAT: e?.UnitPrice_VAT,
                            SalesPrice: e?.SalesPrice,
                            BHYTPrice: e?.BHYTPrice,
                            DisparityPrice: e?.DisparityPrice,
                            SalesPrice_Retail: e?.SalesPrice_Retail,
                            Rate_BH: e?.Rate_BH,
                            CountryID: e?.CountryID,
                            ProducerID: e?.ProducerID,
                            IsBHYT: e?.IsBHYT,
                            // IsBHYT: 1,
                            IsService: e?.IsService,
                            SafelyQuantity: e?.SafelyQuantity,
                            RepositoryCode: e?.RepositoryCode,
                            QtyOfMeasure: e?.QtyOfMeasure,
                            STT: e?.STT === undefined ? 1 : e?.STT,
                            Note: e?.Note,
                            EmployeeID: userlogin.RowID,
                            Generic_BD: 1,
                            VENCode: 1,
                            GroupPrinterID: e?.GroupPrinterID,
                            UnitID_Medi: e?.UnitID_Medi,
                            QtyOfMeasure_Medi: 1,//
                            Toxic_Dose: e?.Toxic_Dose,
                            ItemsBHYT_ID: e?.ItemsBHYT_ID,
                            QtyContent_Medi: e?.QtyContent_Medi,
                            Converted_Medi: e?.Converted_Medi,//
                            IsKeDon_BS: e?.IsKeDon_BS,//
                            Ma_DQG: 1,
                            ServiceID_Attach: e?.ServiceID_Attach,
                            VAT: e?.VAT
                        }
                        handleUpdate(arrayEdit);
                        dispatch(setDataThuocVTYT());
                        form.resetFields();
                        setOpen(false);
                    } else {
                        var arrayAdd = {
                            //ItemID: 1,
                            ItemCode_PK: e?.ItemCode_PK,
                            ItemName: e?.ItemName,
                            ItemContent: e?.ItemContent,
                            Active: e?.Active,
                            Active_Code: e?.Active_Code,
                            UsageID: e?.UsageID,
                            UnitID: e?.UnitID,
                            UnitID_Packed: e?.UnitID_Packed,

                            SODK: e?.SODK,
                            QUYCACH: e?.QUYCACH,
                            DosageForms: e?.DosageForms,
                            ServiceCategoryID: e?.ServiceCategoryID,
                            Hide: checkAnHien === false ? 0 : 1,
                            UnitPrice: e?.UnitPrice,
                            UnitPrice_VAT: e?.UnitPrice_VAT,
                            SalesPrice: e?.SalesPrice,
                            BHYTPrice: e?.BHYTPrice,
                            DisparityPrice: e?.DisparityPrice,
                            SalesPrice_Retail: e?.SalesPrice_Retail,
                            Rate_BH: e?.Rate_BH,
                            CountryID: e?.CountryID,
                            ProducerID: e?.ProducerID,
                            IsBHYT: checkIsBHYT === false ? 0 : 1,
                            IsService: checkIsService === false ? 0 : 1,
                            SafelyQuantity: e?.SafelyQuantity,
                            RepositoryCode: e?.RepositoryCode? NhomKho?.toString(): 0,
                            QtyOfMeasure: e?.QtyOfMeasure,
                            STT: e?.STT === undefined ? 1 : e?.STT,
                            Note: e?.Note,
                            EmployeeID: userlogin.RowID,
                            Generic_BD: 1,
                            VENCode: 1,
                            GroupPrinterID: e?.GroupPrinterID,
                            UnitID_Medi: e?.UnitID_Medi,
                            QtyOfMeasure_Medi: 1,//
                            Toxic_Dose: e?.Toxic_Dose,
                            ItemsBHYT_ID: e?.ItemsBHYT_ID,
                            QtyContent_Medi: e?.QtyContent_Medi,
                            Converted_Medi: checkConvertedMedi === false ? 0 : 1,//
                            IsKeDon_BS: checkIsKeDonBS === false ? 0 : 1,//
                            Ma_DQG: 1,
                            ServiceID_Attach: e?.ServiceID_Attach,
                            VAT: e?.VAT
                        }
                        handleCreate(arrayAdd);


                    }
                }
            });
        }

    }
    const onChangeMultiple = (value) => {
        let loadnhomkho = '';
        setNhomKho([...loadnhomkho, value])
    }
    /*const loainhom = () => {
        return listLoaiThuoc.map((product, index) => {
            if (product.ServiceGroupID === secondCity && thuocvtyt?.ServiceGroupID === undefined) {
                return (
                    <Select.Option
                        title={product.ServiceCategoryName}
                        key={product.ServiceCategoryName}
                        id={product.ServiceCategoryID}
                        value={product.ServiceCategoryID}
                    >
                        {product.ServiceCategoryName}
                    </Select.Option>
                )
            } else if (secondCity === '' && thuocvtyt?.ServiceGroupID !== undefined) {
                return (
                    <Select.Option
                        title={product.ServiceCategoryName}
                        key={product.ServiceCategoryName}
                        id={product.ServiceCategoryID}
                        value={product.ServiceCategoryID}
                    >
                        {product.ServiceCategoryName}
                    </Select.Option>
                )
            }
            else if (product.ServiceGroupID === secondCity && thuocvtyt?.ServiceGroupID !== undefined) {
                return (
                    <Select.Option
                        title={product.ServiceCategoryName}
                        key={product.ServiceCategoryName}
                        id={product.ServiceCategoryID}
                        value={product.ServiceCategoryID}
                    >
                        {product.ServiceCategoryName}
                    </Select.Option>
                )
            } else { return null; }
        })
    }*/
        const loainhom = () => {
            return listLoaiThuoc
                .filter((product) => {
                    return (
                        (product.ServiceGroupID === secondCity && thuocvtyt?.ServiceGroupID === undefined) ||
                        (secondCity === '' && thuocvtyt?.ServiceGroupID !== undefined) ||
                        (product.ServiceGroupID === secondCity && thuocvtyt?.ServiceGroupID !== undefined)
                    );
                })
                .map((product) => (
                    <Select.Option
                        title={product.ServiceCategoryName}
                        key={product.ServiceCategoryName}
                        id={product.ServiceCategoryID}
                        value={product.ServiceCategoryID}
                    >
                        {product.ServiceCategoryName}
                    </Select.Option>
                ));
        };
        
    useEffect(() => {
        getAllUser();
        let RepositoryCodeList = thuocvtyt?.RepositoryCode?.split(",");
        form.setFieldsValue({

            ItemID: thuocvtyt?.ItemID,
            ItemCode_PK: thuocvtyt?.ItemCode_PK,
            ItemName: thuocvtyt?.ItemName,
            ItemContent: thuocvtyt?.ItemContent,
            Active: thuocvtyt?.Active,
            Active_Code: thuocvtyt?.Active_Code,
            UsageID: thuocvtyt?.UsageID,
            UnitID: thuocvtyt?.UnitID,
            UnitID_Packed: thuocvtyt?.UnitID_Packed,

            SODK: thuocvtyt?.SODK,
            QUYCACH: thuocvtyt?.QUYCACH,
            DosageForms: thuocvtyt?.DosageForms,
            ServiceCategoryID: thuocvtyt?.ServiceCategoryID,
            Hide: thuocvtyt?.Hide,
            UnitPrice: thuocvtyt?.UnitPrice,
            UnitPrice_VAT: thuocvtyt?.UnitPrice_VAT,
            SalesPrice: thuocvtyt?.SalesPrice,
            BHYTPrice: thuocvtyt?.BHYTPrice,
            DisparityPrice: thuocvtyt?.DisparityPrice,
            SalesPrice_Retail: thuocvtyt?.SalesPrice_Retail,
            Rate_BH: thuocvtyt?.Rate_BH,
            CountryID: thuocvtyt?.CountryID,
            ProducerID: thuocvtyt?.ProducerID,
            IsBHYT: thuocvtyt?.IsBHYT,
            IsService: thuocvtyt?.IsService,
            SafelyQuantity: thuocvtyt?.SafelyQuantity,
           // RepositoryCode: thuocvtyt?.RepositoryCode,
            RepositoryCode: RepositoryCodeList?.map((item) => {
                return parseInt(item);
            }),

            QtyOfMeasure: thuocvtyt?.QtyOfMeasure,
            STT: thuocvtyt?.STT,
            Note: thuocvtyt?.Note,
            Generic_BD: 1,
            VENCode: 1,
            GroupPrinterID: thuocvtyt?.GroupPrinterID,
            UnitID_Medi: thuocvtyt?.UnitID_Medi,
            QtyOfMeasure_Medi: 1,//
            Toxic_Dose: thuocvtyt?.Toxic_Dose,
            ItemsBHYT_ID: thuocvtyt?.ItemsBHYT_ID,
            QtyContent_Medi: thuocvtyt?.QtyContent_Medi,
            Converted_Medi: thuocvtyt?.Converted_Medi,//
            IsKeDon_BS: thuocvtyt?.IsKeDon_BS,//
            Ma_DQG: 1,
            ServiceID_Attach: thuocvtyt?.ServiceID_Attach,
            VAT: thuocvtyt?.VAT,
            ServiceGroupID: thuocvtyt?.ServiceGroupID
        });
    }, [thuocvtyt, form]);
    return (
        <>
            <Modal forceRender className="DMThuocVTYT"
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo thuốc VTYT
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={"100vw"}
            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form}>
                        <div className="scroll">
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input"></div>}
                                    className="col-md-3 m-0"
                                    name="ItemID"
                                    hidden={true}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Nhóm:</div>}
                                    className="col-md-3 m-0"
                                    name="ServiceGroupID"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <div className="form-input-label">
                                                    Vui lòng chọn nhóm
                                                </div>
                                            ),
                                        },
                                       
                                    ]}

                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        onChange={handleProvinceChange}
                                        options={listNhomThuoc.map((item) => ({
                                            value: item.ServiceGroupID,
                                            label: item.ServiceGroupName
                                        }))}

                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Phân loại:</div>}
                                    className="col-md-3 m-0"
                                    name="ServiceCategoryID"
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        placeholder="Chọn"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return (
                                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );

                                        }}
                                    >
                                        {loainhom()}

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên hàng hoá:</div>}
                                    className="col-md-6 m-0"
                                    name="ItemName"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã hàng hoá:</div>}
                                    className="col-md-3 m-0"
                                    name="ItemCode_PK"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Hàm lượng:</div>}
                                    className="col-md-3 m-0"
                                    name="ItemContent"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Hoạt chất:</div>}
                                    className="col-md-6 m-0"
                                    name="Active"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Đường dùng:</div>}
                                    className="col-md-3 m-0"
                                    name="UsageID"
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        options={listDuongDung.map((item) => ({
                                            value: item.UsageID,
                                            label: item.UsageName
                                        }))}

                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">ĐVT Đ.Gói (Nh.Kho):</div>}
                                    className="col-md-3 m-0"
                                    name="UnitID_Packed"
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        options={listDonViTinh.map((item) => ({
                                            value: item.UnitID,
                                            label: item.UnitName
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">ĐVT:</div>}
                                    className="col-md-3 m-0"
                                    name="UnitID"
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        options={listDonViTinh.map((item) => ({
                                            value: item.UnitID,
                                            label: item.UnitName
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">ĐV kê toa:</div>}
                                    className="col-md-3 m-0"
                                    name="UnitID_Medi"
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        options={listDonViTinh.map((item) => ({
                                            value: item.UnitID,
                                            label: item.UnitName
                                        }))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="row dmdot">

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">SL Q.Đổi N.Kho:</div>}
                                    className="col-md-3 m-0"
                                    name="QtyOfMeasure"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Số đăng ký:</div>}
                                    className="col-md-3 m-0"
                                    name="SODK"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Dạng bào chế:</div>}
                                    className="col-md-6 m-0"
                                    name="DosageForms"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>

                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Giá mua:</div>}
                                    className="col-md-3 m-0"
                                    name="UnitPrice"
                                >
                                    <InputNumber className="form-control inputdm"
                                         formatter={(value) => priceSplitter(value)}
                                         parser={(value) => value.replace(/\D/g, "")} 
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Giá BHYT:</div>}
                                    className="col-md-3 m-0"
                                    name="BHYTPrice"
                                >
                                    <InputNumber className="form-control inputdm" 
                                         formatter={(value) => priceSplitter(value)}
                                         parser={(value) => value.replace(/\D/g, "")} 
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Giá dịch vụ:</div>}
                                    className="col-md-3 m-0"
                                    name="SalesPrice"
                                >
                                    <InputNumber className="form-control inputdm"
                                         formatter={(value) => priceSplitter(value)}
                                         parser={(value) => value.replace(/\D/g, "")} 
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Phụ thu BHYT:</div>}
                                    className="col-md-3 m-0"
                                    name="DisparityPrice"
                                >
                                    <InputNumber className="form-control inputdm" 
                                         formatter={(value) => priceSplitter(value)}
                                         parser={(value) => value.replace(/\D/g, "")} 
                                    />
                                </Form.Item>

                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Giá bán sỉ:</div>}
                                    className="col-md-3 m-0"
                                    name="SalesPrice_Retail"
                                >
                                    <InputNumber className="form-control inputdm" 
                                         formatter={(value) => priceSplitter(value)}
                                         parser={(value) => value.replace(/\D/g, "")} 
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Giá VAT:</div>}
                                    className="col-md-3 m-0"
                                    name="UnitPrice_VAT"
                                >
                                    <InputNumber className="form-control inputdm" 
                                         formatter={(value) => priceSplitter(value)}
                                         parser={(value) => value.replace(/\D/g, "")} 
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã thuốc AX:</div>}
                                    className="col-md-3 m-0"
                                    name="ItemsBHYT_ID"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tỷ lệ Th.Toán BH:</div>}
                                    className="col-md-3 m-0"
                                    name="Rate_BH"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row dmdot">

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Liều độc:</div>}
                                    className="col-md-3 m-0"
                                    name="Toxic_Dose"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Q.Đổi HL K.Đơn:</div>}
                                    className="col-md-3 m-0"
                                    name="QtyContent_Medi"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">VAT:</div>}
                                    className="col-md-3 m-0"
                                    name="VAT"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">D.Vụ đính kèm:</div>}
                                    className="col-md-3 m-0"
                                    name="ServiceID_Attach"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row dmdot">

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Hạn mức tồn:</div>}
                                    className="col-md-3 m-0"
                                    name="SafelyQuantity"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Nhóm in:</div>}
                                    className="col-md-3 m-0"
                                    name="GroupPrinterID"
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        options={listNhomIn.map((item) => ({
                                            value: item.GroupPrinterID,
                                            label: item.GroupPrinterName
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Kho:</div>}
                                    className="col-md-6 m-0"
                                    name="RepositoryCode"
                                >

                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ textAlign: 'left' }}
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"
                                        onChange={onChangeMultiple}
                                        optionFilterProp="label"
                                        options={listKho.map((item) => ({
                                            value: item.RepositoryID,
                                            label: item.RepositoryName
                                        }))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="row dmdot">

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Q.Cách Đ.Gói:</div>}
                                    className="col-md-4 m-0"
                                    name="QUYCACH"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">DM BHYT:</div>}
                                    className="col-md-1 m-0"
                                    name="IsBHYT" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeIsBHYT} value={checkIsBHYT} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">DM Dịch vụ:</div>}
                                    className="col-md-2 m-0"
                                    name="IsService" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeIsService} value={checkIsService} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Quy đổi SL kê toa:</div>}
                                    className="col-md-2 m-0"
                                    name="Converted_Medi" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeConvertedMedi} value={checkConvertedMedi} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Kê đơn BS:</div>}
                                    className="col-md-1 m-0"
                                    name="IsKeDon_BS" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeIsKeDonBS} value={checkIsKeDonBS} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ẩn hiện:</div>}
                                    className="col-md-1 m-0"
                                    name="Hide" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeAnHien} value={checkAnHien} />
                                </Form.Item>


                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Hãng sản xuất:</div>}
                                    className="col-md-4 m-0"
                                    name="ProducerID"
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        options={listHangSanXuat.map((item) => ({
                                            value: item.ProducerID,
                                            label: item.ProducerName
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Nước sản xuất:</div>}
                                    className="col-md-4 m-0"
                                    name="CountryID"
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        options={listNuocSanXuat.map((item) => ({
                                            value: item.CountryID,
                                            label: item.CountryName
                                        }))}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Q.Đổi SL X.Kho:</div>}
                                    className="col-md-2 m-0"
                                    name="QtyOfMeasure_Medi"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">STT:</div>}
                                    className="col-md-2 m-0"
                                    name="STT"
                                >
                                    <InputNumber className="form-control inputdm" />
                                </Form.Item>


                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ghi chú:</div>}
                                    className="col-md-12 m-0"
                                    name="Note"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>


                        </div>
                        <Form.Item className="text-center">
                            <hr />
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                Thoát <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" />
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default DetailThuocVTYT;
