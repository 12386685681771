import { createSlice } from "@reduxjs/toolkit";

export const dataTiepNhanSlice = createSlice({
    name: "dataTiepNhan",
    initialState: {
        tiepnhan:null,
        sinhhieutiepnhan:[],
        searchtiepnhan:null
    },
    reducers: {
        setDataTiepNhan: (state, action) => {
            state.tiepnhan = action.payload;
        },
        setDataSinhHieuTiepNhan: (state, action) => {
            state.sinhhieutiepnhan = action.payload;
        },
        setDataSearchTiepNhan: (state, action) => {
            state.searchtiepnhan = action.payload;
        },
    },
});
export const {
    setDataTiepNhan,
    setDataSinhHieuTiepNhan,
    setDataSearchTiepNhan
} = dataTiepNhanSlice.actions;
export default dataTiepNhanSlice.reducer;