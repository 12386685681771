import { Table, Button, Dropdown, Form, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import {
    faEdit,
    faTrashCan,
    faEye,
    faPenToSquare,
    faPlus,
    faArrowRotateLeft,
    faPrint,
    faFileExport,
    faGear,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { motion } from "framer-motion";

import ds from "../../../../../util/data";

import Search from "antd/es/input/Search";
function ToaMau() {
    const [open, setOpen] = useState(false);
    const [click, setClick] = useState(false);
    const [activeModify, setActiveModify] = useState(false);

    const handleDataCreate = () => {
        setOpen(true);
    };
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    const items = [
        {
            key: "1",
            label: "Xem",
            icon: <FontAwesomeIcon icon={faEye} />,
        },
        {
            key: "2",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "3",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const column0 = [
        {
            title: "Mã toa",
            dataIndex: "TDV",
            fixed: "left",
            render: (TDV) => <div style={{ width: "200px" }}> {TDV} </div>,
        },
        {
            title: "Tên",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "Ghi chú",
            dataIndex: "MADVKT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        }
       
    ];
    const column1 = [
        {
            title: "Thuốc",
            dataIndex: "TDV",
            fixed: "left",
            render: (TDV) => <div style={{ width: "200px" }}> {TDV} </div>,
        },
        {
            title: "ĐVT",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "600px" }}> {TDVVT} </div>,
        },
        {
            title: "Ngày cấp",
            dataIndex: "MADVKT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "S",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "T",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "C",
            dataIndex: "MADVKT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "T",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "Số lượng",
            dataIndex: "MADVKT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "Cách dùng",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        }
    ];
    const items1 = [
        {
            label: "Thêm mới",
            key: "1",
            icon: <FontAwesomeIcon icon={faPlus} />,
        },
        {
            label: "Load",
            key: "2",
            icon: <FontAwesomeIcon icon={faArrowRotateLeft} />,
        },
        {
            label: "In",
            key: "3",
            icon: <FontAwesomeIcon icon={faPrint} />,
        },
        {
            label: "Export file",
            key: "4",
            icon: <FontAwesomeIcon icon={faFileExport} />,
        },
    ];
    const menuProps = {
        items: items1,
    };

    //handle

    return (
        <>
            <Helmet>
                <title>Khai Báo Giá Dịch Vụ Kỹ Thuật - EmedLink </title>
            </Helmet>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.8 } }}
            >
                <div className="text-muted">
                    <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                        <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="vienphi-danhmuc-title mx-2 w-20">
                                Khai báo toa mẫu
                            </div>
                            <div className="w-60">
                                <Search
                                    placeholder="Tìm kiếm..."
                                    allowClear
                                    // onSearch={onSearch}
                                    className="w-100 my-1"
                                />
                            </div>
                            <div className="w-20 d-flex justify-content-center">
                                <Dropdown menu={menuProps}>
                                    <Button className="form-btn bg text-white">
                                        <FontAwesomeIcon icon={faGear} className="mx-1" />
                                        Thao tác
                                    </Button>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <hr className="w-100 my-1" />
                    <div className="m-1 danhmuc">
                        <div className="row m-0">
                            <div className="col-md-6 p-0 ">
                                <div className="banggiadvkt">
                                <div className="form-input-label namekhaibao">Tên toa</div>
                                <Table
                                    columns={column0}
                                    dataSource={ds}
                                    //loading={loading}
                                    scroll={{ x: true, y: 500 }}
                                    size="small"
                                    pagination={false}
                                    bordered
                                   
                                />
                                </div>
                            </div>
                            <div className="col-md-6 p-0 chitietgia">
                                <div className="form-input-label namekhaibao">Chi tiết toa thuốc </div>
                                <Table
                                    columns={column1}
                                    dataSource={ds}
                                    // loading={loading}
                                    scroll={{ x: true, y: 500 }}
                                    size="small"
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    );
}

export default ToaMau;
