import axios from "axios";
import REACT_LOCALHOST from "../../host";
import { authHeader } from '../../helpers';
const goikhamAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-service-package`,obj);
    },
    createupdate: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-update-med-service-package`, obj, {"headers":authHeader()});
    },
    delete: (ServicePackageID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-med-service-package/${ServicePackageID}/${EmployeeID}`);
    },
    getAllChiTietGoiKham: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-service-package-detail`,obj);
    },
    addupCTGoiKham: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-update-med-service-package-detail`, obj, {"headers":authHeader()});
    },
    deleteCTGoiKham: (RowID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-med-service-package-detail/${RowID}/${EmployeeID}`);
    },
};
export default goikhamAPI;
