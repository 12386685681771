import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Input, Checkbox } from "antd";
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPenToSquare, faPlus, faTrashCan, faBars } from '@fortawesome/free-solid-svg-icons';
import { DataGrid, GridColumn } from 'rc-easyui';
import DetailNguoiGioiThieu from '../Detail';
import moment from 'moment';
import '../nguoigioithieu.scss';

import loginAPI from "../../../../services/loginApi";
import { errorInfo, infoRes, successInfo } from "../../../../components/Dialog/Dialog";
import { setDataNguoiGioiThieu } from '../../../../slices/dataTienIch';
import tiepnhanAPI from '../../../../services/tiepnhan/tiepnhanAPI';
import nguoigioithieuAPI from '../../../../services/tienich/nguoigioithieuAPI';

const NguoiGioiThieu = () => {
    const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const [listNguoigioithieu, setListNguoigioithieu] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listNghenghiep, setListNghenghiep] = useState([]);
    const [listgioitinh, setListgioitinh] = useState([]);
    const [listTinhthanh, setlistTinhthanh] = useState([]);
    const [listQuanhuyen, setListQuanhuyen] = useState([]);
    const [listPhuongxa, setListPhuongxa] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [hide, setHide] = useState(0);

    const [userlogin, setUserLogin] = useState();

    const handleDataCreate = () => {
        setOpen(true);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        getAllNguoiGioiThieu();
        getAllNghenghiep();
        getAllgioitinh();
        getAllTinhthanh();
        getAllQuanhuyen();
        getAllPhuongxa();
        getAllUser();
    }, [hide]);
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {}
    };

    const handleToggleHide = async () => {
        const newHide = hide === 0 ? 1 : 0;
        setHide(newHide);
        try {
            setLoading(true);
            const [GioithieuData] = await Promise.all([
                nguoigioithieuAPI.fetchNguoiGioiThieu({ Hide: newHide }),
            ]);
            setListNguoigioithieu(GioithieuData.data);
            setFilteredData(GioithieuData.data);
        } catch (err) {
            console.error("Lỗi khi tải dữ liệu:", err);
        } finally {
            setLoading(false);
        }
    };
    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataNguoiGioiThieu(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa user này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleDelete(record.RowID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };

    const getAllNguoiGioiThieu = useCallback(async () => {
        try {
            setLoading(true);
            const data = await nguoigioithieuAPI.fetchNguoiGioiThieu({ 'Hide': hide });
            setListNguoigioithieu(data.data);
            setFilteredData(data.data);
            setLoading(false);
        } catch (err) {}
    }, [hide]);

    const getAllNghenghiep = async () => {
        try {
            const data = await tiepnhanAPI.getAllCareer({ 'Hide': 0 });
            setListNghenghiep(data.data);
        } catch (err) {}
    };

    const getAllgioitinh = async () => {
        try {
            const data = await tiepnhanAPI.getAllPatientGender();
            setListgioitinh(data.data);
        } catch (err) {}
    };

    const getAllTinhthanh = async () => {
        try {
            const data = await tiepnhanAPI.getAllProvincial({ 'Hide': 0 });
            setlistTinhthanh(data.data);
        } catch (err) {}
    };
    const getAllQuanhuyen = async () => {
        try {
            const data = await tiepnhanAPI.getAllDistrict({ 'Hide': 0 });
            setListQuanhuyen(data.data);
        } catch (err) {}
    };
    const getAllPhuongxa = async () => {
        try {
            const data = await tiepnhanAPI.getAllWard({ 'Hide': 0 });
            setListPhuongxa(data.data);
        } catch (err) {}
    };
    const handleCreate_Update = async (obj) => {
        const data = await nguoigioithieuAPI.addupNguoiGioiThieu(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        } else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllNguoiGioiThieu();
        }
    };

    const handleToggleHideCheckbox = async (record) => {
        const newHideValue = !record.Hide;
        const updatedRecord = { ...record, Hide: newHideValue };

        try {
            await handleCreate_Update(updatedRecord);
            successInfo("Cập nhật trạng thái thành công!");
            getAllNguoiGioiThieu();
        } catch (err) {
            errorInfo("Cập nhật trạng thái thất bại!");
            console.error(err);
        }
    };
    const handleDelete = async (RowID, EmployeeID = 1) => {
        const data = await nguoigioithieuAPI.deleteNguoiGioiThieu(RowID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            setListNguoigioithieu();
            reloadData();
        } else {
            errorInfo(data.data.Content);
        }
    };

    const handleCheckboxChange = async (row, field) => {
        const updatedValue = !row[field];

        setFilteredData((prevData) =>
            prevData.map((item) =>
                item.RowID === row.RowID
                    ? { ...item, [field]: updatedValue }
                    : item
            )
        );

        try {
            await nguoigioithieuAPI.addupNguoiGioiThieu({ ...row, [field]: updatedValue });
            successInfo("Cập nhật trạng thái thành công!");
        } catch (error) {
            errorInfo("Cập nhật trạng thái thất bại!");
        }
    };

    const reloadData = async () => {
        await Promise.all([getAllNguoiGioiThieu()]);
    };

    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];

    return (
        <div className="col-md-12 class-nguoigioithieu">
            <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                <h4 className="text-secondary mx-2">
                    <FontAwesomeIcon icon={faBars} />  Khai báo người giới thiệu
                </h4>
                <div className="textend">
                    <div className='dskhoa'>
                        <Checkbox className="form-btn" style={{ fontWeight: "400" }} onClick={handleToggleHide}>
                          DS Khoá
                        </Checkbox>
                    </div>
                    <div>
                        <Button className="form-btn bg" onClick={handleDataCreate}>
                            <FontAwesomeIcon icon={faPlus} className="mx-1" />
                            Thêm mới
                        </Button>
                    </div>
                </div>
            </div>
            <DetailNguoiGioiThieu
                open={open}
                setOpen={setOpen}
                listNguoigioithieu={listNguoigioithieu}
                listNghenghiep={listNghenghiep}
                listgioitinh={listgioitinh}
                listTinhthanh={listTinhthanh}
                listQuanhuyen={listQuanhuyen}
                listPhuongxa={listPhuongxa}
                refreshData={reloadData}
                handleCreate_Update={handleCreate_Update}
                key="DetailNguoiGioiThieu"
            />
            <div style={{ display: 'flex' }}>
                <DataGrid
                    data={filteredData}
                    loading={loading}
                    style={{ height: 600 }}
                    filterable
                    columnResizing
                >
                    <GridColumn field="IntroCode" title="Mã" align="center" width={100} resizable />
                    <GridColumn field="IntroName" title="Tên người giới thiệu" width={250} resizable />
                    <GridColumn field="Sex" title="Giới tính" align="center" width={100} resizable
                        render={({ row }) => {
                            const gender = listgioitinh.find(item => item.PatientGenderID === row.Sex);
                            return gender ? gender.PatientGenderName : "Không xác định";
                        }}
                    />
                    <GridColumn field="Mobile" title="Số điện thoại" align="center" width={100} resizable />
                    <GridColumn field="Address_" title="Địa chỉ" align="center" width={350} resizable />
                    <GridColumn field="Birthday" title="Ngày sinh" align="center" width={100} resizable
                        render={({ row }) => {
                            if (!row.Birthday) return "Không xác định";

                            return (
                                <div>
                                    {moment(row.Birthday, ["YYYY-MM-DD", "DD-MM-YYYY", "MM-DD-YYYY"]).format("DD/MM/YYYY")}
                                </div>
                            );
                        }}
                    />

                    <GridColumn field="CareerID" title="Nghề nghiệp" align="center" width={150} resizable
                        render={({ row }) => {
                            const Nghenghiep = listNghenghiep?.find((item) => item.CareerID === row.CareerID);
                            return Nghenghiep ? Nghenghiep.CareerName : "Không xác định";
                        }}
                    />
                    <GridColumn field="Note" title="Ghi chú" align="center" width={200} resizable />
                    <GridColumn field="isNguoiGuiMau" title="Người gửi mẫu" align="center" width={150} resizable
                        render={({ row }) => <Checkbox checked={row.isNguoiGuiMau}
                            onChange={() => handleCheckboxChange(row, "isNguoiGuiMau")}
                        />}
                    />
                    <GridColumn
                        field="Hide"
                        title={<span style={{ color: '#1998c2' }}>Ẩn/Hiện</span>}
                        align="center"
                        width={100}
                        filterable={false}
                        render={({ row }) => (
                            <Checkbox
                                checked={row.Hide === true}
                                onChange={() => handleToggleHideCheckbox(row)}
                            />
                        )}
                    />
                    <GridColumn
                        field="actions"
                        title="Thao tác"
                        align="center"
                        width={150}
                        filterable={false}
                        render={({ row }) => (
                            <div className="d-flex justify-content-center align-center">
                                <Dropdown
                                    menu={{
                                        items,
                                        onClick: (e) => handleThaoTac(e, row),
                                    }}
                                    placement="left"
                                    arrow={{
                                        pointAtCenter: true,
                                    }}
                                >
                                    <Button
                                        className="bg-light vienphi-danhmuc-icon-modify"
                                        onClick={() => {
                                            setActiveModify(!activeModify);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            style={{ fontSize: "10px" }}
                                            className="text-dark"
                                        />
                                    </Button>
                                </Dropdown>
                            </div>
                        )}
                    />
                </DataGrid>
            </div>
        </div>
    );
};

export default NguoiGioiThieu;