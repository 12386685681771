import React, { createContext, useContext, useState,useEffect } from "react";
import loginAPI from "../../services/loginApi"
const AuthContext = createContext(null); 
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        getAllUser();
    }, []); 

    useEffect(() => {
    }, [user]);
    
    const getAllUser = async () => {
        const ListUser = JSON.parse(localStorage.getItem("user")) || null;
    
        if (!ListUser) {
            return;
        }
    
        try {
            const response = await loginAPI.phanquyenurl({ EmployeeID: ListUser });
            
            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                setUser(response.data);
            } else {
                setUser([]); // Đặt user là mảng rỗng để tránh lỗi undefined
            }
        } catch (err) {
            setUser([]); // Nếu lỗi, đặt user là []
        }
    }

  

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
    }
    return context;
};
