import { Modal, Button, Form, Input, Checkbox, InputNumber } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { errDialog,errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import loginAPI from "../../../../../services/loginApi";
import { setDataHangSanXuat } from "../../../../../slices/dataDMDuoc";
import hangsanxuatAPI from "../../../../../services/duoc/danhmuc/hangsanxuatAPI";
import  './hangsanxuat.scss'
function DetailHangSanXuat(props) {
    const { open, setOpen, handleUpdate,reLoad} = props;
    const { hangsanxuat } = useSelector((state) => state.dataDMDuoc);
    const [userlogin, setUserLogin] = useState();
    const [checkAnHien, setcheckAnHien] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleCreate = async (obj) => {
        const data = await hangsanxuatAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);
        }
         else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }
    };
    const handleSubmit = async (e) => {
        if (e?.ProducerName === undefined) { errDialog(); } else {
            Swal.fire({
                title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
                confirmButtonText: "Đồng ý",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                customClass: {
                    title: "fs-5 text-dark",
                    confirmButton: "shadow-none",
                    cancelButton: "shadow-none",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    if (hangsanxuat?.ProducerID) {
                        var arrayEdit = {
                            ProducerID: e?.ProducerID,
                            ProducerName: e?.ProducerName,
                            STT: e?.STT,
                            Hide: e?.Hide,
                            EmployeeID: userlogin.RowID,

                        }
                        handleUpdate(arrayEdit);
                        dispatch(setDataHangSanXuat());
                        form.resetFields();
                        setOpen(false);
                    } else {
                        var arrayAdd = {
                            ProducerID: e?.ProducerID,
                            ProducerName: e?.ProducerName,
                            STT: e?.STT === undefined ? 1 : e?.STT,
                            Hide: checkAnHien === false ? 0 : 1,
                            EmployeeID: userlogin.RowID,

                        }
                        handleCreate(arrayAdd);
                    }
                }
                
               
            });
        }

    }
    const onChange = (e) => {
        setcheckAnHien(e.target.checked)
    };

    useEffect(() => {
        getAllUser();
        form.setFieldsValue({
            ProducerID: hangsanxuat?.ProducerID,
            ProducerName: hangsanxuat?.ProducerName,
            STT: hangsanxuat?.STT,
            Hide: hangsanxuat?.Hide,


        });
    }, [hangsanxuat,form]);
    return (
        <>
            <Modal className="DetailHangSanXuat"
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo hãng sản xuất
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={1000}
                closable={false}
                forceRender
                maskClosable={false}

            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form}>
                        <div className="scroll">
                            <div className="row dmdot">
                                <Form.Item
                                    label={
                                        <div className="form-input-label input-label-khaibaouser ">
                                            ID:
                                        </div>
                                    }
                                    className="col-md-6"
                                    name="ProducerID"
                                    hidden={true}

                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên hãng sản xuất:</div>}
                                    className="col-md-8 m-0"
                                    name="ProducerName"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">STT:</div>}
                                    className="col-md-2 m-0"
                                    name="STT"
                                >
                                    <InputNumber className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ẩn/Hiện:</div>}
                                    className="col-md-2 m-0"
                                    name="Hide" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChange} value={checkAnHien} />
                                </Form.Item>

                            </div>
                        </div>
                        <Form.Item className="text-center">
                            <hr />
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default DetailHangSanXuat;
