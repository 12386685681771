import { Modal, Tabs, Button, Form, Input, Checkbox, Table, Dropdown, Radio, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendarCheck,
    faFileExcel,
    faPenToSquare,
    faReply,
    faSave,
    faFile,
    faAngleRight,
    faAngleLeft,
    faEdit,
    faTrashCan,
    faEye,
    faArrowsRotate,
    faBroom,
    faCapsules,
    faPills,
    faHouseChimneyMedical,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "../tiemchung.scss";
import ds from "../../../util/data";
function ChiDinhVacXin() {
    const [click, setClick] = useState(false);
    const [openModelToathuoctainha, setModelToathuoctainha] = useState(false);
    const tabkhambenh = [
        {
            key: "ThuocVatTu",
            label: "Thuốc vật tư",
            children: (
                <div className="kbyhct-tab">
                    nn
                </div>
            ),
        },
        {
            key: "ChiDinh",
            label: "Chỉ định thêm hoặc hẹn mũi tiêm vắcxin",
            children: (
                <div className="kbyhct-tab">
                    nn
                </div>
            ),
        }

    ];
    const items1 = [
        {
            label: <div className="form-input-label khambenh-form-input-label">F3 - Lưu</div>,
            key: "1",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faSave} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">F2 - Bỏ qua</div>,
            key: "2",
            icon: <FontAwesomeIcon icon={faReply} />,
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">F4 - Sửa</div>,
            key: "3",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faPenToSquare} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">Hủy</div>,
            key: "4",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFileExcel} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">F5 - In toa</div>,
            key: "5",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFileExcel} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">Toa trắng</div>,
            key: "6",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFile} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">CĐ hẹn TK</div>,
            key: "7",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faCalendarCheck} type="regular" />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">Đóng HS</div>,
            key: "8",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faBook} />
                </div>
            ),
        },
    ];
    const itemsl = [
        {
            key: "1",
            label: "Xem",
            icon: <FontAwesomeIcon icon={faEye} />,
        },
        {
            key: "2",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "3",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const column0 = [
        {
            title: "STT",
            dataIndex: "TDV",
            fixed: "left",
            render: (TDV) => <div style={{ width: "200px" }}> {TDV} </div>,
        },
        {
            title: "Mã BN",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "600px" }}> {TDVVT} </div>,
        },
        {
            title: "Họ và tên",
            dataIndex: "MADVKT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "Năm sinh",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "Giới tính",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "Đối tượng",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "Thao tác",
            dataIndex: "",
            align: "center",
            fixed: "right",
            render: (record) => (
                <div className="d-flex justify-content-center">
                    <Dropdown
                        menu={{
                            itemsl,
                        }}
                        placement="left"
                        arrow={{
                            pointAtCenter: true,
                        }}
                    >
                        <Button
                            className="bg-light vienphi-danhmuc-icon-modify"
                            onClick={() => {

                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: "10px" }}
                                className="text-dark"
                            />
                        </Button>
                    </Dropdown>
                </div>
            ),
        },
    ];
    const itemsthuoc = [
        {
            label: <div className="form-input-label">Toa thuốc tại chổ</div>,
            key: "1",
            icon: <FontAwesomeIcon icon={faCapsules} />,
        },
        {
            label: <div className="form-input-label">Toa mua ngoài</div>,
            key: "2",
            icon: <FontAwesomeIcon icon={faPills} />,
        },
        {
            label: <div className="form-input-label">Thuốc tủ trực</div>,
            key: "3",
            icon: <FontAwesomeIcon icon={faHouseChimneyMedical} />,
        },
    ];
    const handleSetOpen = (a) => {
        if (a === "1") {
            setModelToathuoctainha(true);
        } else if (a === "2") {
            //setModelToamuangoai(true);
        } else if (a === "3") {
            //setModelThuoctutruc(true);
        }
    };

    return (
      <div></div>
    );
}

export default ChiDinhVacXin;
