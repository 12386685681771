import { Button, Dropdown, Checkbox, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faTrashCan,
    faPenToSquare,
    faPlus,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { DataGrid, GridColumn, ComboBox } from 'rc-easyui';
import 'rc-easyui/dist/themes/material-teal/easyui.css';
import 'rc-easyui/dist/themes/icon.css';
import 'rc-easyui/dist/themes/react.css';
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import DetailKho from "../Detail";
import { errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import khoAPI from "../../../../../services/duoc/danhmuc/khoAPI";
import { setDataKhoDuoc } from "../../../../../slices/dataDMDuoc";
import loginAPI from "../../../../../services/loginApi";
import LoadPage from "../../../../../loadpage";
import '../Detail/khoduoc.scss';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';


dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
function Kho() {
    const [loadpage, setLoadPage] = useState(false);
    const [open, setOpen] = useState(false);
    const [HideCheck, setHideCheck] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const [valueNhom, setvalueNhom] = useState(null);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const [listKho, setlistKho] = useState([]);
    const [listKhoGroup, setlistKhoGroup] = useState([]);
    const [userlogin, setUserLogin] = useState();
    const [filteredData, setFilteredData] = useState([]);

    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };

    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataKhoDuoc(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {

                    handleDelete(record.RepositoryID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };
    const getAllKho = async () => {
        try {
            const data = await khoAPI.getAll({ 'Hide': HideCheck === false ? 0 : 1 });
            setlistKho(data.data);
            setFilteredData(data.data);

        } catch (err) { }
    };
    const getAllGroupKho = async () => {
        try {
            const data = await khoAPI.getAllGroup();
            setlistKhoGroup(data.data);
        } catch (err) { }
    };
    const handleUpdate = async (obj) => {
        const data = await khoAPI.update(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllKho();
        }
        else if (data.data.Code === 404) {
            infoRes(data.data.Content);
        }
        else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (RepositoryID, EmployeeID) => {
        const data = await khoAPI.delete(RepositoryID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllKho();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const onChange_isDongY = async (e, record) => {
        var arrayEdit = {
            RepositoryID: record?.RepositoryID,
            RepositoryName: record?.RepositoryName,
            RepositoryGroupID: record?.RepositoryGroupID,
            RepositoryID_DuTru: record?.RepositoryID_DuTru,
            STT: record?.STT,
            Hide: record?.Hide,
            isDongY: e.target.checked === false ? 0 : 1,
            isDuyetBV01: record?.isDuyetBV01,
            EmployeeID: userlogin.RowID,
            DateReport: record?.DateReport

        }


        const data = await khoAPI.update(arrayEdit);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllKho();
        } else {
            errorInfo(data.data.Content);
        }
    };

    const onChange_isDuyetBV01 = async (e, record) => {
        var arrayEdit = {
            RepositoryID: record?.RepositoryID,
            RepositoryName: record?.RepositoryName,
            RepositoryGroupID: record?.RepositoryGroupID,
            RepositoryID_DuTru: record?.RepositoryID_DuTru,
            STT: record?.STT,
            Hide: record?.Hide,
            isDongY: record?.isDongY,
            isDuyetBV01: e.target.checked === false ? 0 : 1,
            EmployeeID: userlogin.RowID,
            DateReport: record?.DateReport

        }
        const data = await khoAPI.update(arrayEdit);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllKho();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const onChange_Hide = async (e, record) => {
        var arrayEdit = {
            RepositoryID: record?.RepositoryID,
            RepositoryName: record?.RepositoryName,
            RepositoryGroupID: record?.RepositoryGroupID,
            RepositoryID_DuTru: record?.RepositoryID_DuTru,
            STT: record?.STT,
            Hide: e.target.checked === false ? 0 : 1,
            isDongY: record?.isDongY,
            isDuyetBV01: record?.isDuyetBV01,
            EmployeeID: userlogin.RowID,
            DateReport: record?.DateReport

        }
        const data = await khoAPI.update(arrayEdit);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllKho();

        } else {
            errorInfo(data.data.Content);
        }
    };



    useEffect(() => {
        document.title = 'Kho thuốc - EmedLink';
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        const getAllKho = async () => {
            const data = await khoAPI.getAll({ 'Hide': HideCheck === false ? 0 : 1 });
            setlistKho(data.data);
            setFilteredData(data.data);
        };

        getAllKho();
        getAllGroupKho();
        getAllUser();
    }, [HideCheck]);
    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const filterDataNhom = () => {
        return listKhoGroup.map(item => ({
            text: item.RepositoryGroupName,
            value: item.RepositoryGroupID
        }))
    };

    const onChangeNgay = (ngay) => {
        if (ngay !== '') {
            const filteredData = listKho.filter((row) => {
                return row.NgayLap === ngay;
            });

            setFilteredData(filteredData);
        } else {
            getAllKho();
        }


    }

    if (loadpage === true) {
        return (
            <>
                <div className="text-muted khothuoclist">
                    <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                        <div className="d-flex align-items-center ">
                            <div className="vienphi-danhmuc-title mx-2"><FontAwesomeIcon icon={faBars} /> Kho</div>
                        </div>
                        <div className="text-end">
                            <div className="nhomin-act-check">
                                <div className="col-n"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                                <div className="col-n">
                                    <Button className="form-btn bg" onClick={handleDataCreate}>
                                        <FontAwesomeIcon icon={faPlus} className="mx-1" />
                                        Thêm mới
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DetailKho
                        open={open} setOpen={setOpen}
                        listKhoGroup={listKhoGroup}
                        listKho={listKho}
                        reLoad={getAllKho}
                        handleUpdate={handleUpdate}
                        key="DetailKho"
                    />
                    <div className="m-1 danhmuc">
                        <DataGrid filterable data={filteredData} columnResizing style={{ height: '90vh' }}>
                            <GridColumn field="RepositoryID" title="Mã kho" width={150}></GridColumn>
                            <GridColumn field="RepositoryName" title="Tên kho" width={350}></GridColumn>
                            <GridColumn field="RepositoryGroupID" title="Nhóm kho" width={350}
                                filter={() => (
                                    <ComboBox
                                        data={filterDataNhom()}
                                        editable={false}
                                        inputStyle={{}}
                                        value={valueNhom}
                                        onChange={(value) => setvalueNhom(value)}
                                        addonRight={() => (
                                            <span className="textbox-icon icon-clear" title="Clear value" onClick={() => setvalueNhom(null)}></span>
                                        )}

                                    />
                                )}
                                render={({ row }) => {
                                    const matchedGroup = listKhoGroup.find(item => item.RepositoryGroupID === row.RepositoryGroupID);
                                    return <div>{matchedGroup ? matchedGroup.RepositoryGroupName : ''}</div>;
                                }}

                            />
                            <GridColumn field="NgayLap" title="Ngày" width={150}

                                filter={() => (
                                    <DatePicker placeholder="Chọn" className="form-control" format={'DD/MM/YYYY'} onChange={(date, dateString) => { onChangeNgay(dateString); }
                                    } />
                                )
                                }


                                render={({ row }) => (<div>{row.NgayLap}</div>)}
                            />
                            <GridColumn filterable={false} title="Dự trù" width={300}
                                render={({ row }) => {
                                    if (row.RepositoryID_DuTru === 0) return null;

                                    const filteredKho = listKho.filter(item => item.RepositoryGroupID === 1);

                                    return (
                                        <>
                                            {filteredKho.length > 0 ? (
                                                filteredKho.map((itemdutru, indexdt) => (
                                                    <div key={indexdt}>{itemdutru.RepositoryName}</div>
                                                ))
                                            ) : null
                                            }
                                        </>
                                    );
                                }}

                            />
                            <GridColumn filterable={false} title="Đông y" align="center" width={90}
                                render={({ row }) => (
                                    <div>
                                        {row.isDongY === true ?
                                            <Checkbox onChange={(e) => onChange_isDongY(e, row)} defaultChecked />
                                            :
                                            <div> <Checkbox onChange={(e) => onChange_isDongY(e, row)} /></div>
                                        }
                                    </div>

                                )}
                            ></GridColumn>
                            <GridColumn filterable={false} align="center" title="Duyệt BV01" width={90}
                                render={({ row }) => (
                                    <div>
                                        {row.isDuyetBV01 === true ?
                                            <Checkbox onChange={(e) => onChange_isDuyetBV01(e, row)} defaultChecked />
                                            :
                                            <div><Checkbox onChange={(e) => onChange_isDuyetBV01(e, row)} /></div>
                                        }
                                    </div>

                                )}
                            />
                            <GridColumn align="center" title="Ẩn/Hiện" width={90} filterable={false}
                                render={({ row }) => (
                                    <div>
                                        {row?.Hide === true ?
                                            <Checkbox onChange={(e) => onChange_Hide(e, row)} checked={true} />
                                            :
                                            <Checkbox onChange={(e) => onChange_Hide(e, row)} checked={false} />
                                        }
                                    </div>

                                )}
                            />
                            <GridColumn title="Thao tác" width={90} filterable={false}
                                render={({ row }) => (
                                    <div className="d-flex justify-content-center" style={{ width: "80px" }}>
                                        <Dropdown
                                            menu={{
                                                items,
                                                onClick: (e) => handleThaoTac(e, row),
                                            }}
                                            placement="left"
                                            arrow={{
                                                pointAtCenter: true,
                                            }}
                                        >
                                            <Button
                                                className="bg-light vienphi-danhmuc-icon-modify"
                                                onClick={() => {
                                                    setActiveModify(!activeModify);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    style={{ fontSize: "10px" }}
                                                    className="text-dark"
                                                />
                                            </Button>
                                        </Dropdown>
                                    </div>

                                )}
                            />
                        </DataGrid>
                    </div>
                </div>
            </>
        );
    } else { return <LoadPage /> }
}

export default Kho;
