import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Checkbox } from "antd";
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPenToSquare, faPlus, faTrashCan, faBars } from '@fortawesome/free-solid-svg-icons';
import { DataGrid, GridColumn } from 'rc-easyui';
import goikhamAPI from '../../../../../services/vienphi/danhmuc/goikhamAPI';
import dvktAPI from '../../../../../services/vienphi/danhmuc/dvktAPI';
import giadvktAPI from '../../../../../services/vienphi/danhmuc/giadvktAPI';
import { setDataGoiKham } from '../../../../../slices/dataVienPhi';
import { errorInfo, infoRes, successInfo } from '../../../../../components/Dialog/Dialog';
import loginAPI from "../../../../../services/loginApi";
import LoadPage from "../../../../../loadpage";
import "../goidvkt.scss";
import DetailGoiKhamDVKT from '../Detail';
import $ from "jquery";
import ChiTietGoiKham from '../ChiTietGoiKham';
function GoiDVKTVienPhi() {
    const [open, setOpen] = useState(false);
    const [HideCheck, setHideCheck] = useState(false);
    const [loadpage, setLoadPage] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const [ListGoiKham, setListGoiKham] = useState([]);
    const [ListDVKT, setListDVKT] = useState([]);
    const [ListGiaDVKT, setListGiaDVKT] = useState([]);
    const [ListChiTietGK, setListChiTietGK] = useState([]);
    const [openChiTiet, setOpenChiTiet] = useState(false);
    const [selectedServicePackageID, setSelectedServicePackageID] = useState(null);
    const [selectedChiTiet, setSelectedChiTiet] = useState(null);
    const [userlogin, setUserLogin] = useState();
    const [selectedRowActive, setselectedRowActive] = useState(null);
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataGoiKham(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {

                    handleDelete(record.ServicePackageID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
        else if (e.key === "chitiet") {
            setSelectedChiTiet({ ServicePackageID: record.ServicePackageID });
            setOpenChiTiet(true);
        }
    };
    const getAllGoiKham = async () => {
        try {
            const data = await goikhamAPI.getAll({ 'Hide': HideCheck === false ? 0 : 1 });
            setListGoiKham(data.data);

        } catch (err) { }
    };

    const handleUpdate = async (obj) => {
        const data = await goikhamAPI.createupdate(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllGoiKham();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (ServicePackageID, EmployeeID) => {
        const data = await goikhamAPI.delete(ServicePackageID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllGoiKham();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleToggleHideCheckbox = async (record) => {
        const newHideValue = record.Hide === false ? true : false;
        const updatedRecord = { ...record, Hide: newHideValue };

        try {
            await handleUpdate(updatedRecord);
            successInfo("Cập nhật trạng thái thành công!");
            getAllGoiKham();
        } catch (err) {
            errorInfo("Cập nhật trạng thái thất bại!");

        }
    };

    const handleCheckboxChange = async (row, field) => {
        const updatedValue = !row[field];

        setListGoiKham((prevData) =>
            prevData.map((item) =>
                item.ServicePackageID === row.ServicePackageID
                    ? { ...item, [field]: updatedValue }
                    : item
            )
        );

        try {
            await goikhamAPI.createupdate({ ...row, [field]: updatedValue });
            successInfo("Cập nhật trạng thái thành công!");
        } catch (error) {
            errorInfo("Cập nhật trạng thái thất bại!");
            console.error(error);
        }
    };
    const getAllChitietGK = useCallback(async () => {
        try {
            const data = await goikhamAPI.getAllChiTietGoiKham({ 'ServicePackageID': selectedServicePackageID || 0 });
            setListChiTietGK(data.data);
        } catch (err) { }
    }, [selectedServicePackageID]);
    const getAllDVKT = async () => {
        try {
            const data = await dvktAPI.getAll({ 'ServiceID': '', 'Hide': 0 });
            setListDVKT(data.data);
        } catch (err) { }
    };
    const getAllGiaDVKT = async () => {
        try {
            const data = await giadvktAPI.getAll({ 'Hide': 0 });
            setListGiaDVKT(data.data);
        } catch (err) { }
    };
    const handleCreate_Update_CT = async (obj) => {
        const data = await goikhamAPI.addupCTGoiKham(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        } else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            await getAllChitietGK();
        }
    };


    useEffect(() => {
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        const getAllGoiKham = async () => {
            const data = await goikhamAPI.getAll({ 'Hide': HideCheck === false ? 0 : 1 });
            setListGoiKham(data.data);
        };
        const getAllChitietGK = async () => {
            try {
                const data = await goikhamAPI.getAllChiTietGoiKham({ 'ServicePackageID': selectedServicePackageID || 0 });
                setListChiTietGK(data.data);
            } catch (err) { }
        };

        getAllGoiKham();
        getAllUser();
        getAllChitietGK();
        getAllDVKT();
        getAllGiaDVKT();


    }, [HideCheck, selectedServicePackageID, selectedRowActive]);
    const handleRowDoubleClick = (row) => {
        setSelectedServicePackageID(row.ServicePackageID);
    };
    const getRowCss=(row)=> {
        if (row.ServicePackageID === selectedRowActive) {
          return { background: "#eaf2ff", fontSize: "14px", fontStyle: "italic" };
        }
        return null;
      };
    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
        {
            key: "chitiet",
            label: "Chi tiết gói",
            icon: <FontAwesomeIcon icon={faPlus} />,
        },
    ];

    if (loadpage === true) {
        return (
            <>
                <div className="text-muted goi-dvkt-vien-phi">
                    <div className="row mt-2 mx-2">
                        <div className=" col-md-12 m-0 p-0">
                            <div className="title-goi-dvkt justify-content-between">
                                <div className="vienphi-danhmuc-title mx-1"><FontAwesomeIcon icon={faBars} /> Danh sách gói</div>
                                <div className="text-end">
                                    <div className="nhomin-act-check">
                                        <div className="col-n"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                                        <div className="col-n">
                                            <Button className="form-btn bg" onClick={handleDataCreate}>
                                                <FontAwesomeIcon icon={faPlus} className="mx-1" />
                                                Thêm mới
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DetailGoiKhamDVKT
                                open={open}
                                setOpen={setOpen}
                                listGoikham={ListGoiKham}
                                refreshData={getAllGoiKham}
                                handleUpdate={handleUpdate}
                                key="DetailGoiKhamDVKT"
                            />
                            <DataGrid
                                data={ListGoiKham}
                                style={{ height: 400 }}
                                filterable
                                columnResizing
                                dblclickToEdit
                                editMode="row"
                                rowCss={getRowCss}
                                onRowDblClick={(row) => { handleRowDoubleClick(row); setselectedRowActive(row.ServicePackageID); }}
                            >
                                <GridColumn field="ServicePackageID" title="ID Gói khám" align="center" width={120} resizable />
                                <GridColumn field="ServicePackageCode" title="Mã gói khám" align="center" width={150} resizable />
                                <GridColumn field="ServicePackageName" title="Tên gói khám" width={350} resizable />
                                <GridColumn field="isGoiKSK" title="Gói khám sức khỏe" align="center" width={100} resizable filterable={false}
                                    render={({ row }) => <Checkbox checked={row.isGoiKSK}
                                        onChange={() => handleCheckboxChange(row, "isGoiKSK")}
                                    />}
                                />
                                <GridColumn field="Amount" title="Tổng tiền" align="right" width={100} resizable
                                    render={({ row }) => (
                                        <div>
                                            {row.Amount !== undefined
                                                ? `${row.Amount.toLocaleString('vi-VN')}`
                                                : "0"}
                                        </div>
                                    )}
                                />
                                <GridColumn field="STT" title="STT" align="center" width={50} resizable />
                                <GridColumn
                                    field="Hide"
                                    title={<span style={{ color: '#1998c2' }}>Ẩn/Hiện</span>}
                                    align="center"
                                    width={100}
                                    filterable={false}
                                    render={({ row }) => (
                                        <Checkbox
                                            checked={row.Hide === true}
                                            onChange={() => handleToggleHideCheckbox(row)}
                                        />
                                    )}
                                />
                                <GridColumn
                                    field="actions"
                                    title="Thao tác"
                                    align="center"
                                    width={100}
                                    filterable={false}
                                    render={({ row }) => (
                                        <div className="d-flex justify-content-center align-center">
                                            <Dropdown
                                                menu={{
                                                    items,
                                                    /**
                                                     *
                                                     */
                                                    onClick: (e) => handleThaoTac(e, row, "goikham"),
                                                }}
                                                placement="left"
                                                arrow={{
                                                    pointAtCenter: true,
                                                }}
                                            >
                                                <Button
                                                    className="bg-light vienphi-danhmuc-icon-modify"
                                                    onClick={() => {
                                                        setActiveModify(!activeModify);
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                        style={{ fontSize: "10px" }}
                                                        className="text-dark"
                                                    />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    )}
                                />
                            </DataGrid>
                        </div>
                        <ChiTietGoiKham
                            open={openChiTiet}
                            setOpen={setOpenChiTiet}
                            ListChiTietGK={ListChiTietGK}
                            ListDVKT={ListDVKT}
                            ListGoiKham={ListGoiKham}
                            selectedData={selectedChiTiet}
                            ListGiaDVKT={ListGiaDVKT}
                            refreshData={getAllGoiKham}
                            handleCreate_Update_CT={handleCreate_Update_CT}
                            key="ChiTietGoiKham"
                        />
                        <div className='col-md-12 m-0 p-0' >
                            <DataGrid
                                data={ListChiTietGK}
                                style={{ height: 600 }}
                                filterable
                                columnResizing
                            >
                                <GridColumn field="ServicePackageID" title="Mã gói khám" align="center" width={50} />
                                <GridColumn
                                    field="ServiceID" title="Tên dịch vụ" align="left" width={200} resizable
                                    render={({ row }) => {
                                        if (!row.ServiceID || !ListDVKT.length) return "Không xác định";
                                        const ServiceIDs = row.ServiceID.split(',').map((code) => code.trim());
                                        const ServiceNames = ServiceIDs.map((code) => {
                                            const service = ListDVKT.find((item) => item.ServiceID === code);
                                            return service ? service.ServiceName : "Không xác định";
                                        }).filter((name) => name);
                                        const displayText = ServiceNames.length > 0 ? ServiceNames.join(', ') : "Không xác định";
                                        return (
                                            <div
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                }}
                                                title={displayText}
                                            >
                                                {displayText}
                                            </div>
                                        );
                                    }}
                                />


                                <GridColumn field="STT" title="STT" align="center" width={50} resizable />
                            </DataGrid>
                        </div>
                    </div>
                </div>

            </>
        );
    } else { return <LoadPage /> }
}

export default GoiDVKTVienPhi;
