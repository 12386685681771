import { logDOM, logRoles } from "@testing-library/react";
import { Modal } from "antd";
import { useState, useEffect, useRef } from "react";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import 'react-image-crop/dist/ReactCrop.css';
import './cropimage.scss';
function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}
function CropImage(props) {
    const { open, setOpen, dsanh, imgchoose } = props;
    const [imgSrc, setImgSrc] = useState("");
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const hiddenAnchorRef = useRef(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(16 / 9)
    const [imageUrl, setImageUrl] = useState(null)

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    async function onDownloadCropClick() {
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )
        const blob = await offscreen.convertToBlob({
            type: 'image/png',
        })

        if (blobUrlRef.current) {
            URL.revokeObjectURL(blobUrlRef.current)

        }
        blobUrlRef.current = URL.createObjectURL(blob)

        /*if (hiddenAnchorRef.current) {
            hiddenAnchorRef.current.href = blobUrlRef.current
            hiddenAnchorRef.current.click()
        }*/
        const imageUrl = URL.createObjectURL(blob);
        dsanh[imgchoose.vitri] = imageUrl;
        setImageUrl(imageUrl);
        setOpen(false);
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else {
            setAspect(16 / 9)

            if (imgRef.current) {
                const { width, height } = imgRef.current
                const newCrop = centerAspectCrop(width, height, 16 / 9)
                setCrop(newCrop)
                // Updates the preview
                setCompletedCrop(convertToPixelCrop(newCrop, width, height))
            }
        }
    }
    useEffect(() => {
    }, []);
    return (
        <Modal
            centered
            open={open}
            okButtonProps={{
                style: {
                    display: "none",
                },
            }}
            cancelButtonProps={{
                style: {
                    display: "none",
                },
            }}
            closable={false}
            width={"50vw"}

            onCancel={() => setOpen(false)}
            forceRender
            maskClosable={false}

        >
            <div>
                <div className="CropImage">
                    <div className="Crop-Controls">

                        <div className="full">
                            <label htmlFor="scale-input">Phóng to/Thu nhỏ: </label>
                            <input
                                id="scale-input"
                                type="number"
                                step="0.1"
                                value={scale}
                                disabled={!imgchoose.linkanh}
                                onChange={(e) => setScale(Number(e.target.value))}
                            />
                        </div>
                        <div className="xoay">
                            <label htmlFor="rotate-input">Xoay ảnh: </label>
                            <input
                                id="rotate-input"
                                type="number"
                                value={rotate}
                                disabled={!imgchoose.linkanh}
                                onChange={(e) =>
                                    setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                                }
                            />
                        </div>
                        <div className="congcu">
                            <button onClick={handleToggleAspectClick}>
                                Chuyển đổi {aspect ? 'off' : 'on'}
                            </button>
                        </div>
                    </div>
                    <div className="rowimage">
                    {!!imgchoose.linkanh && (
                        <ReactCrop
                            className="hinhanh"
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                            // minWidth={400}
                            minHeight={100}
                        //circularCrop
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgchoose.linkanh}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                    {!!completedCrop && (
                        <div>
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    border: '1px solid black',
                                    objectFit: 'contain',
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                }}
                            />
                        </div>


                    )}
                    </div>
                </div>
                <div className="btncrop">
                    <button onClick={onDownloadCropClick}>Lưu ảnh</button>
                    <button onClick={() => setOpen(false)}>Thoát</button>
                </div>

            </div>
        </Modal>
    );
}

export default CropImage;
