import { Table, Button, Dropdown } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import {
    faEdit,
    faTrashCan,
    faEye,
    faPenToSquare,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { motion } from "framer-motion";
import DetailCVNV from "../Detail";
function KhaiBaoPhongBan() {
    const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const items = [
        {
            key: "1",
            label: "Xem",
            icon: <FontAwesomeIcon icon={faEye} />,
        },
        {
            key: "2",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "3",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const columns = [
        {
            title: "Mã nhóm",
            dataIndex: "TDV",
            fixed: "left",
            render: (TDV) => <div style={{ width: "150px" }}> {TDV} </div>,
        },
        {
            title: "Tên nhóm",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "500px" }}> {TDVVT} </div>,
        },
        {
            title: "STT",
            dataIndex: "MADVKT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "Thao tác",
            dataIndex: "",
            align: "center",
            fixed: "right",
            render: (record) => (
                <div className="d-flex justify-content-center">
                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="left"
                        arrow={{
                            pointAtCenter: true,
                        }}
                    >
                        <Button
                            className="bg-light vienphi-danhmuc-icon-modify"
                            onClick={() => {
                                setActiveModify(!activeModify);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: "10px" }}
                                className="text-dark"
                            />
                        </Button>
                    </Dropdown>
                </div>
            ),
        },
    ];

    return (

        <div className="text-muted">
            <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                <div className="d-flex align-items-center ">
                    <div className="vienphi-danhmuc-title mx-2">Khai báo phòng ban</div>
                </div>
                <div className="text-end">
                    <Button className="form-btn bg" onClick={handleDataCreate}>
                        <FontAwesomeIcon icon={faPlus} className="mx-1" />
                        Thêm mới
                    </Button>
                </div>
            </div>
            <DetailCVNV open={open} setOpen={setOpen} />

            <hr className="w-100 my-1" />
            <div className="m-1 danhmuc danhmuc_nhomvpht">
                <Table
                    columns={columns}
                    dataSource={''}
                    // loading={loading}
                    scroll={{ x: true }}
                    size="small"
                    pagination={{ pageSize: 20 }}
                />
            </div>
        </div>

    );
}

export default KhaiBaoPhongBan;
