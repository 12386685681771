import { Button, Form, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DataGrid, GridColumn } from 'rc-easyui';
import moment from 'moment';
import "./dathenonline.scss";
import dathenAPI from "../../../services/dathen/dathenAPI";
import tiepnhanAPI from "../../../services/tiepnhan/tiepnhanAPI";
import { setDataDatHen } from "../../../slices/dataDatHen";
import { setDataSearchTiepNhan,setDataTiepNhan } from "../../../slices/dataTiepNhan";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
function DatHenOnline(props) {
    const { setActiveKey } = props;
    const [ListDatHen, setListDatHen] = useState([]);
    const [ListGioiTinh, setListGioiTinh] = useState([]);
    const [TuNgay, setTuNgay] = useState('');
    const [DenNgay, setDenNgay] = useState('');
    const dispatch = useDispatch();
    const [form] = Form.useForm();
  
    const getAllGioiTinh = async () => {
        try {
            const data = await tiepnhanAPI.getAllPatientGender();
            setListGioiTinh(data.data);
        } catch (err) { }
    };
    const handleSubmit = async (e) => {
        try {
            const data = await dathenAPI.getAll({ App_Date_From: e?.App_Date_From, App_Date_To: e?.App_Date_To });
            setListDatHen(data.data);
        } catch (err) {
        }
    }
    const switchToTab2 = () => {
        setActiveKey("CongKham"); // Change active tab to "2"
    };
    const handleRowEditEnd = async (event) => {
        dispatch(setDataDatHen(event));
        dispatch(setDataSearchTiepNhan(null));
        dispatch(setDataTiepNhan(null));
        switchToTab2();
    }
    useEffect(() => {
        const getAllDatHen = async () => {
            try {
                const data = await dathenAPI.getAll({ App_Date_From: TuNgay === '' ? dayjs(new Date()) : TuNgay, App_Date_To: DenNgay === '' ? dayjs(new Date()) : DenNgay });
                setListDatHen(data.data);
            } catch (err) {

            }
        };
        getAllDatHen();
        getAllGioiTinh();
    }, [form, ListDatHen,TuNgay,DenNgay]);
    const getFooterData = () => {
        return [
            { PatientName: "Tổng cộng:", PatientGenderID: ListDatHen.length }
        ]
    }
    const initialValues = {
        App_Date_From: dayjs(new Date()),
        App_Date_To: dayjs(new Date())
    };
    return (
        <>
            <div className="m-1 tn-from">
                <Form onFinish={handleSubmit} form={form} initialValues={initialValues}
                >
                    <div className="tiepnhan-row">
                        <div className="tiepnhan-timkiem-form">
                            <div className="mr-tn">
                                <div className="row">
                                    <Form.Item
                                        label={<div className="form-input-label">Từ ngày :</div>}
                                        className="col-md-4 m-0"
                                        name="App_Date_From"

                                    >
                                        <DatePicker placeholder="Từ ngày" className="form-control" format={'DD/MM/YYYY'}
                                            onChange={(date, dateString) => { setTuNgay(date); }
                                            } />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="form-input-label">Đến ngày :</div>}
                                        className="col-md-4 m-0"
                                        name="App_Date_To"
                                    >
                                        <DatePicker placeholder="Đến ngày" className="form-control" format={'DD/MM/YYYY'}
                                            onChange={(date, dateString) => { setDenNgay(date); }
                                            } />
                                    </Form.Item>
                                    <div className="w-15 m-0"></div>
                                    <Form.Item className="w-10 m-0 d-flex align-items-center">
                                        <Button htmlType="submit" className="form-btn">
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            <div className="mx-2"> Tìm kiếm</div>
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>

                <div className="my-2 tiepnhan-tk-table">
                    <DataGrid
                        data={ListDatHen}
                        style={{ height: '80vh' }}
                        filterable
                        columnResizing
                        dblclickToEdit
                        editMode="row"
                        onRowDblClick={(row) => handleRowEditEnd(row)}
                        showFooter
                        footerData={getFooterData()}
                        inert

                    >
                        <GridColumn field="PatientName" title="Họ tên" width={200} resizable />
                        <GridColumn field="PatientGenderID" title="Giới tính" width={80} resizable filterable={false}
                            render={({ row }) => {
                                const category = ListGioiTinh.find((item) => item.PatientGenderID === row.PatientGenderID);
                                return category ? category.PatientGenderName : null;
                            }}
                        />
                        <GridColumn field="PatientBirthday" title="Ngày sinh" width={100} resizable filterable={false} />
                        <GridColumn field="PatientMobile" title="Điện thoại" width={100} resizable />
                        <GridColumn field="Address_" title="Địa chỉ" width={400} resizable />
                        <GridColumn field="App_Date" title="Ngày khám" width={100} resizable filterable={false}
                            render={({ row }) => (
                                <span>{moment(row.App_Date).format('DD/MM/YYYY')}</span>
                            )}
                        />
                        <GridColumn field="Reason" title="Lý do" width={300} resizable filterable={false} />


                    </DataGrid>
                </div>
            </div>
        </>
    );
}

export default DatHenOnline;
