import { Button, Dropdown, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridColumn, ComboBox } from 'rc-easyui';
import {
    faEdit,
    faPlus,
    faPrint,
    faFileExport,
    faTrashCan,
    faArrowRotateLeft,
    faGear,
    faPenToSquare, faBars
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import "../../danhmuc.scss";
import "../Detail/style.scss";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { errorInfo, successInfo } from "../../../../../components/Dialog/Dialog";
import dvktAPI from "../../../../../services/vienphi/danhmuc/dvktAPI";
import giadvktAPI from "../../../../../services/vienphi/danhmuc/giadvktAPI";
import { setDataDichVuKyThuat } from "../../../../../slices/dataVienPhi";
import loginAPI from "../../../../../services/loginApi";
import LoadPage from "../../../../../loadpage";
import DetailDVKT from "../Detail";
import DetailPopUpGiaDVKT from "../DetailGia";

function KhaiBaoDVKT() {
    const [HideCheck, setHideCheck] = useState(false);
    const [loadpage, setLoadPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openGiaDVKT, setopenGiaDVKT] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const [listDVKT, setlistDVKT] = useState([]);
    const [NhomVienPhi, setNhomVienPhi] = useState([]);
    const [LoaiVienPhi, setLoaiVienPhi] = useState([]);
    const [DonViTinh, setDonViTinh] = useState([]);
    const [NhomIn, setNhomIn] = useState([]);
    const [KhoaPhong, setKhoaPhong] = useState([]);
    const [KhoaBHYT, setKhoaBHYT] = useState([]);
    const [NhomBHYT, setNhomBHYT] = useState([]);
    const [filters, setFilters] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [userlogin, setUserLogin] = useState();

    const [selectedChiTiet, setSelectedChiTiet] = useState(null);

    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataDichVuKyThuat(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {

                    handleDelete(record.ServiceID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }else if (e.key === "giadvkt") {
            setSelectedChiTiet({ ServiceID: record.ServiceID });
            setopenGiaDVKT(true);
        }
    };
    const getAllDVKT = async () => {
        try {
            setLoading(true);
            const data = await dvktAPI.getAll({ 'ServiceID': '', 'Hide': HideCheck === false ? 0 : 1 });
            setlistDVKT(data.data);
            setFilteredData(data.data);
            setLoading(false);

        } catch (err) { }
    };
    const getNhomVP = async () => {
        try {
            const data = await dvktAPI.getAllNhomVP('ServiceGroupID');
            setNhomVienPhi(data.data);

        } catch (err) { }
    };
    const getLoaiVP = async () => {
        try {
            const data = await dvktAPI.getAllLoaiVP({ 'ServiceCategoryID': 0, 'Hide': 0 });
            setLoaiVienPhi(data.data);

        } catch (err) { }
    };
    const getKhoaPhong = async () => {
        try {
            const data = await dvktAPI.getAllPhong({ 'Hide': 0 });
            setKhoaPhong(data.data);

        } catch (err) { }
    };
    const getKhoaBHYT = async () => {
        try {
            const data = await dvktAPI.getAllKhoaBHYT({ 'Hide': 0 });
            setKhoaBHYT(data.data);

        } catch (err) { }
    };
    const getDonViTinh = async () => {
        try {
            const data = await dvktAPI.getAllDVT({ 'UnitID': 0, 'Hide': 0 });
            setDonViTinh(data.data);

        } catch (err) { }
    };
    const getNhomIn = async () => {
        try {
            const data = await dvktAPI.getAllNhomIn({ 'GroupPrinterID': 0, 'Hide': 0 });
            setNhomIn(data.data);

        } catch (err) { }
    };
    const getNhomBHYT = async () => {
        try {
            const data = await dvktAPI.getAllNhomBHYT('ServiceGroupBHYTID');
            setNhomBHYT(data.data);

        } catch (err) { }
    };


    const handleUpdate = async (obj) => {
        const data = await dvktAPI.update(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllDVKT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleCreate_Update_CT = async (obj) => {
        const data = await giadvktAPI.addUpdate(obj);
        if (data.data.Code === "err") {
            errorInfo(data.data.Content);
        } else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
           
        }
    };

    const handleDelete = async (ServiceID, EmployeeID) => {
        const data = await dvktAPI.delete(ServiceID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllDVKT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const GroupKhoaPhong = (a) => {
        return KhoaPhong.map((item) => {
            let b = a.split(",");
            return b.filter(item1 => item.DepartmentCode === item1)
                .map((commentReply, idx) => { return <span key={idx}>{item.DepartmentName}</span> });

        });

    }
    const handleCheckboxChange = async (row, field) => {
        const updatedValue = !row[field];

        setFilteredData((prevData) =>
            prevData.map((item) =>
                item.ServiceID === row.ServiceID
                    ? { ...item, [field]: updatedValue }
                    : item
            )
        );

        try {
            await dvktAPI.update({ ...row, [field]: updatedValue });
            getAllDVKT();
            successInfo("Cập nhật trạng thái thành công!");
        } catch (error) {
            errorInfo("Cập nhật trạng thái thất bại!");
            console.error(error);
        }
    };
    useEffect(() => {
        document.title = 'Khai báo DVKT - EmedLink';
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        const getAllDVKT = async () => {
            setLoading(true);
            const data = await dvktAPI.getAll({ 'ServiceID': '', 'Hide': HideCheck === false ? 0 : 1 });
            setlistDVKT(data.data);
            setFilteredData(data.data);
            setLoading(false);
        };
        getAllDVKT();
        getAllUser();
        getNhomVP();
        getLoaiVP();
        getKhoaPhong();
        getKhoaBHYT();
        getDonViTinh();
        getNhomBHYT();
        getNhomIn();
    }, [HideCheck]);

    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
        {
            key: "giadvkt",
            label: "Khai báo giá",
            icon: <FontAwesomeIcon icon={faPlus} />,
        },
    ];



    const items1 = [
        {
            label: <div onClick={handleDataCreate}>Thêm mới</div>,
            key: "1",
            icon: <FontAwesomeIcon icon={faPlus} />,
        },
        {
            label: "Load",
            key: "2",
            icon: <FontAwesomeIcon icon={faArrowRotateLeft} />,
        },
        {
            label: "In",
            key: "3",
            icon: <FontAwesomeIcon icon={faPrint} />,
        },
        {
            label: "Export file",
            key: "4",
            icon: <FontAwesomeIcon icon={faFileExport} />,
        },
    ];
    const menuProps = {
        items: items1,
    };
    const renderComboBox = ({
        data,
        value,
        onChange,
        style = { width: 150 },
        editable = false,
    }) => {
        const enhancedData = [{ value: null, text: "All" }, ...data];

        return (
            <ComboBox
                data={enhancedData}
                value={value}
                onChange={onChange}
                style={style}
                editable={editable}
            />
        );
    };
    const loadPhong = () => {
        //let loaddl = listDVKT.map((item) => { return item.DepartmentCode.split(",") });
        let defaultData = [{ value: null, text: "All" }];
        let data = KhoaPhong.map((item) => {
            let data = {
                value: item.DepartmentCode,
                text: item.DepartmentName,
            }

            return data;
        });
        const merged = defaultData.concat(data);
        return merged;
    };
    const status = [
        { value: null, text: "All" },
        { value: "true", text: "1" },
        { value: "false", text: "0" }
    ]
    if (loadpage === true) {
        return (
            <>
                <div className="text-muted DM-DVKT">
                    <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                        <div className="d-flex align-items-center w-100">
                            <div className="vienphi-danhmuc-title mx-2 w-80">
                                <FontAwesomeIcon icon={faBars} />  Khai báo dịch vụ kỹ thuật
                            </div>
                            <div className="col-n"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                            <div className="actionright">
                                <Dropdown menu={menuProps}>
                                    <Button className="form-btn">
                                        <FontAwesomeIcon icon={faGear} className="mx-1" />
                                        Thao tác
                                    </Button>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <DetailDVKT
                        open={open} setOpen={setOpen}
                        NhomVienPhi={NhomVienPhi}
                        LoaiVienPhi={LoaiVienPhi}
                        DonViTinh={DonViTinh}
                        NhomIn={NhomIn}
                        KhoaPhong={KhoaPhong}
                        KhoaBHYT={KhoaBHYT}
                        NhomBHYT={NhomBHYT}
                        reLoad={getAllDVKT}
                        handleUpdate={handleUpdate}
                        key="DetailDVKT"
                    />
                    <hr className="w-100 my-1" />

                    <div className="m-1 danhmuc tableListDVKT">
                        <DataGrid loading={loading} filterable data={filteredData} columnResizing style={{ height: '90vh' }}>
                            <GridColumn field="ServiceGroupID" title="Nhóm viện phí" width={250}
                                filter={() =>
                                    renderComboBox({
                                        data: NhomVienPhi.map((item) => ({
                                            value: item.ServiceGroupID,
                                            text: item.ServiceGroupName,
                                        })),
                                        value: filters.ServiceGroupID || '',
                                        onChange: (value) => {
                                            const filterValue = value || null;
                                            const filteredData = listDVKT.filter((row) => {

                                                return filterValue ? row.ServiceGroupID === filterValue : true;
                                            });
                                            setFilteredData(filteredData);

                                            setFilters((prev) => ({ ...prev, ServiceGroupID: filterValue }));
                                        },
                                        onReset: () => {
                                            setFilteredData(listDVKT);
                                            setFilters((prev) => ({ ...prev, ServiceGroupID: '' }));
                                        },
                                        style: { width: '100%' },
                                    })
                                }

                                render={({ row }) => {
                                    const category = LoaiVienPhi.find(
                                        (item) => item.ServiceCategoryID === row.ServiceCategoryID
                                    );
                                    const group = category
                                        ? NhomVienPhi.find(
                                            (groupItem) => groupItem.ServiceGroupID === category.ServiceGroupID
                                        )
                                        : null;

                                    return (
                                        <div style={{ width: "200px" }}>
                                            {group ? group.ServiceGroupName : "Không xác định"}
                                        </div>
                                    );
                                }}
                            ></GridColumn>
                            <GridColumn field="ServiceCategoryID" title="Loại viện phí" width={250}
                                filter={() =>
                                    renderComboBox({
                                        data: LoaiVienPhi.map((item) => ({
                                            value: item.ServiceCategoryID,
                                            text: item.ServiceCategoryName,
                                        })),
                                        value: filters.ServiceCategoryID || '',
                                        onChange: (value) => {
                                            const filterValue = value || null;
                                            const filteredData = listDVKT.filter((row) => {

                                                return filterValue ? row.ServiceCategoryID === filterValue : true;
                                            });
                                            setFilteredData(filteredData);
                                            setFilters((prev) => ({ ...prev, ServiceCategoryID: filterValue }));
                                        },
                                        onReset: () => {
                                            setFilteredData(listDVKT);
                                            setFilters((prev) => ({ ...prev, ServiceCategoryID: '' }));
                                        },
                                        style: { width: '100%' },
                                    })
                                }

                                render={({ row }) => {
                                    const category = LoaiVienPhi.find((item) => item.ServiceCategoryID === row.ServiceCategoryID);
                                    return category ? category.ServiceCategoryName : null;
                                }}
                            ></GridColumn>
                            <GridColumn field="ServiceCode_PK" title="Mã DV PK" width={80} />
                            <GridColumn field="ServiceName" title="Tên dịch vụ" width={300} />
                            <GridColumn field="ServiceName_PK" title="Tên dvkt viết tắt thu gọn" width={300}
                            />
                            <GridColumn field="ServiceCode_AX" title="Mã DVKT AX" align="center" width={120}></GridColumn>
                            <GridColumn field="ServiceName_AX" title="Tên DVKT AX" width={300} />
                            <GridColumn field="UnitID" align="center" title="ĐVT" width={90}
                                filter={() =>
                                    renderComboBox({
                                        data: DonViTinh.map((item) => ({
                                            value: item.UnitID,
                                            text: item.UnitName,
                                        })),
                                        value: filters.UnitID || '',
                                        onChange: (value) => {
                                            const filterValue = value || null;
                                            const filteredData = listDVKT.filter((row) => {

                                                return filterValue ? row.UnitID === filterValue : true;
                                            });
                                            setFilteredData(filteredData);
                                            setFilters((prev) => ({ ...prev, UnitID: filterValue }));
                                        },
                                        onReset: () => {
                                            setFilteredData(listDVKT);
                                            setFilters((prev) => ({ ...prev, UnitID: '' }));
                                        },
                                        style: { width: '100%' },
                                    })
                                }
                                render={({ row }) => {

                                    const category = DonViTinh.find((item) => item.UnitID === row.UnitID);
                                    return category ? category.UnitName : null;
                                }}
                            />

                            <GridColumn field="DepartmentCode" title="Phòng thực hiện" width={400}
                                filter={() => (
                                    <ComboBox
                                        SelectedIndex={0}
                                        data={loadPhong()}
                                        editable={false}
                                        inputStyle={{}}

                                    />
                                )}
                                render={({ row }) => {
                                    return <div className="NhomKhoaPhong" >
                                        {GroupKhoaPhong(row.DepartmentCode)}
                                    </div>

                                }}

                            />
                            <GridColumn field="MaNhomTT_BH" title="Mã TT BHYT 9324" width={250}
                                filter={() =>
                                    renderComboBox({
                                        data: NhomBHYT.map((item) => ({
                                            value: item.ServiceGroupBHYTID,
                                            text: item.ServiceGroupBHYTName,
                                        })),
                                        value: filters.MaNhomTT_BH || '',
                                        onChange: (value) => {
                                            const filterValue = value || null;
                                            const filteredData = listDVKT.filter((row) => {

                                                return filterValue ? row.MaNhomTT_BH === filterValue : true;
                                            });
                                            setFilteredData(filteredData);
                                            setFilters((prev) => ({ ...prev, MaNhomTT_BH: filterValue }));
                                        },
                                        onReset: () => {
                                            setFilteredData(listDVKT);
                                            setFilters((prev) => ({ ...prev, MaNhomTT_BH: '' }));
                                        },
                                        style: { width: '100%' },
                                    })
                                }
                                render={({ row }) => {
                                    const category = NhomBHYT.find((item) => item.ServiceGroupBHYTID === row.MaNhomTT_BH);
                                    return category ? category.ServiceGroupBHYTName : null;
                                }}
                            />
                            <GridColumn field="MaKhoa_BH" title="Mã Khoa BHYT" width={100}
                                filter={() =>
                                    renderComboBox({
                                        data: KhoaBHYT.map((item) => ({
                                            value: item.MaKhoa,
                                            text: item.MaKhoa,
                                        })),
                                        value: filters.MaKhoa_BH || '',
                                        onChange: (value) => {
                                            const filterValue = value || null;
                                            const filteredData = listDVKT.filter((row) => {

                                                return filterValue ? row.MaKhoa_BH === filterValue : true;
                                            });
                                            setFilteredData(filteredData);
                                            setFilters((prev) => ({ ...prev, MaKhoa_BH: filterValue }));
                                        },
                                        onReset: () => {
                                            setFilteredData(listDVKT);
                                            setFilters((prev) => ({ ...prev, MaKhoa_BH: '' }));
                                        },
                                        style: { width: '100%' },
                                    })
                                }
                            />
                            <GridColumn field="IsSampleTransfer" title="Gửi mẫu" width={90} align="center"
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.IsSampleTransfer}
                                    onChange={() => handleCheckboxChange(row, "IsSampleTransfer")}
                                />}
                            />
                            <GridColumn field="IsAssure" title="Giấy cam đoan" width={120} align="center"
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.IsAssure}
                                    onChange={() => handleCheckboxChange(row, "IsAssure")} />}
                            />
                            <GridColumn field="HandPoint" title="CĐ trước yhct, vltl" width={100} align="center"
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.HandPoint}
                                    onChange={() => handleCheckboxChange(row, "HandPoint")} />}
                            />
                            <GridColumn field="HandPoint_App" title="CĐ hẹn" width={90} align="center"
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.HandPoint_App}
                                    onChange={() => handleCheckboxChange(row, "HandPoint_App")} />}
                            />
                            <GridColumn field="IsItemAttach" title="Thêm thuốc" width={90} align="center"
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.IsItemAttach}
                                    onChange={() => handleCheckboxChange(row, "IsItemAttach")} />}
                            />
                            <GridColumn field="IsWarningNote" title="Cảnh báo chỉ định" width={90} align="center"
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.IsWarningNote}
                                    onChange={() => handleCheckboxChange(row, "IsWarningNote")} />}
                            />


                            <GridColumn field="IsResultCLS" title="Cảnh báo KQ lưu hs và K.Đơn" width={120} align="center"
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.IsResultCLS}
                                    onChange={() => handleCheckboxChange(row, "IsResultCLS")} />}
                            />
                            <GridColumn field="IsPTTT_XM5" title="Hiện DV vào xml5" width={90} align="center"
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.IsPTTT_XM5}
                                    onChange={() => handleCheckboxChange(row, "IsPTTT_XM5")} />}
                            />
                            <GridColumn field="MachineID" title="Máy thực hiện" width={120} align="center" />
                            <GridColumn field="TimeFinished" title="Thời gian H.Thành" width={90} />
                            <GridColumn field="Rate_BHYT" title="T.Lệ BH Th.Toán" width={90} align="center" />
                            <GridColumn field="VAT" title="VAT" width={90} align="center" />

                            <GridColumn field="IsEKip_xml" title="Ekip xml" width={90} align="center"
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.IsEKip_xml}
                                    onChange={() => handleCheckboxChange(row, "IsEKip_xml")} />}
                            />
                            <GridColumn field="Note" title="Ghi chú" width={200} />
                            <GridColumn field="Hide" title="Ẩn/Hiện" width={90} align="center"
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.Hide}
                                    onChange={() => handleCheckboxChange(row, "Hide")} />}
                            />
                            <GridColumn field="STT" filterable={false} title="STT" width={90} align="center" />


                            <GridColumn title="Thao tác" width={90} filterable={false}
                                render={({ row }) => (
                                    <div className="d-flex justify-content-center" style={{ width: "80px" }}>
                                        <Dropdown
                                            menu={{
                                                items,
                                                onClick: (e) => handleThaoTac(e, row),
                                            }}
                                            placement="left"
                                            arrow={{
                                                pointAtCenter: true,
                                            }}
                                        >
                                            <Button
                                                className="bg-light vienphi-danhmuc-icon-modify"
                                                onClick={() => {
                                                    setActiveModify(!activeModify);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    style={{ fontSize: "10px" }}
                                                    className="text-dark"
                                                />
                                            </Button>
                                        </Dropdown>
                                    </div>

                                )}
                            />
                        </DataGrid>
                        <DetailPopUpGiaDVKT
                            open={openGiaDVKT}
                            setOpen={setopenGiaDVKT}
                            listDVKT={listDVKT}
                            selectedData={selectedChiTiet}
                            refreshData={getAllDVKT}
                            handleCreate_Update_CT={handleCreate_Update_CT}
                            key="DetailPopUpGiaDVKT"
                        />
                    </div>
                </div>
            </>
        );
    } else { return <LoadPage /> }
}
export default KhaiBaoDVKT;
