import axios from "axios";
import REACT_LOCALHOST from "../../host";
import { authHeader } from '../../helpers';
const thuocvtytAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-items-duoc`,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-items-duoc`, obj);
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-items-duoc`, obj);
    },
    delete: (ItemID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-items-duoc/${ItemID}/${EmployeeID}`);
    },
    getNhomThuoc: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-service-group-duoc`,obj);
    },
    getLoaiThuoc: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-service-category-duoc`,obj);
    },
    getNuocSanXuat: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-medcountry-duoc`,obj);
    },
    getHangSanXuat: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-medproducer-duoc`,obj);
    },
    getDonViTinh: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-unitOfmeasure-duoc`,obj);
    },
    getDuongDung: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-usage-duoc`,obj);
    },
    getNhomIn: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-group-printer-duoc`,obj);
    },
    getKhoThuoc: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-kho-duoc`,obj);
    },
};

export default thuocvtytAPI;