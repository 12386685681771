import axios from "axios";
import REACT_LOCALHOST from "../host";
import { authHeader } from '../helpers';
const khambenhAPI = {
    getAllKetQuaDTri: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-ket-qua-dieu-tri`,obj);
    },
    getAllXuTri: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-xu-tri`,obj);
    },
    getAllPPDieuTri: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-phuong-phap-dieu-tri`,obj);
    },
    getAllICD10: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-icd10-chinh`,obj);
    },
    getAllICD10YHCT: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-icd10-yhct-chinh`,obj);
    },
    getAllDSBNKham: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-danh-sach-cho-kham`,obj);
    },
    getBenhNhanID: (PatientReceiveID,PatientCode) => {
        return axios.get(`${REACT_LOCALHOST}/api/lay-thong-tin-benh-nhan-trong-kham-benh/${PatientReceiveID}/${PatientCode}`);
    },

};
export default khambenhAPI;
