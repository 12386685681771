import { Modal, Button, Form, Input, Checkbox,} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import loginAPI from "../../../../../services/loginApi";
import nhacungcapAPI from "../../../../../services/duoc/danhmuc/nhacungcapAPI";
import { setDataNhaCungCap } from "../../../../../slices/dataDMDuoc";
import "../nhacungcap.scss";
function DetailNhaCungCap(props) {
    const { open, setOpen, handleUpdate, reLoad } = props;
    const { nhacungcap } = useSelector((state) => state.dataDMDuoc);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [userlogin, setUserLogin] = useState();
    const [checkAnHien, setcheckAnHien] = useState(false);
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleCreate = async (obj) => {
        const data = await nhacungcapAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);

        }
        else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }

    };
    const handleSubmit = async (e) => {
        Swal.fire({
            title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
            confirmButtonText: "Đồng ý",
            showCancelButton: true,
            cancelButtonText: "Hủy",
            customClass: {
                title: "fs-5 text-dark",
                confirmButton: "shadow-none",
                cancelButton: "shadow-none",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                if (nhacungcap?.RowID) {
                    var upnhacungcap = {
                        RowID: e?.RowID,
                        VendorCode: e?.VendorCode,
                        VendorName: e?.VendorName,
                        Salesman: e?.Salesman,
                        Address: e?.Address,
                        Phone: e?.Phone,
                        Email: e?.Email,
                        VendorTaxNo: e?.VendorTaxNo,
                        VendorFax: e?.VendorFax,
                        VendorBankName: e?.VendorBankName,
                        Hide: e?.Hide,
                        EmployeeID: userlogin.RowID,
                    }
                    handleUpdate(upnhacungcap);
                    dispatch(setDataNhaCungCap());
                    form.resetFields();
                    setOpen(false);
                } else {
                    var arraynhacungcap = {
                        VendorCode: e?.VendorCode,
                        VendorName: e?.VendorName,
                        Salesman: e?.Salesman === undefined ? " ": e?.Salesman,
                        Address: e?.Address === undefined ? " ": e?.Address,
                        Phone: e?.Phone === undefined ? " ": e?.Phone,
                        Email: e?.Email === undefined ? " ": e?.Email,
                        VendorTaxNo: e?.VendorTaxNo === undefined ? " ": e?.VendorTaxNo,
                        VendorFax: e?.VendorFax === undefined ? " ": e?.VendorFax,
                        VendorBankName: e?.VendorBankName === undefined ? " ": e?.VendorBankName,
                        Hide: checkAnHien === false ? 0 : 1,
                        EmployeeID: userlogin.RowID,
                    }
                    handleCreate(arraynhacungcap);
                }
            }
        });
    }
    const onChange = (e) => {
        setcheckAnHien(e.target.checked)
    };
    useEffect(() => {
        getAllUser();
        form.setFieldsValue({
            RowID: nhacungcap?.RowID,
            VendorCode: nhacungcap?.VendorCode,
            VendorName: nhacungcap?.VendorName,
            Salesman: nhacungcap?.Salesman,
            Address: nhacungcap?.Address,
            Phone: nhacungcap?.Phone,
            Email: nhacungcap?.Email,
            VendorTaxNo: nhacungcap?.VendorTaxNo,
            VendorFax: nhacungcap?.VendorFax,
            VendorBankName: nhacungcap?.VendorBankName,
            Hide: nhacungcap?.Hide,
        });
    }, [nhacungcap, form]);
    return (
        <>
            <Modal className="DetailNhaCungCap"
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo nhà cung cấp
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={1000}
                closable={false}
                forceRender
                maskClosable={false}
            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form}>
                        <div className="scroll">
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input"></div>}
                                    name="RowID"
                                    hidden={true}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã NCC :</div>}
                                    className="col-md-4 m-0"
                                    name="VendorCode"
                                    rules={[{ required: true, message: 'Vui lòng nhập mã!' }]}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên nhà CC :</div>}
                                    className="col-md-8 m-0"
                                    name="VendorName"
                                    rules={[{ required: true, message: 'Vui lòng nhập tên!' }]}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã số thuế :</div>}
                                    className="col-md-4 m-0"
                                    name="VendorTaxNo"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Địa chỉ :</div>}
                                    className="col-md-8 m-0"
                                    name="Address"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                            </div>
                            <div className="row dmdot">

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Điện thoại :</div>}
                                    className="col-md-4 m-0"
                                    name="Phone"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Người đại diện :</div>}
                                    className="col-md-5 m-0"
                                    name="Salesman"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Số Fax :</div>}
                                    className="col-md-3 m-0"
                                    name="VendorFax"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>

                            <div className="row dmdot">

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Số TK NH:</div>}
                                    className="col-md-4 m-0"
                                    name="VendorBankName"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Email :</div>}
                                    className="col-md-5 m-0"
                                    name="Email"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ẩn/Hiện:</div>}
                                    className="col-md-2 m-0"
                                    name="Hide" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChange} value={checkAnHien} />
                                </Form.Item>

                            </div>


                        </div>
                        <Form.Item className="text-center">
                            <hr />
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                               <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default DetailNhaCungCap;
