import {Input} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./phieukham.scss";
import { useState, useRef, useEffect, useMemo } from "react";
import khambenhAPI from "../../../../services/khambenh/khambenhAPI";

function ICD10(props) {
    const { } = props;
    const [ListICD10, setListICD10] = useState([]);
    const [FilterICD10, setFilterICD10] = useState([]);
    const [FilterICD10Arr, setFilterICD10Arr] = useState([]);
    const [selectedRowIndexICD10, setSelectedRowIndexICD10] = useState(-1);
    const [isOpenDT, setIsOpenDT] = useState(false);
    const [searchICD10, setSearchICD10] = useState("");
    const tableRefICD10 = useRef(null);
    const refICD10 = useRef(null);
    const rowRefs = useRef([]);
    let lastKeyDownTime = useRef(0);

    const dropdownRef = useRef(null);

    const removeVietnameseTones = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
    //icd 10
    const getICD10 = async () => {
        try {
            const data = await khambenhAPI.getAllICD10({ 'Hide': 0 });
            setListICD10(data.data);
        } catch (err) { }
    };
    const handleSearchChange = (value) => {
        if (value === '') {
            setFilterICD10([]);
        } else {
            const filterBySearch = ListICD10.filter((item) =>
                removeVietnameseTones(item.ICD10_Name || "").toLowerCase().includes(removeVietnameseTones(value)) ||
                removeVietnameseTones(item.ICD10_Ma || "").toLowerCase().includes(removeVietnameseTones(value))
            );
            setFilterICD10(filterBySearch);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchICD10(value);
        handleSearchChange(value);
    };

    const toggleSelection = (option) => {
        setSearchICD10(option.ICD10_Name);
        setIsOpenDT(false);
    };
    const isHandlingKey = useRef(false);
    const handleKeyDownICD10 = (e) => {
        if (isHandlingKey.current) return; 
        isHandlingKey.current = true;

        setTimeout(() => {
            isHandlingKey.current = false;
        }, 50); 

        const now = Date.now();
        if (now - lastKeyDownTime.current < 150) return;
        lastKeyDownTime.current = now;
        if (!FilterICD10Arr || FilterICD10Arr.length === 0) return;

        if (e.key === "Enter") {
            e.preventDefault();
            if (selectedRowIndexICD10 !== -1) {
                setSearchICD10(FilterICD10Arr[selectedRowIndexICD10].ICD10_Name);
                setTimeout(() => {
                    if (refICD10.current) {
                        refICD10.current.focus();
                    }
                }, 100);
                setIsOpenDT(false);
                setSelectedRowIndexICD10(-1);

            }
            return;

        }
        setSelectedRowIndexICD10((prevIndex) => {
            let newIndex = prevIndex;
            if (e.key === "ArrowUp") {
                newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            } else if (e.key === "ArrowDown") {
                newIndex = prevIndex < FilterICD10Arr.length - 1 ? prevIndex + 1 : prevIndex;
            }
            return newIndex;
        });
    };

    //end icd 10
    useEffect(() => {
        getICD10();
        if (selectedRowIndexICD10 >= 0 && rowRefs.current[selectedRowIndexICD10]) {
            setTimeout(() => {
                rowRefs.current[selectedRowIndexICD10]?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                });
            }, 50);
        }

        document.addEventListener("keydown", handleKeyDownICD10);
        return () => {
            document.removeEventListener("keydown", handleKeyDownICD10);
        };
    }, [selectedRowIndexICD10, isOpenDT]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpenDT(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const filteredData = useMemo(() => {
        return (FilterICD10.length > 0 ? FilterICD10 : ListICD10)
            .filter(row =>
            (row?.ICD10_Ma?.toLowerCase().includes(searchICD10.toLowerCase()) ||
                row?.ICD10_Name?.toLowerCase().includes(searchICD10.toLowerCase()))
            );
    }, [FilterICD10, ListICD10, searchICD10]);

    useEffect(() => {
        setFilterICD10Arr(filteredData);
        setSelectedRowIndexICD10((prevIndex) =>
            prevIndex >= 0 && prevIndex < filteredData.length ? prevIndex : (filteredData.length > 0 ? 0 : -1)
        );
    }, [filteredData]);

    return (
        <>
            <div className="icd10-search" ref={dropdownRef}>
                <div className="input-icon">
                    <Input
                        ref={refICD10}
                        type="text"
                        className="w-full inputsearch outline-none"
                        placeholder="Chọn"
                        value={searchICD10}
                        
                        onChange={handleInputChange}
                        onClick={() => setIsOpenDT(!isOpenDT)}
                        onKeyDown={handleKeyDownICD10}
                    />
                    {searchICD10 !== '' ?
                        <FontAwesomeIcon onClick={() => setSearchICD10('')} className="iconsearch iconsearchxoa" icon={faXmark} />
                        :
                        <FontAwesomeIcon className="iconsearch" icon={faAngleDown} />
                    }
                </div>
                {isOpenDT && (
                    <div className="absolute w-full">
                        <table onKeyDown={handleKeyDownICD10} tabIndex={0} ref={tableRefICD10} onFocus={() => setSelectedRowIndexICD10(-1)} className=" table table-responsive">
                            <thead>
                                <tr>
                                    <td className="ma">Mã ICD10</td>
                                    <td className="ten">Tên ICD10</td>
                                </tr>
                            </thead>
                            <tbody>
                                {FilterICD10Arr.map((option, index) => (
                                    <tr
                                        key={index}
                                        ref={(el) => (rowRefs.current[index] = el)}
                                        className={index === selectedRowIndexICD10 ? "active" : ""}
                                        onClick={() => { setSelectedRowIndexICD10(index); toggleSelection(option) }}
                                        tabIndex={0}
                                    >

                                        <td className="ma">{option.ICD10_Ma}</td>
                                        <td className="ten">{option.ICD10_Name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

        </>
    );
}
export default ICD10;
