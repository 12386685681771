import { Modal, Button, Form, Input, InputNumber, Checkbox, Select, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";


import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { errDialog, errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import loginAPI from "../../../../../services/loginApi";
import { setDataGiaDichVuKyThuat } from "../../../../../slices/dataVienPhi";
import giadvktAPI from "../../../../../services/vienphi/danhmuc/giadvktAPI";
import "../../danhmuc.scss";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
function DetailGiaDVKT(props) {
    const { open, setOpen, handleUpdate, reLoad, listDVKT, listDoiTuong } = props;
    const { giadichvukythuat } = useSelector((state) => state.dataVienPhi);
    const [userlogin, setUserLogin] = useState();
    const [checkAnHien, setcheckAnHien] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(false);
    const [endDate, setendDate] = useState(false);


    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleCreate = async (obj) => {
        const data = await giadvktAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);

        }
        else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }

    };
    const handleSubmit = async (e) => {
        if (e?.ServiceID === undefined) { errDialog(); } else {
            Swal.fire({
                title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
                confirmButtonText: "Đồng ý",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                customClass: {
                    title: "fs-5 text-dark",
                    confirmButton: "shadow-none",
                    cancelButton: "shadow-none",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    if (giadichvukythuat?.ServicePriceID) {
                        var upgiadvkt = {
                            ServicePriceID: e?.ServicePriceID,
                            ServiceID: e?.ServiceID,
                            ObjectID: e?.ObjectID,
                            UnitPrice_BV: e?.UnitPrice_BV,
                            UnitPrice: e?.UnitPrice,
                            DisparityPrice: e?.DisparityPrice,
                            DisparityPrice_Other: e?.DisparityPrice_Other,
                            UnitPrice_Transfer: e?.UnitPrice_Transfer,
                            DateOf_Begin: e?.DateOf_Begin,
                            DateOf_End: e?.DateOf_End,
                            EmployeeID: userlogin.RowID,
                            Hide: e?.Hide
                        }
                        handleUpdate(upgiadvkt);
                        dispatch(setDataGiaDichVuKyThuat());
                        form.resetFields();
                        setOpen(false);
                    } else {

                        var GiaDVKT = {
                            ServiceID: e?.ServiceID,
                            ObjectID: e?.ObjectID,
                            UnitPrice_BV: e?.UnitPrice_BV ?? 0,
                            UnitPrice: e?.UnitPrice ?? 0,
                            DisparityPrice: e?.DisparityPrice ?? 0,
                            DisparityPrice_Other: e?.DisparityPrice_Other ?? 0,
                            UnitPrice_Transfer: e?.UnitPrice_Transfer ?? 0,
                            DateOf_Begin: startDate ?? new Date(),
                            DateOf_End: endDate ?? new Date(),
                            Hide: checkAnHien === false ? 0 : 1,
                            EmployeeID: userlogin.RowID,
                        }
                        handleCreate(GiaDVKT);
                    }
                }
            });
        }

    }
    const onChange = (e) => {
        setcheckAnHien(e.target.checked)
    };
    useEffect(() => {
        getAllUser();
        if (giadichvukythuat) {
            form.setFieldsValue({
                ServicePriceID: giadichvukythuat?.ServicePriceID,
                ServiceID: giadichvukythuat?.ServiceID,
                ObjectID: giadichvukythuat?.ObjectID,
                UnitPrice_BV: giadichvukythuat?.UnitPrice_BV,
                UnitPrice: giadichvukythuat?.UnitPrice,
                DisparityPrice: giadichvukythuat?.DisparityPrice,
                DisparityPrice_Other: giadichvukythuat?.DisparityPrice_Other,
                UnitPrice_Transfer: giadichvukythuat?.UnitPrice_Transfer,
                DateOf_Begin: dayjs(giadichvukythuat?.DateOf_Begin),
                DateOf_End: dayjs(giadichvukythuat?.DateOf_End),
                //DateOf_Begin: giadichvukythuat?.DateOf_Begin ? moment(giadichvukythuat?.DateOf_Begin) : null,
                //DateOf_End: giadichvukythuat?.DateOf_End ? moment(giadichvukythuat?.DateOf_End) : null,
                // DateOf_Begin: giadichvukythuat?.DateOf_Begin ? moment(giadichvukythuat?.DateOf_Begin).subtract(1, "days") : null,
                // DateOf_End: giadichvukythuat?.DateOf_End ? moment(giadichvukythuat?.DateOf_End).subtract(1, "days") : null,
                Hide: giadichvukythuat?.Hide
            });
            setStartDate(giadichvukythuat?.DateOf_Begin ? moment(giadichvukythuat?.DateOf_Begin) : moment(new Date()));
            setendDate(giadichvukythuat?.DateOf_End ? moment(giadichvukythuat?.DateOf_End) : moment(new Date()));
        }
    }, [giadichvukythuat, form]);
    const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    return (
        <>
            <Modal className="DetailGiaDVKT"
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo giá DVKT
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={1000}
                closable={false}
                forceRender
                maskClosable={false}

            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form} >
                        <div className="scroll">
                            <div className="row">
                                <Form.Item label={<div className="form-input-label label-giadvkt ">Tên DVKT:</div>}
                                    name="ServiceID"
                                    className="col-md-8"
                                    rules={[{ required: true, message: 'Vui lòng nhập tên dịch vụ!' }]}>
                                    <Select
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={listDVKT.map((item) => ({
                                            value: item.ServiceID,
                                            label: item.ServiceName
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label label-giadvkt ">  Đối tượng: </div>}
                                    name="ObjectID"
                                    className="col-md-4"
                                    rules={[{ required: true, message: 'Vui lòng nhập đối tượng!' }]}>
                                    <Select
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={listDoiTuong.map((item) => ({
                                            value: item.ObjectID,
                                            label: item.ObjectName
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label label-giadvkt ">ID:</div>}
                                    className="col-md-6"
                                    name="ServicePriceID"
                                    hidden={true} >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row">
                                <Form.Item
                                    label={<div className="form-input-label label-giadvkt">Đơn giá BV:</div>}
                                    name="UnitPrice_BV"
                                    className="col-md-4 m-0"
                                >
                                    <InputNumber className="form-control form-control-sm"
                                        formatter={(value) => priceSplitter(value)}
                                        parser={(value) => value.replace(/\D/g, "")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label label-giadvkt">Đơn giá:</div>}
                                    name="UnitPrice"
                                    className="col-md-4 m-0"
                                >
                                    <InputNumber className="form-control form-control-sm"
                                        formatter={(value) => priceSplitter(value)}
                                        parser={(value) => value.replace(/\D/g, "")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label label-giadvkt">Phụ thu:</div>}
                                    className="col-md-4 m-0"
                                    name="DisparityPrice">
                                    <InputNumber className="form-control form-control-sm"
                                        formatter={(value) => priceSplitter(value)}
                                        parser={(value) => value.replace(/\D/g, "")}
                                    />
                                </Form.Item>
                            </div>
                            <div className="row">
                                <Form.Item
                                    label={<div className="form-input-label label-giadvkt">Ph.Thu ngoài giờ: </div>}
                                    name="DisparityPrice_Other"
                                    className="col-md-4 m-0">
                                    <InputNumber className="form-control form-control-sm ma-input"
                                        formatter={(value) => priceSplitter(value)}
                                        parser={(value) => value.replace(/\D/g, "")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label label-giadvkt">Ph.Thu gửi mẫu:</div>}
                                    name="UnitPrice_Transfer"
                                    className="col-md-4 m-0">
                                    <InputNumber className="form-control form-control-sm ma-input"
                                        formatter={(value) => priceSplitter(value)}
                                        parser={(value) => value.replace(/\D/g, "")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label label-giadvkt">Ẩn/Hiện:</div>}
                                    className="col-md-2 m-0"
                                    name="Hide" valuePropName="checked">
                                    <Checkbox onChange={onChange} value={checkAnHien} />
                                </Form.Item>

                            </div>
                            <div className="row">
                                <Form.Item
                                    label={<div className="form-input-label label-giadvkt">Ngày bắt đầu:</div>}
                                    name="DateOf_Begin"
                                    className="col-md-4 m-0"
                                    rules={[{ required: true, message: "Vui lòng chọn ngày bắt đầu!", },]}
                                >
                                    <DatePicker className="form-control"
                                        placeholder=""
                                        format={'DD/MM/YYYY'} onChange={(date, dateString) => {
                                            setStartDate(date);
                                        }} />


                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label label-giadvkt">Ngày kết thúc:</div>}
                                    name="DateOf_End"
                                    className="col-md-4 m-0"
                                    rules={[{ required: true, message: "Vui lòng chọn ngày kết thúc!" },
                                        /*({ getFieldValue }) => ({
                                            validator(_, value) {
                                               if (!value || moment(value).isSameOrAfter(startDate)) {
                                                   console.log('fgdgdfgd',moment(value).isSameOrAfter(startDate) ,value);
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error("Ngày kết thúc không được nhỏ ngày bắt đầu!")
                                                );
                                            },
                                        }),*/
                                    ]}
                                >
                                    <DatePicker className="form-control" format={'DD/MM/YYYY'}
                                        placeholder=""
                                        onChange={(date, dateString) => {
                                            setendDate(date);
                                        }
                                        } />


                                </Form.Item>
                            </div>

                        </div>
                        <Form.Item className="text-center">
                            <hr />
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}
export default DetailGiaDVKT;
