import { createSlice } from "@reduxjs/toolkit";

export const dataDMDuocSlice = createSlice({
    name: "dataDMDuoc",
    initialState: {
        //Cận lâm sàn
        sieuam: [],
        nhomthuoc:[],
        nuocsanxuat:[],
        hangsanxuat:[],
        loaithuoc:[],
        donvitinhduoc:[],
        thuocvtyt:[],
        nhominduoc:[],
        duongdung:[],
        khoduoc:[],
        cachdung:[],
        nhacungcap:[],
        thuocthauBHYT:[]
    },
    reducers: {
        setDataSieuAm: (state, action) => {
            state.sieuam = action.payload;
        },
        setDataNhomThuoc: (state, action) => {
            state.nhomthuoc = action.payload;
        },
        setDataLoaiThuoc: (state, action) => {
            state.loaithuoc = action.payload;
        },
        setDataNuocSanXuat: (state, action) => {
            state.nuocsanxuat = action.payload;
        },
        setDataHangSanXuat: (state, action) => {
            state.hangsanxuat = action.payload;
        },
        setDataDonViTinhDuoc: (state, action) => {
            state.donvitinhduoc = action.payload;
        },
        setDataThuocVTYT: (state, action) => {
            state.thuocvtyt = action.payload;
        },
        setDataNhomInDuoc: (state, action) => {
            state.nhominduoc = action.payload;
        },
        setDataDuongDung: (state, action) => {
            state.duongdung = action.payload;
        },
        setDataKhoDuoc: (state, action) => {
            state.khoduoc = action.payload;
        },
        setDataCachDung: (state, action) => {
            state.cachdung = action.payload;
        },
        setDataNhaCungCap: (state, action) => {
            state.nhacungcap = action.payload;
        },
        setDataThuocThauBHYT: (state, action) => {
            state.thuocthauBHYT = action.payload;
        },
    },
});
export const {
    setDataSieuAm,
    setDataNhomThuoc,
    setDataNuocSanXuat,
    setDataHangSanXuat,
    setDataLoaiThuoc,
    setDataDonViTinhDuoc,
    setDataThuocVTYT,
    setDataNhomInDuoc,
    setDataDuongDung,
    setDataKhoDuoc,
    setDataCachDung,
    setDataNhaCungCap,
    setDataThuocThauBHYT
} = dataDMDuocSlice.actions;
export default dataDMDuocSlice.reducer;