import { Modal, Button, Form, Input, InputNumber, Checkbox, Select, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect,useCallback } from "react";
import Swal from "sweetalert2";
import moment from 'moment';
import { DataGrid, GridColumn } from 'rc-easyui';
import "../dvkt.scss";
import { useDispatch } from "react-redux";
import { errDialog, errorInfo, successInfo } from "../../../../../components/Dialog/Dialog";
import loginAPI from "../../../../../services/loginApi";
import { setDataGiaDichVuKyThuat } from "../../../../../slices/dataVienPhi";
import giadvktAPI from "../../../../../services/vienphi/danhmuc/giadvktAPI";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
function DetailPopUpGiaDVKT(props) {
    const { open, setOpen, listDVKT, selectedData, handleCreate_Update_CT } = props;

    const [HideCheck, setHideCheck] = useState(false);
    const [userlogin, setUserLogin] = useState();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [listDoiTuong, setlistDoiTuong] = useState([]);
    const [checkAnHien, setcheckAnHien] = useState(false);
    const [listGiaDVKT, setlistGiaDVKT] = useState([]);
    const [listGiaDVKTRow, setlistGiaDVKTRow] = useState(null);


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());


    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const getAllDoiTuong = async () => {
        try {
            const data = await giadvktAPI.getAllObject();
            setlistDoiTuong(data.data);

        } catch (err) { }
    };
    const getAllGiaDVKT =useCallback( async () => {
        try {
            const data = await giadvktAPI.getAll({ 'Hide': HideCheck === false ? 0 : 1 });
            setlistGiaDVKT(data.data);

        } catch (err) { }
    },[HideCheck]);

    const handleSubmit = async (e) => {
        if (e?.ServiceID === undefined) { errDialog(); } else {
            Swal.fire({
                title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
                confirmButtonText: "Đồng ý",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                customClass: {
                    title: "fs-5 text-dark",
                    confirmButton: "shadow-none",
                    cancelButton: "shadow-none",
                },
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    var upgiadvkt = {
                        ServicePriceID: e?.ServicePriceID === undefined ? 0: e?.ServicePriceID,
                        ServiceID: e?.ServiceID ?? 0,
                        ObjectID: e?.ObjectID ?? 0,
                        UnitPrice_BV: e?.UnitPrice_BV ?? 0,
                        UnitPrice: e?.UnitPrice ?? 0,
                        DisparityPrice: e?.DisparityPrice ?? 0,
                        DisparityPrice_Other: e?.DisparityPrice_Other ?? 0,
                        UnitPrice_Transfer: e?.UnitPrice_Transfer ?? 0,
                        DateOf_Begin: e?.DateOf_Begin ?? startDate,
                        DateOf_End: e?.DateOf_End ?? endDate,
                        EmployeeID: userlogin.RowID,
                        Hide: e?.Hide === true ? 1 : 0
                    }
                    console.log(upgiadvkt)
                    handleCreate_Update_CT(upgiadvkt);
                    dispatch(setDataGiaDichVuKyThuat());
                    setlistGiaDVKTRow(null);
                 
                    form.setFieldsValue({
                        ServicePriceID:0,
                        ObjectID: undefined,
                        UnitPrice_BV: undefined,
                        UnitPrice: undefined,
                        DisparityPrice: undefined,
                        DisparityPrice_Other: undefined,
                        UnitPrice_Transfer: undefined,
                        DateOf_Begin: undefined,
                        DateOf_End: undefined,
                        Hide: 0
                    });
                    getAllGiaDVKT();
                  //  setOpen(false);
                }
            });
        }
    }

    const onChange = (e) => {
        setcheckAnHien(e.target.checked)
    };



    const handleDeleteRow = async (ServicePriceID, EmployeeID = userlogin.RowID) => {

        Swal.fire({
            title: "Bạn có chắc muốn xóa?",
            text: "Dữ liệu sẽ bị xóa khỏi hệ thống!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            cancelButtonText: "Hủy",
            confirmButtonText: "Xóa",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await giadvktAPI.delete(ServicePriceID, EmployeeID);
                    if (data.data.Code === "ok") {
                        successInfo(data.data.Content);
                        getAllGiaDVKT();
                    } else {
                        errorInfo(data.data.Content);
                    }
                } catch (error) {
                    errorInfo("Lỗi khi xóa dữ liệu!");
                }
            }
        });
    };
    const handleRowDoubleClick = (row) => {
        setlistGiaDVKTRow(row)
    };
    const filteredData = selectedData?.ServiceID
        ? listGiaDVKT.filter(item => item.ServiceID === selectedData.ServiceID)
        : listGiaDVKT;

    useEffect(() => {
        getAllDoiTuong();
        getAllUser();
        getAllGiaDVKT();
        if (open && selectedData?.ServiceID) {
            form.setFieldsValue({
                ServiceID: selectedData.ServiceID,
            });
            if (listGiaDVKTRow !== null) {
                form.setFieldsValue({
                    ServicePriceID: listGiaDVKTRow?.ServicePriceID,
                    ServiceID: listGiaDVKTRow?.ServiceID,
                    ObjectID: listGiaDVKTRow?.ObjectID,
                    UnitPrice_BV: listGiaDVKTRow?.UnitPrice_BV,
                    UnitPrice: listGiaDVKTRow?.UnitPrice,
                    DisparityPrice: listGiaDVKTRow?.DisparityPrice,
                    DisparityPrice_Other: listGiaDVKTRow?.DisparityPrice_Other,
                    UnitPrice_Transfer: listGiaDVKTRow?.UnitPrice_Transfer,
                    DateOf_Begin: dayjs(listGiaDVKTRow?.DateOf_Begin),
                    DateOf_End: dayjs(listGiaDVKTRow?.DateOf_End),
                    Hide: listGiaDVKTRow?.Hide
                });
                setStartDate(listGiaDVKTRow?.DateOf_Begin ? moment(listGiaDVKTRow?.DateOf_Begin) : moment(new Date()));
                setendDate(listGiaDVKTRow?.DateOf_End ? moment(listGiaDVKTRow?.DateOf_End) : moment(new Date()));
            }


        }
    }, [form, selectedData, listGiaDVKTRow, HideCheck, getAllGiaDVKT,open]);

    const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));


    return (
        <Modal
            title={
                <div className="vienphi-danhmuc-add">
                    Khai báo giá dịch vụ kỹ thuật
                </div>
            }
            centered
            open={open}
            okButtonProps={{
                style: {
                    display: "none",
                },
            }}
            cancelButtonProps={{
                style: {
                    display: "none",
                },
            }}
            onCancel={() => {
                setOpen(false); form.setFieldsValue({
                    ServicePriceID: 0,
                    ObjectID: undefined,
                    UnitPrice_BV: undefined,
                    UnitPrice: undefined,
                    DisparityPrice: undefined,
                    DisparityPrice_Other: undefined,
                    UnitPrice_Transfer: undefined,
                    DateOf_Begin: undefined,
                    DateOf_End: undefined,
                    Hide: 0
                });
            }}
            width={"100vw"}
            closable={false}
            forceRender
            maskClosable={false}
            className="DetailDVKTTheoGia"
        >
            <div className="text-center">
                <Form onFinish={handleSubmit} form={form} >
                    <div className="scroll">
                        <div className="row">
                            <Form.Item label={<div className="form-input-label label-giadvkt ">Tên DVKT:</div>}
                                name="ServiceID"
                                className="col-md-8">
                                <Select
                                    showSearch
                                    disabled
                                    allowClear
                                    className="selectac"
                                    placeholder="Chọn"
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={listDVKT.map((item) => ({
                                        value: item.ServiceID,
                                        label: item.ServiceName
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div className="form-input-label label-giadvkt ">  Đối tượng: </div>}
                                name="ObjectID"
                                className="col-md-4">
                                <Select
                                    showSearch
                                    allowClear
                                    className="selectac"
                                    placeholder="Chọn"
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={listDoiTuong.map((item) => ({
                                        value: item.ObjectID,
                                        label: item.ObjectName
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div className="form-input-label label-giadvkt ">ID:</div>}
                                className="col-md-6"
                                name="ServicePriceID"
                                hidden={true} >
                                <Input className="form-control inputdm" />
                            </Form.Item>
                        </div>
                        <div className="row">
                            <Form.Item
                                label={<div className="form-input-label label-giadvkt">Đơn giá BV:</div>}
                                name="UnitPrice_BV"
                                className="col-md-4 m-0"
                            >
                                <InputNumber className="form-control form-control-sm"
                                    formatter={(value) => priceSplitter(value)}
                                    parser={(value) => value.replace(/\D/g, "")}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div className="form-input-label label-giadvkt">Đơn giá:</div>}
                                name="UnitPrice"
                                className="col-md-4 m-0"
                            >
                                <InputNumber className="form-control form-control-sm"
                                    formatter={(value) => priceSplitter(value)}
                                    parser={(value) => value.replace(/\D/g, "")}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div className="form-input-label label-giadvkt">Phụ thu:</div>}
                                className="col-md-4 m-0"
                                name="DisparityPrice">
                                <InputNumber className="form-control form-control-sm"
                                    formatter={(value) => priceSplitter(value)}
                                    parser={(value) => value.replace(/\D/g, "")}
                                />
                            </Form.Item>
                        </div>
                        <div className="row">
                            <Form.Item
                                label={<div className="form-input-label label-giadvkt">Ph.Thu ngoài giờ: </div>}
                                name="DisparityPrice_Other"
                                className="col-md-4 m-0">
                                <InputNumber className="form-control form-control-sm ma-input"
                                    formatter={(value) => priceSplitter(value)}
                                    parser={(value) => value.replace(/\D/g, "")}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div className="form-input-label label-giadvkt">Ph.Thu gửi mẫu:</div>}
                                name="UnitPrice_Transfer"
                                className="col-md-4 m-0">
                                <InputNumber className="form-control form-control-sm ma-input"
                                    formatter={(value) => priceSplitter(value)}
                                    parser={(value) => value.replace(/\D/g, "")}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div className="form-input-label label-giadvkt">Ẩn/Hiện:</div>}
                                className="col-md-2 m-0"
                                name="Hide" valuePropName="checked">
                                <Checkbox onChange={onChange} value={checkAnHien} />
                            </Form.Item>

                        </div>
                        <div className="row">
                            <Form.Item
                                label={<div className="form-input-label label-giadvkt">Ngày bắt đầu:</div>}
                                name="DateOf_Begin"
                                className="col-md-4 m-0"
                            >
                                <DatePicker className="form-control"
                                    placeholder=""
                                    format={'DD/MM/YYYY'} onChange={(date, dateString) => { setStartDate(date); }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<div className="form-input-label label-giadvkt">Ngày kết thúc:</div>}
                                name="DateOf_End"
                                className="col-md-4 m-0"
                            >
                                <DatePicker className="form-control" format={'DD/MM/YYYY'}
                                    placeholder=""
                                    onChange={(date, dateString) => { setendDate(date); }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <DataGrid
                        data={filteredData}
                        style={{ height: 200 }}
                        columnResizing
                        dblclickToEdit
                        editMode="row"
                        onRowDblClick={(row) => { handleRowDoubleClick(row); }}

                    >
                        <GridColumn field="ServiceID" title="Tên DVKT" width={300}
                            render={({ row }) => {
                                const dvkt = (listDVKT || []).find((item) => item.ServiceID === row.ServiceID);
                                return dvkt ? dvkt.ServiceName : null;
                            }}
                        />
                        <GridColumn
                            field="ObjectID"
                            title="Đối tượng"
                            align="center"
                            width={130}
                            render={({ row }) => {
                                const doituong = listDoiTuong.find((item) => item.ObjectID === row.ObjectID);
                                return doituong ? doituong.ObjectName : null;
                            }}
                        />
                        <GridColumn field="UnitPrice_BV" title="Đơn giá BV" align="center" width={150} resizable
                            render={({ row }) => (
                                <div>
                                    {row.UnitPrice_BV !== undefined
                                        ? `${row.UnitPrice_BV.toLocaleString('vi-VN')}`
                                        : "0"}
                                </div>
                            )} />
                        <GridColumn field="UnitPrice" title="Đơn giá" align="center" width={120} resizable
                            render={({ row }) => (
                                <div>
                                    {row.UnitPrice !== undefined
                                        ? `${row.UnitPrice.toLocaleString('vi-VN')}`
                                        : "0"}
                                </div>
                            )} />
                        <GridColumn field="DisparityPrice" title="Phụ thu" align="center" width={120} resizable
                            render={({ row }) => (
                                <div>
                                    {row.DisparityPrice !== undefined
                                        ? `${row.DisparityPrice.toLocaleString('vi-VN')}`
                                        : "0"}
                                </div>
                            )} />
                        <GridColumn field="DisparityPrice_Other" title="Phụ thu N.Giờ" align="center" width={120} resizable
                            render={({ row }) => (
                                <div>
                                    {row.DisparityPrice_Other !== undefined
                                        ? `${row.DisparityPrice_Other.toLocaleString('vi-VN')}`
                                        : "0"}
                                </div>
                            )} />
                        <GridColumn field="UnitPrice_Transfer" title="Phụ thu G.Mẫu" align="center" width={120} resizable
                            render={({ row }) => (
                                <div>
                                    {row.UnitPrice_Transfer !== undefined
                                        ? `${row.UnitPrice_Transfer.toLocaleString('vi-VN')}`
                                        : "0"}
                                </div>
                            )} />
                        <GridColumn
                            field="DateOf_Begin"
                            title="Ngày bắt đầu"
                            align="center"
                            width={120}
                            resizable
                            render={({ row }) => (<div>{moment(row.DateOf_Begin).format('DD/MM/YYYY')}</div>)}

                        />

                        <GridColumn
                            field="DateOf_End"
                            title="Ngày kết thúc"
                            align="center"
                            resizable
                            width={120}
                            render={({ row }) => (<div>{moment(row.DateOf_End).format('DD/MM/YYYY')}</div>)}

                        />
                        <GridColumn
                            field="Hide"
                            title="Ẩn/Hiện"
                            align="center"
                            resizable
                            width={100}
                            render={({ row }) => (
                                <Checkbox checked={row.Hide === true} />
                            )}
                        />
                        <GridColumn
                            field="actions"
                            title="Thao tác"
                            align="center"
                            width={150}
                            resizable
                            render={({ row }) => (
                                <div className="d-flex justify-content-center align-center">

                                    <Button
                                        className="bg-light vienphi-danhmuc-icon-modify"
                                        onClick={() => handleDeleteRow(row.ServicePriceID)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faFileExcel}
                                            style={{ fontSize: "10px" }}
                                            className="text-dark"
                                        />
                                    </Button>

                                </div>
                            )}
                        />

                    </DataGrid>

                    <Form.Item className="text-center">
                        <hr />
                        <div className=" btn-action-giadvkt">
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => {
                                setOpen(false); setlistGiaDVKTRow(null);
                                form.setFieldsValue({
                                    ServicePriceID: 0,
                                    ObjectID: undefined,
                                    UnitPrice_BV: undefined,
                                    UnitPrice: undefined,
                                    DisparityPrice: undefined,
                                    DisparityPrice_Other: undefined,
                                    UnitPrice_Transfer: undefined,
                                    DateOf_Begin: undefined,
                                    DateOf_End: undefined,
                                    Hide: 0
                                });

                            }}>
                                <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                            </Button>
                            <div className="col-n check-dskhoa"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                        </div>
                    </Form.Item>
                </Form>
            </div>

        </Modal>
    );
}
export default DetailPopUpGiaDVKT;
