import axios from "axios";
import REACT_LOCALHOST from "../../host";
import { authHeader } from '../../helpers';
const giadvktAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-services-price-vien-phi`,obj);
    },
    getAllDVKT: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-services-vien-phi`,obj);
    },
    getAllObject: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-object`,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-med-services-price-vien-phi`, obj, {"headers":authHeader()});
    },
    addUpdate: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-update-med-services-price-vien-phi`, obj, {"headers":authHeader()});
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-med-services-price-vien-phi`, obj);
    },
    delete: (ServicePriceID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-med-services-price-vien-phi/${ServicePriceID}/${EmployeeID}`);
    }
};

export default giadvktAPI;
