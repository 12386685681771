import imguser from "../../../assets/image/user.png";
import imgiconcheck from "../../../assets/image/icons-check.png";
import "./congkham.scss";
import { useReactToPrint } from "react-to-print";
import { DataGrid, GridColumn, CheckBox, NumberBox } from 'rc-easyui';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave,
    faPenToSquare,
    faXmarkCircle,
    faAdd,
    faCamera, faBarcode, faFileLines, faUsers, faAngleDown, faXmark, faPrint, faCircleUser
} from "@fortawesome/free-solid-svg-icons";
import { Form, Input, Select, Button, Modal } from "antd";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import React from "react";
import { errorInfo, successInfo, infoRes, printNhan } from "../../../components/Dialog/Dialog";

import tiepnhanAPI from "../../../services/tiepnhan/tiepnhanAPI";
import goikhamAPI from '../../../services/vienphi/danhmuc/goikhamAPI';
import khoaphongAPI from '../../../services/tienich/khoaphongAPI';
import employeeAPI from '../../../services/employeeAPI';
import { setDataTiepNhan } from "../../../slices/dataTiepNhan";
import { setDataSearchTiepNhan } from "../../../slices/dataTiepNhan";
import { setDataDatHen } from "../../../slices/dataDatHen";
import loginAPI from "../../../services/loginApi";
import PrintDanNhan from "../Print/PrintDanNhan";
import PrintChiDinh from "../Print/PrintChiDinh";
import PrintSTT from "../Print/PrintSTT";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const withCheckbox = (WrappedComponent) => {
    class CheckGrid extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                clicked: false,
                data: this.props.data,
                selectedRows: []
            }
        }
        componentDidMount() {
            //this.setState({ selectedRows: this.props.selection || [] })
            this.setState({ selectedRows: Array.isArray(this.props.selection) ? this.props.selection : [] });
        }
        componentDidUpdate(prevProps) {
            /* if (this.props.selection !== prevProps.selection) {
                 this.setState({ selectedRows: this.props.selection })
             }
             if (this.props.data !== prevProps.data) {
                 this.setState({ data: this.props.data })
             }*/
            if (this.props.selection !== prevProps.selection) {
                this.setState({ selectedRows: Array.isArray(this.props.selection) ? this.props.selection : [] });
            }
            if (this.props.data !== prevProps.data) {
                this.setState({ data: this.props.data });
            }

        }
        /*isChecked(row) {
            if (this.props.idField) {
                const index = this.state.selectedRows.findIndex(s => s[this.props.idField] === row[this.props.idField]);
                if (index >= 0) {
                    return true;
                }
            } else {
                const index = this.state.selectedRows.indexOf(row);
                if (index >= 0) {
                    return true;
                }
            }
            return false;
        }*/
        isChecked(row) {
            if (!Array.isArray(this.state.selectedRows)) {
                return false;
            }
            if (this.props.idField) {
                return this.state.selectedRows.some(s => s[this.props.idField] === row[this.props.idField]);
            }
            return this.state.selectedRows.includes(row);
        }
        isAllChecked() {
            const { selectedRows, data } = this.state;
            if (selectedRows.length && selectedRows.length === data.length) {
                return true;
            }
            return false;
        }
        handleRowCheck(row, checked) {
            if (this.state.clicked) {
                return;
            }
            const index = this.state.data.indexOf(row);
            const data = this.state.data.slice();
            data.splice(index, 1, Object.assign({}, row));
            this.setState({ data: data })
            if (checked) {
                this.setState({
                    selectedRows: [...this.state.selectedRows, data[index]]
                })
            } else {
                const selection = this.state.selectedRows.filter(r => {
                    if (this.props.idField) {
                        if (r[this.props.idField] !== row[this.props.idField]) {
                            return true;
                        }
                    } else {
                        if (r !== row) {
                            return true;
                        }
                    }
                    return false;
                });
                this.setState({
                    selectedRows: selection
                })
            }
            this.setState({ clicked: true }, () => {
                setTimeout(() => this.setState({ clicked: false }))
                if (this.props.onSelectionChange) {
                    this.props.onSelectionChange(this.state.selectedRows)
                }
            })
        }
        handleAllCheck(checked) {
            if (this.state.clicked) {
                return;
            }
            const data = this.state.data.map(row => Object.assign({}, row))
            this.setState({
                data: data,
                selectedRows: checked ? data : [],
                clicked: true
            }, () => {
                setTimeout(() => this.setState({ clicked: false }))
                if (this.props.onSelectionChange) {
                    this.props.onSelectionChange(this.state.selectedRows)
                }
            })
        }
        render() {
            return (
                <WrappedComponent {...this.props} data={this.state.data} ref={el => this.datagrid = el}>
                    <GridColumn key="ck_column_key" width={50} align="center"
                        field="ck"
                        render={({ row }) => (
                            <CheckBox checked={this.isChecked(row)} onChange={(checked) => this.handleRowCheck(row, checked)}></CheckBox>
                        )}
                        header={() => (
                            <CheckBox checked={this.isAllChecked()} onChange={(checked) => this.handleAllCheck(checked)}></CheckBox>
                        )}
                        filter={() => <span></span>}
                    />
                    {this.props.children}
                </WrappedComponent>
            )
        }
    }
    return CheckGrid
}
const CheckGrid = withCheckbox(DataGrid)

function Congkham() {
    const [birthDate, setBirthDate] = useState(null);
    const [NgayCapCC, setNgayCapCC] = useState(null);

    const [PatientGender, setPatientGender] = useState([]);
    const [Ethnic, setEthnic] = useState([]);

    const [ListProvincial, setProvincial] = useState([]);
    const [ListDistrict, setdistrict] = useState([]);
    const [ListWard, setward] = useState([]);
    const [ListNationality, setNationality] = useState([]);
    const [ListCareer, setCareer] = useState([]);
    const [ListObject, setObject] = useState([]);
    const [ListChiDinh, setChiDinh] = useState([]);
    const [ListDepartment, setListDepartment] = useState([]);
    const [userlogin, setUserLogin] = useState();
    const { tiepnhan, sinhhieutiepnhan, searchtiepnhan } = useSelector((state) => state.dataTiepNhan);
    const { dathen } = useSelector((state) => state.dataDatHen);
    const [form] = Form.useForm();
    const [hienthi, setHienThi] = useState(true);
    const [btnEdit, setbtnEdit] = useState(false);
    const [btnTiepNhan, setbtnTiepNhan] = useState(true);
    const [btnChiDinh, setbtnChiDinh] = useState(true);
    const [btnMoi, setbtnMoi] = useState(false);
    const [btnSTT, setbtnSTT] = useState(true);
    const [chonquanhuyen, setchonquanhuyen] = useState([]);
    const [chonphuongxa, setchonphuongxa] = useState([]);
    const dispatch = useDispatch();
    const [SearchCongKham, setSearchCongKham] = useState("");
    const [ShowOptionTable, setShowOptionTable] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const [FiterChiDinh, setFiterChiDinh] = useState([]);
    const [ChonCongKham, setChonCongKham] = useState([]);
    const [ViewCongKhamID, setViewCongKhamID] = useState([]);
    const [ChonDoiTuong, setChonDoiTuong] = useState(0);
    const [selection, setSelection] = useState([]);
    const [BacSiGioiThieu, setBacSiGioiThieu] = useState([]);
    const [NgGioiThieu, setNgGioiThieu] = useState([]);
    const [NgChamSoc, setNgChamSoc] = useState([]);
    const [KSK, setKSK] = useState([]);
    const [DoiTuongKhamBenh, setDoiTuongKhamBenh] = useState([]);
    const [LoaiKhamBenh, setLoaiKhamBenh] = useState([]);
    const [DoiTuongUuTien, setDoiTuongUuTien] = useState([]);
    const [TaiNanTT, setTaiNanTT] = useState([]);
    const [InNhanTem, setInNhanTem] = useState([]);
    const [InNhanTemID, setInNhanTemID] = useState(null);
    const [InListChiDinh, setInListChiDinh] = useState(null);
    const [InSTTID, setInSTTID] = useState(null);

    const [CheckGiaDV, setCheckGiaDV] = useState(false);

    const [isOpenDT, setIsOpenDT] = useState(false);
    const [searchDTMa, setSearchDTMaMa] = useState("");
    const [searchDT, setSearchDT] = useState("");
    const dropdownRef = useRef(null);

    const refHoTen = useRef(null);
    const refNgaySinh = useRef(null);
    const refGioiTinh = useRef(null);
    const refDanToc = useRef(null);
    const refNgheNghiep = useRef(null);
    const refQuocTich = useRef(null);
    const refDiaChi = useRef(null);
    const refTinh = useRef(null);
    const refQuanHuyen = useRef(null);
    const refPhuongXa = useRef(null);
    const refDoiTuong = useRef(null);
    const refCongKham = useRef(null);

    const refCCCD = useRef(null);
    const refNgayCap = useRef(null);
    const refNoiCap = useRef(null);
    const refSoDT = useRef(null);
    const refEmail = useRef(null);
    const refTenCty = useRef(null);
    const refMaSoThue = useRef(null);
    const refTienSuBenh = useRef(null);
    const refNgGiamHo = useRef(null);
    const refQuanHe = useRef(null);
    const refNamSinhNGH = useRef(null);
    const refSoDTNgGH = useRef(null);
    const refMaBH = useRef(null);
    const refDKKCBBD = useRef(null);
    const refTuNgay = useRef(null);
    const refDenNgay = useRef(null);
    const refDu5Nam = useRef(null);
    const refNgayMien = useRef(null);
    const refKhuVuc = useRef(null);
    const refLyDoDT = useRef(null);
    const refDTThe = useRef(null);
    const refLoaiKCB = useRef(null);
    const refDoiTUTien = useRef(null);
    const refUuDai = useRef(null);
    const refLyDoKham = useRef(null);
    const refNhipMach = useRef(null);
    const refNhietDo = useRef(null);
    const refHuyetAp1 = useRef(null);
    const refHuyetAp2 = useRef(null);
    const refNhipHo = useRef(null);
    const refChieuCao = useRef(null);
    const refCanNang = useRef(null);
    const refSPO2 = useRef(null);
    const refNgGioiThieu = useRef(null);
    const refBSYeuCau = useRef(null);
    const refTaiNan = useRef(null);
    const refKSK = useRef(null);
    const tableRef = useRef(null);
    let lastKeyDownTime = useRef(0);


    const [filteredData, setFilteredData] = useState([]);

    const handleNextFocus = (currentRef, nextRef) => {
        if (nextRef?.current) {
            setTimeout(() => {
              nextRef.current.focus();
            }, 0);
          }
    };
    const onClhandleNextFocus = (nextRef) => {
        nextRef?.current?.focus();
    };

    const removeVietnameseTones = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    /* const selectRef = useState(null);
     function setChange() {
         const selected = selectRef?.current?.querySelector(".active");
         if (selected) {
             selected?.scrollIntoView({
                 behavior: "smooth",
                 block: "start"
             });
         }
     }*/

    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleChangeProvincial = (value) => {
        if (!value) return;
        form.setFieldsValue({
            ProvincialID: value,
            DistrictID: undefined,
            WardID: undefined,
        });
        if (Array.isArray(ListDistrict) && ListDistrict.length > 0) {
            const filtered = ListDistrict.filter((item) => item.ProvincialID === value);
            setchonquanhuyen(filtered);
        } else {
            setchonquanhuyen([]);
        }
    };

    const handleChangeDistrict = (value) => {
        if (!value) return;
        form.setFieldsValue({
            DistrictID: value,
            WardID: undefined,
        });
        if (Array.isArray(ListWard) && ListWard.length > 0) {
            const filtered = ListWard.filter((item) => item.DistrictID === value);
            setchonphuongxa(filtered);
        } else {
            setchonphuongxa([]);
        }
    };
    const ChonDoiTuongLayCongKham = (e) => {
        setChonDoiTuong(e);
        if (e === 2) {
            setCheckGiaDV(true);
        } else { setCheckGiaDV(false); }

    }
    const getAllChiDinh = async () => {
        try {
            const data = await tiepnhanAPI.getChiDinh();
            setChiDinh(data.data);
        } catch (err) { }
    };
    const getAllNgGioiThieu = async () => {
        try {
            const data = await tiepnhanAPI.getIntroducer({ 'Hide': 0 });
            setNgGioiThieu(data.data);
        } catch (err) { }
    };
    const getAllBSGioiThieu = async () => {
        try {
            const data = await employeeAPI.getAll();
            setBacSiGioiThieu(data.data);
        } catch (err) { }
    };
    const getAllNgChamSoc = async () => {
        try {
            const data = await tiepnhanAPI.getNgChamSoc();
            setNgChamSoc(data.data);
        } catch (err) { }
    };
    const getAllKSK = async () => {
        try {
            const data = await tiepnhanAPI.getKSKTN();
            setKSK(data.data);
        } catch (err) { }
    };
    const getAllDTKhamBenh = async () => {
        try {
            const data = await tiepnhanAPI.getDoiTuongKhamBenh();
            setDoiTuongKhamBenh(data.data);
        } catch (err) { }
    };
    const getAllLoaiKhamBenh = async () => {
        try {
            const data = await tiepnhanAPI.getLoaiKhamBenh();
            setLoaiKhamBenh(data.data);
        } catch (err) { }
    };
    const getAllDoiTuongUuTien = async () => {
        try {
            const data = await tiepnhanAPI.getDoiTuongUuTien();
            setDoiTuongUuTien(data.data);
        } catch (err) { }
    };
    const getAllTaiNanTT = async () => {
        try {
            const data = await tiepnhanAPI.getTaiNanTT({ 'Hide': 0 });
            setTaiNanTT(data.data);
        } catch (err) { }
    };

    const getViewCongKhamID = useCallback(async () => {
        try {
            const data = await tiepnhanAPI.getChiDinhPatientReceiveid({ PatientReceiveID: tiepnhan === null ? 0 : tiepnhan[0]?.PatientReceiveID,ReferenceCode: tiepnhan === null ? 0 : tiepnhan[0]?.ReferenceCode });
            setViewCongKhamID(data.data);
        } catch (err) { }
    }, [tiepnhan]);

    const getPatientGender = async () => {
        try {
            const data = await tiepnhanAPI.getAllPatientGender();
            setPatientGender(data.data);
        } catch (err) { }
    };
    const getEthnic = async () => {
        try {
            const data = await tiepnhanAPI.getAllEthnic({ 'Hide': 0 });
            setEthnic(data.data);
        } catch (err) { }
    };
    const getProvincial = async () => {
        try {
            const data = await tiepnhanAPI.getAllProvincial({ 'Hide': 0 });
            setProvincial(data.data);
        } catch (err) { }
    };
    const getDistrict = async () => {
        try {
            const data = await tiepnhanAPI.getAllDistrict({ 'Hide': 0 });
            setdistrict(data.data);
        } catch (err) { }
    };
    const getWard = async () => {
        try {
            const data = await tiepnhanAPI.getAllWard({ 'Hide': 0 });
            setward(data.data);
        } catch (err) { }
    };
    const getNationality = async () => {
        try {
            const data = await tiepnhanAPI.getAllNationality({ 'Hide': 0 });
            setNationality(data.data);
        } catch (err) { }
    };
    const getCareer = async () => {
        try {
            const data = await tiepnhanAPI.getAllCareer({ 'Hide': 0 });
            setCareer(data.data);
        } catch (err) { }
    };
    const getObject = async () => {
        try {
            const data = await tiepnhanAPI.getAllObject();
            setObject(data.data);
        } catch (err) { }
    };
    const getInNhanTem = async (id) => {
        try {
            const data = await tiepnhanAPI.getInDanNhan({ 'PatientReceiveID': id });
            setInNhanTem(data.data);
        } catch (err) { }
    };
    const getDepartment = async () => {
        try {
            const data = await khoaphongAPI.fetchKhoaPhong({ 'Hide': 0 });
            setListDepartment(data.data);
        } catch (err) { }
    };


    const handleCreate = async (obj) => {

        const data = await tiepnhanAPI.AddUpdatePatients(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);

        }
        else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            const updatedRecord = { ...obj, PatientCode: data.data.Content };
            const addpatient = await tiepnhanAPI.AddUpdatePatientsReceive(updatedRecord);
            if (addpatient.data.Code === "ok") {
                const addRecordSinhHieu = { ...obj, PatientCode: data.data.Content, PatientReceiveID: addpatient.data.PatientReceiveID, ReferenceCode: addpatient.data.ReferenceCode };
                await tiepnhanAPI.AddUpdateSurvivesign(addRecordSinhHieu);
                await tiepnhanAPI.AddUpdateChiDinh(addRecordSinhHieu);
                setInNhanTemID(addpatient.data.PatientReceiveID);
                getInNhanTem(addpatient.data.PatientReceiveID);
            }
        }
    };
    const handleUpdate = async (obj) => {
        const data = await tiepnhanAPI.AddUpdatePatients(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            const addpatient = await tiepnhanAPI.AddUpdatePatientsReceive(obj);
            if (addpatient.data.Code === "ok") {
                setInNhanTemID(obj.PatientReceiveID);
                getInNhanTem(obj.PatientReceiveID);
                await tiepnhanAPI.AddUpdateSurvivesign(obj);
                await tiepnhanAPI.AddUpdateChiDinh(obj);
            }

        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleSubmit = async (e) => {
        if (e?.PatientName === undefined || e?.PatientName === '') {
            refHoTen.current?.focus(); Swal.fire("Vui lòng nhập họ tên !");

        }
        else if (birthDate === null && e?.PatientBirthday === undefined) { refNgaySinh.current?.focus(); Swal.fire("Vui lòng nhập ngày sinh !"); }
        else if (e?.PatientGenderID === undefined) { refGioiTinh.current?.focus(); Swal.fire("Vui lòng nhập giới tính !"); }
        else if (e?.EThnicID === undefined || e?.EThnicID === 0) { refDanToc.current?.focus(); Swal.fire("Vui lòng nhập dân tộc !"); }
        else if (e?.CareerID === undefined || e?.CareerID === 0) { refNgheNghiep.current?.focus(); Swal.fire("Vui lòng nhập nghề nghiệp !"); }
        else if (e?.NationalityID === undefined || e?.NationalityID === 0) { refQuocTich.current?.focus(); Swal.fire("Vui lòng nhập quốc tịch !"); }
        else if (e?.Address_ === undefined || e?.Address_ === '') { refDiaChi.current?.focus(); Swal.fire("Vui lòng nhập địa chỉ !"); }
        else if (e?.ProvincialID === undefined || e?.ProvincialID === 0) { refTinh.current?.focus(); Swal.fire("Vui lòng nhập tỉnh thành !"); }
        else if (e?.DistrictID === undefined || e?.DistrictID === 0) { refQuanHuyen.current?.focus(); Swal.fire("Vui lòng nhập quận huyện !"); }
        else if (e?.WardID === undefined || e?.WardID === 0) { refPhuongXa.current?.focus(); Swal.fire("Vui lòng nhập phường xã !"); }
        else if (e?.ObjectID === undefined || e?.ObjectID === 0) { refDoiTuong.current?.focus(); Swal.fire("Vui lòng chọn đối tượng!"); }
        else if (ChonCongKham.length === 0 && ViewCongKhamID.length === 0) { refCongKham.current?.focus(); Swal.fire("Vui lòng chọn công khám !"); }

        else {
            Swal.fire({
                title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
                confirmButtonText: "Đồng ý",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                customClass: {
                    title: "fs-5 text-dark",
                    confirmButton: "shadow-none",
                    cancelButton: "shadow-none",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    if (tiepnhan !== null) {
                        var arrayTiepNhan = {
                            PatientCode: e?.PatientCode,
                            PatientReceiveID: e?.PatientReceiveID,
                            PatientCode_PK: e?.PatientCode_PK,
                            PatientName: e?.PatientName ?? " ",
                            PatientGenderID: e?.PatientGenderID ?? 0,
                            PatientBirthday: birthDate === null ? dayjs(e?.PatientBirthday).utc(true) : dayjs(birthDate).utc(true),
                            PatientBirthday_BH: e?.PatientBirthday_BH ?? " ",
                            Reason: e?.Reason ?? " ",
                            ObjectID: e?.ObjectID ?? 0,
                            Address_: e?.Address_ ?? " ",
                            ProvincialID: e?.ProvincialID ?? 0,
                            DistrictID: e?.DistrictID ?? 0,
                            WardID: e?.WardID ?? 0,
                            PatientMobile: e?.PatientMobile ?? " ",
                            PatientEmail: e?.PatientEmail ?? " ",
                            CareerID: e?.CareerID ?? 0,
                            EThnicID: e?.EThnicID ?? 0,
                            NationalityID: e?.NationalityID ?? 0,
                            MedicalHistory: e?.MedicalHistory ?? " ",
                            Allergy: e?.Allergy ?? " ",
                            CC_Serial: e?.CC_Serial ?? " ",
                            CC_NgayCap: NgayCapCC === null ? dayjs(e?.CC_NgayCap).utc(true) : dayjs(NgayCapCC).utc(true),
                            CC_NoiCap: e?.CC_NoiCap ?? " ",
                            CompanyName: e?.CompanyName ?? " ",
                            CompanyMST: e?.CompanyMST ?? " ",
                            NCS_FullName: e?.NCS_FullName ?? " ",
                            NCS_TypeID: e?.NCS_TypeID ?? 0,
                            NCS_Birthyear: e?.NCS_Birthyear ?? 0,
                            NSC_Mobile: e?.NSC_Mobile ?? " ",
                            MA_DOITUONG_KCB: searchDTMa === '' ? tiepnhan[0]?.MA_DOITUONG_KCB : searchDTMa,
                            MA_LOAI_KCB: e?.MA_LOAI_KCB ?? " ",
                            IntroCode: e?.IntroCode ?? " ",
                            EmployeeDoctorID_YC: e?.EmployeeDoctorID_YC ?? 0,
                            TNTT_ID: e?.TNTT_ID ?? 1,
                            ObjectPriorityID: e?.ObjectPriorityID ?? 1,
                            PromotionsCode: e?.PromotionsCode ?? " ",
                            IsKCB_NgoaiGio: 0,
                            IsCheck_TT: 0,
                            IsSend_CheckIn: 0,
                            IsNew: 0,
                            Is_SavedBV: 0,
                            Is_KCB_Done: 0,
                            PatientTypeID: 1,
                            GM_MaDV: 1,
                            PatientAppID: 1,
                            KSK_ID: e?.KSK_ID ?? 0,
                            BloodPressure: e?.BloodPressure === undefined ? ' ' : e?.BloodPressure + ',' + e?.BloodPressure1,
                            Pulse: e?.Pulse ?? ' ',
                            Breath: e?.Breath ?? ' ',
                            Temperature: e?.Temperature ?? ' ',
                            Hight: e?.Hight ?? ' ',
                            Weight: e?.Weight ?? ' ',
                            SPO2: e?.SPO2 ?? ' ',
                            BMI: e?.BMI ?? ' ',
                            VongNguc: e?.VongNguc ?? ' ',
                            ReferenceCode: e?.ReferenceCode,
                            RowID: e?.RowID,
                            WorkDate: new Date(),
                            IUDate: new Date(),
                            EmployeeID: userlogin.RowID,
                            ChiDinh: [...ChonCongKham, ...ViewCongKhamID],

                        }
                        handleUpdate(arrayTiepNhan);
                        dispatch(setDataTiepNhan(null));
                        form.resetFields();
                        setbtnEdit(false);
                        setHienThi(true);
                        setChonCongKham([]);
                        setbtnSTT(false);
                        setbtnMoi(false);

                    } else {
                        var addarrayTiepNhan = {
                            PatientCode: e?.PatientCode ?? " ",
                            RowID: e?.RowID ?? 0,
                            PatientReceiveID: e?.PatientReceiveID ?? 0,
                            PatientCode_PK: e?.PatientCode_PK ?? " ",
                            PatientName: e?.PatientName ?? " ",
                            PatientGenderID: e?.PatientGenderID ?? 0,
                            PatientBirthday: dayjs(birthDate).utc(true) ?? " ",
                            PatientBirthday_BH: e?.PatientBirthday_BH ?? " ",
                            Reason: e?.Reason ?? " ",
                            ObjectID: e?.ObjectID ?? 0,
                            Address_: e?.Address_ ?? " ",
                            ProvincialID: e?.ProvincialID ?? 0,
                            DistrictID: e?.DistrictID ?? 0,
                            WardID: e?.WardID ?? 0,
                            PatientMobile: e?.PatientMobile ?? " ",
                            PatientEmail: e?.PatientEmail ?? " ",
                            CareerID: e?.CareerID ?? 0,
                            EThnicID: e?.EThnicID ?? 0,
                            NationalityID: e?.NationalityID ?? 0,
                            MedicalHistory: e?.MedicalHistory ?? " ",
                            Allergy: e?.Allergy ?? " ",
                            CC_Serial: e?.CC_Serial ?? " ",
                            CC_NgayCap: dayjs(NgayCapCC) ?? " ",
                            CC_NoiCap: e?.CC_NoiCap ?? " ",
                            CompanyName: e?.CompanyName ?? " ",
                            CompanyMST: e?.CompanyMST ?? " ",
                            NCS_FullName: e?.NCS_FullName ?? " ",
                            NCS_TypeID: e?.NCS_TypeID ?? 0,
                            NCS_Birthyear: e?.NCS_Birthyear ?? 0,
                            NSC_Mobile: e?.NSC_Mobile ?? " ",
                            MA_DOITUONG_KCB: searchDTMa ?? " ",
                            MA_LOAI_KCB: e?.MA_LOAI_KCB ?? " ",
                            IntroCode: e?.IntroCode ?? " ",
                            EmployeeDoctorID_YC: e?.EmployeeDoctorID_YC ?? 0,
                            TNTT_ID: e?.TNTT_ID ?? 1,
                            ObjectPriorityID: e?.ObjectPriorityID ?? 1,
                            PromotionsCode: e?.PromotionsCode ?? " ",
                            IsKCB_NgoaiGio: 0,
                            IsCheck_TT: 0,
                            IsSend_CheckIn: 0,
                            IsNew: 0,
                            Is_SavedBV: 0,
                            Is_KCB_Done: 0,
                            PatientTypeID: 1,
                            GM_MaDV: 1,
                            PatientAppID: 1,
                            KSK_ID: e?.KSK_ID ?? 0,

                            BloodPressure: e?.BloodPressure === undefined ? ' ' : e?.BloodPressure + ',' + (e?.BloodPressure1 === undefined ? ' ' : e?.BloodPressure1),
                            Pulse: e?.Pulse ?? ' ',
                            Breath: e?.Breath ?? ' ',
                            Temperature: e?.Temperature ?? ' ',
                            Hight: e?.Hight ?? ' ',
                            Weight: e?.Weight ?? ' ',
                            SPO2: e?.SPO2 ?? ' ',

                            BMI: e?.BMI ?? ' ',
                            VongNguc: e?.VongNguc ?? ' ',
                            WorkDate: new Date(),
                            IUDate: new Date(),
                            EmployeeID: userlogin.RowID,

                            ChiDinh: ChonCongKham,
                        };
                        handleCreate(addarrayTiepNhan);
                        setChonCongKham([]);
                        setHienThi(true);
                        setbtnMoi(false);
                        setbtnSTT(false);


                    }
                }
            });
        }
    }
    const handleKeyDownSubmit = (event) => {
        if (event.key === "F3") {
            event.preventDefault();
            handleSubmit();
        }
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };
    const FilterCongKham = (e) => {
        if (ChonDoiTuong === 0 && tiepnhan === null) {
            Swal.fire({
                icon: "warning",
                title: "Chưa chọn đối tượng",
                showConfirmButton: false,
                timer: 1000,
                customClass: {
                    title: "fs-5 text-muted",
                },
            });
        }
        else {
            const searchValue = e.target.value.toLowerCase();
            setSearchCongKham(searchValue);

            if (searchValue === "") {
                getAllChiDinh();
                setFiterChiDinh([]);
                return;
            }
            if (SearchCongKham !== '') {
                setShowOptionTable(true);
            }
            const filterBySearch = ListChiDinh.filter((item) =>
                removeVietnameseTones(item.ServiceName || "").toLowerCase().includes(removeVietnameseTones(searchValue))
            );
            setFiterChiDinh(filterBySearch);
        }

    };

    /* const handleKeyDown = useCallback(async (e) => {
         if (e.key === "ArrowUp" && selectedRowIndex > 0) {
             setSelectedRowIndex(selectedRowIndex - 1);
         } else if (e.key === "ArrowDown") {
             const maxIndex = FiterChiDinh.length > 0 ? FiterChiDinh.length - 1 : ListChiDinh.length - 1;
             if (selectedRowIndex < maxIndex) {
                 setSelectedRowIndex(selectedRowIndex + 1);
             }
         }
         if (e.key === "Enter" && ShowOptionTable) {
             // setSelectedRowIndex(0);
             const sourceArray = FiterChiDinh[selectedRowIndex] || ListChiDinh[selectedRowIndex];
             if (!sourceArray) return;;
             const mergedArray = [...ChonCongKham, ...ViewCongKhamID];
             const exists = mergedArray.some((dv) => dv.ServiceID === sourceArray.ServiceID);
             const defaultServiceData = {
                 checked: false,
                 SuggestedID: 0,
                 ServicePriceID: 0,
                 UnitPrice_BV: 0,
                 DisparityPrice: 0,
                 RateBHYT: 0,
                 RateBHYT_DV: 0,
                 Quantity: 1,
                 IsTransfer: 0,
                 UnitPrice_Transfer: 0,
                 Status: 0,
                 Paid: 0,
                 BanksAccountCode: 0,
                 Is_KCB: 0,
                 Content: " ",
                 CreateDate: new Date(),
                 NGAY_YL: new Date(),
                 NGAY_TH_YL: new Date(),
                 NGAY_KQ: new Date(),
                 DepartmentID_CD: 6,
                 ServicePackageCode: 0,
                 STT_CD: 0,
                 Confirm_CK_BS_CD: 0,
                 Confirm_CK_BS_TH: 0,
                 Confirm_CK_BS_GT: 0,
                 Confirm_CK_GuiMau: 0,
                 SuggestedID_Disparity: 0,
                 SuggestedID_NextDay: 0,
                 EmployeeID: 0,
                 EmployeeID_CD_DD_KVT: 0,
                 EmployeeID_CD_Doctor: 0,
                 EmployeeID_CD_Doctor_CCHN: 0,
                 EmployeeID_TH: 0,
                 EmployeeID_TH_DD_KVT: 0,
                 EmployeeID_TH_DD_KVT_2: 0,
                 EmployeeID_TH_Doctor: 0,
                 EmployeeID_TH_Doctor_CCHN: 0,
                 PromotionsCode: 0,
                 Is_Add_BV: 0,
                 Is_BC: 0,
                 KSK_CTy_isDuyet: 0,
                 KSK_CTy_is_CDThem: 0,
                 KSK_CTy_TypePaidCode: 0,
                 KSK_CTy_Status: 0,
                 ServiceMenuID: 0,
                 MachineID: 0,
             };
 
             if (!exists) {
                 if (sourceArray?.ServicePackageID !== -1) {
                     const data = await goikhamAPI.getAllChiTietGoiKham({ 'ServicePackageID': sourceArray?.ServicePackageID });
                     const chitiet = data.data.map(item => ({
                         ...defaultServiceData,
                         ServiceID: item?.ServiceID,
                         ServiceName: item?.ServiceName,
                         idgoi: item?.ServicePackageID,
                         ObjectID: 2,
                         STT: 1,
                         UnitPrice: item?.ServicePrice,
                         DepartmentID_TH: item.DepartmentID.split(",")[0],
                         ...item,
                     }));
                     setChonCongKham((prev) => [...prev, ...chitiet]);
                     setShowOptionTable(false);
                     setSearchCongKham('');
                     setFiterChiDinh(ListChiDinh);
 
                 } else {
                     const arraychidinh = {
                         ...defaultServiceData,
                         ServiceID: sourceArray?.ServiceID,
                         ServiceName: sourceArray?.ServiceName,
                         idgoi: sourceArray?.idgoi,
                         ObjectID: sourceArray?.ObjectID,
                         STT: 1,
                         UnitPrice: sourceArray?.UnitPrice,
                         DepartmentID_TH: sourceArray.DepartmentID.split(",")[0],
                         ...sourceArray
                     };
                     setChonCongKham((oldArray) => [...oldArray, arraychidinh]);
                     setSearchCongKham(" ");
                     setShowOptionTable(false);
                     setFiterChiDinh(ListChiDinh);
                 }
             }
             else {
                 Swal.fire({
                     icon: "warning",
                     title: "DỊCH VỤ KỸ THUẬT ĐÃ TỒN TẠI",
                     showConfirmButton: false,
                     timer: 1000,
                     customClass: {
                         title: "fs-5 text-muted",
                     },
                 });
                 return;
             }
         }
     }, [selectedRowIndex, FiterChiDinh, ListChiDinh]);*/

    //[ChonCongKham, FiterChiDinh, ListChiDinh, ShowOptionTable, ViewCongKhamID, selectedRowIndex]);
    /* const handleKeyDown = (e) => {
         if (e.key === "ArrowUp" && selectedRowIndex > 0) {
             setSelectedRowIndex(selectedRowIndex - 1);
         } else if (e.key === "ArrowDown" && selectedRowIndex < filteredData.length - 1) {
             setSelectedRowIndex(selectedRowIndex + 1);
         } else if (e.key === "Enter" && selectedRowIndex >= 0 && selectedRowIndex < filteredData.length) {
             e.preventDefault();
             clickChooseThuoc(filteredData[selectedRowIndex]);
             setSearchCongKham('');
             setShowOptionTable(false);
            
         }
 
     };*/


    const handleKeyDown = (e) => {
        const now = Date.now();
        if (now - lastKeyDownTime.current < 150) return;
        lastKeyDownTime.current = now;
        if (!filteredData || filteredData.length === 0) return;

        if (e.key === "Enter") {
            setSearchCongKham('');
            e.preventDefault();
            if (selectedRowIndex !== -1) {
                clickChooseThuoc(filteredData[selectedRowIndex]);
                setTimeout(() => {
                    if (refCongKham.current) {
                        refCongKham.current.focus();
                    }
                }, 100);

                setShowOptionTable(false);
                setSelectedRowIndex(-1);

            }
            return;

        }

        setSelectedRowIndex((prevIndex) => {
            let newIndex = prevIndex;

            if (e.key === "ArrowUp") {
                newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            } else if (e.key === "ArrowDown") {
                newIndex = prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex;
            }

            return newIndex;
        });
    };
    const clickChooseThuoc = async (e) => {
        const exists = ChonCongKham.some(dv => dv.ServiceID === e.ServiceID) ||
            ViewCongKhamID.some(dv => dv.ServiceID === e.ServiceID);
        if (exists) {
            Swal.fire({
                icon: "warning",
                title: "DỊCH VỤ KỸ THUẬT ĐÃ TỒN TẠI",
                showConfirmButton: false,
                timer: 1000,
                customClass: {
                    title: "fs-5",
                },
            });
            return;
        }
        const defaultServiceData = {
            checked: false,
            SuggestedID: 0,
            ServicePriceID: 0,
            UnitPrice_BV: 0,
            DisparityPrice: 0,
            RateBHYT: 0,
            RateBHYT_DV: 0,
            Quantity: 1,
            IsTransfer: 0,
            UnitPrice_Transfer: 0,
            Status: 0,
            Paid: 0,
            BanksAccountCode: 0,
            Is_KCB: 0,
            Content: " ",
            CreateDate: new Date(),
            NGAY_YL: new Date(),
            NGAY_TH_YL: new Date(),
            NGAY_KQ: new Date(),
            DepartmentID_CD: 6,
            ServicePackageCode: 0,
            STT_CD: 0,
            Confirm_CK_BS_CD: 0,
            Confirm_CK_BS_TH: 0,
            Confirm_CK_BS_GT: 0,
            Confirm_CK_GuiMau: 0,
            SuggestedID_Disparity: 0,
            SuggestedID_NextDay: 0,
            EmployeeID: 0,
            EmployeeID_CD_DD_KVT: 0,
            EmployeeID_CD_Doctor: 0,
            EmployeeID_CD_Doctor_CCHN: 0,
            EmployeeID_TH: 0,
            EmployeeID_TH_DD_KVT: 0,
            EmployeeID_TH_DD_KVT_2: 0,
            EmployeeID_TH_Doctor: 0,
            EmployeeID_TH_Doctor_CCHN: 0,
            PromotionsCode: 0,
            Is_Add_BV: 0,
            Is_BC: 0,
            KSK_CTy_isDuyet: 0,
            KSK_CTy_is_CDThem: 0,
            KSK_CTy_TypePaidCode: 0,
            KSK_CTy_Status: 0,
            ServiceMenuID: 0,
            MachineID: 0
        };
        const departmentArray = typeof e.DepartmentID === "string"
            ? e.DepartmentID.split(",").map(id => Number(id))
            : [];


        if (e?.ServicePackageID !== -1) {
            const data = await goikhamAPI.getAllChiTietGoiKham({ 'ServicePackageID': e?.ServicePackageID });
            const chitiet = data.data.map(item => ({
                ...defaultServiceData,
                ServiceID: item?.ServiceID,
                ServiceName: item?.ServiceName,
                idgoi: item?.ServicePackageID,
                ObjectID: 2,
                STT: 1,
                UnitPrice: item?.ServicePrice,
                DepartmentID_TH: item.DepartmentID.split(",")[0],
                ...item,
            }));
            setChonCongKham((prev) => [...prev, ...chitiet]);
            setShowOptionTable(false);
            setSearchCongKham('');
            setFiterChiDinh(ListChiDinh);

        } else {
            const arraychidinh = {
                ...defaultServiceData,
                ServiceID: e?.ServiceID,
                ServiceName: e?.ServiceName,
                idgoi: e?.idgoi,
                ObjectID: e?.ObjectID,
                STT: 1,
                UnitPrice: e?.UnitPrice,
                DepartmentID_TH: departmentArray[0],
                ...e
            };
            setChonCongKham((oldArray) => [...oldArray, arraychidinh]);
            setShowOptionTable(false);
            setSearchCongKham('');
            setFiterChiDinh(ListChiDinh);
        }
    };

    const onclickshowOptionTable = () => {
        if (ChonDoiTuong === 0 && tiepnhan === null) {
            Swal.fire({
                icon: "warning",
                title: "Chưa chọn đối tượng",
                showConfirmButton: false,
                timer: 1000,
                customClass: {
                    title: "fs-5 text-muted",
                },
            });
        }
        else {
            setShowOptionTable(true);
            setFiterChiDinh(ListChiDinh);
        }
    }

    const memoizedListDistrict = useMemo(() => ListDistrict, [ListDistrict]);
    const memoizedListWard = useMemo(() => ListWard, [ListWard]);

    useEffect(() => {
        document.title = 'Tiếp nhận - Emedical';

        Promise.all([
            getPatientGender(), getEthnic(), getProvincial(), getDistrict(), getWard(), getNationality(),
            getCareer(), getObject(), getAllUser(), getAllChiDinh(), getViewCongKhamID(),
            getAllNgGioiThieu(), getAllBSGioiThieu(), getAllNgChamSoc(), getAllKSK(),
            getAllDTKhamBenh(), getAllLoaiKhamBenh(), getDepartment(), getAllDoiTuongUuTien(), getAllTaiNanTT()

        ]);


        if (tiepnhan !== null) {
            setbtnTiepNhan(true);
            setChonDoiTuong(tiepnhan[0]?.ObjectID);
            setInNhanTemID(tiepnhan[0]?.PatientReceiveID);
            setInListChiDinh(tiepnhan[0]);
            setbtnSTT(false);
            setHienThi(false);
            setbtnChiDinh(false);
            setInSTTID(tiepnhan[0]?.PatientReceiveID);

            const getInNhanTem = async () => {
                try {
                    const data = await tiepnhanAPI.getInDanNhan({ 'PatientReceiveID': tiepnhan[0]?.PatientReceiveID });
                    setInNhanTem(data.data);
                } catch (err) { }
            };
            getInNhanTem();


            const filteredQuanHuyen = memoizedListDistrict.filter(item => item.ProvincialID === tiepnhan[0]?.ProvincialID);
            setchonquanhuyen(filteredQuanHuyen.length ? filteredQuanHuyen : []);

            const filteredPhuongXa = memoizedListWard.filter(item => item.DistrictID === tiepnhan[0]?.DistrictID);
            setchonphuongxa(filteredPhuongXa.length ? filteredPhuongXa : []);

            const huyetap = sinhhieutiepnhan[0]?.BloodPressure.split(",");
            form.setFieldsValue({
                PatientCode: tiepnhan[0]?.PatientCode,
                PatientReceiveID: tiepnhan[0]?.PatientReceiveID,
                PatientCode_PK: tiepnhan[0]?.PatientCode_PK,
                PatientName: tiepnhan[0]?.PatientName,
                PatientGenderID: tiepnhan[0]?.PatientGenderID,
                PatientBirthday: dayjs(tiepnhan[0]?.PatientBirthday),
                PatientBirthday_BH: tiepnhan[0]?.PatientBirthday_BH,
                Reason: tiepnhan[0]?.Reason,
                ObjectID: tiepnhan[0]?.ObjectID,
                Address_: tiepnhan[0]?.Address_,
                ProvincialID: tiepnhan[0]?.ProvincialID,
                DistrictID: tiepnhan[0]?.DistrictID,
                WardID: tiepnhan[0]?.WardID,
                PatientMobile: tiepnhan[0]?.PatientMobile,
                PatientEmail: tiepnhan[0]?.PatientEmail,
                CareerID: tiepnhan[0]?.CareerID,
                EThnicID: tiepnhan[0]?.EThnicID,
                NationalityID: tiepnhan[0]?.NationalityID,
                MedicalHistory: tiepnhan[0]?.MedicalHistory,
                Allergy: tiepnhan[0]?.Allergy,
                CC_Serial: tiepnhan[0]?.CC_Serial,
                CC_NgayCap: dayjs(tiepnhan[0]?.CC_NgayCap),
                CC_NoiCap: tiepnhan[0]?.CC_NoiCap,
                CompanyName: tiepnhan[0]?.CompanyName,
                CompanyMST: tiepnhan[0]?.CompanyMST,
                NCS_FullName: tiepnhan[0]?.NCS_FullName,
                NCS_TypeID: tiepnhan[0]?.NCS_TypeID,
                NCS_Birthyear: tiepnhan[0]?.NCS_Birthyear,
                NSC_Mobile: tiepnhan[0]?.NSC_Mobile,
                MA_DOITUONG_KCB: tiepnhan[0]?.MA_DOITUONG_KCB,
                MA_LOAI_KCB: tiepnhan[0]?.MA_LOAI_KCB,
                IntroCode: tiepnhan[0]?.IntroCode,
                EmployeeDoctorID_YC: tiepnhan[0]?.EmployeeDoctorID_YC,
                TNTT_ID: tiepnhan[0]?.TNTT_ID,
                ObjectPriorityID: tiepnhan[0]?.ObjectPriorityID,
                PromotionsCode: tiepnhan[0]?.PromotionsCode,
                IsKCB_NgoaiGio: tiepnhan[0]?.IsKCB_NgoaiGio,
                IsCheck_TT: tiepnhan[0]?.IsCheck_TT,
                IsSend_CheckIn: tiepnhan[0]?.IsSend_CheckIn,
                IsNew: tiepnhan[0]?.IsNew,
                Is_SavedBV: tiepnhan[0]?.Is_SavedBV,
                Is_KCB_Done: tiepnhan[0]?.Is_KCB_Done,
                PatientTypeID: tiepnhan[0]?.PatientTypeID,
                GM_MaDV: tiepnhan[0]?.GM_MaDV,
                PatientAppID: tiepnhan[0]?.PatientAppID,
                KSK_ID: tiepnhan[0]?.KSK_ID,

                BloodPressure: huyetap[0],
                BloodPressure1: huyetap[1],
                Pulse: sinhhieutiepnhan[0]?.Pulse,
                Breath: sinhhieutiepnhan[0]?.Breath,
                Temperature: sinhhieutiepnhan[0]?.Temperature,
                Hight: sinhhieutiepnhan[0]?.Hight,
                Weight: sinhhieutiepnhan[0]?.Weight,
                SPO2: sinhhieutiepnhan[0]?.SPO2,
                BMI: sinhhieutiepnhan[0]?.BMI,
                VongNguc: sinhhieutiepnhan[0]?.VongNguc,
                ReferenceCode: sinhhieutiepnhan[0]?.ReferenceCode,
                RowID: sinhhieutiepnhan[0]?.RowID,
                WorkDate: new Date(),
                IUDate: new Date(),


            });
            dispatch(setDataDatHen(null));
            dispatch(setDataSearchTiepNhan(null));
        }

        if (SearchCongKham !== '') {
            setShowOptionTable(true);
        }

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };


    }, [tiepnhan, sinhhieutiepnhan, form, selectedRowIndex]);

    useEffect(() => {
        if (dathen != null) {
            setHienThi(true);
            setbtnTiepNhan(false);
            setbtnMoi(false);

            const filteredQuanHuyen = memoizedListDistrict.filter(item => item.ProvincialID === dathen?.ProvincialID);
            setchonquanhuyen(filteredQuanHuyen.length ? filteredQuanHuyen : []);

            const filteredPhuongXa = memoizedListWard.filter(item => item.DistrictID === dathen?.DistrictID);
            setchonphuongxa(filteredPhuongXa.length ? filteredPhuongXa : []);


            form.setFieldsValue({
                PatientName: dathen?.PatientName,
                PatientGenderID: dathen?.PatientGenderID,
                PatientBirthday: dayjs(dathen?.PatientBirthday),
                Reason: dathen?.Reason,
                Address_: dathen?.Address_,
                ProvincialID: dathen?.ProvincialID,
                DistrictID: dathen?.DistrictID,
                WardID: dathen?.WardID,
                PatientMobile: dathen?.PatientMobile,
                CareerID: dathen?.CareerID,
                EThnicID: dathen?.EThnicID,
                NationalityID: dathen?.NationalityID,
            });
        }
        if (searchtiepnhan != null) {
            setHienThi(true);
            setbtnTiepNhan(false);
            setbtnMoi(false);

            const filteredQuanHuyen = memoizedListDistrict.filter(item => item.ProvincialID === searchtiepnhan?.ProvincialID);
            setchonquanhuyen(filteredQuanHuyen.length ? filteredQuanHuyen : []);

            const filteredPhuongXa = memoizedListWard.filter(item => item.DistrictID === searchtiepnhan?.DistrictID);
            setchonphuongxa(filteredPhuongXa.length ? filteredPhuongXa : []);

            form.setFieldsValue({
                PatientCode: searchtiepnhan?.PatientCode,
                PatientName: searchtiepnhan?.PatientName,
                PatientGenderID: searchtiepnhan?.PatientGenderID,
                PatientBirthday: dayjs(searchtiepnhan?.PatientBirthday),
                Reason: searchtiepnhan?.Reason,
                Address_: searchtiepnhan?.Address_,
                ProvincialID: searchtiepnhan?.ProvincialID,
                DistrictID: searchtiepnhan?.DistrictID,
                WardID: searchtiepnhan?.WardID,
                PatientMobile: searchtiepnhan?.PatientMobile,
                CareerID: searchtiepnhan?.CareerID,
                EThnicID: searchtiepnhan?.EThnicID,
                NationalityID: searchtiepnhan?.NationalityID,
            });
        }
    }, [dathen, searchtiepnhan]);


    const handleDeleteChiDinh = () => {
        if (selection.length === 0) return;
        Modal.confirm({
            title: "Xác nhận xóa",
            content: "Bạn có muốn xóa dịch vụ này?",
            okText: "Có",
            cancelText: "Không",
            onOk: async () => {
                const deletedServiceIDs = new Set(selection.map(({ ServiceID }) => ServiceID));
                setChonCongKham(prev => prev.filter(dv => !deletedServiceIDs.has(dv.ServiceID)));
                setViewCongKhamID(prev => prev.filter(dv => !deletedServiceIDs.has(dv.ServiceID)));
                if (tiepnhan?.length > 0) {
                    await Promise.all(
                        selection.map(row =>
                            tiepnhanAPI.deleteChiDinh(
                                row.SuggestedID,
                                tiepnhan[0].PatientReceiveID,
                                tiepnhan[0].PatientCode,
                                userlogin.RowID
                            )
                        )
                    );
                }
            },
        });
    };
    const checkgiaDVKT = (e) => {
        setCheckGiaDV(e.target.checked);
    }
    var showhienthi = hienthi === true ? true : btnEdit === false && tiepnhan !== null ? true : false;
    const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));


    const toggleSelection = (option) => {
        setSearchDT(option.TrichDan);
        setSearchDTMaMa(option.Ma);
        setIsOpenDT(false);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpenDT(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const refInDanNhan = useRef(null);
    const refInChiDinh = useRef(null);
    const refInSTT = useRef(null);

    const handleAfterPrint = React.useCallback(() => {
    }, []);
    const handleBeforePrint = React.useCallback(() => {
        return Promise.resolve();
    }, []);
    const PrintPhieuDanNhan = useReactToPrint({
        content: () => refInDanNhan.current,
        documentTitle: "AwesomeFileName",
        onAfterPrint: handleAfterPrint,
        onBeforePrint: handleBeforePrint,
    });
    const PrintPhieuChiDinh = useReactToPrint({
        content: () => refInChiDinh.current,
        documentTitle: "AwesomeFileName",
        onAfterPrint: handleAfterPrint,
        onBeforePrint: handleBeforePrint,
    });
    const PrintSTTClick = useReactToPrint({
        content: () => refInSTT.current,
        documentTitle: "AwesomeFileName",
        pageStyle: `@page { size: 58mm auto; margin: 0; }`,
        onAfterPrint: handleAfterPrint,
        onBeforePrint: handleBeforePrint,
    });
    const chonphongrow = async (e, row) => {
        setChonCongKham((prevData) =>
            prevData.map((item) =>
                item.ServiceID === row ? { ...item, DepartmentID_TH: e } : item
            )
        );
    };

    const handleTuoiToNam = (e) => {
        const inputAge = e.target.value;
        if (!isNaN(inputAge) && inputAge > 0) {
            const currentYear = new Date().getFullYear();
            const birthYear = currentYear - inputAge;
            setBirthDate(dayjs(`${birthYear}-01-01`));
        } else {
            setBirthDate(null);
        }
    };

    const mergedArray = [...ChonCongKham, ...ViewCongKhamID];
    useEffect(() => {
        const newData = (FiterChiDinh.length > 0 ? FiterChiDinh : ListChiDinh)
            .filter(row =>
                row?.ObjectID === (CheckGiaDV ? 2 : ChonDoiTuong) &&
                (SearchCongKham === "" || row?.ServiceName?.toLowerCase().includes(SearchCongKham.toLowerCase()))
            );
        setFilteredData(newData);
        setSelectedRowIndex((prevIndex) =>
            prevIndex >= 0 && prevIndex < newData.length ? prevIndex : (newData.length > 0 ? 0 : -1)
        );

    }, [FiterChiDinh, ListChiDinh, CheckGiaDV, ChonDoiTuong, SearchCongKham]);

    return (
        <>
            <div className="tiepnhan-congkham" onClick={() => (ShowOptionTable === true ? setShowOptionTable(false) : '')}>
                <Form onFinish={handleSubmit} form={form} onKeyDown={handleKeyDownSubmit}>
                    <div className="d-flex">
                        <div className="w-70 m-1">
                            <div className="tiepnhan-congkham-title">THÔNG TIN HÀNH CHÍNH</div>
                            <div className="row m-0 p-0 tiepnhan-hc">
                                <div className="col-md-2 ">
                                    <div className="d-flex justify-content-center ">
                                        <div className="text-center align-items-center">
                                            <img src={imguser} width={100} alt="User" />
                                            <div className="tiepcan-congkham-icon">
                                                <div className="onclickimg">
                                                    <FontAwesomeIcon icon={faCamera} />
                                                </div>
                                                <div className="onclickimg" onClick={() => (InNhanTemID === null ? printNhan() : PrintPhieuDanNhan())}>
                                                    <FontAwesomeIcon icon={faBarcode} />
                                                </div>


                                                <div className="onclickimg">
                                                    <FontAwesomeIcon icon={faFileLines} />
                                                </div>
                                                <div className="onclickimg">
                                                    <FontAwesomeIcon icon={faUsers} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-10">
                                    <div>
                                        <div className="row form-row">
                                            <Form.Item
                                                label={
                                                    <div className="tiepnhan-congkham-form-input-label">
                                                        QR Code gd
                                                    </div>
                                                }

                                                name="qrcode"
                                                className="col-md-10 m-0"
                                            >
                                                <Input className="form-control" disabled={showhienthi} />
                                            </Form.Item>
                                            <div className="col-md-2">
                                                <img src={imgiconcheck} width={30} alt="User" />
                                            </div>
                                        </div>
                                        <div className="row form-row">
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Mã BN </div>}
                                                name="PatientCode"
                                            >
                                                <Input className="form-control" disabled={true}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> </div>}
                                                name="PatientReceiveID" hidden={true}
                                            >
                                                <Input className="form-control" ></Input>
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> </div>}
                                                name="RowID" hidden={true}
                                            >
                                                <Input className="form-control" ></Input>
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> </div>}
                                                name="ReferenceCode" hidden={true}
                                            >
                                                <Input className="form-control" ></Input>
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-8 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Họ và tên <span className="batbuoc">*</span></div>}
                                                name="PatientName"
                                            >
                                                <Input ref={refHoTen}
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refHoTen, refNgaySinh)}
                                                    className="form-control" disabled={showhienthi}></Input>
                                            </Form.Item>

                                        </div>
                                        <div className="row form-row">
                                            <div className="col-md-4">
                                                <div className="ngaysinh-tuoi">
                                                    <Form.Item
                                                        className="m-0 ngaysinhtiepnhan"
                                                        label={<div className="tiepnhan-congkham-form-input-label"> N.sinh <span className="batbuoc">*</span></div>}
                                                        name="PatientBirthday"
                                                    >
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker disabled={showhienthi}

                                                                value={birthDate === null ? (tiepnhan !== null ? dayjs(tiepnhan[0]?.PatientBirthday) : null) : birthDate}
                                                                format="DD/MM/YYYY"

                                                                onChange={(newValue) => setBirthDate(newValue)}
                                                                slotProps={{
                                                                    textField: {
                                                                        fullWidth: true,
                                                                        inputRef: refNgaySinh,
                                                                        onKeyDown: (e) => e.key === "Enter" && handleNextFocus(refNgaySinh, refGioiTinh),
                                                                    },
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Form.Item>
                                                    <Form.Item
                                                        className="m-0 tuoitiepnhan"
                                                        label={''}
                                                        name=""
                                                    >
                                                        <Input className="form-control" disabled={showhienthi} onChange={handleTuoiToNam} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> G.Tính <span className="batbuoc">*</span></div>}
                                                name="PatientGenderID"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refGioiTinh, refDanToc)}
                                                    ref={refGioiTinh}
                                                    onSelect={() => onClhandleNextFocus(refDanToc)}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    showSearch
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    placeholder="Chọn"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={
                                                        PatientGender.map((item) => ({
                                                            value: item.PatientGenderID,
                                                            label: item.PatientGenderName
                                                        }))
                                                    }
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label">  Dân tộc  <span className="batbuoc">*</span></div>}
                                                name="EThnicID"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refDanToc, refNgheNghiep)}
                                                    ref={refDanToc}
                                                    onSelect={() => onClhandleNextFocus(refNgheNghiep)}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    showSearch
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    placeholder="Chọn"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={
                                                        Ethnic.map((item) => ({
                                                            value: item.EThnicID,
                                                            label: item.EThnicName
                                                        }))
                                                    }
                                                />
                                            </Form.Item>

                                        </div>
                                        <div className="row form-row">
                                            <Form.Item
                                                className="col-md-6 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Nghề nghiệp <span className="batbuoc">*</span></div>}
                                                name="CareerID"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refNgheNghiep, refQuocTich)}
                                                    ref={refNgheNghiep}
                                                    onSelect={() => onClhandleNextFocus(refQuocTich)}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    showSearch
                                                    allowClear
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    placeholder="Chọn"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={
                                                        ListCareer.map((item) => ({
                                                            value: item.CareerID,
                                                            label: item.CareerName
                                                        }))
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-6 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Quốc tịch  <span className="batbuoc">*</span></div>}
                                                name="NationalityID"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refQuocTich, refDiaChi)}
                                                    ref={refQuocTich}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    showSearch
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    placeholder="Chọn"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={
                                                        ListNationality.map((item) => ({
                                                            value: item.NationalityID,
                                                            label: item.NationalityName
                                                        }))
                                                    }
                                                />
                                            </Form.Item>


                                        </div>

                                        <div className="row form-row">
                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label"> Địa chỉ <span className="batbuoc">*</span></div>}
                                                name="Address_"
                                                className="w-100 m-0"
                                            >
                                                <Input ref={refDiaChi}
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refDiaChi, refTinh)}
                                                    className="form-control" disabled={showhienthi} />
                                            </Form.Item>
                                        </div>
                                        <div className="row form-row">
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Tỉnh <span className="batbuoc">*</span></div>}
                                                name="ProvincialID"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refTinh, refQuanHuyen)}
                                                    ref={refTinh}
                                                    onSelect={() => onClhandleNextFocus(refQuanHuyen)}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    showSearch
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    placeholder="Chọn"
                                                    optionFilterProp="label"
                                                    onChange={handleChangeProvincial}
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={
                                                        ListProvincial.map((item) => ({
                                                            value: item.ProvincialID,
                                                            label: item.ProvincialName
                                                        }))
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Quận <span className="batbuoc">*</span></div>}
                                                name="DistrictID"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refQuanHuyen, refPhuongXa)}
                                                    ref={refQuanHuyen}
                                                    onSelect={() => onClhandleNextFocus(refPhuongXa)}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    showSearch
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    placeholder="Chọn"
                                                    optionFilterProp="label"
                                                    onChange={handleChangeDistrict}
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={
                                                        chonquanhuyen.map((item) => ({
                                                            value: item.DistrictID,
                                                            label: item.DistrictName
                                                        }))
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Xã <span className="batbuoc">*</span></div>}
                                                name="WardID"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refPhuongXa, refCCCD)}
                                                    ref={refPhuongXa}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    placeholder="Chọn"
                                                    showSearch
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={
                                                        chonphuongxa.map((item) => ({
                                                            value: item.WardID,
                                                            label: item.WardName
                                                        }))
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="row form-row">
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> CCCD </div>}
                                                name="CC_Serial"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refCCCD, refNgayCap)}
                                                    ref={refCCCD}
                                                    className="form-control" disabled={showhienthi}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label">Ngày cấp</div>}
                                                name="CC_NgayCap"
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker disabled={showhienthi}
                                                        label=""
                                                        value={NgayCapCC === null ? (tiepnhan !== null ? dayjs(tiepnhan[0]?.CC_NgayCap) : null) : NgayCapCC}
                                                        format="DD/MM/YYYY"
                                                        onChange={(newValue) => setNgayCapCC(newValue)}
                                                        slotProps={{
                                                            textField: {
                                                                fullWidth: true,
                                                                inputRef: refNgayCap, // Gán ref vào input của DatePicker
                                                                onKeyDown: (e) => e.key === "Enter" && handleNextFocus(refNgayCap, refNoiCap),
                                                            },
                                                        }}

                                                    />
                                                </LocalizationProvider>

                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Nơi cấp </div>}
                                                name="CC_NoiCap"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refNoiCap, refSoDT)}
                                                    ref={refNoiCap}
                                                    className="form-control " disabled={showhienthi}></Input>
                                            </Form.Item>
                                        </div>
                                        <div className="row form-row">
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Số điện thoại </div>}
                                                name="PatientMobile"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refSoDT, refEmail)}
                                                    ref={refSoDT}
                                                    className="form-control" disabled={showhienthi}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-8 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Email </div>}
                                                name="PatientEmail"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refEmail, refTenCty)}
                                                    ref={refEmail}
                                                    className="form-control" disabled={showhienthi}></Input>
                                            </Form.Item>
                                        </div>

                                        <div className="row form-row">
                                            <Form.Item
                                                className="col-md-8 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Tên Cty </div>}
                                                name="CompanyName"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refTenCty, refMaSoThue)}
                                                    ref={refTenCty}
                                                    className="form-control " disabled={showhienthi}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-4 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label">Mã số thuế</div>}
                                                name="CompanyMST"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refMaSoThue, refTienSuBenh)}
                                                    ref={refMaSoThue}
                                                    className="form-control " disabled={showhienthi}></Input>
                                            </Form.Item>

                                        </div>
                                        <div className="row form-row">
                                            <Form.Item
                                                className="col-md-12 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Tiền sử bệnh</div>}
                                                name="MedicalHistory"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refTienSuBenh, refNgGiamHo)}
                                                    ref={refTienSuBenh}
                                                    className="form-control " disabled={showhienthi}></Input>
                                            </Form.Item>
                                        </div>
                                        <div className="row form-row">
                                            <Form.Item
                                                className="col-md-6 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Người GH </div>}
                                                name="NCS_FullName"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refNgGiamHo, refQuanHe)}
                                                    ref={refNgGiamHo}
                                                    className="form-control " disabled={showhienthi}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-6 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label">Quan hệ</div>}
                                                name="NCS_TypeID"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refQuanHe, refNamSinhNGH)}
                                                    ref={refQuanHe}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    showSearch
                                                    allowClear
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    placeholder="Chọn"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={
                                                        NgChamSoc.filter(row => row?.NCS_TypeID !== 0).map((item) => ({
                                                            value: item.NCS_TypeID,
                                                            label: item.NCS_TypeName
                                                        }))
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="row form-row">
                                            <Form.Item
                                                className="col-md-6 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label"> Năm sinh </div>}
                                                name="NCS_Birthyear"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refNamSinhNGH, refSoDTNgGH)}
                                                    ref={refNamSinhNGH}
                                                    className="form-control " disabled={showhienthi}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-6 m-0"
                                                label={<div className="tiepnhan-congkham-form-input-label">Số điện thoại</div>}
                                                name="NSC_Mobile"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refSoDTNgGH, refDoiTuong)}
                                                    ref={refSoDTNgGH}
                                                    className="form-control " disabled={showhienthi}></Input>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-30 p-1 tiepnhan-congkham-border">
                            <div className="tiepnhan-congkham-title">
                                THÔNG TIN THẺ BẢO HIỂM Y TẾ
                            </div>
                            <div className=" container m-0 p-0 tt-bhyt">
                                <div className="row form-row row-fix">
                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label-right"> Đối tượng <span className="batbuoc">*</span> :</div>}
                                        className="col-md-12 m-0"
                                        name="ObjectID"
                                    >
                                        <Select
                                            onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refDoiTuong, refMaBH)}
                                            ref={refDoiTuong}
                                            notFoundContent={"Chưa có dữ liệu"}
                                            showSearch
                                            disabled={showhienthi}
                                            className="selectac"
                                            placeholder="Chọn"
                                            onChange={ChonDoiTuongLayCongKham}
                                            optionFilterProp="label"
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={
                                                ListObject.map((item) => ({
                                                    value: item.ObjectID,
                                                    label: item.ObjectName
                                                }))
                                            }
                                        />

                                    </Form.Item>

                                </div>
                                <div className="row form-row row-fix">
                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label-right"> Mã BHYT :</div>}
                                        className="col-md-12 m-0"
                                        name=""
                                    >
                                        <div className="d-flex">
                                            <Input
                                                onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refMaBH, refDKKCBBD)}
                                                ref={refMaBH}

                                                className="form-control w-75" disabled={showhienthi} />
                                            <Input className="form-control w-25" disabled={showhienthi} />
                                        </div>
                                    </Form.Item>
                                </div>
                                <div className="row form-row row-fix">
                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label-right"> ĐK KCBBĐ : </div>}
                                        className="col-md-12 m-0"
                                        name=""
                                    >
                                        <Select
                                            onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refDKKCBBD, refTuNgay)}
                                            ref={refDKKCBBD}


                                            disabled={showhienthi} />
                                    </Form.Item>
                                </div>

                                <div className="row form-row row-fix">
                                    <Form.Item
                                        label={
                                            <div className="tiepnhan-congkham-form-input-label-right">
                                                Từ ngày :
                                            </div>
                                        }
                                        className="col-md-6 m-0"
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker disabled={showhienthi}
                                                label=""
                                                format="DD/MM/YYYY"
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        inputRef: refTuNgay, // Gán ref vào input của DatePicker
                                                        onKeyDown: (e) => e.key === "Enter" && handleNextFocus(refTuNgay, refDenNgay),
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>



                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <div className="tiepnhan-congkham-form-input-label-right">
                                                Đến ngày :
                                            </div>
                                        }
                                        className="col-md-6  m-0"
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker disabled={showhienthi}
                                                label=""
                                                format="DD/MM/YYYY"
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        inputRef: refDenNgay, // Gán ref vào input của DatePicker
                                                        onKeyDown: (e) => e.key === "Enter" && handleNextFocus(refDenNgay, refDu5Nam),
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Form.Item>
                                </div>
                                <div className="row form-row row-fix">
                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label-right"> Đủ 5 năm :</div>}
                                        className="col-md-6 m-0"
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker disabled={showhienthi}
                                                label=""
                                                format="DD/MM/YYYY"
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        inputRef: refDu5Nam, // Gán ref vào input của DatePicker
                                                        onKeyDown: (e) => e.key === "Enter" && handleNextFocus(refDu5Nam, refNgayMien),
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Form.Item>
                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label-right"> Ngày miễn :</div>}
                                        className="col-md-6 m-0"
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker disabled={showhienthi}
                                                label=""

                                                format="DD/MM/YYYY"

                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        inputRef: refNgayMien, // Gán ref vào input của DatePicker
                                                        onKeyDown: (e) => e.key === "Enter" && handleNextFocus(refNgayMien, refKhuVuc),
                                                    },
                                                }}


                                            />
                                        </LocalizationProvider>
                                    </Form.Item>
                                </div>
                                <div className="row form-row row-fix">

                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label-right-form-last"> Khu vực :</div>}
                                        className="col-md-12 m-0"
                                    >
                                        <Select
                                            onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refKhuVuc, refLyDoDT)}
                                            ref={refKhuVuc}
                                            disabled={showhienthi} />
                                    </Form.Item>
                                </div>

                                <div className="row form-row row-fix">
                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label-right-form-last">L.Do Đ.Tuyến : </div>}
                                        className="col-md-12 m-0"
                                    >
                                        <Select
                                            onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refLyDoDT, refDTThe)}
                                            ref={refLyDoDT}
                                            disabled={showhienthi} />
                                    </Form.Item>
                                </div>

                                <div className="row form-row row-fix">

                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label"> Đối tượng thẻ:</div>}
                                        name="MA_DOITUONG_KCB"
                                        className="col-md-12 m-0"
                                    >
                                        <div className="doi-tuong-kham-benh" ref={dropdownRef}>
                                            <div className="input-icon">
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refDTThe, refLoaiKCB)}
                                                    ref={refDTThe}
                                                    type="text"
                                                    className="w-full inputsearch outline-none"
                                                    placeholder="Chọn"
                                                    value={
                                                        searchDT !== ''
                                                            ? searchDT
                                                            : (
                                                                Array.isArray(tiepnhan) && tiepnhan.length > 0
                                                                    ? DoiTuongKhamBenh.find((item) => item.Ma === tiepnhan[0]?.MA_DOITUONG_KCB)?.TrichDan || ''
                                                                    : ''
                                                            )
                                                    }
                                                    disabled={showhienthi}
                                                    onChange={(e) => setSearchDT(e.target.value)}
                                                    onClick={() => setIsOpenDT(!isOpenDT)}
                                                />
                                                {searchDT !== '' ?
                                                    <FontAwesomeIcon onClick={() => setSearchDT('')} className="iconsearch iconsearchxoa" icon={faXmark} />
                                                    :
                                                    <FontAwesomeIcon className="iconsearch" icon={faAngleDown} />
                                                }

                                            </div>
                                            {isOpenDT && (
                                                <div className="absolute w-full">

                                                    <table className=" table table-responsive">
                                                        <thead>
                                                            <tr>
                                                                <td className="ma">Mã</td>
                                                                <td className="trichdan">Trích dẫn</td>
                                                                <td className="diengiai">Diễn giải</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {DoiTuongKhamBenh.map((option) => (
                                                                <tr
                                                                    key={option.Ma}
                                                                    onClick={() => toggleSelection(option)}
                                                                >

                                                                    <td className="ma">{option.Ma}</td>
                                                                    <td className="trichdan">{option.TrichDan}</td>
                                                                    <td className="diengiai">{option.DienGiai}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                    </Form.Item>
                                </div>
                                <div className="row form-row row-fix">
                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label"> Loại KCB:</div>}
                                        name="MA_LOAI_KCB"
                                        className="col-md-12 m-0"
                                    >
                                        <Select
                                            onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refLoaiKCB, refDoiTUTien)}
                                            ref={refLoaiKCB}
                                            notFoundContent={"Chưa có dữ liệu"}
                                            showSearch
                                            allowClear
                                            disabled={showhienthi}
                                            className="selectac"
                                            placeholder="Chọn"
                                            optionFilterProp="label"
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={
                                                LoaiKhamBenh.map((item) => ({
                                                    value: item.Ma,
                                                    label: item.DienGiai
                                                }))
                                            }
                                        />
                                    </Form.Item>
                                </div>
                                <div className="row form-row row-fix">
                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label"> ĐT ưu tiên:</div>}
                                        name="ObjectPriorityID"
                                        className="col-md-12 m-0"
                                    >
                                        <Select
                                            onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refDoiTUTien, refUuDai)}
                                            ref={refDoiTUTien}
                                            notFoundContent={"Chưa có dữ liệu"}
                                            showSearch
                                            allowClear
                                            disabled={showhienthi}
                                            className="selectac"
                                            placeholder="Chọn"
                                            optionFilterProp="label"
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={
                                                DoiTuongUuTien.map((item) => ({
                                                    value: item.ObjectPriorityID,
                                                    label: item.ObjectPriorityName
                                                }))
                                            }
                                        />
                                    </Form.Item>
                                </div>
                                <div className="row form-row row-fix">
                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label"> Tr.Trình Ưu đãi:</div>}
                                        name="PromotionsCode"
                                        className="col-md-12 m-0"
                                    >
                                        <Select
                                            onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refUuDai, refLyDoKham)}
                                            ref={refUuDai}
                                            disabled={showhienthi} />
                                    </Form.Item>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="m-1 tiepnhan-tt">
                        <div className="tiepnhan-congkham-title mt-2">THÔNG TIN ĐĂNG KÝ KHÁM</div>
                        <div className="row m-0 p-0">
                            <div className="col-md-5">
                                <div className="row form-row">
                                    <Form.Item
                                        label={<div className="tiepnhan-congkham-form-input-label"> Lý do khám</div>}
                                        className=" m-0"
                                        name="Reason"
                                    >
                                        <Input
                                            onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refLyDoKham, refNhipMach)}
                                            ref={refLyDoKham}
                                            className="form-control " disabled={showhienthi} />
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="form-row">
                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label"> Nhịp mạch</div>}
                                                className="col-md-12 m-0"
                                                name="Pulse"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refNhipMach, refNhietDo)}
                                                    ref={refNhipMach}
                                                    className="form-control" disabled={showhienthi} />
                                            </Form.Item>
                                        </div>
                                        <div className="form-row">
                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label">Nhiệt độ</div>}
                                                className="col-md-12 m-0"
                                                name="Temperature"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refNhietDo, refHuyetAp1)}
                                                    ref={refNhietDo}
                                                    className="form-control" disabled={showhienthi} />
                                            </Form.Item>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <div className="huyetap-tiepnhan">
                                                    <Form.Item
                                                        label={<div className="tiepnhan-congkham-form-input-label"> H.Áp(mm/Hg) </div>}
                                                        className="huyetap-1"
                                                        name="BloodPressure"
                                                    >
                                                        <Input
                                                            onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refHuyetAp1, refHuyetAp2)}
                                                            ref={refHuyetAp1}
                                                            className="form-control " disabled={showhienthi} mask='9999/9999' />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={''}
                                                        className="huyetap-2"
                                                        name="BloodPressure1"
                                                    >
                                                        <Input
                                                            onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refHuyetAp2, refNhipHo)}
                                                            ref={refHuyetAp2}
                                                            className="form-control " disabled={showhienthi} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label"> Nhịp thở(l/p)</div>}
                                                className="col-md-12 m-0"
                                                name="Breath"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refNhipHo, refChieuCao)}
                                                    ref={refNhipHo}
                                                    className="form-control " disabled={showhienthi} />
                                            </Form.Item>
                                        </div>
                                        <div className="form-row">


                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label">Chiều cao</div>}
                                                className="col-md-12 m-0"
                                                name="Hight"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refChieuCao, refCanNang)}
                                                    ref={refChieuCao}
                                                    className="form-control " disabled={showhienthi} />
                                            </Form.Item>
                                        </div>
                                        <div className="form-row">
                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label"> Cân nặng</div>}
                                                className="col-md-12 m-0"
                                                name="Weight"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refCanNang, refSPO2)}
                                                    ref={refCanNang}
                                                    className="form-control" disabled={showhienthi} />
                                            </Form.Item>
                                        </div>
                                        <div className="form-row">
                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label"> SPO2 </div>}
                                                className="col-md-12 m-0 "
                                                name="SPO2"
                                            >
                                                <Input
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refSPO2, refNgGioiThieu)}
                                                    ref={refSPO2}
                                                    className="form-control" disabled={showhienthi} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-md-7 thongtindangky-2">
                                        <div className="form-row">
                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label"> Người G.Thiệu</div>}
                                                className="col-md-12 m-0"
                                                name="IntroCode"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refNgGioiThieu, refBSYeuCau)}
                                                    ref={refNgGioiThieu}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    showSearch
                                                    allowClear
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    placeholder="Chọn"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={
                                                        NgGioiThieu.map((item) => ({
                                                            value: item.IntroCode,
                                                            label: item.IntroName
                                                        }))
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-row">
                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label"> BS yêu cầu</div>}
                                                className="col-md-12 m-0 mt-1"
                                                name="EmployeeDoctorID_YC"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refBSYeuCau, refTaiNan)}
                                                    ref={refBSYeuCau}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    showSearch
                                                    allowClear
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    placeholder="Chọn"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={Array.isArray(BacSiGioiThieu)
                                                        ? BacSiGioiThieu.filter(row => row?.PositionID === '3')
                                                            .map(item => ({
                                                                value: item.RowID,
                                                                label: item.EmployeeName
                                                            }))
                                                        : []}


                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-row">
                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label">Tai nạn</div>}
                                                className="col-md-12 m-0 mt-1"
                                                name="TNTT_ID"
                                            >
                                                <div className="input-select">
                                                    <Select
                                                        onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refTaiNan, refKSK)}
                                                        ref={refTaiNan}
                                                        notFoundContent={"Chưa có dữ liệu"}
                                                        showSearch
                                                        allowClear
                                                        disabled={showhienthi}
                                                        className="selectac"
                                                        placeholder="Chọn"
                                                        optionFilterProp="label"
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        options={
                                                            TaiNanTT.map((item) => ({
                                                                value: item.TNTT_ID,
                                                                label: item.TNTT_Name
                                                            }))
                                                        }

                                                    />
                                                </div>
                                            </Form.Item>
                                        </div>
                                        <div className="">
                                            <Form.Item
                                                label={<div className="tiepnhan-congkham-form-input-label"> KSK </div>}
                                                className="col-md-12 m-0 mt-1"
                                                name="KSK_ID"
                                            >
                                                <Select
                                                    onKeyDown={(e) => e.key === "Enter" && handleNextFocus(refKSK, refCongKham)}
                                                    ref={refKSK}
                                                    notFoundContent={"Chưa có dữ liệu"}
                                                    showSearch
                                                    allowClear
                                                    disabled={showhienthi}
                                                    className="selectac"
                                                    placeholder="Chọn"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={
                                                        KSK.filter(row => row.KSK_ID !== 0).map((item) => ({
                                                            value: item.KSK_ID,
                                                            label: item.KSK_Name
                                                        }))
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="m-1">
                                    <div className="searchkhoa">
                                        <div className="congkham">
                                            <div className="congkham-name">Công khám <span className="batbuoc">*</span>:</div>
                                            <div className="congkham-search">
                                                <input
                                                    ref={refCongKham}
                                                    disabled={showhienthi}
                                                    autoFocus
                                                    type="text"
                                                    name="tenthuoc"
                                                    className="form-control"
                                                    value={SearchCongKham}
                                                    onChange={(e) => FilterCongKham(e)}
                                                    onClick={() => onclickshowOptionTable()}
                                                    onKeyDown={handleKeyDown}

                                                />

                                            </div>
                                        </div>

                                        <div className={ShowOptionTable === false ? "optiontablehide" : "optiontableshow"}>
                                            <div className="scrolltable-ck">
                                                <table onKeyDown={handleKeyDown} tabIndex={0} ref={tableRef} onFocus={() => setSelectedRowIndex(-1)} className="table table-bordered" >
                                                    <thead>
                                                        <tr>
                                                            <th className="tendvkt">Tên DVKT</th>
                                                            <th className="doituong">Đối tượng</th>
                                                            <th className="dongia">Đơn giá</th>
                                                            <th className="goikham">Gói khám</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="form-input-label">
                                                        {filteredData.map((row, index) => {
                                                            const goikham = row?.ServicePackageID === -1 ? null : <input type="checkbox" disabled checked />;
                                                            return (
                                                                <tr
                                                                    key={index}
                                                                    className={index === selectedRowIndex ? "active" : ""}
                                                                    onClick={() => { setSelectedRowIndex(index); clickChooseThuoc(row) }}
                                                                    tabIndex={0}



                                                                >
                                                                    <td className="tendvkt">{row?.ServiceName}</td>
                                                                    <td className="doituong">{row?.ObjectName}</td>
                                                                    <td className="dongia">{priceSplitter(row?.UnitPrice || 0)}</td>
                                                                    <td className="goikham">{goikham}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                        {/*SearchCongKham !== '' ?
                                                            (FiterChiDinh.length > 0 ? FiterChiDinh : [])
                                                                .filter(row => row?.ObjectID === (CheckGiaDV ? 2 : ChonDoiTuong)) // Kiểm tra `ObjectID`
                                                                .map((row, index) => {
                                                                    const goikham = row?.ServicePackageID === -1 ? null : <input type="checkbox" disabled checked />;
                                                                    return (
                                                                        <tr
                                                                            key={index}
                                                                            className={index === selectedRowIndex ? "active" : ""}
                                                                            onClick={() => clickChooseThuoc(row)}
                                                                        >
                                                                            <td className="tendvkt">{row?.ServiceName}</td>
                                                                            <td className="doituong">{row?.ObjectName}</td>
                                                                            <td className="dongia">{priceSplitter(row?.UnitPrice || 0)}</td>
                                                                            <td className="goikham">{goikham}</td>
                                                                        </tr>
                                                                    );
                                                                })

                                                            :
                                                            (FiterChiDinh.length > 0 ? FiterChiDinh : ListChiDinh)
                                                                .filter(row => row?.ObjectID === (CheckGiaDV ? 2 : ChonDoiTuong)) // Kiểm tra `ObjectID`
                                                                .map((row, index) => {
                                                                    const goikham = row?.ServicePackageID === -1 ? null : <input type="checkbox" disabled checked />;
                                                                    return (
                                                                        <tr
                                                                            key={index}
                                                                            className={index === selectedRowIndex ? "active" : ""}
                                                                            onClick={() => clickChooseThuoc(row)}
                                                                        >
                                                                            <td className="tendvkt">{row?.ServiceName}</td>
                                                                            <td className="doituong">{row?.ObjectName}</td>
                                                                            <td className="dongia">{priceSplitter(row?.UnitPrice || 0)}</td>
                                                                            <td className="goikham">{goikham}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                        */}
                                                    </tbody>

                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="table-chidinh">
                                        <div className="btn-check-congkham">
                                            <div className="text-end btn-congkham">
                                                <div className="check-giadv">
                                                    <span>Giá DV:</span>
                                                    <input disabled={showhienthi} type="checkbox" className="checkgiadv" value={CheckGiaDV} checked={CheckGiaDV} onChange={checkgiaDVKT} />
                                                </div>
                                                <Button disabled={showhienthi}
                                                    className="color-icon-edit red bnthuy"
                                                    onClick={() => handleDeleteChiDinh()}
                                                >
                                                    <FontAwesomeIcon icon={faXmarkCircle} /> Huỷ
                                                </Button>
                                            </div>
                                        </div>


                                        <CheckGrid
                                            style={{ height: 200 }}
                                            data={mergedArray}
                                            clickToEdit
                                            selectionMode="cell"
                                            editMode="cell"
                                            idField="ServiceID"
                                            selection={selection}
                                            onSelectionChange={(selection) => setSelection(selection)}
                                        >
                                            {/* <GridColumn field="checked" align="center" width={50}
                                                header={() => (
                                                    <Checkbox checked={isAllSelected}
                                                        onChange={handleSelectAll} />
                                                )}
                                                render={({ row }) => (
                                                    <Checkbox checked={row.checked || false}
                                                        onChange={() => handleCheckboxChange(row.serviceid)} />
                                                )}

                                            />*/}
                                            <GridColumn field="ServiceID" hidden align="center" title="ServiceID" width={50}

                                            />

                                            <GridColumn field="STT" align="center" title="STT" width={50}

                                                render={({ rowIndex }) => (
                                                    <span>{rowIndex + 1}</span>
                                                )}
                                            />
                                            <GridColumn field="ServiceName" title="Tên DVKT" width={350}
                                                render={({ row }) => {
                                                    const tendvkt = ListChiDinh.find((item) => item.ServiceID === row.ServiceID);
                                                    return tendvkt ? tendvkt.ServiceName : row.ServiceName;
                                                }}
                                            ></GridColumn>
                                            <GridColumn field="ObjectID" title="Đối tượng" width={100}
                                                render={({ row }) => {
                                                    const doituong = ListObject.find((item) => item.ObjectID === row.ObjectID);
                                                    return doituong ? doituong.ObjectName : '';
                                                }}
                                            ></GridColumn>
                                            <GridColumn field="Quantity" title="Số lượng" width={100} align="center"
                                                editable
                                                editor={({ row }) => (
                                                    <NumberBox value={row.Quantity} precision={0}></NumberBox>
                                                )}

                                            ></GridColumn>
                                            <GridColumn field="UnitPrice" title="Đơn giá" align="center" width={150}
                                                render={({ row }) => (<span>{priceSplitter(row.UnitPrice)}</span>)}
                                            />
                                            <GridColumn field="DepartmentID" title="Phòng TH" width={250}
                                                editable
                                                editor={({ row }) => {
                                                    const departmentArray = typeof row.DepartmentID === "string"
                                                        ? row.DepartmentID.split(",").map(id => Number(id))
                                                        : [];

                                                    const selectedDepartments = ListDepartment.filter((item) =>
                                                        departmentArray.includes(Number(item.DepartmentID))
                                                    );
                                                    const categoryTH = ListDepartment.find((item) => Number(item.DepartmentID) === Number(row.DepartmentID_TH));

                                                    if (departmentArray.length > 1) {
                                                        return (
                                                            <Select
                                                                notFoundContent={"Chưa có dữ liệu"}
                                                                showSearch
                                                                allowClear
                                                                onChange={(value) => chonphongrow(value, row.ServiceID)}
                                                                className="phong-chi-dinh"
                                                                placeholder="Chọn"
                                                                optionFilterProp="label"
                                                                filterSort={(optionA, optionB) =>
                                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                                }
                                                                options={
                                                                    selectedDepartments.map((item) => ({
                                                                        value: item.DepartmentID,
                                                                        label: item.DepartmentName
                                                                    }))
                                                                }
                                                            />

                                                        );
                                                    } else return <span>{categoryTH ? categoryTH.DepartmentName : 'CHỌN PHÒNG'}</span>;

                                                }}

                                                render={({ row }) => {
                                                    const departmentArray = typeof row.DepartmentID === "string"
                                                        ? row.DepartmentID.split(",").map(id => Number(id))
                                                        : Array.isArray(row.DepartmentID)
                                                            ? row.DepartmentID.map(id => Number(id))
                                                            : [];

                                                    const selectedDepartments = ListDepartment.filter((item) =>
                                                        departmentArray.includes(Number(item.DepartmentID))
                                                    );
                                                    const categoryTH = ListDepartment.find((item) => Number(item.DepartmentID) === Number(row.DepartmentID_TH));

                                                    return selectedDepartments.length > 0
                                                        ? selectedDepartments[0].DepartmentName
                                                        : (categoryTH ? categoryTH.DepartmentName : 'CHỌN PHÒNG');


                                                }}
                                            />
                                            <GridColumn field="ghichu" title="Ghi chú" width={150} />
                                            <GridColumn field="idgoi" title="ID gói" width={100} />

                                        </CheckGrid>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="btn-tiepnhan">
                        <div className="d-flex justify-content-end">
                            <div className="d-flex">
                                <Button className="vienphi-danhmuc-btn"
                                    disabled={btnMoi}
                                    onClick={() => {
                                        setHienThi(false);
                                        form.resetFields();
                                        dispatch(setDataTiepNhan(null));
                                        setDataTiepNhan(null);
                                        setbtnTiepNhan(true);
                                        setbtnMoi(true);
                                        setbtnChiDinh(true);
                                        setbtnSTT(true);
                                    }} >
                                    <FontAwesomeIcon icon={faAdd} /> Mới
                                </Button>
                                <Button disabled={showhienthi}
                                    htmlType="submit"
                                    className=" vienphi-danhmuc-btn"
                                >
                                    <FontAwesomeIcon icon={faSave} className="mx-1" /> Lưu
                                </Button>
                                <Button className="vienphi-danhmuc-btn" disabled={showhienthi}
                                    onClick={() => {
                                        setHienThi(true);
                                        setbtnEdit(false);
                                        form.resetFields();
                                        dispatch(setDataTiepNhan(null));
                                        setbtnMoi(false);
                                        setbtnTiepNhan(true);
                                        setbtnChiDinh(true);
                                    }} >
                                    <FontAwesomeIcon icon={faXmarkCircle} /> Bỏ qua
                                </Button>
                                <Button className="vienphi-danhmuc-btn" disabled={btnTiepNhan}
                                    onClick={() => {
                                        setHienThi(false);
                                        setbtnTiepNhan(true);
                                        setbtnMoi(true);
                                    }} >
                                    <FontAwesomeIcon icon={faCircleUser} /> Tiếp nhận
                                </Button>
                                <Button className="vienphi-danhmuc-btn" disabled={btnChiDinh}
                                    onClick={() => {
                                        PrintPhieuChiDinh()
                                    }} >
                                    <FontAwesomeIcon icon={faPrint} /> In chỉ định
                                </Button>



                                <Button className="vienphi-danhmuc-btn"
                                    disabled={tiepnhan !== null && btnEdit === false ? false : true}
                                    onClick={() => {
                                        setbtnEdit(true);
                                        setHienThi(false);
                                        setbtnTiepNhan(true);
                                        setbtnMoi(true);
                                        setbtnChiDinh(true);
                                        setbtnSTT(true);
                                    }} >
                                    <FontAwesomeIcon icon={faPenToSquare} /> Sửa
                                </Button>
                                <Button className="vienphi-danhmuc-btn"
                                    disabled={btnSTT}
                                    onClick={() => {
                                        PrintSTTClick()
                                    }} >
                                    <FontAwesomeIcon icon={faPrint} /> STT
                                </Button>

                            </div>
                        </div>
                    </div>

                </Form>
                <div className="print-congkham">

                    <PrintDanNhan ref={refInDanNhan} InNhanTem={InNhanTem} PatientGender={PatientGender}></PrintDanNhan>
                    <PrintChiDinh ref={refInChiDinh} InListChiDinh={InListChiDinh} PatientGender={PatientGender}></PrintChiDinh>
                    <PrintSTT ref={refInSTT} InSTTID={InSTTID} ></PrintSTT>
                </div>
            </div>
        </>
    );
}

export default Congkham;


