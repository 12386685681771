import axios from "axios";
import REACT_LOCALHOST from "../../host";
//import { authHeader } from '../../helpers';
const khoAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-kho-duoc`,obj);
    },
    getAllGroup: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-kho-group-duoc`,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-kho-duoc`, obj);
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-kho-duoc`, obj);
    },
    delete: (RepositoryID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-kho-duoc/${RepositoryID}/${EmployeeID}`);
    },
};

export default khoAPI;