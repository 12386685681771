import React, { useEffect, useState } from 'react';
import "../goidvkt.scss";
import { Form, Input, Button, Modal, Checkbox,InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import loginAPI from "../../../../../services/loginApi";
import { setDataGoiKham } from '../../../../../slices/dataVienPhi';

const DetailGoiKhamDVKT = (props) => {
    const { open, setOpen, handleUpdate } = props;
    const { goikham } = useSelector((state) => state.dataVienPhi);
    const [userlogin, setUserLogin] = useState();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleSubmit = async (e) => {
        Swal.fire({
            title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
            confirmButtonText: "Đồng ý",
            showCancelButton: true,
            cancelButtonText: "Hủy",
            customClass: {
                title: "fs-5 text-dark",
                confirmButton: "shadow-none",
                cancelButton: "shadow-none",
            },
        }).then(async (result) => {
            if (result?.isConfirmed) {
                const GoiKham = {
                    ServicePackageID: e?.ServicePackageID || 1,
                    ServicePackageCode: e?.ServicePackageCode,
                    ServicePackageName: e?.ServicePackageName,
                    isGoiKSK: e?.isGoiKSK ?? 0,
                    Hide: goikham?.Hide || 0,
                    Amount: e?.Amount,
                    STT: e?.STT ?? 1,
                    EmployeeID: userlogin.RowID,
                };
                await handleUpdate(GoiKham);
                dispatch(setDataGoiKham());
                form.resetFields();
                setOpen(false);
            }
        });
    };

    useEffect(() => {
        getAllUser();
        if (open) {
            form.setFieldsValue({
                ServicePackageID: goikham?.ServicePackageID,
                ServicePackageCode: goikham?.ServicePackageCode,
                ServicePackageName: goikham?.ServicePackageName,
                isGoiKSK: goikham?.isGoiKSK,
                Hide: goikham?.Hide,
                Amount: goikham?.Amount,
                STT: goikham?.STT,
            });
        }
    }, [form, goikham, open]);
    const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    return (
        <Modal
            className="DetailGoiKhamVP"
            title={<div className="vienphi-danhmuc-add">Khai báo gói khám</div>}
            centered
            open={open}
            okButtonProps={{
                style: {
                    display: "none",
                },
            }}
            cancelButtonProps={{
                style: {
                    display: "none",
                },
            }}
            onCancel={() => setOpen(false)}
            width={1000}
            closable={false}
            forceRender
            maskClosable={false}
        >
            <div className="container">
                <Form form={form} onFinish={handleSubmit} className="container">
                    <div className="row mb-2">
                        <div className="row">
                            <Form.Item
                                label="Phòng thực hiện (ID):"
                                name="ServicePackageID"
                                hidden
                            >
                                <Input className="form-control form-control-sm" />
                            </Form.Item>

                            <div className="col-md-4">
                                <Form.Item
                                    label="Mã gói khám:"
                                    name="ServicePackageCode"
                                    labelCol={{ span: 10 }}
                                    wrapperCol={{ span: 14 }}
                                    rules={[{ required: true, message: 'Vui lòng chọn mã gói khám!' }]}
                                >
                                    <Input className="form-control form-control-sm" />
                                </Form.Item>
                            </div>

                            <div className="col-md-8">
                                <Form.Item
                                    label="Tên gói khám:"
                                    name="ServicePackageName"
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 20 }}
                                    rules={[{ required: true, message: 'Vui lòng nhập tên gói khám!' }]}
                                >
                                    <Input className="form-control form-control-sm" />
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    label="Giá tiền:"
                                    name="Amount"
                                    labelCol={{ span: 10 }}
                                    wrapperCol={{ span: 14 }}
                                    rules={[{ required: true, message: 'Vui lòng nhập giá tiền!' }]}
                                >
                                    <InputNumber
                                    className="form-control form-control-sm numberinput"
                                        formatter={(value) => priceSplitter(value)}
                                        parser={(value) => value.replace(/\D/g, "")} 
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    label="Gói KSK:"
                                    name="isGoiKSK"
                                    labelCol={{ span: 10 }}
                                    wrapperCol={{ span: 14 }}
                                    valuePropName="checked" initialValue={false}
                                >
                                    <Checkbox />
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    label="Số thứ tự:"
                                    name="STT"
                                >
                                    <Input className="form-control form-control-sm" type="number"
                                    />
                                </Form.Item>
                            </div>

                        </div>
                    </div>

                    <div className="divider"></div>

                    <Form.Item className="text-center">
                        <hr />
                        <Button
                            htmlType="submit"
                            className="mx-2 vienphi-danhmuc-btn"
                        >
                            <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                        </Button>
                        <Button danger className="mx-2" onClick={() => { setOpen(false); form.resetFields(); }}>
                            <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                        </Button>
                    </Form.Item>

                </Form>
            </div>
        </Modal>
    );
};

export default DetailGoiKhamDVKT;
