
import { Button, Dropdown, Checkbox, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { DataGrid, GridColumn, ComboBox } from 'rc-easyui';
import { useDispatch } from "react-redux";
import {
    faEdit,
    faTrashCan,
    faPenToSquare,
    faPlus,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";

import { errorInfo, successInfo } from "../../../../../components/Dialog/Dialog";
import giadvktAPI from "../../../../../services/vienphi/danhmuc/giadvktAPI";
import { setDataGiaDichVuKyThuat } from "../../../../../slices/dataVienPhi";
import loginAPI from "../../../../../services/loginApi";
import LoadPage from "../../../../../loadpage";
import "../../danhmuc.scss";
import DetailGiaDVKT from "../Detail";

function KhaiBaoGiaDVKT() {
    const [open, setOpen] = useState(false);
    const [loadpage, setLoadPage] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const [HideCheck, setHideCheck] = useState(false);
    const [listGiaDVKT, setlistGiaDVKT] = useState([]);
    const [filters, setFilters] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [listDVKT, setlistDVKT] = useState([]);
    const [listDoiTuong, setlistDoiTuong] = useState([]);
    //const [filterNgay, setfilterNgay] = useState(null);
    //const [filterNgayKetThuc, setfilterNgayKetThuc] = useState(null);
    //const [listfilterNgay, setlistfilterNgay] = useState([]);
    const [userlogin, setUserLogin] = useState();
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataGiaDichVuKyThuat(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {

                    handleDelete(record.ServicePriceID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };
    const getAllGiaDVKT = async () => {
        try {
            const data = await giadvktAPI.getAll({ 'Hide': HideCheck === false ? 0 : 1 });
            setlistGiaDVKT(data.data);
            setFilteredData(data.data);

        } catch (err) {}
    };
    const getAllDVKTTheoGia = async () => {
        try {
            const data = await giadvktAPI.getAllDVKT({ 'ServiceID': '', 'Hide': 0 });
            setlistDVKT(data.data);

        } catch (err) { }
    };
    const getAllDoiTuong = async () => {
        try {
            const data = await giadvktAPI.getAllObject();
            setlistDoiTuong(data.data);

        } catch (err) { }
    };

    const handleUpdate = async (obj) => {
        const data = await giadvktAPI.update(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllGiaDVKT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (ServicePriceID, EmployeeID) => {
        const data = await giadvktAPI.delete(ServicePriceID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllGiaDVKT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleToggleHideCheckbox = async (record) => {
        const newHideValue = record.Hide === false ? true : false;
        const updatedRecord = { ...record, Hide: newHideValue };

        try {
            await handleUpdate(updatedRecord);
            successInfo("Cập nhật trạng thái thành công!");
            getAllGiaDVKT();
        } catch (err) {
            errorInfo("Cập nhật trạng thái thất bại!");
            console.error(err);
        }
    };
    useEffect(() => {
        document.title = 'Khai báo giá DVKT - EmedLink';
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        const getAllGiaDVKT = async () => {
            const data = await giadvktAPI.getAll({ 'Hide': HideCheck === false ? 0 : 1 });
            setlistGiaDVKT(data.data);
            setFilteredData(data.data);
        };
        getAllGiaDVKT();
        getAllUser();
        getAllDVKTTheoGia();
        getAllDoiTuong();

    }, [HideCheck]);
    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const renderComboBox = ({
        data,
        value,
        onChange,
        style = { width: 150 },
        editable = false,
    }) => {
        const enhancedData = [{ value: null, text: "All" }, ...data];

        return (
            <ComboBox
                data={enhancedData}
                value={value}
                onChange={onChange}
                style={style}
                editable={editable}
            />
        );
    };
  /*  const renderInputFilter = ({ placeholder, onFilter, onReset }) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <DatePicker className="form-control" format={'DD/MM/YYYY'} onChange={(e)=> onFilter(e) }  />

        </div>

    );*/

const onChangeNgayBatDau=(ngaybatdau)=> {
   if(ngaybatdau !== ''){
    const filteredData = listGiaDVKT.filter((row) => {
         return row.NgayBatDau === ngaybatdau;
     });
     
     setFilteredData(filteredData);
    }else{
        getAllGiaDVKT();
    }


}
const onChangeNgayKetThuc=(ngayketthuc)=> {
    if(ngayketthuc !== ''){
     const filteredData = listGiaDVKT.filter((row) => {
          return row.NgayKetThuc === ngayketthuc;
      });
      
      setFilteredData(filteredData);
     }else{
         getAllGiaDVKT();
     }
 
 
 }


const status = [
    { value: null, text: "All" },
    { value: "true", text: "1" },
    { value: "false", text: "0" }
]

    if (loadpage === true) {
        return (
            <>
                <div className="text-muted listgiadvkt">
                    <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                        <div className="d-flex align-items-center ">
                            <div className="vienphi-danhmuc-title mx-2"><FontAwesomeIcon icon={faBars} /> Khai báo giá DVKT</div>
                        </div>

                        <div className="text-end ">
                            <div className="col-n"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                            <Button className="form-btn bg" onClick={handleDataCreate}>
                                <FontAwesomeIcon icon={faPlus} className="mx-1" />
                                Thêm mới
                            </Button>
                        </div>
                    </div>

                    <DetailGiaDVKT
                        open={open} setOpen={setOpen}
                        reLoad={getAllGiaDVKT}
                        handleUpdate={handleUpdate}
                        listDVKT={listDVKT}
                        listDoiTuong={listDoiTuong}
                        key="DetailGiaDVKT"
                    />
                    <hr className="w-100 my-1" />
                    <div className="m-1 danhmuc tablegiadvkt">
                        <DataGrid
                            data={filteredData.length <= 0 ? null : filteredData}
                            style={{ height: '90vh' }}
                            filterable
                            columnResizing
                        >
                            <GridColumn
                                field="ServiceID"
                                title="Tên dịch vụ"
                                filterable
                                resizable
                                width={300}
                                filter={() =>
                                    renderComboBox({
                                        data: listDVKT.map((item) => ({
                                            value: item.ServiceID,
                                            text: item.ServiceName,
                                        })),
                                        value: filters.ServiceID || '',
                                        onChange: (value) => {
                                            const filterValue = value || null;
                                            const filteredData = listGiaDVKT.filter((row) => {

                                                return filterValue ? row.ServiceID === filterValue : true;
                                            });
                                            setFilteredData(filteredData);
                                            setFilters((prev) => ({ ...prev, ServiceID: filterValue }));
                                        },
                                        onReset: () => {
                                            setFilteredData(listGiaDVKT);
                                            setFilters((prev) => ({ ...prev, ServiceID: '' }));
                                        },
                                        style: { width: '100%' },
                                    })
                                }
                                render={({ row }) => {
                                    const dvkt = (listDVKT || []).find((item) => item.ServiceID === row.ServiceID);
                                    return dvkt ? dvkt.ServiceName : null;
                                }}
                            />
                            <GridColumn
                                field="ObjectID"
                                title="Đối tượng"
                                filterable
                                align="center"
                                width={130}
                                resizable
                                filter={() =>
                                    renderComboBox({
                                        data: listDoiTuong.map((item) => ({
                                            value: item.ObjectID,
                                            text: item.ObjectName,
                                        })),
                                        value: filters.ObjectID || '',
                                        onChange: (value) => {
                                            const filterValue = value || null;
                                            const filteredData = listGiaDVKT.filter((row) => {

                                                return filterValue ? row.ObjectID === filterValue : true;
                                            });
                                            setFilteredData(filteredData);
                                            setFilters((prev) => ({ ...prev, ObjectID: filterValue }));
                                        },
                                        onReset: () => {
                                            setFilteredData(listGiaDVKT);
                                            setFilters((prev) => ({ ...prev, ObjectID: '' }));
                                        },
                                        style: { width: '100%' },
                                    })
                                }

                                render={({ row }) => {
                                    const doituong = listDoiTuong.find((item) => item.ObjectID === row.ObjectID);
                                    return doituong ? doituong.ObjectName : null;
                                }}
                            />
                            <GridColumn field="UnitPrice_BV" title="Đơn giá tại bệnh viện" align="center" filterable width={150} resizable
                                render={({ row }) => (
                                    <div>
                                        {row.UnitPrice_BV !== undefined
                                            ? `${row.UnitPrice_BV.toLocaleString('vi-VN')}`
                                            : "0"}
                                    </div>
                                )} />
                            <GridColumn field="UnitPrice" title="Đơn giá" align="center" filterable width={120} resizable
                                render={({ row }) => (
                                    <div>
                                        {row.UnitPrice !== undefined
                                            ? `${row.UnitPrice.toLocaleString('vi-VN')}`
                                            : "0"}
                                    </div>
                                )} />
                            <GridColumn field="DisparityPrice" title="Phụ thu" align="center" filterable width={120} resizable
                                render={({ row }) => (
                                    <div>
                                        {row.UnitPrice !== undefined
                                            ? `${row.UnitPrice.toLocaleString('vi-VN')}`
                                            : "0"}
                                    </div>
                                )} />
                            <GridColumn field="DisparityPrice_Other" title="Phụ thu ngoài giờ" align="center" filterable width={120} resizable
                                render={({ row }) => (
                                    <div>
                                        {row.UnitPrice !== undefined
                                            ? `${row.UnitPrice.toLocaleString('vi-VN')}`
                                            : "0"}
                                    </div>
                                )} />
                            <GridColumn field="UnitPrice_Transfer" title="Phụ thu gửi mẫu" align="center" filterable width={120} resizable
                                render={({ row }) => (
                                    <div>
                                        {row.UnitPrice !== undefined
                                            ? `${row.UnitPrice.toLocaleString('vi-VN')}`
                                            : "0"}
                                    </div>
                                )} />
                            <GridColumn
                                field="DateOf_Begin"
                                title="Ngày bắt đầu"
                                align="center"
                                filterable
                                width={120}
                                resizable
                               
                               
                                filter={() =>(
                                    <DatePicker className="form-control" format={'DD/MM/YYYY'} onChange={(date, dateString) => {onChangeNgayBatDau(dateString);}
                                    } />
                                )
                               }
                                render={({ row }) => (<div>{row.NgayBatDau}</div>)}

                            />

                            <GridColumn
                                field="DateOf_End"
                                title="Ngày kết thúc"
                                align="center"
                                filterable
                                resizable
                                width={120}
                                filter={() =>(
                                    <DatePicker className="form-control" format={'DD/MM/YYYY'} onChange={(date, dateString) => {onChangeNgayKetThuc(dateString);}
                                    } />
                                )
                               }
                                render={({ row }) => (<div>{row.NgayKetThuc}</div>)}

                            />
                            <GridColumn
                                field="Hide"
                                title="Ẩn/Hiện"
                                align="center"
                                resizable
                                width={100}
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => (
                                    <Checkbox
                                        checked={row.Hide === true}
                                        onChange={() => handleToggleHideCheckbox(row)}
                                    />
                                )}
                            />
                            <GridColumn
                                field="actions"
                                title="Thao tác"
                                align="center"
                                width={150}
                                resizable
                                filterable={false}
                                render={({ row }) => (
                                    <div className="d-flex justify-content-center align-center">
                                        <Dropdown
                                            menu={{
                                                items,
                                                /**
                                                 *
                                                 */
                                                onClick: (e) => handleThaoTac(e, row),
                                            }}
                                            placement="left"
                                            arrow={{
                                                pointAtCenter: true,
                                            }}
                                        >
                                            <Button
                                                className="bg-light vienphi-danhmuc-icon-modify"
                                                onClick={() => {
                                                    setActiveModify(!activeModify);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    style={{ fontSize: "10px" }}
                                                    className="text-dark"
                                                />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </DataGrid>
                    </div>
                </div>

            </>
        );
    } else { return <LoadPage /> }
}

export default KhaiBaoGiaDVKT;