import React, { useEffect, useState } from 'react';
import "../goidvkt.scss";
import { Form, Input, Button, Modal, Select, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faRightFromBracket, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import goikhamAPI from '../../../../../services/vienphi/danhmuc/goikhamAPI';
import { errorInfo, successInfo } from '../../../../../components/Dialog/Dialog';
import loginAPI from "../../../../../services/loginApi";
import { setDataChiTietGoiKham } from '../../../../../slices/dataVienPhi';
import { DataGrid, GridColumn } from 'rc-easyui';
const ChiTietGoiKham = (props) => {
    const { open, setOpen, handleCreate_Update_CT, ListDVKT, ListGoiKham, selectedData, ListGiaDVKT } = props;
    const { chitietgoikham } = useSelector((state) => state.dataVienPhi);
    const [form] = Form.useForm();
    const [userlogin, setUserLogin] = useState();
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState([]);
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    useEffect(() => {
        if (open && selectedData?.ServicePackageID) {
            form.setFieldsValue({
                ServicePackageID: selectedData.ServicePackageID,
            });

            const fetchData = async () => {
                try {
                    const response = await goikhamAPI.getAllChiTietGoiKham({ ServicePackageID: selectedData.ServicePackageID });
                    const selectedServices = response.data.map(item => item.ServiceID);
                    setTableData(response.data);
                    form.setFieldsValue({ ServiceID: selectedServices });
                } catch (error) {
                    console.error("Lỗi khi lấy chi tiết gói:", error);
                }
            };

            fetchData();
            getAllUser();
        }
    }, [open, selectedData, form]);
    const handleServiceChange = (selectedServices) => {
        const filteredData = tableData.filter(item => selectedServices.includes(item.ServiceID));

        const newData = selectedServices
            .filter(serviceID => !tableData.some(item => item.ServiceID === serviceID))
            .map((serviceID, index) => {
                const servicePriceObj = ListGiaDVKT.find(priceItem => priceItem.ServiceID === serviceID);
                const unitPrice = servicePriceObj ? servicePriceObj.UnitPrice : 0;

                return {
                    ServicePackageID: selectedData?.ServicePackageID,
                    RowID: Math.floor(Math.random() * 90000) + 10000,
                    ServiceID: serviceID,
                    ServicePrice: unitPrice,
                    STT: filteredData.length + index + 1,
                    EmployeeID: userlogin.RowID,
                    isNew:true
                };
            });

        setTableData([...filteredData, ...newData]);
    };


    const handleSubmit = async () => {
        Swal.fire({
            title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
            confirmButtonText: "Đồng ý",
            showCancelButton: true,
            cancelButtonText: "Hủy",
            customClass: {
                title: "fs-5 text-dark",
                confirmButton: "shadow-none",
                cancelButton: "shadow-none",
            },
        }).then(async (result) => {
            if (result?.isConfirmed) {
                await Promise.all(tableData.map(item => handleCreate_Update_CT(item)));
                dispatch(setDataChiTietGoiKham());
                form.resetFields();
                setOpen(false);
            }
        });
    };

    useEffect(() => {
        getAllUser();
        if (open) {
            form.setFieldsValue({
                ServicePackageID: selectedData?.ServicePackageID,
                RowID: chitietgoikham?.RowID,
                ServiceID: chitietgoikham?.ServiceID,
                ServicePrice: chitietgoikham?.ServicePrice,
                STT: chitietgoikham?.STT
            });
        }
    }, [form, chitietgoikham, open, selectedData]);


    const handleDeleteRow = async (RowID, EmployeeID = userlogin.RowID) => {
        const rowToDelete = tableData.find(item => item.RowID === RowID);

        if (rowToDelete?.isNew) {
            const updatedTableData = tableData.filter(item => item.RowID !== RowID);
            setTableData(updatedTableData);
            form.setFieldsValue({ ServiceID: updatedTableData.map(item => item.ServiceID) });
            return;
        }
        Swal.fire({
            title: "Bạn có chắc muốn xóa?",
            text: "Dữ liệu sẽ bị xóa khỏi hệ thống!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            cancelButtonText: "Hủy",
            confirmButtonText: "Xóa",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await goikhamAPI.deleteCTGoiKham(RowID, EmployeeID);
                    if (response.data.Code === "ok") {
                        successInfo("Xóa thành công!");
                        const updatedTableData = tableData.filter(item => item.RowID !== RowID);
                        setTableData(updatedTableData);
                        const updatedServiceIDs = updatedTableData.map(item => item.ServiceID);
                        form.setFieldsValue({ ServiceID: updatedServiceIDs });

                    } else {
                        errorInfo("Xóa thất bại! " + response.data.Content);
                    }
                } catch (error) {
                    errorInfo("Lỗi khi xóa dữ liệu!");
                    console.error("Lỗi khi xóa:", error);
                }
            }
        });
    };


    return (
        <Modal
            className="DetailCTGoiKham"
            title={<div className="vienphi-danhmuc-add">Khai báo chi tiết gói khám</div>}
            centered
            open={open}
            onCancel={() => setOpen(false)}
            width={1450}
            closable={false}
            footer={null}
            maskClosable={false}
        >
            <div>
                <Form form={form} onFinish={handleSubmit}>
                    <div className=" mb-2">
                        <div className="row">
                            <Form.Item
                                label="Phòng thực hiện (ID):"
                                name="RowID"
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                                hidden
                            >
                                <Input className="form-control form-control-sm" />
                            </Form.Item>


                            <Form.Item
                                label="Mã gói khám:"
                                name="ServicePackageID"
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                                className="col-md-6"
                            >
                                <Select
                                    showSearch
                                    className="selectac"
                                    placeholder="Chọn mã gói khám"
                                    options={ListGoiKham.map((item) => ({
                                        value: item.ServicePackageID,
                                        label: item.ServicePackageName
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Tên dịch vụ:"
                                name="ServiceID"
                                className="col-md-6"
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    className="selectac"
                                    placeholder="Chọn dịch vụ"
                                    options={ListDVKT.map((item) => ({
                                        value: item.ServiceID,
                                        label: item.ServiceName,
                                    }))}
                                    maxTagCount={2}
                                    maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} mục`}
                                    dropdownRender={(menu) => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '8px 0' }} />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexWrap: 'nowrap',
                                                    padding: 8,
                                                }}
                                            >
                                                <Button
                                                    type="text"
                                                    style={{ marginLeft: 'auto', color: '#1890ff' }}
                                                    onClick={() => form.setFieldsValue({ ServiceID: ListDVKT.map((item) => item.ServiceID) })}
                                                >
                                                    Chọn tất cả
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                    onChange={handleServiceChange}
                                />
                            </Form.Item>

                        </div>
                    </div>

                    <div className="divider"></div>
                    <DataGrid
                        data={tableData}
                        style={{ height: 500 }}
                        columnResizing
                    >
                        <GridColumn field="ServicePackageID" title="Mã gói khám" align="center" width={100} />
                        <GridColumn
                            field="ServiceID" title="Tên dịch vụ" align="left" width={250}
                            render={({ row }) => {
                                if (!row.ServiceID || !ListDVKT.length) return "Không xác định";
                                const ServiceIDs = row.ServiceID.split(',').map((code) => code.trim());
                                const ServiceNames = ServiceIDs.map((code) => {
                                    const service = ListDVKT.find((item) => item.ServiceID === code);
                                    return service ? service.ServiceName : "Không xác định";
                                }).filter((name) => name);
                                const displayText = ServiceNames.length > 0 ? ServiceNames.join(', ') : "Không xác định";
                                return (
                                    <div
                                        style={{
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        }}
                                        title={displayText}
                                    >
                                        {displayText}
                                    </div>
                                );
                            }}
                        />

                        <GridColumn field="ServicePrice" title="Giá dịch vụ" align="right" width={50}
                            render={({ row }) => (
                                <div>
                                    {row.ServicePrice !== undefined
                                        ? `${row.ServicePrice.toLocaleString('vi-VN')}`
                                        : "0"}
                                </div>
                            )}
                        />
                        <GridColumn field="STT" title="STT" align="center" width={50} />
                        <GridColumn
                            title="Thao tác"
                            align="center"
                            width={100}
                            render={({ row }) => (
                                <div className="d-flex justify-content-center align-center">

                                    <Button
                                        className="bg-light vienphi-danhmuc-icon-modify"
                                        onClick={() => handleDeleteRow(row.RowID)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faFileExcel}
                                            style={{ fontSize: "10px" }}
                                            className="text-dark"
                                        />
                                    </Button>

                                </div>
                            )}
                        />
                    </DataGrid>
                    <Form.Item className="text-center">
                        <hr />
                        <Button
                            htmlType="submit"
                            className="mx-2 vienphi-danhmuc-btn"
                        >
                            <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                        </Button>
                        <Button danger className="mx-2" onClick={() => { setOpen(false); form.resetFields(); }}>
                            <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                        </Button>
                    </Form.Item>

                </Form>
            </div>
        </Modal>
    );
};

export default ChiTietGoiKham;
