import { Modal, Tabs, Button, Form, Input, Checkbox, Table, Dropdown, Radio, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendarCheck,
    faFileExcel,
    faPenToSquare,
    faReply,
    faSave,
    faFile,
    faAngleRight,
    faAngleLeft,
    faEdit,
    faTrashCan,
    faEye,
    faArrowsRotate,
    faBroom,
    faCapsules,
    faPills,
    faHouseChimneyMedical,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "../tiemchung.scss";
import ds from "../../../util/data";
import ThuocVatTuTiemChung from './ThuocVatTuTiemChung';
import ChiDinhVacXin from './ChiDinhVacXin';
function TiemChung() {
    const [click, setClick] = useState(false);
    const [openModelToathuoctainha, setModelToathuoctainha] = useState(false);
    const tabkhambenh = [
        {
            key: "ThuocVatTu",
            label: "Thuốc vật tư",
            children: (
                <div className="kbyhct-tab">
                    <ThuocVatTuTiemChung/>
                </div>
            ),
        },
        {
            key: "ChiDinh",
            label: "Chỉ định thêm hoặc hẹn mũi tiêm vắcxin",
            children: (
                <div className="kbyhct-tab">
                    <ChiDinhVacXin />
                </div>
            ),
        }

    ];
    const items1 = [
        {
            label: <div className="form-input-label khambenh-form-input-label">F3 - Lưu</div>,
            key: "1",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faSave} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">F2 - Bỏ qua</div>,
            key: "2",
            icon: <FontAwesomeIcon icon={faReply} />,
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">F4 - Sửa</div>,
            key: "3",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faPenToSquare} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">Hủy</div>,
            key: "4",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFileExcel} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">F5 - In toa</div>,
            key: "5",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFileExcel} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">Toa trắng</div>,
            key: "6",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFile} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">CĐ hẹn TK</div>,
            key: "7",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faCalendarCheck} type="regular" />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">Đóng HS</div>,
            key: "8",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faBook} />
                </div>
            ),
        },
    ];
    const itemsl = [
        {
            key: "1",
            label: "Xem",
            icon: <FontAwesomeIcon icon={faEye} />,
        },
        {
            key: "2",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "3",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const column0 = [
        {
            title: "STT",
            dataIndex: "TDV",
            fixed: "left",
            render: (TDV) => <div style={{ width: "200px" }}> {TDV} </div>,
        },
        {
            title: "Mã BN",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "600px" }}> {TDVVT} </div>,
        },
        {
            title: "Họ và tên",
            dataIndex: "MADVKT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "Năm sinh",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "Giới tính",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "Đối tượng",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "Thao tác",
            dataIndex: "",
            align: "center",
            fixed: "right",
            render: (record) => (
                <div className="d-flex justify-content-center">
                    <Dropdown
                        menu={{
                            itemsl,
                        }}
                        placement="left"
                        arrow={{
                            pointAtCenter: true,
                        }}
                    >
                        <Button
                            className="bg-light vienphi-danhmuc-icon-modify"
                            onClick={() => {

                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: "10px" }}
                                className="text-dark"
                            />
                        </Button>
                    </Dropdown>
                </div>
            ),
        },
    ];
    const itemsthuoc = [
        {
            label: <div className="form-input-label">Toa thuốc tại chổ</div>,
            key: "1",
            icon: <FontAwesomeIcon icon={faCapsules} />,
        },
        {
            label: <div className="form-input-label">Toa mua ngoài</div>,
            key: "2",
            icon: <FontAwesomeIcon icon={faPills} />,
        },
        {
            label: <div className="form-input-label">Thuốc tủ trực</div>,
            key: "3",
            icon: <FontAwesomeIcon icon={faHouseChimneyMedical} />,
        },
    ];
    const handleSetOpen = (a) => {
        if (a === "1") {
            setModelToathuoctainha(true);
        } else if (a === "2") {
            //setModelToamuangoai(true);
        } else if (a === "3") {
            //setModelThuoctutruc(true);
        }
    };

    return (
        <>
            <div className="div-shadow v3">
                <div className="d-flex justify-content-end align-items-center p-1">
                    <Form>
                        <div className="kb-checktoa">
                            <div className=" kb-checkintoa checkintoa-name"> In toa A4 :</div>
                            <div className=" kb-checkintoa check-1"> <Checkbox /></div>


                        </div>
                    </Form>

                    {items1.map((item) => (
                        <Button className="form-btn bg mx-1" key={item.key}>
                            {item.icon}
                            <div className="mx-1 fw-bold ">{item.label}</div>
                        </Button>
                    ))}
                </div>
            </div>
            <div className="d-flex h-max pt-2">
                <div
                    className={
                        click
                            ? "khambenh-danhsach-left w-0 div-shadow v5"
                            : "khambenh-danhsach-left w-25 div-shadow color-border v5"
                    }
                >
                    <div
                        className={
                            click
                                ? "khambenh-danhsach-close-left"
                                : "khambenh-danhsach-open-left"
                        }
                        onClick={() => setClick(!click)}
                    >
                        {click ? (
                            <FontAwesomeIcon icon={faAngleRight} />
                        ) : (
                            <FontAwesomeIcon icon={faAngleLeft} />
                        )}
                    </div>
                    <div className="TiemChung">
                        <div className="mt-1 shadow ">
                            <div className="v6">
                                <div className={click ? "d-none" : "w-100 scroll-dsleft"}>
                                    <div className="fixed">
                                        <div className="text-muted">
                                            <Form className="mx-1">
                                                <div className="row form-row">
                                                    <Radio.Group className="d-flex align-items-center justify-content-around">
                                                        <Radio value={1} className="form-input-label">
                                                            Chưa tiêm
                                                        </Radio>
                                                        <Radio value={2} className="form-input-label">
                                                            Đang tiêm
                                                        </Radio>
                                                        <Radio value={3} className="form-input-label">
                                                            Kết thúc
                                                        </Radio>
                                                    </Radio.Group>
                                                </div>
                                                <div className="row form-row">
                                                    <Form.Item
                                                        className="col-md-6 m-0 "
                                                        label={<div className="form-input-label">Ngày</div>}
                                                    >
                                                        <Input className="form-control" type="date" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        className="col-md-6 "
                                                        label={<div className="form-input-label">đến</div>}
                                                    >
                                                        <Input className="form-control " type="date" />
                                                    </Form.Item>
                                                </div>
                                                <div className="row form-row mb-3 mt-1">
                                                    <Form.Item className="col-md-2 ">
                                                        <Button className="danhsachleft-btn bg m-0">
                                                            <FontAwesomeIcon icon={faBroom} />
                                                        </Button>
                                                    </Form.Item>
                                                    <Form.Item className="col-md-8 p-0">
                                                        <Input type="search" className="form-control" />
                                                    </Form.Item>
                                                    <Form.Item className="col-md-2">
                                                        <Button className="danhsachleft-btn bg m-0">
                                                            <FontAwesomeIcon icon={faArrowsRotate} />
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                            <div className="m-1">
                                                <Table
                                                    columns={column0}
                                                    dataSource={ds}
                                                    // loading={loading}
                                                    scroll={{ x: true, y: 285 }}
                                                    size="small"
                                                    pagination={false}
                                                />
                                                <div className="fw-bold mx-1">Tổng:1000</div>
                                            </div>
                                            <div className="fw-bold color-text mx-2">Lịch sử khám bệnh</div>
                                            <div className="m-1">
                                                jj
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={click ? "w-100 vh-100" : "w-75"}>
                    <div className="mx-1 my-2 TiemChungTab">
                        <div className="title">Thực Hiện Tiêm Chủng Ngừa</div>
                        <div className="TiemChungTT">
                            <div className="TiemChungTTCol1">
                                <div className="itemTT">
                                    <div className="namett">Tiền sử:</div>
                                    <div className="inputtt"><Input.TextArea rows={2} className="form-control" /></div>
                                </div>
                                <div className="itemTT">
                                    <div className="namett">Lý do khám:</div>
                                    <div className="inputtt"><Input.TextArea rows={2} className="form-control" /></div>
                                </div>

                                <div className="itemTT">
                                    <div className="namett">Nội dung hẹn:</div>
                                    <div className="inputtt"><Input.TextArea rows={3} className="form-control" /></div>
                                </div>
                                <div className="itemTT">
                                    <div className="namett">Ghi chú:</div>
                                    <div className="inputtt"><Input.TextArea rows={2} className="form-control" /></div>
                                </div>


                            </div>
                            <div className="TiemChungTTCol2">
                                <div className="itemTT">
                                    <div className="namett">Mũi tiêm:</div>
                                    <div className="inputtt">
                                        <Select
                                            showSearch
                                            className="form-control selectac"
                                            placeholder="Search to Select"
                                            optionFilterProp="label"
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={[
                                                { value: 'jack', label: 'Jack' },
                                                { value: 'lucy', label: 'Lucy' },
                                                { value: 'Yiminghe', label: 'yiminghe' },
                                                { value: 'disabled', label: 'Disabled', disabled: true },
                                            ]}

                                        />
                                    </div>
                                </div>
                                <div className="itemTT">
                                    <div className="namett">Ngày tiêm:</div>
                                    <div className="inputtt">
                                        <DatePicker className="form-control" name="BirthDay" selected={new Date()}
                                            dateFormat="dd/MM/yyyy" />
                                    </div>
                                </div>
                                <div className="itemTT">
                                    <div className="namett">Dị ứng:</div>
                                    <div className="inputtt"><Input.TextArea rows={2} className="form-control" /></div>
                                </div>
                                <div className="itemTT">
                                    <div className="namett">Lịch tiêm:</div>
                                    <div className="inputtt"><Input.TextArea rows={5} className="form-control" /></div>
                                </div>

                            </div>
                            <div className="TiemChungTTCol3">
                                <div className="itemTT">
                                    <div className="namecs">N.Tim(l/p):</div>
                                    <div className="inputcs"><Input className="form-control" /></div>
                                </div>
                                <div className="itemTT">
                                    <div className="namecs">HA (mmHg):</div>
                                    <div className="inputcs"><Input className="form-control" /></div>
                                </div>
                                <div className="itemTT">
                                    <div className="namecs"></div>
                                    <div className="inputcs"><Input className="form-control" /></div>
                                </div>
                                <div className="itemTT">
                                    <div className="namecs">Nh.Độ (°C):</div>
                                    <div className="inputcs"><Input className="form-control" /></div>
                                </div>
                                <div className="itemTT">
                                    <div className="namecs">Ch.Cao (Cm):</div>
                                    <div className="inputcs"><Input className="form-control" /></div>
                                </div>
                                <div className="itemTT">
                                    <div className="namecs">C.Nặng (Kg):</div>
                                    <div className="inputcs"><Input className="form-control" /></div>
                                </div>

                            </div>
                        </div>
                        <div className="TiemChungBottom">
                            <Tabs
                                defaultActiveKey="1"
                                type="card"
                                size="small"
                                items={tabkhambenh}
                            />
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}

export default TiemChung;
