import { Dropdown, Form, Input, Table, Button, Tabs, Space, Checkbox, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPrint,
    faCapsules,
    faPills,
    faHouseChimneyMedical,
    faChevronDown,
    faPen,
    faPenToSquare,
    faSearchDollar,
    faBook,
    faCalendarCheck,
    faFileExcel,
    faReply,
    faSave,
    faFile,
    faAngleDown,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import TextArea from "antd/es/input/TextArea";
import "./phieukham.scss";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import { setDataLayMotBenhNhan } from "../../../../slices/dataKhamBenh";
import Toathuoctaicho from "./Toathuoctaicho";
import { useState, useRef, useEffect, useMemo } from "react";
import Toamuangoai from "./Toamuangoai";
import Thuoctutruc from "./Thuoctutruc";
import Donthuoc from "./Donthuoc";
import ChiDinh from "./ChiDinh";
import ICD10 from "./ICD10";

/*import Bienbanhoichuan from "./Tienich/Bienbanhoichuan";
import Bienbantuvong from "./Tienich/Bienbantuvong";
import Ghinhantheodosinhhieu from "./Tienich/Ghinhantheodoisinhhieu";
import Lapphieutheodoidieutri from "./Tienich/Lapphieutheodoidieutri";
import Lapphieuchamsoc from "./Tienich/Lapphieuchamsoc";
import Lapphieutheodoitruyendich from "./Tienich/Lapphieutheodoitruyendich";
import Intheodoidieutri from "./Tienich/In/Intheodoidieutri";
import Inphieuchamsoc from "./Tienich/In/Inphieuchamsoc";
import Inphieucongkhaisudungthuoc from "./Tienich/In/Inphieucongkhaisudungthuoc";
import Intheodoichucnangsong from "./Tienich/In/Intheodoichucnangsong";
import Inphieukhambenh from "./Tienich/In/Inphieukhambenh";
import Inhosongoaitru from "./Tienich/In/Inhosongoaitru";
import Fileghinhanhoso from "./Tienich/Fileghinhanhoso";
import Xemchiphikcb from "./Tienich/In/Xemchiphikcb";*/
import ds from "../../../../util/data";
import khambenhAPI from "../../../../services/khambenh/khambenhAPI";

function Phieukham(props) {
    const { setStatusDistable, StatusDistable, StatusDMLeft, setStatusDMLeft } = props;
    const [btnLuu, setbtnLuu] = useState(false);
    const [btnBoQua, setbtnBoQua] = useState(true);
    const [btnSua, setbtnSua] = useState(true);
    const [btnHuy, setbtnHuy] = useState(true);
    const [btnInToa, setbtnInToa] = useState(true);
    const [btnToaTrang, setbtnToaTrang] = useState(false);
    const [btnCDHenTK, setbtnCDHenTK] = useState(true);
    const [btnDongHS, setbtnDongHS] = useState(true);

    const [openModelToathuoctainha, setModelToathuoctainha] = useState(false);
    const [openModelToamuangoai, setModelToamuangoai] = useState(false);
    const [openModelThuoctutruc, setModelThuoctutruc] = useState(false);

    const [ListXuTri, setListXuTri] = useState([]);
    const [ListKQDieuTri, setListKQDieuTri] = useState([]);
    const [ListPPDieuTri, setListPPDieuTri] = useState([]);
    const dispatch = useDispatch();

    /* 
     const [openModelBienbanhoichuan, setModelBienbanhoichuan] = useState(false);
     const [openModelBienbantuvong, setModelBienbantuvong] = useState(false);
     const [openModelTheodoisinhhieu, setModelTheodoisinhhieu] = useState(false);
     const [openModelLapphieutheodoidieutri, setModelLapphieutheodoidieutri] = useState(false);
     const [openModelLapphieuchamsoc, setModelLapphieuchamsoc] = useState(false);
     const [openModelLapphieutheodoitruyendich, setModelLapphieutheodoitruyendich] = useState(false);
     const [openModelFileghinhanhoso, setModelFileghinhanhoso] = useState(false);
 
 
 
     const [refInTest, setRefInTest] = useState("");
     const [refIntheodoidieutri, setRefIntheodoidieutri] = useState("");
     const [refInphieuchamsoc, setRefInphieuchamsoc] = useState("");
     const [refPhieuCongKhaiSuDungThuoc, setrefPhieuCongKhaiSuDungThuoc] = useState("");
     const [refIntheodoichucnangsong, setRefIntheodoichucnangsong] = useState("");
     const [refInphieukhambenh, setRefInphieukhambenh] = useState("");
     // const [refIntheodoitruyendich, setRefIntheodoitruyendich] = useState("");
     const [refInhosongoaitru, setRefInhosongoaitru] = useState("");
     const [refXemchiphikcb, setRefXemchiphikcb] = useState("");
 
     const handlePrint1 = useReactToPrint({
         content: () => refInTest,
         documentTitle: "emp-data",
         onafterprint: () => alert("Print Success"),
     });
 
     const handlePrint2 = useReactToPrint({
         content: () => refIntheodoidieutri,
         documentTitle: "emp-data",
         onafterprint: () => alert("Print Success"),
     });
     const PrintPhieuChamSoc = useReactToPrint({
         content: () => refInphieuchamsoc,
         documentTitle: "emp-data",
         onafterprint: () => alert("Print Success"),
     });
     const PrintPhieuCongKhaiSuDungThuoc = useReactToPrint({
         content: () => refPhieuCongKhaiSuDungThuoc,
         documentTitle: "emp-data",
         onafterprint: () => alert("Print Success"),
     });
     const PrintPhieuTheoDoiChucNangSong = useReactToPrint({
         content: () => refIntheodoichucnangsong,
         documentTitle: "emp-data",
         onafterprint: () => alert("Print Success"),
     });
     const PrintPhieuKhamBenh = useReactToPrint({
         content: () => refInphieukhambenh,
         documentTitle: "emp-data",
         onafterprint: () => alert("Print Success"),
     });
     const PrintHoSoNgoaiTru = useReactToPrint({
         content: () => refInhosongoaitru,
         documentTitle: "emp-data",
         onafterprint: () => alert("Print Success"),
     });
     const PrintXemChiPhiKCB = useReactToPrint({
         content: () => refXemchiphikcb,
         documentTitle: "emp-data",
         onafterprint: () => alert("Print Success"),
     });
     const onClickDropdownTienich = ({ key }) => {
         if (key === "MBienBanHoiChuan") {
             setModelBienbanhoichuan(true);
         } else if (key === "MBienBanTuVong") {
             setModelBienbantuvong(true);
         } else if (key === "MTheoDoiSinhHieu") {
             setModelTheodoisinhhieu(true);
         } else if (key === "MPhieuTheoDoiDTri") {
             setModelLapphieutheodoidieutri(true);
         } else if (key === "MPhieuChamSoc") {
             setModelLapphieuchamsoc(true);
         } else if (key === "MPhieuTheoDoiTDich") {
             setModelLapphieutheodoitruyendich(true);
         }
         else if (key === "MNhanFileHS") {
             setModelFileghinhanhoso(true);
         }
     };*/

    const handleSetOpen = (e) => {
        if (e === "ToaTaiCho") {
            setModelToathuoctainha(true);
        } else if (e === "ToaMuaNgoai") {
            setModelToamuangoai(true);
        } else if (e === "ThuocTuTruc") {
            setModelThuoctutruc(true);
        }
    };
    const getXuTri = async () => {
        try {
            const data = await khambenhAPI.getAllXuTri();
            setListXuTri(data.data);
        } catch (err) { }
    };
    const getKetQuaDieuTri = async () => {
        try {
            const data = await khambenhAPI.getAllKetQuaDTri();
            setListKQDieuTri(data.data);
        } catch (err) { }
    };
    const getPPDieuTri = async () => {
        try {
            const data = await khambenhAPI.getAllPPDieuTri();
            setListPPDieuTri(data.data);
        } catch (err) { }
    };

    useEffect(() => {
        Promise.all([getXuTri(), getKetQuaDieuTri(), getPPDieuTri()]);
    }, []);

   

    const tabtienich = [
        {
            label: <div className="form-input-label">Toa thuốc tại chổ</div>,
            key: "ToaTaiCho",
            icon: <FontAwesomeIcon icon={faCapsules} />,
        },
        {
            label: <div className="form-input-label">Toa mua ngoài</div>,
            key: "ToaMuaNgoai",
            icon: <FontAwesomeIcon icon={faPills} />,
        },
        {
            label: <div className="form-input-label">Thuốc tủ trực</div>,
            key: "ThuocTuTruc",
            icon: <FontAwesomeIcon icon={faHouseChimneyMedical} />,
        },
    ];
    const items = [
        /*  {
              label: <div className="form-input-label">Ghi - Biên bản hội chuẩn</div>,
              key: "MBienBanHoiChuan",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPen} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label">Ghi - Biên bản tử vong</div>,
              key: "MBienBanTuVong",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPen} />
                  </div>
              ),
          },
          <hr />,
          {
              label: <div className="form-input-label">Ghi - Theo dõi sinh hiệu</div>,
              key: "MTheoDoiSinhHieu",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPen} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label">Ghi - Dị ứng thuốc</div>,
              key: "MDiUngThuoc",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPen} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label">Lập - Phiếu theo dõi điều trị</div>,
              key: "MPhieuTheoDoiDTri",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPenToSquare} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label">Lập - Phiếu chăm sóc</div>,
              key: "MPhieuChamSoc",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPenToSquare} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label">Lập - Phiếu theo dõi truyền dịch</div>,
              key: "MPhieuTheoDoiTDich",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPenToSquare} />
                  </div>
              ),
          },
          <hr />,
          {
              label: (
                  <div className="form-input-label" onClick={handlePrint1}>
                      In - Test
                  </div>
              ),
              key: "8",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPrint} />
                  </div>
              ),
          },
          {
              label: (
                  <div className="form-input-label" onClick={handlePrint2}>
                      In - Phiếu theo dõi điều trị
                  </div>
              ),
              key: "MInPhieuTheoDoiDTri",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPrint} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label" onClick={PrintPhieuChamSoc}>In - Phiếu chăm sóc</div>,
              key: "MInPhieuChamSoc",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPrint} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label" onClick={PrintPhieuCongKhaiSuDungThuoc}>In - Phiếu công khai sử dụng thuốc</div>,
              key: "MInPhieuCKSDThuoc",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPrint} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label" onClick={PrintPhieuTheoDoiChucNangSong}>In - Phiếu theo dõi chức năng sống</div>,
              key: "MInPhieuTDCNSong",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPrint} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label">In - Phiếu theo dõi truyền dịch</div>,
              key: "MInPhieuTDTDich",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPrint} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label" onClick={PrintPhieuKhamBenh}>In - Phiếu khám bệnh</div>,
              key: "MInPhieuKhamBenh",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPrint} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label" onClick={PrintHoSoNgoaiTru}>In - Hồ sơ ngoại trú</div>,
              key: "MInHoSoNgoaiTru",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPrint} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label">Ghi - Nhận file hồ sơ</div>,
              key: "MNhanFileHS",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPen} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label" onClick={PrintXemChiPhiKCB}>Xem chi phí khám chữa bệnh</div>,
              key: "MXemCTKBenh",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faSearchDollar} />
                  </div>
              ),
          },
          {
              label: <div className="form-input-label">Ghi - Giấy xin ra viện</div>,
              key: "MGiayRaVien",
              icon: (
                  <div>
                      <FontAwesomeIcon icon={faPen} />
                  </div>
              ),
          },*/
    ];

    const menuProps = {
        items,
        // onClick: onClickDropdownTienich,
    };

    const tabphieukham = [
        {
            key: "ChiDinh",
            label: "Chỉ định",
            children: (
                <div className={StatusDistable ? `class-cd`:`tiepnhan-tab`} >
                    <ChiDinh />
                </div>
            ),
        },
        {
            key: "DonThuoc",
            label: "Đơn thuốc",
            children: (
                <div className="tiepnhan-tab">
                    <Donthuoc />
                </div>
            ),
        },
    ];

    const column1 = [
        {
            title: "Mã ",
            dataIndex: "TDV",
            fixed: "left",
            render: (TDV) => <div> {TDV} </div>,
        },
        {
            title: "Tên",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div> {TDVVT} </div>,
        },
    ];
    return (
        <>
            <div className={StatusDistable ? `page-kham-benh disabled` : `page-kham-benh`}>
                <div className="color-border">
                    <div className="m-1 tab-tien-ich">
                        <div className="d-flex">
                            {tabtienich.map((item) => (
                                <Button
                                    className="form-btn "
                                    key={item.key}
                                    onClick={() => handleSetOpen(item.key)}
                                >
                                    {item.icon}
                                    <div className="mx-1 fw-bold ">{item.label}</div>
                                </Button>
                            ))}
                            <Dropdown menu={menuProps}>
                                <Button className="form-btn">
                                    <Space>
                                        <div className="fw-bold form-input-label">Tiện ích</div>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                    <div>
                        <Form>
                            <div className="d-flex khambenh">
                                <div className="w-70 px-2 khambenh-label">
                                    <div className="row">
                                        <Form.Item
                                            label={
                                                <div className="form-input-label khambenh-phieukham-left-input-label ">
                                                    Tr.chứng, B.Lý
                                                </div>
                                            }
                                            className="m-0 col-md-6"
                                            name="HS_Pathological_Process"
                                        >
                                            <TextArea disabled={StatusDistable}
                                                className="form-control"
                                                autoSize={{
                                                    minRows: 3,
                                                    maxRows: 5,
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <div className="form-input-label khambenh-phieukham-left-input-label ">
                                                    T.Sử bản thân
                                                </div>
                                            }
                                            className="m-0 col-md-6"
                                            name=""
                                        >
                                            <TextArea disabled={StatusDistable}
                                                className="form-control"
                                                autoSize={{
                                                    minRows: 3,
                                                    maxRows: 5,
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="row form-row">
                                        <Form.Item
                                            label={
                                                <div className="form-input-label khambenh-phieukham-left-input-label ">
                                                    Lý do khám
                                                </div>
                                            }
                                            name="HS_ReasonIn"
                                        >
                                            <Input className="form-control" disabled={StatusDistable} />
                                        </Form.Item>
                                    </div>
                                    <div className="row form-row">
                                        <Form.Item
                                            className="m-0"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-left-input-label ">
                                                    CĐ ban đầu
                                                </div>
                                            }
                                            name="DiagnosisCustom"
                                        >
                                            <Input className="form-control" disabled={StatusDistable} />
                                        </Form.Item>
                                    </div>
                                    <div className="row form-row">
                                        <Form.Item
                                            className="m-0"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-left-input-label ">
                                                    Xử trí
                                                </div>
                                            }
                                            name="TackleID_Multi"
                                        >
                                            <Select
                                                disabled={StatusDistable}
                                                notFoundContent={"Chưa có dữ liệu"}
                                                showSearch
                                                allowClear
                                                placeholder="Chọn"
                                                optionFilterProp="label"
                                                options={ListXuTri.map((item) => ({
                                                    value: item.TackleID,
                                                    label: item.TackleName
                                                }))}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="row form-row select-khb">
                                        <Form.Item
                                            label={
                                                <div className="form-input-label khambenh-phieukham-left-input-label ">
                                                    ICD10
                                                </div>
                                            }
                                            className="col-md-12 m-0"
                                            name="ICD10_Ma"
                                        >
                                            <ICD10 disabled={StatusDistable} className="dgdsgdgdfgd"/>
                                        </Form.Item>
                                    </div>
                                    <div className="row">
                                        <Form.Item
                                            label={
                                                <div className="form-input-label khambenh-phieukham-left-input-label ">
                                                    ICD10 KT
                                                </div>
                                            }
                                            className="col-md-12 m-0"
                                        >
                                            <div className="input-select">
                                                <Select />
                                            </div>
                                            <div className="color-border">
                                                <Table
                                                    dataSource={ds}
                                                    columns={column1}
                                                    scroll={{ X: true, y: 50 }}
                                                    pagination={false}
                                                    size="small"
                                                />
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div className="row kb-loidan">
                                        <Form.Item
                                            label={
                                                <div className="form-input-label khambenh-phieukham-left-input-label ">
                                                    Lời dặn
                                                </div>
                                            }
                                            className="col-md-12 mt-1"
                                        >
                                            <TextArea disabled={StatusDistable}
                                                className="form-control"
                                                autoSize={{
                                                    minRows: 1,
                                                    maxRows: 1,
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="w-30 px-2 border-line-left-kb sinh-hieu-kham-benh">
                                    <div className="row">
                                        <Form.Item
                                            className="m-0 form-row "
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label">
                                                    Mạch(lần/phút)
                                                </div>
                                            }
                                        >
                                            <Input className="form-control text-center" disabled={StatusDistable} />
                                        </Form.Item>
                                        <Form.Item
                                            className="m-0  form-row"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label">
                                                    H.áp(mm/Hg)
                                                </div>
                                            }
                                        >
                                            <Input className="form-control text-center" disabled={StatusDistable} />
                                        </Form.Item>
                                        <Form.Item
                                            className="m-0  form-row"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label">
                                                    SPO2
                                                </div>
                                            }
                                        >
                                            <Input className="form-control text-center" disabled={StatusDistable} />
                                        </Form.Item>
                                        <Form.Item
                                            className="m-0  form-row"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label">
                                                    N.thở(lần/phút)
                                                </div>
                                            }
                                        >
                                            <Input className="form-control text-center" disabled={StatusDistable} />
                                        </Form.Item>
                                        <Form.Item
                                            className="m-0  form-row"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label">
                                                    Nhiệt độ (<sup>o</sup>C)
                                                </div>
                                            }
                                        >
                                            <Input className="form-control text-center" disabled={StatusDistable} />
                                        </Form.Item>
                                    </div>
                                    <div className="row form-row">
                                        <Form.Item
                                            className="w-55"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label">
                                                    Ch.Cao (cm)
                                                </div>
                                            }
                                        >
                                            <Input className="form-control text-center" disabled={StatusDistable} />
                                        </Form.Item>

                                        <Form.Item
                                            className=" w-45"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label-cannang">
                                                    C.Nặng(kg)
                                                </div>
                                            }
                                        >
                                            <Input className="form-control text-center" disabled={StatusDistable} />
                                        </Form.Item>
                                    </div>
                                    <div className="row">
                                        <Form.Item
                                            className="m-0 mt-2"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label">
                                                    PP Đ.trị
                                                </div>
                                            }
                                        >
                                            <TextArea disabled={StatusDistable}
                                                className="form-control"
                                                autoSize={{
                                                    minRows: 3,
                                                    maxRows: 3,
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="row">
                                        <Form.Item
                                            className="m-0  form-row"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label">
                                                    KQ Đ.trị
                                                </div>
                                            }
                                        >
                                            <Input className="form-control text-center" disabled={StatusDistable} />
                                        </Form.Item>
                                    </div>
                                    <div className="row">
                                        <Form.Item
                                            className="m-0  form-row"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label">
                                                    TT Ra viện
                                                </div>
                                            }
                                        >
                                            <Input className="form-control text-center" disabled={StatusDistable} />
                                        </Form.Item>
                                    </div>
                                    <div className="row">
                                        <Form.Item
                                            className="m-0 form-row"
                                            label={
                                                <div className="form-input-label khambenh-phieukham-right-input-label">
                                                    Ngày ra
                                                </div>
                                            }
                                        >
                                            <Input className="form-control text-center" disabled={StatusDistable} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="div-shadow v3 btn-action-kham-benh">
                                <div className="  d-flex justify-content-end">
                                    <Button className="STTKeTiep"

                                        onClick={() => {
                                            setStatusDMLeft(false);
                                            setStatusDistable(true);
                                             dispatch(setDataLayMotBenhNhan(null));
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faSave} /> STT - Kế tiếp
                                    </Button>
                                    <Button disabled={btnLuu}>
                                        <FontAwesomeIcon icon={faSave} /> F3 - Lưu
                                    </Button>
                                    <Button disabled={btnBoQua}>
                                        <FontAwesomeIcon icon={faReply} /> F2- Bỏ qua
                                    </Button>
                                    <Button disabled={btnSua}>
                                        <FontAwesomeIcon icon={faPenToSquare} /> F4 - Sửa
                                    </Button>
                                    <Button disabled={btnHuy}>
                                        <FontAwesomeIcon icon={faFileExcel} /> Huỷ
                                    </Button>
                                    <Button disabled={btnInToa}>
                                        <FontAwesomeIcon icon={faPrint} /> In toa
                                    </Button>
                                    <Button disabled={btnToaTrang}>
                                        <FontAwesomeIcon icon={faFile} /> Toa trắng
                                    </Button>
                                    <Button disabled={btnCDHenTK}>
                                        <FontAwesomeIcon icon={faCalendarCheck} /> CĐ hẹn TK
                                    </Button>
                                    <Button disabled={btnDongHS}>
                                        <FontAwesomeIcon icon={faBook} /> Đóng HS
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="color-border mt-1 tab-cd-thuoc-kham-benh">
                    <div className="tabphieukham-position">
                        <div className="mx-1 my-2 tab-khambenh">
                            <Tabs
                                defaultActiveKey="ChiDinh"
                                type="card"
                                size="small"
                                items={tabphieukham}
                            />
                        </div>
                        <div className="phieukham-input-position">
                            <Form>
                                <div className="row m-0">
                                    <div className="col-md-2 kb-checktoa">
                                        <div className=" kb-checkintoa checkintoa-name "> Thuốc DV :</div>
                                        <div className=" kb-checkintoa check-1"> <Checkbox /></div>

                                    </div>
                                    <div className="col-md-4 pk-tt-row">
                                        <label>TT BHYT :</label>
                                        <Input className="form-control" />
                                    </div>
                                    <div className="col-md-3 pk-tt-row tong-dich-vu">
                                        <label>Dịch vụ :</label>
                                        <Input className="form-control" />
                                    </div>
                                    <div className="col-md-3 pk-tt-row">
                                        <label>Tổng :</label>
                                        <Input className="form-control" />
                                    </div>

                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Page In     */}


            {/* Modal hien thi */}
            <Toathuoctaicho open={openModelToathuoctainha} setOpen={setModelToathuoctainha} />
            <Toamuangoai open={openModelToamuangoai} setOpen={setModelToamuangoai} />
            <Thuoctutruc open={openModelThuoctutruc} setOpen={setModelThuoctutruc} />

            <div className="d-none">
                {/*
                  <Bienbanhoichuan open={openModelBienbanhoichuan} setOpen={setModelBienbanhoichuan} />
            <Bienbantuvong open={openModelBienbantuvong} setOpen={setModelBienbantuvong} />
            <Ghinhantheodosinhhieu open={openModelTheodoisinhhieu} setOpen={setModelTheodoisinhhieu} />
            <Lapphieutheodoidieutri open={openModelLapphieutheodoidieutri} setOpen={setModelLapphieutheodoidieutri} />
            <Lapphieuchamsoc open={openModelLapphieuchamsoc} setOpen={setModelLapphieuchamsoc} />
            <Fileghinhanhoso open={openModelFileghinhanhoso} setOpen={setModelFileghinhanhoso} />

            <Lapphieutheodoitruyendich open={openModelLapphieutheodoitruyendich} setOpen={setModelLapphieutheodoitruyendich} />
            
                <InTest setRef={setRefInTest}></InTest>
               
                <Intheodoidieutri setRef={setRefIntheodoidieutri}></Intheodoidieutri>
                <Inphieuchamsoc setRef={setRefInphieuchamsoc}></Inphieuchamsoc>
                <Inphieucongkhaisudungthuoc setRef={setrefPhieuCongKhaiSuDungThuoc}></Inphieucongkhaisudungthuoc>
                <Intheodoichucnangsong setRef={setRefIntheodoichucnangsong}></Intheodoichucnangsong>
                <Inphieukhambenh setRef={setRefInphieukhambenh}></Inphieukhambenh>
               <Intheodoitruyendich setRef={setRefIntheodoitruyendich}></Intheodoitruyendich>
               
                <Inhosongoaitru  setRef={setRefInhosongoaitru}></Inhosongoaitru>
                <Xemchiphikcb setRef={setRefXemchiphikcb}></Xemchiphikcb>*/}
            </div>
        </>
    );
}

export default Phieukham;
