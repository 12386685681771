import axios from "axios";
import REACT_LOCALHOST from "../../host";
import { authHeader } from '../../helpers';
const loaivienphiAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-service-category-vien-phi`,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-service-category-vien-phi`, obj, {"headers":authHeader()});
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-service-category-vien-phi`, obj);
    },
    delete: (ServiceCategoryID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-service-category-vien-phi/${ServiceCategoryID}/${EmployeeID}`);
    }
};

export default loaivienphiAPI;
