import axios from "axios";
import REACT_LOCALHOST from "../host";
import { authHeader } from '../helpers';
const khoaphongAPI = {
    fetchKhoaPhong: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-department`, obj);
    },
    fetchKhoaPhong_ck: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-department-ck`, obj);
    },
    addKhoaPhong: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-med-department`, obj);
    },
    updateKhoaPhong: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-med-department`, obj);
    },
    deleteKhoaPhong: (DepartmentID, EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-med-department/${DepartmentID}/${EmployeeID}`);
    },
    getServiceModule: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-service-module`,obj);
    },

};

export default khoaphongAPI;

