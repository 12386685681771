import { Table, Button, Dropdown, Form, Input, Select, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faTrashCan,
    faEye,
    faPenToSquare,
    faPlus,
    faBook
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import {
    ComboBoxTable,
    useTokenSearch,
    TokenHighlight,
    layoutMaxWidth,
    layoutMaxHeight,
    layoutColumnsAlignLeft,
} from '@citizensadvice/react-combo-boxes';

import DetailXuatThuocTuTrucVTTH from "../Detail";
import '../dieuchinhhsbangoaitru.scss';
import '../../../../styles/combobox.scss';
/*function mapOption({ breed,country }) {
   var  abc = {country:country,  breed:breed};
   console.log('jjj',breed,country,abc);
    return abc;
}*/


function mapOption({ breed, country }) {
    return breed;
}

function index({ breed }) {
    console.log('sfdsf', breed);
    return breed;
}

function renderValue({ children }, { search, column: { name } }) {
    // console.log('mmmm',children);
    if (name === 'breed' || name === 'country') {

        return (
            <TokenHighlight
                search={search}
                value={children}
            />
        );
    }
    return children;
}

function DieuChinhHSBANgoaiTru() {
    const cats = [
        {
            "breed": "Abyssinian",
            "country": "nguyễn văn hùng",
            "origin": "Natural",
            "bodyType": "Moderate",
            "coatLength": "Short",
            "pattern": "Ticked"
        },
        {
            "breed": "Aegean",
            "country": "Greece",
            "origin": "Natural",
            "bodyType": "Moderate",
            "coatLength": "Semi-long",
            "pattern": "Bicolored or tricolored"
        },
        {
            "breed": "American Bobtail",
            "country": "United States",
            "origin": "Mutation",
            "bodyType": "Moderate",
            "coatLength": "Short and long",
            "pattern": "All"
        },
        {
            "breed": "American Curl",
            "country": "United States",
            "origin": "Mutation",
            "bodyType": "Moderate",
            "coatLength": "Short and long",
            "pattern": "All"
        },
        {
            "breed": "American Shorthair",
            "country": "United States",
            "origin": "Natural",
            "bodyType": "Moderate",
            "coatLength": "Short",
            "pattern": "All but colorpoint"
        },
        {
            "breed": "American Wirehair",
            "country": "United States",
            "origin": "Mutation",
            "bodyType": "Moderate",
            "coatLength": "Rex",
            "pattern": "All but colorpoint"
        },
    ];
    const list = [
        {
            value: '1',
            label: 'Jack',
        },
        {
            value: '2',
            label: 'Lưu Văn Tèo',
        },
        {
            value: '3',
            label: 'Nguyễn Việt Hùng',
        },
    ];
    const [open, setOpen] = useState(false);
    const [click, setClick] = useState(false);
    const [activeModify, setActiveModify] = useState(false);

    //const [value, setValue] = useState(null);
    //const [search, setSearch] = useState('');

    //const [products, setProducts] = useState(cats);
    const [data, setData] = useState(cats)
    const [searchValue, setSearchValue] = useState("")
    const [selectedRowIndex, setSelectedRowIndex] = useState(0);
    const [chonthuoc, setchonthuoc] = useState([]);
    const [ValueTable, setValueTable] = useState('');
    const [ShowOptionTable, setShowOptionTable] = useState(false);
    const selectRef = useState(null);
    function setChange() {
        const selected = selectRef?.current?.querySelector(".active");
        if (selected) {
            selected?.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
    }
    /*

    const filteredOptions = useTokenSearch(search, {
        options: cats,
        index:cats.breed
    });
    */

    /*const aaaa = () => {
        if (search != '') {




            const filterBySearch = cats.filter((item) => {
                 if (item.breed.toLowerCase()
                     .indexOf(search.toLowerCase()) >=0 || item.country.toLowerCase()
                 .indexOf(search.toLowerCase()) >=0  ) { return item; }
             })
             setProducts(filterBySearch);

            if (search.target.value.length > 0) {
                console.log('dsfsdf', search.target.value);
              
    
              const filterBySearch = products.filter((item) => {
                if (item.breed.toLowerCase()
                    .indexOf(search.target.value.toLowerCase()) >=0 || item.country.toLowerCase()
                .indexOf(search.target.value.toLowerCase()) >=0  ) { return item; }
            })
            setProducts(filterBySearch);
            


            const filterBySearch = cats.filter((item) => {
                if (item.breed.toLowerCase()
                    .indexOf(search.toLowerCase()) >= 0 || item.country.toLowerCase()
                        .indexOf(search.toLowerCase()) >= 0) { return item; }
            })
            setProducts(filterBySearch);

        } else if (search === '') {
            console.log('mmmm');
            setProducts(cats);
        }
        else {
            console.log('ffffff');
            return setProducts(cats);
        }


        // option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        // option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0


    };*/
    //const [managedFocus, setManagedFocus] = useState(true);

    const handleDataCreate = () => {
        setOpen(true);
    };
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    const items = [
        {
            key: "1",
            label: "Xem",
            icon: <FontAwesomeIcon icon={faEye} />,
        },
        {
            key: "2",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "3",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const columns = [
        {
            title: "Ngày",
            dataIndex: "NGAY",
            render: (NGAY) => <div style={{ width: "50px" }}> {NGAY} </div>,
        },
        {
            title: "Mã số",
            dataIndex: "MASO",
            render: (MASO) => <div style={{ width: "50px" }}> {MASO} </div>,
        },
        {
            title: "Họ & Tên",
            dataIndex: "TEN",
            render: (TEN) => <div style={{ width: "200px" }}> {TEN} </div>,
        },
        {
            title: "Phái",
            dataIndex: "PHAI",
            render: (PHAI) => <div style={{ width: "50px" }}> {PHAI} </div>,
        },
        {
            title: "Năm sinh",
            dataIndex: "NAM",
            render: (NAM) => <div style={{ width: "80px" }}> {NAM} </div>,
        },
        {
            title: "Phòng khám",
            dataIndex: "PhongKham",
            render: (PhongKham) => <div style={{ width: "200px" }}> {PhongKham} </div>,
        },
        {
            title: "Bác sĩ",
            dataIndex: "BacSi",
            render: (BacSi) => <div style={{ width: "200px" }}> {BacSi} </div>,
        },
        {
            title: "Đối tượng",
            dataIndex: "DoiTuong",
            render: (DoiTuong) => <div style={{ width: "80px" }}> {DoiTuong} </div>,
        },

        {
            title: "Thao tác",
            dataIndex: "",
            align: "center",
            fixed: "right",
            render: (record) => (
                <div className="d-flex justify-content-center">
                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="left"
                        arrow={{
                            pointAtCenter: true,
                        }}
                    >
                        <Button
                            className="bg-light vienphi-danhmuc-icon-modify"
                            onClick={() => {
                                setActiveModify(!activeModify);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: "10px" }}
                                className="text-dark"
                            />
                        </Button>
                    </Dropdown>
                </div>
            ),
        },
    ];

    /* const columnssearch = [
         { name: 'breed', label: 'Breed' },
         { name: 'country', label: 'Country' },
         { name: 'origin', label: 'Origin' },
         { name: 'bodyType', label: 'Body type' },
         { name: 'coatLength', label: 'Coat length' },
         { name: 'pattern', label: 'Pattern' },
         { hhh: 'nnn', bb: 'Pattern' },
     ];
 
 
 
     const onLayoutListBox = [
         layoutMaxWidth,
         layoutMaxHeight,
         layoutColumnsAlignLeft,
     ];*/
    const Filter = (e) => {



        setSearchValue(e.target.value);

        if (searchValue === "") { setData(cats); return; }
        else {
            const filterBySearch = cats.filter((item) => {
                if (
                    //item.breed.toLowerCase().includes(searchValue.toLowerCase())

                    item.breed.includes(e.target.value) ||
                    item.breed.toUpperCase().includes(e.target.value) ||
                    item.breed.toLowerCase().includes(e.target.value) ||

                    item.country.includes(e.target.value) ||
                    item.country.toUpperCase().includes(e.target.value) ||
                    item.country.toLowerCase().includes(e.target.value)


                ) { return item; }
            })
            setData(filterBySearch);
        }



    };
    const handleKeyDown = (e) => {
        if (e.key === "ArrowUp" && selectedRowIndex > 0) {
            setSelectedRowIndex(selectedRowIndex - 1);
            console.log('ArrowUp', selectedRowIndex)


        } else if (e.key === "ArrowDown" && selectedRowIndex < data.length - 1) {
            setSelectedRowIndex(selectedRowIndex + 1);
            console.log('ArrowDown', selectedRowIndex)



        }
        if (e.key === "Enter" && ShowOptionTable === true) {
            setSelectedRowIndex(0);
            setchonthuoc(data[selectedRowIndex]);
            setSearchValue(data[selectedRowIndex].breed);
            setShowOptionTable(false);
            onclickshowOptionTable();

        }
    };
    const clickChooseThuoc = (e) => {
        setchonthuoc(e.breed);
        setSearchValue(e.breed);
        setShowOptionTable(false);
    };
    const onclickshowOptionTable = () => {
        setShowOptionTable(ShowOptionTable === false ? true : false);


    }
    useEffect(() => {
        if (searchValue.length > 0) {
            setShowOptionTable(true);
        }

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };



    }, [searchValue === '', selectedRowIndex, data]);



    console.log('kkkkk', chonthuoc.breed);


    return (
        <>
            <div className="text-muted DieuChinhHSBANgoaiTru" onClick={() => (ShowOptionTable === true ? setShowOptionTable(false) : '')}>
                <div className="header-dieuchinh">
                    <div className="ngay">
                        <span className="namengay">Ngày:</span>
                        <DatePicker className="form-control" name="BirthDay" selected={new Date()}
                            dateFormat="dd/MM/yyyy" />
                        <span>~</span>
                        <DatePicker className="form-control" name="BirthDay" selected={new Date()}
                            dateFormat="dd/MM/yyyy" />
                    </div>
                    <div className="categorykhoa">
                        <div className="tenkhoa">Khoa:</div>
                        <div className="searchkhoa">
                            <input
                                autoFocus
                                type="text"
                                name="tenthuoc"
                                className="form-control"
                                value={searchValue}
                                onChange={Filter}
                                onClick={() => onclickshowOptionTable()}
                            />
                            <div className={ShowOptionTable === false ? "optiontablehide" : "optiontableshow"}>
                                <table onKeyDown={handleKeyDown} tabIndex="0" className="w-100" >
                                    <thead>
                                        <th className="ma">Mã</th>
                                        <th className="khoa">Khoa</th>
                                    </thead>
                                    <tbody className="form-input-label">
                                        {data.map((row, index) => {

                                            let className;
                                            if (index === selectedRowIndex) {
                                                className = "active";
                                            }
                                            setTimeout(() => {
                                                setChange();
                                            }, [100]);
                                            return (
                                                <tr
                                                    key={index}
                                                    className={
                                                        className
                                                        //index === selectedRowIndex ? "bg-active" : "bg-unactive"
                                                    }
                                                    onClick={() => clickChooseThuoc(row)}
                                                >
                                                    <td className="ma">{row.breed}</td>
                                                    <td className="khoa">{row.country}</td>

                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        {/* <ComboBoxTable
                            id="select"
                            aria-labelledby="select-label"
                            value={value}
                            onValue={setValue}
                            onSearch={setSearch}
                            onChange={aaaa}
                            options={search ? products : cats}
                            columns={columnssearch}
                            renderColumnValue={renderValue}
                            mapOption={mapOption}
                            onLayoutListBox={onLayoutListBox}
                            managedFocus={managedFocus}
                        />*/}
                    </div>
                    <div className="categoryphongkham">
                        <div className="namephong">Phòng khám:</div>
                        <Select
                            className="form-control input-select"
                            showSearch
                            optionFilterProp="children"
                            placeholder={"select a country"}

                            filterOption={(input, option) => {
                                return (
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >
                            {list.map((item) => (

                                <Select.Option
                                    title={item.label}
                                    key={item.label}
                                    id={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="categorydoituong">
                        <div className="namedoituong">Đối tượng:</div>
                        <Select
                            className="form-control input-select"
                            showSearch
                            optionFilterProp="children"
                            placeholder={"select a country"}

                            filterOption={(input, option) => {
                                return (
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >
                            {list.map((item) => (

                                <Select.Option
                                    title={item.label}
                                    key={item.label}
                                    id={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="header-row2">
                    <div className="categorybacsy">
                        <div className="namebacsy">Bác sỹ:</div>
                        <Select
                            className="form-control input-select"
                            showSearch
                            optionFilterProp="children"
                            placeholder={"select a country"}

                            filterOption={(input, option) => {
                                return (
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >
                            {list.map((item) => (

                                <Select.Option
                                    title={item.label}
                                    key={item.label}
                                    id={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="categoryloaihs">
                        <div className="nameloaihs">Loại HS:</div>
                        <Select
                            className="form-control input-select"
                            showSearch
                            optionFilterProp="children"
                            placeholder={"select a country"}

                            filterOption={(input, option) => {
                                return (
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >
                            {list.map((item) => (

                                <Select.Option
                                    title={item.label}
                                    key={item.label}
                                    id={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="checkin">
                        <Form.Item
                            label={<div>In</div>}
                            className="col-md-12 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Checkbox />
                        </Form.Item>
                    </div>
                    <div className="btnDieuChinhHSBA">
                        <Button className="form-btn bg" onClick={handleDataCreate}>
                            <FontAwesomeIcon icon={faBook} className="mx-1" />
                            Danh sách
                        </Button>
                    </div>

                </div>
                <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                    <div className="d-flex align-items-center ">
                        <div className="vienphi-danhmuc-title mx-2">Hồ sơ bệnh án ngoại trú </div>
                    </div>

                </div>
                <DetailXuatThuocTuTrucVTTH open={open} setOpen={setOpen} />
                <hr className="w-100 my-1" />
                <div className="m-1 danhmuc">
                    <Table
                        columns={columns}
                        dataSource=""
                        // loading={loading}
                        scroll={{ x: true }}
                        size="small"
                        pagination={{ pageSize: 20 }}
                    />
                </div>
            </div>
        </>
    );
}

export default DieuChinhHSBANgoaiTru;
