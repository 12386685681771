import { Button, Dropdown, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
    faEdit,
    faTrashCan,
    faPenToSquare,
    faPlus,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { DataGrid, GridColumn, ComboBox } from 'rc-easyui';
import 'rc-easyui/dist/themes/material-teal/easyui.css';
import 'rc-easyui/dist/themes/icon.css';
import 'rc-easyui/dist/themes/react.css';

import "../Detail/thuocVTYT.scss";
import DetailThuocVTYT from "../Detail";
import { errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import thuocvtytAPI from "../../../../../services/duoc/danhmuc/thuocvtytAPI";
import { setDataThuocVTYT } from "../../../../../slices/dataDMDuoc";
import loginAPI from "../../../../../services/loginApi";
import LoadPage from "../../../../../loadpage";
function ThuocVTYT() {
    const [loadpage, setLoadPage] = useState(false);
    const [HideCheck, setHideCheck] = useState(false);
    const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };

    const [listThuocVTYT, setListThuocVTYT] = useState([]);

    const [listNhomThuoc, setlistNhomThuoc] = useState([]);
    const [listLoaiThuoc, setlistLoaiThuoc] = useState([]);
    const [listDuongDung, setlistDuongDung] = useState([]);
    const [listDonViTinh, setlistDonViTinh] = useState([]);
    const [listNhomIn, setlistNhomIn] = useState([]);
    const [listNuocSanXuat, setlistNuocSanXuat] = useState([]);
    const [listHangSanXuat, setlistHangSanXuat] = useState([]);
    const [listKho, setlistKho] = useState([]);


    const [userlogin, setUserLogin] = useState();
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };

    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataThuocVTYT(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {

                    handleDelete(record.ItemID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };
    const getAllThuocVTYT = async () => {
        try {
            const data = await thuocvtytAPI.getAll({ 'ItemID': '', 'Hide': HideCheck === false ? 0 : 1 });
            setListThuocVTYT(data.data);

        } catch (err) { }
    };
    const getAllNhomThuoc = async () => {
        try {
            const data = await thuocvtytAPI.getNhomThuoc('ServiceGroupID');
            setlistNhomThuoc(data.data.recordset);

        } catch (err) { }
    };
    const getAllLoaiThuoc = async () => {
        try {
            const data = await thuocvtytAPI.getLoaiThuoc({ 'ServiceCategoryID': 0, 'Hide': 0 });
            setlistLoaiThuoc(data.data);

        } catch (err) { }
    };
    const getAllDuongDung = async () => {
        try {
            const data = await thuocvtytAPI.getDuongDung({ 'UsageID': 0, 'Hide': 0 });
            setlistDuongDung(data.data);

        } catch (err) { }
    };
    const getAllDonViTinh = async () => {
        try {
            const data = await thuocvtytAPI.getDonViTinh({ 'UnitID': 0, 'Hide': 0 });
            setlistDonViTinh(data.data);

        } catch (err) { }
    };
    const getAllNhomIn = async () => {
        try {
            const data = await thuocvtytAPI.getNhomIn({ 'GroupPrinterID': 0, 'Hide': 0 });
            setlistNhomIn(data.data);

        } catch (err) { }
    };
    const getAllNuocSanXuat = async () => {
        try {
            const data = await thuocvtytAPI.getNuocSanXuat({ 'CountryID': 0, 'Hide': 0 });
            setlistNuocSanXuat(data.data);

        } catch (err) { }
    };
    const getAllHangSanXuat = async () => {
        try {
            const data = await thuocvtytAPI.getHangSanXuat({ 'ProducerID': 0, 'Hide': 0 });
            setlistHangSanXuat(data.data);

        } catch (err) { }
    };
    const getAllKhoThuoc = async () => {
        try {
            const data = await thuocvtytAPI.getKhoThuoc({ 'Hide': 0 });
            setlistKho(data.data);

        } catch (err) { }
    };

    const handleUpdate = async (obj) => {
        const data = await thuocvtytAPI.update(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllThuocVTYT();
        }
        else if (data.data.Code === 404) {
            infoRes(data.data.Content);
        }
        else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (ItemID, EmployeeID) => {
        const data = await thuocvtytAPI.delete(ItemID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllThuocVTYT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const onChange_isBHYT = async (e, record) => {
        var arrayEdit = {
            ItemID: record?.ItemID,
            ItemCode_PK: record?.ItemCode_PK,
            ItemName: record?.ItemName,
            ItemContent: record?.ItemContent,
            Active: record?.Active,
            Active_Code: record?.Active_Code,
            UsageID: record?.UsageID,
            UnitID: record?.UnitID,
            UnitID_Packed: record?.UnitID_Packed,
            SODK: record?.SODK,
            QUYCACH: record?.QUYCACH,
            DosageForms: record?.DosageForms,
            ServiceCategoryID: record?.ServiceCategoryID,
            Hide: record?.Hide,
            UnitPrice: record?.UnitPrice,
            UnitPrice_VAT: record?.UnitPrice_VAT,
            SalesPrice: record?.SalesPrice,
            BHYTPrice: record?.BHYTPrice,
            DisparityPrice: record?.DisparityPrice,
            SalesPrice_Retail: record?.SalesPrice_Retail,
            Rate_BH: record?.Rate_BH,
            CountryID: record?.CountryID,
            ProducerID: record?.ProducerID,
            IsBHYT: e.target.checked === false ? 0 : 1,
            IsService: record?.IsService,
            SafelyQuantity: record?.SafelyQuantity,
            RepositoryCode: record?.RepositoryCode,
            QtyOfMeasure: record?.QtyOfMeasure,
            STT: record?.STT,
            Note: record?.Note,
            EmployeeID: userlogin.RowID,
            Generic_BD: 1,
            VENCode: 1,
            GroupPrinterID: record?.GroupPrinterID,
            UnitID_Medi: record?.UnitID_Medi,
            QtyOfMeasure_Medi: 1,//
            Toxic_Dose: record?.Toxic_Dose,
            ItemsBHYT_ID: record?.ItemsBHYT_ID,
            QtyContent_Medi: record?.QtyContent_Medi,
            Converted_Medi: record?.Converted_Medi,//
            IsKeDon_BS: record?.IsKeDon_BS,//
            Ma_DQG: 1,
            ServiceID_Attach: record?.ServiceID_Attach,
            VAT: record?.VAT
        }


        const data = await thuocvtytAPI.update(arrayEdit);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllThuocVTYT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const onChange_IsService = async (e, record) => {
        var arrayEdit = {
            ItemID: record?.ItemID,
            ItemCode_PK: record?.ItemCode_PK,
            ItemName: record?.ItemName,
            ItemContent: record?.ItemContent,
            Active: record?.Active,
            Active_Code: record?.Active_Code,
            UsageID: record?.UsageID,
            UnitID: record?.UnitID,
            UnitID_Packed: record?.UnitID_Packed,
            SODK: record?.SODK,
            QUYCACH: record?.QUYCACH,
            DosageForms: record?.DosageForms,
            ServiceCategoryID: record?.ServiceCategoryID,
            Hide: record?.Hide,
            UnitPrice: record?.UnitPrice,
            UnitPrice_VAT: record?.UnitPrice_VAT,
            SalesPrice: record?.SalesPrice,
            BHYTPrice: record?.BHYTPrice,
            DisparityPrice: record?.DisparityPrice,
            SalesPrice_Retail: record?.SalesPrice_Retail,
            Rate_BH: record?.Rate_BH,
            CountryID: record?.CountryID,
            ProducerID: record?.ProducerID,
            IsBHYT: record?.IsBHYT,
            IsService: e.target.checked === false ? 0 : 1,
            SafelyQuantity: record?.SafelyQuantity,
            RepositoryCode: record?.RepositoryCode,
            QtyOfMeasure: record?.QtyOfMeasure,
            STT: record?.STT,
            Note: record?.Note,
            EmployeeID: userlogin.RowID,
            Generic_BD: 1,
            VENCode: 1,
            GroupPrinterID: record?.GroupPrinterID,
            UnitID_Medi: record?.UnitID_Medi,
            QtyOfMeasure_Medi: 1,//
            Toxic_Dose: record?.Toxic_Dose,
            ItemsBHYT_ID: record?.ItemsBHYT_ID,
            QtyContent_Medi: record?.QtyContent_Medi,
            Converted_Medi: record?.Converted_Medi,//
            IsKeDon_BS: record?.IsKeDon_BS,//
            Ma_DQG: 1,
            ServiceID_Attach: record?.ServiceID_Attach,
            VAT: record?.VAT
        }
        const data = await thuocvtytAPI.update(arrayEdit);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllThuocVTYT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const onChange_Converted_Medi = async (e, record) => {
        var arrayEdit = {
            ItemID: record?.ItemID,
            ItemCode_PK: record?.ItemCode_PK,
            ItemName: record?.ItemName,
            ItemContent: record?.ItemContent,
            Active: record?.Active,
            Active_Code: record?.Active_Code,
            UsageID: record?.UsageID,
            UnitID: record?.UnitID,
            UnitID_Packed: record?.UnitID_Packed,
            SODK: record?.SODK,
            QUYCACH: record?.QUYCACH,
            DosageForms: record?.DosageForms,
            ServiceCategoryID: record?.ServiceCategoryID,
            Hide: record?.Hide,
            UnitPrice: record?.UnitPrice,
            UnitPrice_VAT: record?.UnitPrice_VAT,
            SalesPrice: record?.SalesPrice,
            BHYTPrice: record?.BHYTPrice,
            DisparityPrice: record?.DisparityPrice,
            SalesPrice_Retail: record?.SalesPrice_Retail,
            Rate_BH: record?.Rate_BH,
            CountryID: record?.CountryID,
            ProducerID: record?.ProducerID,
            IsBHYT: record?.IsBHYT,
            IsService: record?.IsService,
            SafelyQuantity: record?.SafelyQuantity,
            RepositoryCode: record?.RepositoryCode,
            QtyOfMeasure: record?.QtyOfMeasure,
            STT: record?.STT,
            Note: record?.Note,
            EmployeeID: userlogin.RowID,
            Generic_BD: 1,
            VENCode: 1,
            GroupPrinterID: record?.GroupPrinterID,
            UnitID_Medi: record?.UnitID_Medi,
            QtyOfMeasure_Medi: 1,//
            Toxic_Dose: record?.Toxic_Dose,
            ItemsBHYT_ID: record?.ItemsBHYT_ID,
            QtyContent_Medi: record?.QtyContent_Medi,
            Converted_Medi: e.target.checked === false ? 0 : 1,//
            IsKeDon_BS: record?.IsKeDon_BS,//
            Ma_DQG: 1,
            ServiceID_Attach: record?.ServiceID_Attach,
            VAT: record?.VAT
        }

        const data = await thuocvtytAPI.update(arrayEdit);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllThuocVTYT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const onChange_KeDonBS = async (e, record) => {
        var arrayEdit = {
            ItemID: record?.ItemID,
            ItemCode_PK: record?.ItemCode_PK,
            ItemName: record?.ItemName,
            ItemContent: record?.ItemContent,
            Active: record?.Active,
            Active_Code: record?.Active_Code,
            UsageID: record?.UsageID,
            UnitID: record?.UnitID,
            UnitID_Packed: record?.UnitID_Packed,
            SODK: record?.SODK,
            QUYCACH: record?.QUYCACH,
            DosageForms: record?.DosageForms,
            ServiceCategoryID: record?.ServiceCategoryID,
            Hide: record?.Hide,
            UnitPrice: record?.UnitPrice,
            UnitPrice_VAT: record?.UnitPrice_VAT,
            SalesPrice: record?.SalesPrice,
            BHYTPrice: record?.BHYTPrice,
            DisparityPrice: record?.DisparityPrice,
            SalesPrice_Retail: record?.SalesPrice_Retail,
            Rate_BH: record?.Rate_BH,
            CountryID: record?.CountryID,
            ProducerID: record?.ProducerID,
            IsBHYT: record?.IsBHYT,
            IsService: record?.IsService,
            SafelyQuantity: record?.SafelyQuantity,
            RepositoryCode: record?.RepositoryCode,
            QtyOfMeasure: record?.QtyOfMeasure,
            STT: record?.STT,
            Note: record?.Note,
            EmployeeID: userlogin.RowID,
            Generic_BD: 1,
            VENCode: 1,
            GroupPrinterID: record?.GroupPrinterID,
            UnitID_Medi: record?.UnitID_Medi,
            QtyOfMeasure_Medi: 1,//
            Toxic_Dose: record?.Toxic_Dose,
            ItemsBHYT_ID: record?.ItemsBHYT_ID,
            QtyContent_Medi: record?.QtyContent_Medi,
            Converted_Medi: record?.Converted_Medi,//
            IsKeDon_BS: e.target.checked === false ? 0 : 1,//
            Ma_DQG: 1,
            ServiceID_Attach: record?.ServiceID_Attach,
            VAT: record?.VAT
        }

        const data = await thuocvtytAPI.update(arrayEdit);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllThuocVTYT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const onChange_AnHien = async (e, record) => {
        var arrayEdit = {
            ItemID: record?.ItemID,
            ItemCode_PK: record?.ItemCode_PK,
            ItemName: record?.ItemName,
            ItemContent: record?.ItemContent,
            Active: record?.Active,
            Active_Code: record?.Active_Code,
            UsageID: record?.UsageID,
            UnitID: record?.UnitID,
            UnitID_Packed: record?.UnitID_Packed,
            SODK: record?.SODK,
            QUYCACH: record?.QUYCACH,
            DosageForms: record?.DosageForms,
            ServiceCategoryID: record?.ServiceCategoryID,
            Hide: e.target.checked === false ? 0 : 1,
            UnitPrice: record?.UnitPrice,
            UnitPrice_VAT: record?.UnitPrice_VAT,
            SalesPrice: record?.SalesPrice,
            BHYTPrice: record?.BHYTPrice,
            DisparityPrice: record?.DisparityPrice,
            SalesPrice_Retail: record?.SalesPrice_Retail,
            Rate_BH: record?.Rate_BH,
            CountryID: record?.CountryID,
            ProducerID: record?.ProducerID,
            IsBHYT: record?.IsBHYT,
            IsService: record?.IsService,
            SafelyQuantity: record?.SafelyQuantity,
            RepositoryCode: record?.RepositoryCode,
            QtyOfMeasure: record?.QtyOfMeasure,
            STT: record?.STT,
            Note: record?.Note,
            EmployeeID: userlogin.RowID,
            Generic_BD: 1,
            VENCode: 1,
            GroupPrinterID: record?.GroupPrinterID,
            UnitID_Medi: record?.UnitID_Medi,
            QtyOfMeasure_Medi: 1,//
            Toxic_Dose: record?.Toxic_Dose,
            ItemsBHYT_ID: record?.ItemsBHYT_ID,
            QtyContent_Medi: record?.QtyContent_Medi,
            Converted_Medi: record?.Converted_Medi,//
            IsKeDon_BS: record?.IsKeDon_BS,//
            Ma_DQG: 1,
            ServiceID_Attach: record?.ServiceID_Attach,
            VAT: record?.VAT
        }

        const data = await thuocvtytAPI.update(arrayEdit);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllThuocVTYT();
        } else {
            errorInfo(data.data.Content);
        }
    };



    /* const filterDataNhomYhuoc = listNhomThuoc => formatter => listNhomThuoc.map(item => ({
         text: formatter(item),
         value: formatter(item)
     }));
     const filterDataLoaiThuoc = listLoaiThuoc => formatter => listLoaiThuoc.map(item => ({
         text: formatter(item),
         value: formatter(item)
     }));
     const filterDataThuocVatTu = listThuocVTYT => formatter => listThuocVTYT.map(item => ({
         text: formatter(item),
         value: formatter(item)
     }));
     const filterDataDuongDung = () => {
         return listDuongDung.map(item => ({
             text: item.UsageName,
             value: item.UsageID
         }))
     };
     const filterDataDonVi = () => {
         return listDonViTinh.map(item => ({
             text: item.UnitName,
             value: item.UnitID
         }))
     };*/
    const GroupKho = (a) => {
        return listKho.map((item) => {
            let b = a.split(",");
            return b.filter(item1 => item.RepositoryID === parseInt(item1))
                .map((commentReply, idx) => <span key={idx}>{item.RepositoryName}  </span>);

        });

    }
    useEffect(() => {
        document.title = 'Thuốc - VTYT - EmedLink';
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        const getAllThuocVTYT = async () => {
            const data = await thuocvtytAPI.getAll({ 'ItemID': '', 'Hide': HideCheck === false ? 0 : 1 });
            setListThuocVTYT(data.data);
        };
        getAllThuocVTYT();
        getAllUser();
        getAllNhomThuoc();
        getAllLoaiThuoc();
        getAllDuongDung();
        getAllDonViTinh();
        getAllNhomIn();
        getAllNuocSanXuat();
        getAllHangSanXuat();
        getAllKhoThuoc();

    }, [HideCheck]);
    const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    const status = [
        { value: null, text: "All" },
        { value: "true", text: "1" },
        { value: "false", text: "0" }
    ]
    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    /* const columns = [
         {
             title: "Nhóm",
             dataIndex: "ServiceGroupName",
             fixed: "left",
             width: '30%',
             filterSearch: true,
             filters: filterDataNhomYhuoc(listNhomThuoc)(i => i.ServiceGroupName),
             onFilter: (value, record) => record.ServiceGroupName.includes(value),
             sorter: (a, b) => a.ServiceGroupName.length - b.ServiceGroupName.length,
             ellipsis: true,
 
             render: (ServiceGroupName) => <div style={{ width: "200px" }}> {ServiceGroupName} </div>,
         },
         {
             title: "Phân loại",
             dataIndex: "ServiceCategoryName",
             width: '30%',
             filterSearch: true,
             filters: filterDataLoaiThuoc(listLoaiThuoc)(i => i.ServiceCategoryName),
             onFilter: (value, record) => record.ServiceCategoryName.includes(value),
             sorter: (a, b) => a.ServiceCategoryName.length - b.ServiceCategoryName.length,
             ellipsis: true,
 
             render: (ServiceCategoryName) => <div style={{ width: "200px" }}> {ServiceCategoryName} </div>,
         },
 
         {
             title: "Tên hàng hoá",
             dataIndex: "ItemName",
             width: '40%',
             filterSearch: true,
             filters: filterDataThuocVatTu(listThuocVTYT)(i => i.ItemName),
             onFilter: (value, record) => record.ItemName.includes(value),
             sorter: (a, b) => a.ItemName.length - b.ItemName.length,
             ellipsis: true,
 
             render: (ItemName) => <div style={{ width: "200px" }}> {ItemName} </div>,
         },
         {
             title: "Mã hàng hoá",
             dataIndex: "ItemCode_PK",
             width: '20%',
             filterSearch: true,
             filters: filterDataThuocVatTu(listThuocVTYT)(i => i.ItemCode_PK),
             onFilter: (value, record) => record.ItemCode_PK.includes(value),
             sorter: (a, b) => a.ItemCode_PK.length - b.ItemCode_PK.length,
             ellipsis: true,
 
             render: (ItemCode_PK) => <div style={{ width: "200px" }}> {ItemCode_PK} </div>,
         },
         {
             title: "Hoạt chất",
             dataIndex: "Active",
             width: '40%',
             filterSearch: true,
             filters: filterDataThuocVatTu(listThuocVTYT)(i => i.Active),
             onFilter: (value, record) => record.Active.includes(value),
             sorter: (a, b) => a.Active.length - b.Active.length,
             ellipsis: true,
             render: (Active) => <div style={{ width: "200px" }}> {Active} </div>,
         },
         {
             title: "Hàm lượng",
             dataIndex: "ItemContent",
             width: '40%',
             filterSearch: true,
             filters: filterDataThuocVatTu(listThuocVTYT)(i => i.ItemContent),
             onFilter: (value, record) => record.ItemContent.includes(value),
             sorter: (a, b) => a.ItemContent.length - b.ItemContent.length,
             ellipsis: true,
             render: (ItemContent) => <div style={{ width: "200px" }}> {ItemContent} </div>,
         },
         {
             title: "Đường dùng",
             dataIndex: "UsageID",
             width: '15%',
             key: 'UsageID',
             filterSearch: true,
             filters: filterDataDuongDung(),
             filterMultiple: false,
             onFilter: (value, record) => {
                 return record.UsageID === value;
             },
             sorter: (a, b) => a.UsageID - b.UsageID,
             ellipsis: true,
             render: (UsageID) => (
                 <div style={{ width: "200px" }}>
                     {listDuongDung.map((item) => {
                         let a = [];
                         if (item.UsageID === UsageID) {
                             a.push(item.UsageName);
                         }
                         return a;
                     })}
                 </div>
             ),
         },
 
         {
             title: "ĐVT đóng gói (Nh.Kho)",
             dataIndex: "UnitID_Packed",
             width: '200px',
             key: 'UnitID_Packed',
             filterSearch: true,
             filters: filterDataDonVi(),
             filterMultiple: false,
             onFilter: (value, record) => {
                 return record.UnitID_Packed === value;
             },
             sorter: (a, b) => a.UnitID_Packed - b.UnitID_Packed,
             ellipsis: true,
 
             render: (UnitID_Packed) => (
                 <div style={{ width: "200px" }}>
                     {listDonViTinh.map((item) => {
                         let a = [];
                         if (item.UnitID === UnitID_Packed) {
                             a.push(item.UnitName);
                         }
                         return a;
                     })}
                 </div>
             ),
         },
         {
             title: "ĐVT",
             dataIndex: "UnitID",
             width: '10%',
             render: (UnitID) => (
                 <div style={{ width: "200px" }}>
                     {listDonViTinh.map((item) => {
                         let a = [];
                         if (item.UnitID === UnitID) {
                             a.push(item.UnitName);
                         }
                         return a;
                     })}
                 </div>
             ),
         },
         {
             title: "ĐV kê toa",
             dataIndex: "UnitID_Medi",
             width: '10%',
             render: (UnitID_Medi) => (
                 <div style={{ width: "200px" }}>
                     {listDonViTinh.map((item) => {
                         let a = [];
                         if (item.UnitID === UnitID_Medi) {
                             a.push(item.UnitName);
                         }
                         return a;
                     })}
                 </div>
             ),
         },
         {
             title: "SL quy đổi nhập kho",
             dataIndex: "QtyOfMeasure",
             width: '20%',
             render: (QtyOfMeasure) => <div style={{ width: "200px" }}> {QtyOfMeasure} </div>,
         },
         {
             title: "Số đăng ký",
             dataIndex: "SODK",
             width: '15%',
             render: (SODK) => <div style={{ width: "200px" }}> {SODK} </div>,
         },
         {
             title: "Dạng bào chế",
             dataIndex: "DosageForms",
             width: '15%',
             render: (DosageForms) => <div style={{ width: "200px" }}> {DosageForms} </div>,
         },
         {
             title: "Quy cách đóng gói",
             dataIndex: "QUYCACH",
             width: '20%',
             render: (QUYCACH) => <div style={{ width: "200px" }}> {QUYCACH} </div>,
         },
         {
             title: "Giá mua",
             dataIndex: "UnitPrice",
             width: '10%',
             render: (UnitPrice) => <div style={{ width: "200px" }}> {UnitPrice} </div>,
         },
         {
             title: "Giá BHYT",
             dataIndex: "BHYTPrice",
             width: '10%',
             render: (BHYTPrice) => <div style={{ width: "200px" }}> {BHYTPrice} </div>,
         },
         {
             title: "Phụ thu BHYT",
             dataIndex: "DisparityPrice",
             width: '15%',
             render: (DisparityPrice) => <div style={{ width: "200px" }}> {DisparityPrice} </div>,
         },
         {
             title: "Giá dịch vụ",
             dataIndex: "SalesPrice",
             width: '15%',
             render: (SalesPrice) => <div style={{ width: "200px" }}> {SalesPrice} </div>,
         },
         {
             title: "Giá bán sỉ",
             dataIndex: "SalesPrice_Retail",
             width: '10%',
             render: (SalesPrice_Retail) => <div style={{ width: "200px" }}> {SalesPrice_Retail} </div>,
         },
         {
             title: "DM BHYT",
             dataIndex: "",
             align: "center",
             width: '10%',
             render: (record) => (
                 <div style={{ width: "80px" }}>
                     {record.IsBHYT === true ?
                         <Checkbox onChange={(e) => onChange_isBHYT(e, record)} defaultChecked />
                         :
                         <Checkbox onChange={(e) => onChange_isBHYT(e, record)} />
                     }
 
                 </div>
             ),
         },
         {
             title: "DM dịch vụ",
             dataIndex: "",
             align: "center",
             width: '15%',
             render: (record) => (
                 <div style={{ width: "80px" }}>
                     {record.IsService === true ?
                         <Checkbox onChange={(e) => onChange_IsService(e, record)} defaultChecked />
                         :
                         <Checkbox onChange={(e) => onChange_IsService(e, record)} />
                     }
 
                 </div>
             ),
         },
         {
             title: "Q.Đổi SL Kê Toa",
             dataIndex: "",
             align: "center",
             width: '20%',
             render: (record) => (
                 <div style={{ width: "80px" }}>
                     {record.Converted_Medi === true ?
                         <Checkbox onChange={(e) => onChange_Converted_Medi(e, record)} defaultChecked />
                         :
                         <Checkbox onChange={(e) => onChange_Converted_Medi(e, record)} />
                     }
 
                 </div>
             ),
         },
         {
             title: "Hãng sản xuất",
             dataIndex: "ProducerName",
             width: '20%',
             render: (ProducerName) => <div style={{ width: "200px" }}> {ProducerName} </div>,
         },
         {
             title: "Nước sản xuất",
             dataIndex: "CountryName",
             width: '20%',
             render: (CountryName) => <div style={{ width: "200px" }}> {CountryName} </div>,
         },
         {
             title: "Hạn mức tồn",
             dataIndex: "SafelyQuantity",
             width: '15%',
             render: (SafelyQuantity) => <div style={{ width: "200px" }}> {SafelyQuantity} </div>,
         },
         {
             title: "Ẩn/Hiện",
             dataIndex: "",
             align: "center",
             width: '10%',
             render: (record) => (
                 <div style={{ width: "80px" }}>
                     {record.Hide === true ?
                         <Checkbox onChange={(e) => onChange_AnHien(e, record)} defaultChecked />
                         :
                         <Checkbox onChange={(e) => onChange_AnHien(e, record)} />
                     }
 
                 </div>
             ),
         },
         {
             title: "Mã thuốc AX",
             dataIndex: "ItemsBHYT_ID",
             width: '15%',
             render: (ItemsBHYT_ID) => <div style={{ width: "200px" }}> {ItemsBHYT_ID} </div>,
         },
         {
             title: "Ghi chú",
             dataIndex: "Note",
             width: '10%',
             render: (Note) => <div style={{ width: "200px" }}> {Note} </div>,
         },
         {
             title: "Nhóm in",
             dataIndex: "GroupPrinterName",
             width: '10%',
             render: (GroupPrinterName) => <div style={{ width: "200px" }}> {GroupPrinterName} </div>,
         },
         {
             title: "Kho",
             dataIndex: "TDVVT",
             width: '10%',
             render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
         },
         {
             title: "Kê đơn BS",
             dataIndex: "",
             align: "center",
             width: '10%',
             render: (record) => (
                 <div style={{ width: "80px" }}>
                     {record.IsKeDon_BS === true ?
                         <Checkbox onChange={(e) => onChange_KeDonBS(e, record)} defaultChecked />
                         :
                         <Checkbox onChange={(e) => onChange_KeDonBS(e, record)} />
                     }
 
                 </div>
             ),
         },
 
         {
             title: "Thao tác",
             dataIndex: "",
             align: "center",
             fixed: "right",
             width: '10%',
             render: (record) => (
                 <div className="d-flex justify-content-center" style={{ width: "80px" }}>
                     <Dropdown
                         menu={{
                             items,
                             onClick: (e) => handleThaoTac(e, record),
                         }}
                         placement="left"
                         arrow={{
                             pointAtCenter: true,
                         }}
                     >
                         <Button
                             className="bg-light vienphi-danhmuc-icon-modify"
                             onClick={() => {
                                 setActiveModify(!activeModify);
                             }}
                         >
                             <FontAwesomeIcon
                                 icon={faEdit}
                                 style={{ fontSize: "10px" }}
                                 className="text-dark"
                             />
                         </Button>
                     </Dropdown>
                 </div>
             ),
         },
     ];*/
    if (loadpage === true) {
        return (
            <div className="text-muted StyleNhomIn">
                <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                    <div className="d-flex align-items-center ">
                        <div className="vienphi-danhmuc-title mx-2"><FontAwesomeIcon icon={faBars} /> Thuốc & VTYT</div>
                    </div>

                    <div className="text-end">
                        <div className="nhomin-act-check">
                            <div className="col-n"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                            <div className="col-n">
                                <Button className="form-btn bg" onClick={handleDataCreate}>
                                    <FontAwesomeIcon icon={faPlus} className="mx-1" />
                                    Thêm mới
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <DetailThuocVTYT
                    open={open} setOpen={setOpen}
                    reLoad={getAllThuocVTYT}
                    handleUpdate={handleUpdate}
                    listNhomThuoc={listNhomThuoc}
                    listLoaiThuoc={listLoaiThuoc}
                    listDuongDung={listDuongDung}
                    listDonViTinh={listDonViTinh}
                    listNhomIn={listNhomIn}
                    listNuocSanXuat={listNuocSanXuat}
                    listHangSanXuat={listHangSanXuat}
                    listKho={listKho}

                    key="DetailThuocVTYT"
                />
                <hr className="w-100 my-1" />
                <div className="m-1 danhmuc list-style-thuoc">
                    <DataGrid filterable data={listThuocVTYT} clickToEdit selectionMode="cell" editMode="cell" columnResizing style={{ height: '90vh' }}>
                        <GridColumn editable field="ServiceGroupName" title="Nhóm thuốc" width={200} ></GridColumn>
                        <GridColumn field="ServiceCategoryName" title="Loại thuốc" width={200}></GridColumn>
                        <GridColumn field="ItemName" editable title="Tên thuốc - vtyt" width={350} />
                        <GridColumn field="ItemCode_PK" editable title="Mã hàng hoá" width={100} />
                        <GridColumn field="Active" title="Hoạt chất" width={250} />
                        <GridColumn field="ItemContent" title="Hàm lượng" width={250} />
                        <GridColumn field="UsageName" title="Đường dùng" width={100} />
                        <GridColumn field="UnitName" title="ĐVT đóng gói (Nh.Kho)" width={150} />
                        <GridColumn field="dvt_UnitName" title="ĐVT" width={100} />
                        <GridColumn field="dvtkt_UnitName" title="ĐV kê toa" width={100} />
                        <GridColumn field="QtyOfMeasure" title="SL quy đổi nhập kho" width={150} />
                        <GridColumn field="SODK" title="Số đăng ký" width={150} />
                        <GridColumn field="DosageForms" title="Dạng bào chế" width={200} />
                        <GridColumn field="QUYCACH" title="Quy cách đóng gói" width={200} />
                        <GridColumn field="UnitPrice" title="Giá mua" width={100}
                            render={({ row }) => (
                                <span>{priceSplitter(row.UnitPrice)}</span>
                            )}
                        />
                        <GridColumn field="BHYTPrice" title="Giá BHYT" width={100}
                            render={({ row }) => (
                                <span>{priceSplitter(row.BHYTPrice)}</span>
                            )}
                        />
                        <GridColumn field="DisparityPrice" title="Phụ thu BHYT" width={100}
                            render={({ row }) => (
                                <span>{priceSplitter(row.DisparityPrice)}</span>
                            )}
                        />
                        <GridColumn field="SalesPrice" title="Giá dịch vụ" width={100}
                            render={({ row }) => (
                                <span>{priceSplitter(row.SalesPrice)}</span>
                            )}
                        />
                        <GridColumn field="SalesPrice_Retail" title="Giá bán sĩ" width={100}

                            render={({ row }) => (
                                <span>{priceSplitter(row.SalesPrice_Retail)}</span>
                            )}
                        />
                        <GridColumn field="IsBHYT" title="DM BHYT" width={100}
                            filter={() => (
                                <ComboBox
                                    data={status}
                                    editable={false}
                                />
                            )}

                            render={({ row }) => (
                                <div>
                                    {row?.IsBHYT === true ?
                                        <Checkbox onChange={(e) => onChange_isBHYT(e, row)} checked={true} />
                                        :
                                        <Checkbox onChange={(e) => onChange_isBHYT(e, row)} checked={false} />
                                    }
                                </div>

                            )}

                        />
                        <GridColumn field="IsService" title="DM Dịch vụ" width={100}
                            filter={() => (
                                <ComboBox
                                    data={status}
                                    editable={false}
                                />
                            )}
                            render={({ row }) => (
                                <div>
                                    {row?.IsService === true ?
                                        <Checkbox onChange={(e) => onChange_IsService(e, row)} checked={true} />
                                        :
                                        <Checkbox onChange={(e) => onChange_IsService(e, row)} checked={false} />
                                    }
                                </div>

                            )}
                        />
                        <GridColumn field="Converted_Medi" title="Q.Đổi SL kê toa" width={100}
                            filter={() => (
                                <ComboBox
                                    data={status}
                                    editable={false}
                                />
                            )}
                            render={({ row }) => (
                                <div>
                                    {row?.Converted_Medi === true ?
                                        <Checkbox onChange={(e) => onChange_Converted_Medi(e, row)} checked={true} />
                                        :
                                        <Checkbox onChange={(e) => onChange_Converted_Medi(e, row)} checked={false} />
                                    }
                                </div>

                            )}
                        />
                        <GridColumn field="ProducerID" title="Hãng sản xuất" width={300}
                            render={({ row }) => {
                                const producer = listHangSanXuat.find(item => item.ProducerID === row.ProducerID);
                                return producer ? producer.ProducerName : '';
                            }}
                        />
                        <GridColumn field="CountryID" title="Nước sản xuất" width={300}
                            render={({ row }) => {
                                const producer = listNuocSanXuat.find(item => item.CountryID === row.CountryID);
                                return producer ? producer.CountryName : '';
                            }}
                        />
                        <GridColumn field="SafelyQuantity" title="Hạn mức tồn" width={100} />
                        <GridColumn field="Hide" title="Ẩn/Hiện" width={100}
                            filter={() => (
                                <ComboBox
                                    data={status}
                                    editable={false}
                                />
                            )}
                            render={({ row }) => (
                                <div>
                                    {row?.Hide === true ?
                                        <Checkbox onChange={(e) => onChange_AnHien(e, row)} checked={true} />
                                        :
                                        <Checkbox onChange={(e) => onChange_AnHien(e, row)} checked={false} />
                                    }
                                </div>

                            )}
                        />
                        <GridColumn field="ItemsBHYT_ID" title="Mã thuốc AX" width={100} />
                        <GridColumn field="Note" title="Ghi chú" width={200} />
                        <GridColumn field="GroupPrinterName" title="Nhóm in" width={250} />

                        <GridColumn field="RepositoryCode" title="Kho" width={300}
                            render={({ row }) => (
                                <div className="ListNhomKho">
                                    {GroupKho(row.RepositoryCode)}
                                </div>

                            )}
                        />
                        <GridColumn field="IsKeDon_BS" title="KĐ bác sĩ" width={100}
                            filter={() => (
                                <ComboBox
                                    data={status}
                                    editable={false}
                                />
                            )}
                            render={({ row }) => (
                                <div>
                                    {row?.IsKeDon_BS === true ?
                                        <Checkbox onChange={(e) => onChange_KeDonBS(e, row)} checked={true} />
                                        :
                                        <Checkbox onChange={(e) => onChange_KeDonBS(e, row)} checked={false} />
                                    }
                                </div>

                            )}
                        />
                        <GridColumn title="Thao tác" width={90} filterable={false}
                            render={({ row }) => (
                                <div className="d-flex justify-content-center" style={{ width: "80px" }}>
                                    <Dropdown
                                        menu={{
                                            items,
                                            onClick: (e) => handleThaoTac(e, row),
                                        }}
                                        placement="left"
                                        arrow={{
                                            pointAtCenter: true,
                                        }}
                                    >
                                        <Button
                                            className="bg-light vienphi-danhmuc-icon-modify"
                                            onClick={() => {
                                                setActiveModify(!activeModify);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                style={{ fontSize: "10px" }}
                                                className="text-dark"
                                            />
                                        </Button>
                                    </Dropdown>
                                </div>

                            )}
                        />

                    </DataGrid>

                    {/*  <Table
                        dataSource={listThuocVTYT}
                        columns={columns}
                        scroll={{ x: true, y: "100vh" }}
                        size="small"
                        pagination={false}
                        loading={loading}
                        bordered={true}
                        locale={{
                            emptyText: 'Dữ liệu không tồn tại', filterConfirm: 'Đồng ý',
                            filterReset: 'Huỷ',
                        }}
                        rowKey={(record) => record?.ItemID}
                        onChange={(pagination, filters, sorter) => {}}
                    />*/}
                </div>
            </div>

        );
    } else { return <LoadPage /> }
}

export default ThuocVTYT;
