import { Modal, Button, Form, Input, Select, InputNumber } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../danhmuc.scss";
import Swal from "sweetalert2";
import loginAPI from "../../../../../services/loginApi";
import { errDialog, errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import { setDataNhomVienPhi } from "../../../../../slices/dataVienPhi";
import phannhomAPI from "../../../../../services/vienphi/danhmuc/phannhomAPI";

function DetailNhomVienPhiPK(props) {
    const { open, setOpen, handleUpdate, listServiceModule, listServiceGroupBHYT, reLoad } = props;
    const { nhomvienphi } = useSelector((state) => state.dataVienPhi);
    const [form] = Form.useForm();
    const [userlogin, setUserLogin] = useState();
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleCreate = async (obj) => {
        const data = await phannhomAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);
        }
        else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }
    };

    const handleSubmit = async (e) => {
        if (e?.ServiceGroupCode === undefined || e?.ServiceGroupName === undefined || e?.ServiceModuleCode === undefined || e?.ServiceGroupBHYTID === undefined) {
            errDialog();
        } else {
            Swal.fire({
                title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
                confirmButtonText: "Đồng ý",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                customClass: {
                    title: "fs-5 text-dark",
                    confirmButton: "shadow-none",
                    cancelButton: "shadow-none",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    if (nhomvienphi?.ServiceGroupID) {
                        var upVienPhi = {
                            ServiceGroupID: e?.ServiceGroupID,
                            ServiceGroupCode: e?.ServiceGroupCode,
                            ServiceGroupName: e?.ServiceGroupName,
                            ServiceModuleCode: e?.ServiceModuleCode,
                            STT: e?.STT,
                            ServiceGroupBHYTID: e?.ServiceGroupBHYTID,
                            EmployeeID: userlogin.RowID

                        }
                        handleUpdate(upVienPhi);
                        dispatch(setDataNhomVienPhi());
                        form.resetFields();
                        setOpen(false);
                    } else {
                        var NhomVienPhi = {
                            ServiceGroupCode: e.ServiceGroupCode,
                            ServiceGroupName: e.ServiceGroupName,
                            ServiceModuleCode: e.ServiceModuleCode,
                            STT: e?.STT === undefined ? 1 : e?.STT,
                            ServiceGroupBHYTID: e.ServiceGroupBHYTID,
                            EmployeeID: userlogin.RowID

                        }
                        handleCreate(NhomVienPhi);

                    }
                }
            });
        }

    }
    useEffect(() => {
        getAllUser();
        form.setFieldsValue({
            ServiceGroupID: nhomvienphi?.ServiceGroupID,
            ServiceGroupCode: nhomvienphi?.ServiceGroupCode,
            ServiceGroupName: nhomvienphi?.ServiceGroupName,
            ServiceModuleCode: nhomvienphi?.ServiceModuleCode,

            STT: nhomvienphi?.STT,
            ServiceGroupBHYTID: nhomvienphi?.ServiceGroupBHYTID,
            EmployeeID: nhomvienphi?.EmployeeID

        });
    }, [nhomvienphi, form]);
    return (
        <>
            <Modal className="DetalPhanNhomDuoc"
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo nhóm viện phí phòng khám
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={1000}
                closable={false}
                forceRender
                maskClosable={false}

            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form}
                        initialValues={{ STT: '1' }}
                    >
                        <div >
                            <div className="row dmdot">
                                <Form.Item
                                    label={
                                        <div className="form-input-label input-label-khaibaouser ">
                                            ID:
                                        </div>
                                    }
                                    className="col-md-6 ServiceGroupID"
                                    name="ServiceGroupID"
                                    hidden={true}

                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <div className="form-input-label input-label-khaibaouser ">
                                            Tên nhóm:
                                        </div>
                                    }
                                    className="col-md-6"
                                    name="ServiceGroupName"

                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <div className="form-input-label input-label-khaibaouser ">
                                            Mã nhóm:
                                        </div>
                                    }
                                    className="col-md-3"
                                    name="ServiceGroupCode"

                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <div className="form-input-label input-label-khaibaouser ">
                                            STT:
                                        </div>
                                    }
                                    className="col-md-3"
                                    name="STT"

                                >
                                    <InputNumber className="form-control inputdm" />
                                </Form.Item>


                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={
                                        <div className="form-input-label input-label-khaibaouser ">
                                            Phân hệ:
                                        </div>
                                    }
                                    className="col-md-6"
                                    name="ServiceModuleCode"

                                >
                                    <Select
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={listServiceModule.map((item) => ({
                                            value: item.ServiceModuleCode,
                                            label: item.ServiceModuleName
                                        }))}

                                    />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <div className="form-input-label input-label-khaibaouser ">
                                            Nhóm BHYT:
                                        </div>
                                    }
                                    className="col-md-6"
                                    name="ServiceGroupBHYTID"

                                >
                                    <Select
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={listServiceGroupBHYT.map((item) => ({
                                            value: item.ServiceGroupBHYTID,
                                            label: item.ServiceGroupBHYTName
                                        }))}

                                    />

                                </Form.Item>
                            </div>
                        </div><hr />
                        <Form.Item className="text-center">
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                Thoát <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" />
                            </Button>
                        </Form.Item>

                    </Form>


                </div>
            </Modal>
        </>
    );
}

export default DetailNhomVienPhiPK;
