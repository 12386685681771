import { Modal, Tabs, Button, Form, Input, Checkbox, Table, Dropdown, Radio, Select } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendarCheck,
    faFileExcel,
    faPenToSquare,
    faReply,
    faSave,
    faFile,
    faAngleRight,
    faAngleLeft,
    faEdit,
    faTrashCan,
    faEye,
    faArrowsRotate,
    faBroom,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import "./hosobenhanyhct.scss";
function FormTongKetHoSo() {
    const onDropdownChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };
    const list = [
        {
            value: '1',
            label: 'Jack',
        },
        {
            value: '2',
            label: 'Lưu Văn Tèo',
        },
        {
            value: '3',
            label: 'Nguyễn Việt Hùng',
        },
    ];
    const dataSource = [
        {
            key: '1',
            name: 'Mike',
            age: 32,
            address: '10 Downing Street',
        },
        {
            key: '2',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },
    ];

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tên',
            dataIndex: 'age',
            key: 'age',
        }
    ];
    return (
        <>
            <div className="FromTKHSYHCT">
                <div className="w-40">
                    <div className="row">
                        <Form.Item
                            label={<div className="form-input-label lablename-input">Tiên lượng:</div>}
                            className="col-md-12 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Input className="form-control" />
                        </Form.Item>
                    </div>
                    <div className="title">TỔNG KẾT BỆNH ÁN</div>
                    <div className="item-pp">
                        <div className="title-2">1. Quá trình bệnh lý và diễn biến lâm sàng:</div>
                        <div className="row texarea">
                            <Form.Item
                                label={''}
                                className="col-md-12 m-0"
                                name="ServiceGroup_ID"
                            >
                                <Input.TextArea rows={10} className="form-control" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="item-pp">
                        <div className="title-2">2. Tóm tắt kết quả CLS có giá trị chẩn đoán:</div>
                        <div className="row texarea">
                            <Form.Item
                                label={''}
                                className="col-md-12 m-0"
                                name="ServiceGroup_ID"
                            >
                                <Input.TextArea rows={12} className="form-control" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="title-2">3. Chẩn đoán ra viện:</div>
                    <div className="row formrow">
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs">+ ICD10 :</div>}
                            className="col-md-12 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Select
                                className="form-control input-select"
                                showSearch
                                optionFilterProp="children"
                                placeholder={"select a country"}
                                onChange={onDropdownChange}
                                filterOption={(input, option) => {
                                    return (
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );

                                }}
                            >
                                {list.map((item) => (
                                    <Select.Option
                                        title={item.label}
                                        key={item.label}
                                        id={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="row formrow">
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs">+ ICD10 KT :</div>}
                            className="col-md-12 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Select
                                className="form-control input-select"
                                showSearch
                                optionFilterProp="children"
                                placeholder={"select a country"}
                                onChange={onDropdownChange}
                                filterOption={(input, option) => {
                                    return (
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );

                                }}
                            >
                                {list.map((item) => (
                                    <Select.Option
                                        title={item.label}
                                        key={item.label}
                                        id={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="tableICDKT">
                        <Table dataSource={dataSource} columns={columns} pagination={false} />
                    </div>
                    <div className="row">
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs-c">Thủ thuật:</div>}
                            className="col-md-4 m-0"
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs-c">Tai biến:</div>}
                            className="col-md-4 m-0"
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs-c">Biến chứng:</div>}
                            className="col-md-4 m-0"
                        >
                            <Checkbox />
                        </Form.Item>
                    </div>


                </div>
                <div className="w-60">
                    <div className="row formrow1">
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs">YHCT :</div>}
                            className="col-md-12 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Select
                                className="form-control input-select"
                                showSearch
                                optionFilterProp="children"
                                placeholder={"select a country"}
                                onChange={onDropdownChange}
                                filterOption={(input, option) => {
                                    return (
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );

                                }}
                            >
                                {list.map((item) => (
                                    <Select.Option
                                        title={item.label}
                                        key={item.label}
                                        id={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="row iteminput">
                        <Form.Item
                            label={<div className="form-input-label lablename-input">CĐ Ph.Biệt:</div>}
                            className="col-md-12 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Input className="form-control" />
                        </Form.Item>
                    </div>
                    <div className="row">
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs">Thủ thuật:</div>}
                            className="col-md-4 m-0"
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs">Tai biến:</div>}
                            className="col-md-4 m-0"
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs">Biến chứng:</div>}
                            className="col-md-4 m-0"
                        >
                            <Checkbox />
                        </Form.Item>
                    </div>
                    <div className="item-pp">
                        <div className="title-2">4. Phương pháp điều trị:</div>
                        <div className="row texarea">
                            <Form.Item
                                label={''}
                                className="col-md-12 m-0"
                                name="ServiceGroup_ID"
                            >
                                <Input.TextArea rows={10} className="form-control" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row formrow1">
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs">5. TT ra viện:</div>}
                            className="col-md-6 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Select
                                className="form-control input-select"
                                showSearch
                                optionFilterProp="children"
                                placeholder={"select a country"}
                                onChange={onDropdownChange}
                                filterOption={(input, option) => {
                                    return (
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );

                                }}
                            >
                                {list.map((item) => (
                                    <Select.Option
                                        title={item.label}
                                        key={item.label}
                                        id={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs">KQ điều trị:</div>}
                            className="col-md-6 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Select
                                className="form-control input-select"
                                showSearch
                                optionFilterProp="children"
                                placeholder={"select a country"}
                                onChange={onDropdownChange}
                                filterOption={(input, option) => {
                                    return (
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );

                                }}
                            >
                                {list.map((item) => (
                                    <Select.Option
                                        title={item.label}
                                        key={item.label}
                                        id={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="item-pp tinhtrangnbrv">
                        <div className="title-2">6. Tình trạng người bệnh ra viện:</div>
                        <div className="row texarea">
                            <Form.Item
                                label={''}
                                className="col-md-12 m-0"
                                name="ServiceGroup_ID"
                            >
                                <Input.TextArea rows={1} className="form-control" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="item-pp tinhtrangnbrv">
                        <div className="title-2">7. Hướng điều trị và các chế độ tiếp theo:</div>
                        <div className="row texarea">
                            <Form.Item
                                label={''}
                                className="col-md-12 m-0"
                                name="ServiceGroup_ID"
                            >
                                <Input.TextArea rows={10} className="form-control" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row formrow1">
                        <Form.Item
                            label={<div className="form-input-label lablename-tkhs">BS KL HS:</div>}
                            className="col-md-12 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Select
                                className="form-control input-select"
                                showSearch
                                optionFilterProp="children"
                                placeholder={"select a country"}
                                onChange={onDropdownChange}
                                filterOption={(input, option) => {
                                    return (
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );

                                }}
                            >
                                {list.map((item) => (
                                    <Select.Option
                                        title={item.label}
                                        key={item.label}
                                        id={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="row">
                        <Form.Item
                            label={<div className="form-input-label lablename-input">Ngày KB:</div>}
                            className="col-md-6 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Input className="form-control" />
                        </Form.Item>
                        <Form.Item
                            label={<div className="form-input-label lablename-input">Ngày/giờ ra:</div>}
                            className="col-md-6 m-0"
                            name="ServiceGroup_ID"
                        >
                            <Input className="form-control" />
                        </Form.Item>
                    </div>


                </div>
            </div>
        </>
    );
}

export default FormTongKetHoSo;
