import React, { forwardRef,useEffect,useRef,useState } from "react";
import JsBarcode from "jsbarcode";
import "./printtiepnhan.scss";
import tiepnhanAPI from "../../../services/tiepnhan/tiepnhanAPI";

const PrintSTT = forwardRef((props, ref) => {
    const { InSTTID } = props;
    const [ListinSTT, setListInSTT] = useState([]);

    const barcodeRef = useRef();
    useEffect(() => {
        const getAllInSTT = async () => {
            try {
                const data = await tiepnhanAPI.getInSTT({'PatientReceiveID':InSTTID });
                setListInSTT(data.data);
            } catch (err) { }
        };
        getAllInSTT();
        
    }, [ref,InSTTID]);
    return (
        <div ref={ref} className="in-stt">
            <div className="pagestt">
                <div className="name">{ListinSTT.PatientName}</div>
                <div className="phong">{ListinSTT.DepartmentName}</div>
                <div className="phong">STT KHÁM BỆNH</div>
                <div className="stt"> {ListinSTT.STT}</div>
            </div>
      </div>
    );
})

export default PrintSTT;
