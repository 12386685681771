import { createSlice } from "@reduxjs/toolkit";

export const dataDatHenSlice = createSlice({
    name: "dataDatHen",
    initialState: {
        dathen:null,
    },
    reducers: {
        setDataDatHen: (state, action) => {
            state.dathen = action.payload;
        },
    },
});
export const {
    setDataDatHen
} = dataDatHenSlice.actions;
export default dataDatHenSlice.reducer;