import React, { forwardRef,useEffect,useRef } from "react";
import JsBarcode from "jsbarcode";
import "./printtiepnhan.scss";

const PrintDanNhan = forwardRef((props, ref) => {
    const { InNhanTem,PatientGender } = props;
    const barcodeRef = useRef();
    useEffect(() => {
        JsBarcode(barcodeRef.current, InNhanTem.barcode, {
            format: "CODE128",
            displayValue: true,
            fontSize: 10,
            lineColor: "#000",
            width: 1,
            height: 20,
          });
    }, [ref,InNhanTem,PatientGender]);

    const category = PatientGender.find((item) => item.PatientGenderID === InNhanTem.PatientGenderID);
 

    return (
        <div ref={ref} className="in-nhan-dan">
        <h3 className="label-title">Họ tên: <span>{InNhanTem.PatientName}</span></h3>
        <p className="label-text">Giới tính: {category ? category.PatientGenderName : null} - Ngày sinh: {InNhanTem.PatientBirthyear}</p>
        <p className="label-text">Điện thoại: {InNhanTem.PatientMobile}</p>
        <p className="label-text">Địa chỉ: {InNhanTem.Address_Full}</p>
        <canvas ref={barcodeRef} className="barcode" ></canvas>
      </div>
    );
})

export default PrintDanNhan;
