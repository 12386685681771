import {  Button, Dropdown } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { DataGrid, GridColumn, ComboBox } from 'rc-easyui';
import { useDispatch } from "react-redux";
import {
    faEdit,
    faTrashCan,
    faPenToSquare,
    faPlus,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import DetailNhomThuoc from "../Detail";
import { errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import nhomthuocAPI from "../../../../../services/duoc/danhmuc/nhomthuocAPI";
import { setDataNhomThuoc } from "../../../../../slices/dataDMDuoc";
import loginAPI from "../../../../../services/loginApi";
import LoadPage from "../../../../../loadpage";
function PhanNhom() {
    const [loadpage, setLoadPage] = useState(false);
    const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);

    const handleDataCreate = () => {
        setOpen(true);
    };
    const [listNhomThuoc, setListNhomThuoc] = useState([]);
    const [listServiceModule, setlistServiceModule] = useState([]);
    const [listServiceGroupBHYT, setlistServiceGroupBHYT] = useState([]);
    const [filters, setFilters] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [userlogin, setUserLogin] = useState();
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };

    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataNhomThuoc(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {

                    handleDelete(record.ServiceGroupID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };
    const getAllNhomThuoc = async () => {
        try {
            const data = await nhomthuocAPI.getAll('ServiceGroupID');
            setListNhomThuoc(data.data.recordset);
            setFilteredData(data.data.recordset);
        } catch (err) { }
    };
    const getAllServiceModule = async () => {
        try {
            const data = await nhomthuocAPI.getServiceModule('ServiceModuleCode');
            setlistServiceModule(data.data);
        } catch (err) {}
    };

    const getAllServiceGroupBHYT = async () => {
        try {
            const data = await nhomthuocAPI.getServiceGroupBHYT('ServiceGroupBHYTID');
            setlistServiceGroupBHYT(data.data);
        } catch (err) {}
    };


    const handleUpdate = async (obj) => {
        const data = await nhomthuocAPI.update(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllNhomThuoc();
        }
        else if (data.data.Code === 404) {
            infoRes(data.data.Content);
        }
        else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (ServiceGroupID, EmployeeID) => {
        const data = await nhomthuocAPI.delete(ServiceGroupID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllNhomThuoc();
        } else {
            errorInfo(data.data.Content);
        }
    };

    useEffect(() => {
        document.title = 'Nhóm thuốc - EmedLink';
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        const getAllNhomThuoc = async () => {
            const data = await nhomthuocAPI.getAll('ServiceGroupID');
            setListNhomThuoc(data.data.recordset);
            setFilteredData(data.data.recordset);
        };
        getAllNhomThuoc();
        getAllServiceModule();
        getAllServiceGroupBHYT();
        getAllUser();
    }, []);

    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const renderComboBox = ({
        data,
        value,
        onChange,
        style = { width: 150 },
        editable = false,
    }) => {
        const enhancedData = [{ value: null, text: "All" }, ...data];

        return (
            <ComboBox
                data={enhancedData}
                value={value}
                onChange={onChange}
                style={style}
                editable={editable}
            />
        );
    };
   
    if (loadpage === true) {
        return (
            <>
                <div className="text-muted">
                    <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                        <div className="d-flex align-items-center ">
                            <div className="vienphi-danhmuc-title mx-2"><FontAwesomeIcon icon={faBars} /> Phân nhóm</div>
                        </div>
                        <div className="text-end">
                            <Button className="form-btn bg" onClick={handleDataCreate}>
                                <FontAwesomeIcon icon={faPlus} className="mx-1" />
                                Thêm mới
                            </Button>
                        </div>
                    </div>
                    <DetailNhomThuoc
                        open={open} setOpen={setOpen}
                        listServiceModule={listServiceModule}
                        listServiceGroupBHYT={listServiceGroupBHYT}
                        reLoad={getAllNhomThuoc}
                        handleUpdate={handleUpdate}
                        key="DetailNhomThuoc"
                    />
                    <hr className="w-100 my-1" />
                    <div className="m-1 danhmuc">
                    <DataGrid
                            data={filteredData}
                            style={{ height: '90vh' }}
                            filterable
                            columnResizing
                        >
                            <GridColumn field="ServiceGroupCode" title="Mã nhóm" filterable resizable width={100} />
                            <GridColumn field="ServiceGroupName" title="Tên nhóm" filterable width={250} resizable />

                            <GridColumn field="ServiceModuleCode" title="Phân hệ" filterable width={250} resizable
                                filter={() =>
                                    renderComboBox({
                                        data: listServiceModule.map((item) => ({
                                            value: item.ServiceModuleCode,
                                            text: item.ServiceModuleName,
                                        })),
                                        value: filters.ServiceModuleCode || '',
                                        onChange: (value) => {
                                            const filterValue = value || null;
                                            const filteredData = listNhomThuoc.filter((row) => {

                                                return filterValue ? row.ServiceModuleCode === filterValue : true;
                                            });
                                            setFilteredData(filteredData);
                                            setFilters((prev) => ({ ...prev, ServiceModuleCode: filterValue }));
                                        },
                                        onReset: () => {
                                            setFilteredData(listNhomThuoc);
                                            setFilters((prev) => ({ ...prev, ServiceModuleCode: '' }));
                                        },
                                        style: { width: '100%' },
                                    })
                                }

                                render={({ row }) => {
                                    const phanhe = listServiceModule.find((item) => item.ServiceModuleCode === row.ServiceModuleCode);
                                    return phanhe ? phanhe.ServiceModuleName : null;
                                }}

                            />
                            <GridColumn field="ServiceGroupBHYTID" title="Nhóm BHYT" filterable width={250} resizable
                                filter={() =>
                                    renderComboBox({
                                        data: listServiceGroupBHYT.map((item) => ({
                                            value: item.ServiceGroupBHYTID,
                                            text: item.ServiceGroupBHYTName,
                                        })),
                                        value: filters.ServiceGroupBHYTID || '',
                                        onChange: (value) => {
                                            const filterValue = value || null;
                                            const filteredData = listNhomThuoc.filter((row) => {

                                                return filterValue ? row.ServiceGroupBHYTID === filterValue : true;
                                            });
                                            setFilteredData(filteredData);
                                            setFilters((prev) => ({ ...prev, ServiceGroupBHYTID: filterValue }));
                                        },
                                        onReset: () => {
                                            setFilteredData(listNhomThuoc);
                                            setFilters((prev) => ({ ...prev, ServiceGroupBHYTID: '' }));
                                        },
                                        style: { width: '100%' },
                                    })
                                }

                                render={({ row }) => {
                                    const nhombhyt = listServiceGroupBHYT.find((item) => item.ServiceGroupBHYTID === row.ServiceGroupBHYTID);
                                    return nhombhyt ? nhombhyt.ServiceGroupBHYTName : null;
                                }}
                            />
                            <GridColumn
                                field="actions"
                                title="Thao tác"
                                align="center"
                                width={150}
                                resizable
                                filterable={false}
                                render={({ row }) => (
                                    <div className="d-flex justify-content-center align-center">
                                        <Dropdown
                                            menu={{
                                                items,
                                                onClick: (e) => handleThaoTac(e, row),
                                            }}
                                            placement="left"
                                            arrow={{
                                                pointAtCenter: true,
                                            }}
                                        >
                                            <Button
                                                className="bg-light vienphi-danhmuc-icon-modify"
                                                onClick={() => {
                                                    setActiveModify(!activeModify);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    style={{ fontSize: "10px" }}
                                                    className="text-dark"
                                                />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </DataGrid>
                    </div>
                </div>

            </>
        );
    } else { return <LoadPage /> }

}

export default PhanNhom;