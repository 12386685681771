import { Button, Dropdown, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { DataGrid, GridColumn, ComboBox } from 'rc-easyui';
import { useDispatch } from "react-redux";
import {
    faEdit,
    faTrashCan,
    faPenToSquare,
    faPlus,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { errorInfo, successInfo } from "../../../../../components/Dialog/Dialog";
import thuocthauBHYTAPI from "../../../../../services/duoc/danhmuc/thuocthauBHYTAPI";
import { setDataThuocThauBHYT } from "../../../../../slices/dataDMDuoc";
import loginAPI from "../../../../../services/loginApi";
import LoadPage from "../../../../../loadpage";
import "../Detail/thuocthauBHYT.scss";
import DetailThuocThauBHYT from "../Detail";
function DanhMucThuocThauBHYT() {
    const [loadpage, setLoadPage] = useState(false);
    const [open, setOpen] = useState(false);
    const [HideCheck, setHideCheck] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const [listThuocThauBHYT, setlistThuocThauBHYT] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const dispatch = useDispatch();
    const [userlogin, setUserLogin] = useState();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataThuocThauBHYT(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {

                    handleDelete(record.RowID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };


    const getAlThuocThauBHYT = async () => {
        try {
            const data = await thuocthauBHYTAPI.getAll({ 'RowID': 0 });
            setlistThuocThauBHYT(data.data);
            setFilteredData(data.data)

        } catch (err) { }
    };

    const handleUpdate = async (obj) => {
        const data = await thuocthauBHYTAPI.update(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAlThuocThauBHYT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (RowID, EmployeeID) => {
        const data = await thuocthauBHYTAPI.delete(RowID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAlThuocThauBHYT();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleToggleHideCheckbox = async (record) => {
        const newHideValue = record.Hide === false ? true : false;
        const updatedRecord = { ...record, Hide: newHideValue, EmployeeID: record.EmployeeID || userlogin.RowID };
        try {
            await handleUpdate(updatedRecord);
            successInfo("Cập nhật trạng thái thành công!");
            getAlThuocThauBHYT();
        } catch (err) {
            errorInfo("Cập nhật trạng thái thất bại!");

        }
    };
    const handleCheckboxChange = async (row, field) => {
        const updatedValue = !row[field];

        setFilteredData((prevData) =>
            prevData.map((item) =>
                item.RowID === row.RowID
                    ? { ...item, [field]: updatedValue }
                    : item
            )
        );

        try {
            await thuocthauBHYTAPI.update({ ...row, [field]: updatedValue });
            getAlThuocThauBHYT();
            successInfo("Cập nhật trạng thái thành công!");
        } catch (error) {
            errorInfo("Cập nhật trạng thái thất bại!");
            console.error(error);
        }
    };

    useEffect(() => {
        document.title = 'Thuốc thầu BHYT - EmedLink';
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        const getAlThuocThauBHYT = async () => {
            const data = await thuocthauBHYTAPI.getAll({ 'RowID': 0 });
            setlistThuocThauBHYT(data.data);
            setFilteredData(data.data);
        };
        getAlThuocThauBHYT();
        getAllUser();

    }, []);
    const status = [
        { value: null, text: "All" },
        { value: "true", text: "1" },
        { value: "false", text: "0" }
    ]
    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];

    if (loadpage === true) {
        return (
            <>
                <div className="text-muted thuoc-thaubhyt">
                    <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                        <div className="d-flex align-items-center ">
                            <div className="vienphi-danhmuc-title mx-2"><FontAwesomeIcon icon={faBars} /> Danh mục thuốc thầu BHYT</div>
                        </div>
                        <div className="text-end">
                            <div className="nhomin-act-check">
                                <div className="col-n"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                                <div className="col-n">
                                    <Button className="form-btn bg" onClick={handleDataCreate}>
                                        <FontAwesomeIcon icon={faPlus} className="mx-1" />
                                        Thêm mới
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DetailThuocThauBHYT
                        open={open} setOpen={setOpen}
                        reLoad={getAlThuocThauBHYT}
                        handleUpdate={handleUpdate}
                        key="DetailThuocThauBHYT"
                    />
                    <hr className="w-100 my-1" />
                    <div className="m-1 danhmuc">
                        <DataGrid
                            data={filteredData}
                            style={{ height: 600 }}
                            filterable
                            columnResizing
                        >
                            <GridColumn field="TenDonViThau_PK" title="Tên đơn vị đấu thầu" width={250} resizable />
                            <GridColumn field="MaDauThau_PK" title="Mã theo kết qủa đấu thầu" align="center" width={200} resizable />
                            <GridColumn field="ItemCode_PK" title="Mã HH" align="center" width={200} resizable />
                            <GridColumn field="ItemName_BYT_XML" title="Tên thuốc _AX" width={200} resizable />
                            <GridColumn field="ActiceName_BYT_XML" title="Hoạt chất AX" width={200} resizable />
                            <GridColumn field="ActiceCode" title="Mã hoạt chất" align="center" width={200} resizable />
                            <GridColumn field="UsageCode_BYT_XML" title="Mã đường dùng AX" align="center" width={200} resizable />
                            <GridColumn field="ItemContent_BYT_XML" title="Hàm lượng" width={200} resizable />
                            <GridColumn field="SODKGP" title="Số đăng ký" align="center" width={200} resizable />
                            <GridColumn field="BHYTPrice" title="Giá BHYT theo kết quả đấu thầu" align="center" width={200} resizable
                                render={({ row }) => (
                                    <div>
                                        {row.BHYTPrice !== undefined
                                            ? `${row.BHYTPrice.toLocaleString('vi-VN')}`
                                            : "0"}
                                    </div>
                                )}
                            />
                            <GridColumn field="MaBYT_PK" title="Mã BHYT AX" align="center" width={200} resizable />

                            <GridColumn field="Rate_BHYT" title="% hưởng BHYT" align="center" width={200} resizable
                                render={({ row }) => (
                                    <div>
                                        {row.Rate_BHYT !== undefined ? `${row.Rate_BHYT}%` : "0%"}
                                    </div>
                                )}
                            />
                            <GridColumn field="PheDuyet_BHXH" title="Số quyết định trúng thầu" align="center" width={200} resizable />
                            <GridColumn field="MA_GOITHAU" title="Gói thầu" align="center" width={200} resizable />
                            <GridColumn field="Nhom_TCKT_TCCN" title="Nhóm thầu" align="center" width={200} resizable />
                            <GridColumn field="TTThau_BH" title="Thông tin thầu" align="center" width={200} resizable />
                            <GridColumn field="SO_CVGUI_BHXH" title="Số công văn gửi BHXH" align="center" width={200} resizable />
                            <GridColumn field="SoLuong" title="Số lượng" align="center" width={200} resizable />
                            <GridColumn field="MaDonViThau" title="Mã đơn vị thầu" align="center" width={200} resizable />
                            <GridColumn field="MaNhom_BH" title="Mã nhóm bảo hiểm" align="center" width={200} resizable />
                            <GridColumn field="MaNhom_BC" title="Mã nhóm báo cáo" align="center" width={200} resizable />
                            <GridColumn field="Nuoc_SX" title="Nước sản xuất" width={200} resizable />
                            <GridColumn field="Hang_SX" title="Hãng sản xuất" width={200} resizable />

                            <GridColumn field="Ngay_Hieu_Luc" title="Ngày hiệu lực" align="center" width={150} resizable />

                            <GridColumn field="NgayNop_M16" title="Ngày nộp mẫu M16" align="center" width={200} resizable />
                            <GridColumn field="NgayDuyet_BHXH" title="Ngày duyệt BHXH" align="center" width={200} resizable />

                            <GridColumn field="Hide_BV" title="Ẩn/Hiện BV" align="center" width={100} resizable
                               filter={() => (
                                <ComboBox
                                    data={status}
                                    editable={false}
                                />
                            )}
                               render={({ row }) => <Checkbox checked={row.Hide_BV}
                                    onChange={() => handleCheckboxChange(row, "Hide_BV")}
                                />}
                            />

                            <GridColumn field="is_Map" title="Map thuốc thầu qua DM thuốc" align="center" width={120} resizable
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => <Checkbox checked={row.is_Map}
                                    onChange={() => handleCheckboxChange(row, "is_Map")}
                                />}
                            />
                            <GridColumn field="Du_Phong" title="Dự phòng" width={200} resizable />
                            <GridColumn
                                field="Hide"
                                title="Ẩn/Hiện"
                                align="center"
                                width={100}
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => (
                                    <Checkbox
                                        checked={row.Hide === true}
                                        onChange={() => handleToggleHideCheckbox(row)}
                                    />
                                )}
                            />
                            <GridColumn
                                field="actions"
                                title="Thao tác"
                                align="center"
                                width={100}
                                filterable={false}
                                render={({ row }) => (
                                    <div className="d-flex justify-content-center align-center">
                                        <Dropdown
                                            menu={{
                                                items,
                                                onClick: (e) => handleThaoTac(e, row),
                                            }}
                                            placement="left"
                                            arrow={{
                                                pointAtCenter: true,
                                            }}
                                        >
                                            <Button
                                                className="bg-light vienphi-danhmuc-icon-modify"
                                                onClick={() => {
                                                    setActiveModify(!activeModify);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    style={{ fontSize: "10px" }}
                                                    className="text-dark"
                                                />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </DataGrid>
                    </div>
                </div>

            </>
        );
    } else { return <LoadPage /> }
}

export default DanhMucThuocThauBHYT;
