
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import LoadPage from "../../loadpage";
import "./notfound.scss";

const ProtectedRoute = ({ children, allowedRoles = [] }) => {
    const { user } = useAuth();
    useEffect(() => {
    }, [user]);
     if (user === null) {
        return <div className="loading"><LoadPage/></div>;
    }
    if (!user || user.length === 0) {
        return <Navigate to="/not-found" />;
    }
    const userRolesArray = user.map(item => Number(item.MenuID));
    const allowedRolesn = allowedRoles.map(role => Number(role)); 
    const hasAccess = userRolesArray.some(role => allowedRolesn.includes(role));


    if (!hasAccess) {
        return <Navigate to="/not-found" replace />;
      }
      
 
   
    return children;
};

export default ProtectedRoute;
