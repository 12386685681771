import { Modal, Tabs, Button, Form, Input, Checkbox, Table, Dropdown, Radio, Select } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendarCheck,
    faFileExcel,
    faPenToSquare,
    faReply,
    faSave,
    faFile,
    faAngleRight,
    faAngleLeft,
    faEdit,
    faTrashCan,
    faEye,
    faArrowsRotate,
    faBroom,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import "./hosobenhanyhct.scss";
function LichSuDonThuoc() {
    const onDropdownChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };
    const list = [
        {
            value: '1',
            label: 'Jack',
        },
        {
            value: '2',
            label: 'Lưu Văn Tèo',
        },
        {
            value: '3',
            label: 'Nguyễn Việt Hùng',
        },
    ];
    const dataSource = [
        {
            key: '1',
            name: 'Mike',
            age: 32,
            address: '10 Downing Street',
        },
        {
            key: '2',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },
    ];

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tên',
            dataIndex: 'age',
            key: 'age',
        }
    ];
    const tabkhambenh = [
        {
            key: "DVKT",
            label: "Dịch vụ kỹ thuật",
            children: (
                <div >
                    fgdg
                </div>
            ),
        },
        {
            key: "DT",
            label: "Đơn thuốc",
            children: (
                <div >
                    gggg
                </div>
            ),
        },
        {
            key: "DTDY",
            label: "Đơn thuốc đông y",
            children: (
                <div >
                    kkk
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="FormLichSuCLSThuoc">
                hh
            </div>
        </>
    );
}

export default LichSuDonThuoc;
