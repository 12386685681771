import { Tabs, Form, Input, Button, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
    faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import logo from "../../../assets/image/logo.png";
import iconUser from "../../../assets/image/user-icon.png";
import "./khambenh.scss";
import Danhsachleft from "./Danhsachleft";
import Phieukham from "./Phieukham";
import LichsuCLSThuoc from "./LichsuCLS&Thuoc";
import khoaphongAPI from "../../../services/tienich/khoaphongAPI";
import employeeAPI from "../../../services/employeeAPI";

function Phieukhambenh() {
    const [StatusDistable, setStatusDistable] = useState(true);
    const [StatusDMLeft, setStatusDMLeft] = useState(false);
    const [ChonPhong, setChonPhong] = useState(true);
    const [BacSiThucHien, setBacSiThucHien] = useState([]);
    const [PhongThucHien, setPhongThucHien] = useState([]);
    const [click, setClick] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { rowbenhnhan } = useSelector((state) => state.dataKhamBenh);

    const getAllBSTH = async () => {
        try {
            const data = await employeeAPI.getAll();
            setBacSiThucHien(data.data);
        } catch (err) { }
    };
    const getAllKhoaphong = async () => {
        try {
            const data = await khoaphongAPI.fetchKhoaPhong({ 'Hide': 0 });
            setPhongThucHien(data.data);
        } catch (err) { }
    };
    const handleSubmit = async (e) => {
        if (e.DepartmentID === undefined) {
            Swal.fire("Vui lòng nhập chọn phòng !");
        } else if (e.RowID === undefined) {
            Swal.fire("Vui lòng nhập chọn bác sĩ !");
        }  
        else {
            sessionStorage.setItem("selectedRoomBacSi", JSON.stringify(e));
            setChonPhong(false)
        }
    }

    useEffect(() => {
        getAllBSTH();
        getAllKhoaphong();
        const savedRoom = JSON.parse(sessionStorage.getItem("selectedRoomBacSi"));
        if (savedRoom) {
            setChonPhong(false);
            const categoryTH = PhongThucHien.find((item) => Number(item.DepartmentID) === Number(savedRoom.DepartmentID));
            document.title = `${categoryTH?.DepartmentName} - Emedical`;

        }
       
        if (StatusDMLeft === false) {
            form.setFieldsValue({
                PatientName: '',
                PatientBirthyear:'',
                PatientGenderName:'',
                ObjectPriorityName:'',
                ObjectName:'',
                NGAY_YL:'',
            });
        } else {
            form.setFieldsValue({
                PatientName: rowbenhnhan?.PatientName,
                PatientBirthyear:rowbenhnhan?.PatientBirthyear,
                PatientGenderName:rowbenhnhan?.PatientGenderName,
                ObjectPriorityName:rowbenhnhan?.ObjectPriorityName,
                ObjectName:rowbenhnhan?.ObjectName,
                NGAY_YL:rowbenhnhan?.NGAY_YL,
               

            });
        }

    }, [form, rowbenhnhan, StatusDMLeft,PhongThucHien]);


    const tabkhambenh = [
        {
            key: "KhamBenh",
            label: "Phiếu khám",
            children: (
                <div className="tiepnhan-tab">
                    <Phieukham
                        StatusDistable={StatusDistable}
                        setStatusDistable={setStatusDistable}
                        StatusDMLeft={StatusDMLeft}
                        setStatusDMLeft={setStatusDMLeft}
                    />
                </div>
            ),
        },
        {
            key: "LichSuCLSThuoc",
            label: "Lịch sử CLS & Thuốc",
            children: (
                <div className="tiepnhan-tab">
                    <LichsuCLSThuoc />
                </div>
            ),
        },
    ];
    if (ChonPhong) {
        return (
            <div className="bg-chon-phong-thuc-hien">
                <div className="vh-100 d-flex justify-content-end align-items-center ">
                    <div className="box-chon-phong rounded">
                        <div className="title rounded-top">
                            <img src={logo} width={30} height={30} alt="logo" />
                            <div>CHỌN PHÒNG & BÁC SĨ THỰC HIỆN e-MedLink Y Tế Điện Tử</div>
                        </div>
                        <div className="content rounded-bottom">
                            <Form layout="horizontal" onFinish={handleSubmit} form={form}>
                                <div className="log-chon-phong">
                                    <div className="iconuser"><img src={iconUser} width={100} height={100} alt="logo" /></div>
                                    <div className="box-input">
                                        <div className="inputrow">
                                            <Form.Item
                                                name="DepartmentID"
                                                label={
                                                    <div className="form-input-label w-label">
                                                        Phòng thực hiện
                                                    </div>
                                                }
                                                className="form-row-login"
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Chọn phòng"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={PhongThucHien.map((item) => ({
                                                        value: item.DepartmentID,
                                                        label: item.DepartmentName,
                                                    }))}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="inputrow">
                                            <Form.Item
                                                name="RowID"
                                                label={
                                                    <div className="form-input-label w-label">
                                                        Bác sĩ/Người TH
                                                    </div>
                                                }
                                                className="form-row-login"
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Chọn bác sĩ"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={Array.isArray(BacSiThucHien)
                                                        ? BacSiThucHien.filter(row => row?.PositionID === '3')
                                                            .map(item => ({
                                                                value: item.RowID,
                                                                label: item.EmployeeName
                                                            }))
                                                        : []}

                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="inputrow">
                                            <Form.Item
                                                label={
                                                    <div className="form-input-label w-label">
                                                        Y tá (ĐD)/KTV
                                                    </div>
                                                }
                                                className="form-row-login"
                                                name="HospitalID"
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Chọn cơ sở"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={[
                                                        {
                                                            value: "1",
                                                            label: "Cơ sở 1",
                                                        },
                                                        {
                                                            value: "2",
                                                            label: "Cơ sở 2",
                                                        },
                                                        {
                                                            value: "3",
                                                            label: "Cơ sở 3",
                                                        },
                                                    ]}
                                                />

                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <div className="d-flex justify-content-end">
                                    <Button className="btn-login" htmlType="submit">
                                        Đăng nhập
                                    </Button>

                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="page-kham-benh-main">
                <div className="px-1 pk-profile">
                    <Form form={form}>
                        <div className="row form-row m-0 p-0">
                            <Form.Item
                                className="m-0 w-30"
                                name="PatientName"
                                label={
                                    <div className="form-input-label khambenh-form-input-label">
                                        Họ và tên
                                    </div>
                                }
                            >
                                <Input className="form-control m-0" disabled />
                            </Form.Item>
                            <Form.Item
                                className="m-0 w-15"
                                name="PatientBirthyear"
                                label={
                                    <div className="form-input-label khambenh-form-input-label">
                                        N.sinh
                                    </div>
                                }
                            >
                                <Input className="form-control m-0" disabled />
                            </Form.Item>
                            <Form.Item
                                className="m-0 w-10"
                                name="PatientGenderName"
                                label={
                                    <div className="form-input-label khambenh-form-input-label">
                                        Giới tính
                                    </div>
                                }
                            >
                                <Input className="form-control m-0" disabled />
                            </Form.Item>
                            <Form.Item
                                className="m-0 w-15"
                                name="ObjectPriorityName"
                                label={
                                    <div className="form-input-label khambenh-form-input-label">
                                        N.Nghiệp
                                    </div>
                                }
                            >
                                <Input className="form-control m-0" disabled />
                            </Form.Item>
                            <Form.Item
                                className="m-0 w-15"
                                label={
                                    <div className="form-input-label khambenh-form-input-label">
                                        Ngày vv
                                    </div>
                                }
                            >
                                <Input className="form-control m-0" disabled />
                            </Form.Item>
                            <Form.Item
                                className="m-0 w-15"
                                label={
                                    <div className="form-input-label khambenh-form-input-label">
                                        Ngày KB
                                    </div>
                                }
                            >
                                <Input className="form-control m-0" disabled />
                            </Form.Item>
                        </div>
                        <div className="row form-row m-0 p-0">
                            <Form.Item
                                className="m-0 w-15"
                                label={
                                    <div className="form-input-label khambenh-form-input-label">
                                        Dân tộc
                                    </div>
                                }
                            >
                                <Input className="form-control m-0" disabled />
                            </Form.Item>
                            <Form.Item
                                className="m-0 w-15"
                                label={
                                    <div className="form-input-label khambenh-form-input-label">
                                        Q.tịch
                                    </div>
                                }
                            >
                                <Input className="form-control m-0" disabled />
                            </Form.Item>
                            <Form.Item
                                className="m-0 w-40"
                                label={
                                    <div className="form-input-label khambenh-form-input-label">
                                        Địa chỉ
                                    </div>
                                }
                            >
                                <Input className="form-control m-0" disabled />
                            </Form.Item>
                            <Form.Item
                                className="m-0 w-30"
                                name="ObjectName"
                                label={
                                    <div className="form-input-label khambenh-form-input-label">
                                        Đ.Tượng
                                    </div>
                                }
                            >
                                <Input className="form-control m-0" disabled />
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="d-flex h-max pt-2">
                    <div
                        className={
                            click
                                ? "khambenh-danhsach-left w-0 div-shadow v5"
                                : "khambenh-danhsach-left w-25 div-shadow color-border v5"
                        }
                    >
                        <div
                            className={
                                click
                                    ? "khambenh-danhsach-close-left"
                                    : "khambenh-danhsach-open-left"
                            }
                            onClick={() => setClick(!click)}
                        >
                            {click ? (
                                <FontAwesomeIcon icon={faAngleRight} />
                            ) : (
                                <FontAwesomeIcon icon={faAngleLeft} />
                            )}
                        </div>
                        <div>
                            <div className="mt-1 shadow ">
                                <div className="v6">
                                    <div className={click ? "d-none" : "w-100 scroll-dsleft"}>
                                        <Danhsachleft
                                            StatusDistable={StatusDistable}
                                            setStatusDistable={setStatusDistable}
                                            StatusDMLeft={StatusDMLeft}
                                            setStatusDMLeft={setStatusDMLeft}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={click ? "w-100 vh-100" : "w-75"}>
                        <div className="mx-1 my-2 tab-khambenh">
                            <Tabs
                                defaultActiveKey="KhamBenh"
                                type="card"
                                size="small"
                                items={tabkhambenh}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Phieukhambenh;
