import { Modal, Tabs, Button, Form, Input, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendarCheck,
    faFileExcel,
    faPenToSquare,
    faReply,
    faSave,
    faFile,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Canlamsanthuoc from "./Canlamsangthuoc";

function LichsuCLSThuoc() {
    //khaibao
    const [click, setClick] = useState(false);
    //truyền props
    const tabkhambenh = [
        {
            key: "1",
            label: "Cận Lâm Sàng - Thuốc",
            children: (
                <div className="tiepnhan-tab">
                    <Canlamsanthuoc />
                </div>
            ),
        },
        {
            key: "2",
            label: "CĐ Hẹn",
            children: <div className="tiepnhan-tab"></div>,
        },
    ];

    return (
        <>
            <div className="div-shadow v3 btn-action-kham-benh">
                <div className="  d-flex justify-content-end">
                    <Button disabled>
                        <FontAwesomeIcon icon={faSave} /> F3 - Lưu
                    </Button>
                    <Button disabled>
                        <FontAwesomeIcon icon={faReply} /> F2- Bỏ qua
                    </Button>
                    <Button disabled>
                        <FontAwesomeIcon icon={faPenToSquare} /> F4 - Sửa
                    </Button>
                    <Button disabled>
                        <FontAwesomeIcon icon={faFileExcel} /> Huỷ
                    </Button>
                    <Button disabled>
                        <FontAwesomeIcon icon={faPrint} /> In toa
                    </Button>
                    <Button disabled>
                        <FontAwesomeIcon icon={faFile} /> Toa trắng
                    </Button>
                    <Button disabled>
                        <FontAwesomeIcon icon={faCalendarCheck} /> CĐ hẹn TK
                    </Button>
                    <Button disabled>
                        <FontAwesomeIcon icon={faBook} /> Đóng HS
                    </Button>
                </div>
            </div>
            <div className="color-border">
                <div className="m-1">
                    <Tabs defaultActiveKey="1" type="card" size="small" items={tabkhambenh} />
                </div>
            </div>
        </>
    );
}

export default LichsuCLSThuoc;
