import { configureStore } from "@reduxjs/toolkit";
import dataDMDuoc from "../slices/dataDMDuoc";
import dataVienPhi from "../slices/dataVienPhi";
import dataUser from "../slices/dataUser";
import dataTienIch from "../slices/dataTienIch";
import dataTiepNhan from "../slices/dataTiepNhan";
import dataDatHen from "../slices/dataDatHen";
import dataKhamBenh from "../slices/dataKhamBenh";

const rootReducer = {
   // user: userSlice,
    dataDMDuoc: dataDMDuoc,
    dataVienPhi:dataVienPhi,
    dataUser:dataUser,
    dataTienIch:dataTienIch,
    dataTiepNhan:dataTiepNhan,
    dataDatHen:dataDatHen,
    dataKhamBenh:dataKhamBenh
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;
