import { Modal, Button, Form, Input, Select, Checkbox, InputNumber, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import dayjs from 'dayjs';
import { useDispatch, useSelector } from "react-redux";
import { errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import loginAPI from "../../../../../services/loginApi";
import { setDataKhoDuoc } from "../../../../../slices/dataDMDuoc";
import khoAPI from "../../../../../services/duoc/danhmuc/khoAPI";
import './khoduoc.scss';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
function DetailKho(props) {
    const { open, setOpen, handleUpdate, listKhoGroup, reLoad, listKho } = props;
    const { khoduoc } = useSelector((state) => state.dataDMDuoc);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [userlogin, setUserLogin] = useState();
    const [checkAnHien, setcheckAnHien] = useState(false);
    const [checkDongY, setcheckDongY] = useState(false);
    const [checkBV01, setcheckBV01] = useState(false);
    const [ngaylapkho, setngaylapkho] = useState(false);
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleCreate = async (obj) => {
        const data = await khoAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);

        }
        else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }

    };
    const handleSubmit = async (e) => {
        Swal.fire({
            title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
            confirmButtonText: "Đồng ý",
            showCancelButton: true,
            cancelButtonText: "Hủy",
            customClass: {
                title: "fs-5 text-dark",
                confirmButton: "shadow-none",
                cancelButton: "shadow-none",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                if (khoduoc?.RepositoryID) {
                    var UpKhoThuoc = {
                        RepositoryID: e?.RepositoryID,
                        RepositoryName: e?.RepositoryName,
                        RepositoryGroupID: e?.RepositoryGroupID,
                        RepositoryID_DuTru: e?.RepositoryID_DuTru,
                        DateReport: ngaylapkho === false ? e.DateReport : ngaylapkho,
                        STT: e?.STT === undefined ? 1 : e?.STT,
                        Hide: e?.Hide,
                        isDongY: e?.isDongY,
                        isDuyetBV01: e?.isDuyetBV01,
                        EmployeeID: userlogin.RowID,
                    }
                    handleUpdate(UpKhoThuoc);
                    dispatch(setDataKhoDuoc());
                    form.resetFields();
                    setOpen(false);
                } else {
                    var KhoThuoc = {
                        RepositoryName: e?.RepositoryName,
                        RepositoryGroupID: e?.RepositoryGroupID,
                        RepositoryID_DuTru: e?.RepositoryID_DuTru === undefined ? 0 : e?.RepositoryID_DuTru,
                        STT: e?.STT === undefined ? 1 : e?.STT,
                        Hide: checkAnHien === false ? 0 : 1,
                        isDongY: checkDongY === false ? 0 : 1,
                        isDuyetBV01: checkBV01 === false ? 0 : 1,
                        EmployeeID: userlogin.RowID,
                        DateReport: ngaylapkho === false ? new Date() : ngaylapkho
                    }

                    handleCreate(KhoThuoc);
                }
            }
        });

    }
    const onChangeAnHien = (e) => {
        setcheckAnHien(e.target.checked)
    };
    const onChangeDongY = (e) => {
        setcheckDongY(e.target.checked)
    };
    const onChangeBV01 = (e) => {
        setcheckBV01(e.target.checked)
    };
    const KhoDuTru = () => {
        return listKho.map((product, index) => {


            if (product.RepositoryID_DuTru === 0) {
                return (
                    <Select.Option
                        title={product.RepositoryName}
                        key={product.RepositoryName}
                        id={product.RepositoryID}
                        value={product.RepositoryID}
                    >
                        {product.RepositoryName}
                    </Select.Option>
                );
            } else {
                return null;
            }

        })
    }
    useEffect(() => {
        getAllUser();
        form.setFieldsValue({
            RepositoryID: khoduoc?.RepositoryID,
            RepositoryName: khoduoc?.RepositoryName,
            RepositoryGroupID: khoduoc?.RepositoryGroupID,
            RepositoryID_DuTru: khoduoc?.RepositoryID_DuTru === 0? "" : khoduoc?.RepositoryID_DuTru,
            DateReport: dayjs(khoduoc?.DateReport),
            STT: khoduoc?.STT,
            Hide: khoduoc?.Hide,
            isDongY: khoduoc?.isDongY,
            isDuyetBV01: khoduoc?.isDuyetBV01

        });
    }, [khoduoc, form]);
    const initialValues = {
        DateReport: dayjs(new Date())
    };
    return (
        <>
            <Modal forceRender className="KhoDuoc"
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo kho
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={"80vw"}
            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form}
                        initialValues={initialValues}

                    >
                        <div className="scroll">
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input"></div>}
                                    className="col-md-6 m-0"
                                    name="RepositoryID"
                                    hidden={true}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên kho :</div>}
                                    className="col-md-6 m-0"
                                    name="RepositoryName"
                                    rules={[{ required: true, message: 'Vui lòng nhập tên!' }]}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Kho dự trù :</div>}
                                    className="col-md-6 m-0"
                                    name="RepositoryID_DuTru"
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        placeholder="Chọn kho"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return (
                                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );

                                        }}
                                    >
                                        {KhoDuTru()}
                                    </Select>

                                </Form.Item>

                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Nhóm kho :</div>}
                                    className="col-md-3 m-0"
                                    name="RepositoryGroupID"
                                    rules={[{ required: true, message: 'Vui lòng chọn nhóm!' }]}
                                >
                                    <Select
                                        notFoundContent={"Chưa có dữ liệu"}
                                        showSearch
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        options={listKhoGroup.map((item) => ({
                                            value: item.RepositoryGroupID,
                                            label: item.RepositoryGroupName
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item label={<div className="form-input-label lablename-input">Ngày :</div>}
                                    name="DateReport" className="col-md-3 m-0"
                                >
                                    <DatePicker className="form-control" format={'DD/MM/YYYY'} onChange={(date, dateString) => {
                                        setngaylapkho(date);
                                    }
                                    } />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">STT:</div>}
                                    className="col-md-2 m-0"
                                    name="STT"
                                >
                                    <InputNumber className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Duyệt ĐY:</div>}
                                    className="col-md-1 m-0"
                                    name="isDongY" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeDongY} value={checkDongY} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Duyệt BV:</div>}
                                    className="col-md-1 m-0"
                                    name="isDuyetBV01" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeBV01} value={checkBV01} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ẩn / Hiện:</div>}
                                    className="col-md-1 m-0"
                                    name="Hide" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChangeAnHien} value={checkAnHien} />
                                </Form.Item>
                            </div>
                        </div>
                        <Form.Item className="text-center">
                            <hr />
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                Thoát <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" />
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default DetailKho;
