import { Tabs, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarPlus,
    faStethoscope
} from "@fortawesome/free-solid-svg-icons";
import "./tiepnhan.scss";
import Congkham from "./Congkham";
import Timkiem from "./Timkiem";
import CDinhhentruoc from "./Cdinhhentruoc";
import Khamsuckhoe from "./Khamsuckhoe";
import { useState, useEffect } from "react";
import Thongtindangkykham from "./Thongtindangkykham";
import DatHenOnline from "./DatHenOnline";
import tiepnhanAPI from "../../services/tiepnhan/tiepnhanAPI";
import { removeSession } from "../../components/Dialog/Dialog";
function Tiepnhan() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ListThongKePhongKham, setListThongKePhongKham] = useState([]);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const [activeKey, setActiveKey] = useState("CongKham");
    const handleTabClick = (key) => {
        setActiveKey(key);
    };
    useEffect(() => {
        const getThongKe = async () => {
            try {
                const data = await tiepnhanAPI.getThongKeKhoaPhong();
                setListThongKePhongKham(data.data);
            } catch (err) { }
            removeSession();
        };
        getThongKe();
    }, [])
    const items = [
        {
            key: "CongKham",
            label: "Công khám",
            children: (
                <div className="tiepnhan-tab">
                    <Congkham />
                </div>
            ),
        },
        {
            key: "TimKiem",
            label: "Tìm kiếm",
            children: (
                <div className="tiepnhan-tab">
                    <Timkiem activeKey={activeKey} setActiveKey={setActiveKey} />
                </div>
            ),
        },
        {
            key: "HenTaiKham",
            label: "C.Định hẹn trước",
            children: (
                <div className="tiepnhan-tab">
                    <CDinhhentruoc />
                </div>
            ),
        },
        {
            key: "KSK",
            label: "Khám sức khỏe",
            children: (
                <div className="tiepnhan-tab">
                    <Khamsuckhoe />
                </div>
            ),
        },
        {
            key: "ONLINE",
            label: "Đặt hẹn online",
            children: (
                <div className="tiepnhan-tab">
                    <DatHenOnline activeKey={activeKey} setActiveKey={setActiveKey} />
                </div>
            ),
        },
    ];
    return (
        <>
            <div className=" h-100">
                <div className="d-flex">
                    <div className="w-99 m-1">
                        <Tabs activeKey={activeKey} onTabClick={handleTabClick} type="card" size="small" items={items} />
                    </div>
                    <div className="tiepnhan-btn-thongtinkhambenh">
                        <Button type="primary" onClick={showModal}>
                            <FontAwesomeIcon icon={faStethoscope} />
                        </Button>
                        <Thongtindangkykham isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

                    </div>
                </div>
                <div className="my-3">
                    <div className="tiepnhan-title mx-2">THÔNG TIN PHÒNG KHÁM</div>
                    <div className="d-flex flex-wrap justify-content-between">
                        {ListThongKePhongKham.map((item, index) => (
                            <div className="tiepnhan-phongkham-box" key={index}>
                                <div className="tiepnhan-phongkham-box-title">
                                    <div className="d-flex ">
                                        <div>
                                            <FontAwesomeIcon icon={faCalendarPlus} color="white" />
                                        </div>
                                        <div>{item.DepartmentName}</div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div>Tổng lượt khám: {item.tong_luot}</div>
                                    <div>Tổng lượt đã khám: {item.tong_dakham}</div>
                                    <div>Tổng lượt gửi GĐ: {item.tong_gui_gd}</div>
                                </div>
                            </div>
                        ))

                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tiepnhan;
