import { createSlice } from "@reduxjs/toolkit";
export const dataAddVienPhi = createSlice({
    name: "dataVienPhi",
    initialState: {
        nhomvienphi:[],
        loaivienphi:[],
        donvitinhvp:[],
        nhominvp:[],
        dichvukythuat:[],
        giadichvukythuat:[],
        goikham:[],
        chitietgoikham:[]
    },
    reducers: {
        setDataNhomVienPhi: (state, action) => {
            state.nhomvienphi = action.payload;
        },
        setDataLoaiVienPhi: (state, action) => {
            state.loaivienphi = action.payload;
        },
        setDataDonViTinhVienPhi: (state, action) => {
            state.donvitinhvp = action.payload;
        },
        setDataNhomInVienPhi: (state, action) => {
            state.nhominvp = action.payload;
        },
        setDataDichVuKyThuat: (state, action) => {
            state.dichvukythuat = action.payload;
        },
        setDataGiaDichVuKyThuat: (state, action) => {
            state.giadichvukythuat = action.payload;
        },
        setDataGoiKham: (state, action) => {
            state.goikham = action.payload;
        },
        setDataChiTietGoiKham: (state, action) => {
            state.chitietgoikham = action.payload;
        },
        
    },
});
export const {
    setDataNhomVienPhi,
    setDataLoaiVienPhi,
    setDataDonViTinhVienPhi,
    setDataNhomInVienPhi,
    setDataDichVuKyThuat,
    setDataGiaDichVuKyThuat,
    setDataGoiKham,
    setDataChiTietGoiKham
} = dataAddVienPhi.actions;
export default dataAddVienPhi.reducer;
