import axios from "axios";
import REACT_LOCALHOST from "../../host";
import { authHeader } from '../../helpers';
const cachdungAPI = {
    getAll: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/view-med-instruction-duoc`,obj);
    },
    create: (obj) => {
        return axios.post(`${REACT_LOCALHOST}/api/add-med-instruction-duoc`, obj);
    },
    update: (obj) => {
        return axios.put(`${REACT_LOCALHOST}/api/update-med-instruction-duoc`, obj);
    },
    delete: (RowID,EmployeeID) => {
        return axios.delete(`${REACT_LOCALHOST}/api/delete-med-instruction-duoc/${RowID}/${EmployeeID}`);
    },
};

export default cachdungAPI;