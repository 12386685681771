import { Button, Dropdown, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridColumn,ComboBox } from 'rc-easyui';
import {
    faEdit,
    faTrashCan,
    faPenToSquare,
    faPlus,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { errorInfo, successInfo } from "../../../../../components/Dialog/Dialog";
import nhacungcapAPI from "../../../../../services/duoc/danhmuc/nhacungcapAPI";
import { setDataNhaCungCap } from "../../../../../slices/dataDMDuoc";
import loginAPI from "../../../../../services/loginApi";
import LoadPage from "../../../../../loadpage";
import DetailNhaCungCap from "../Detail";
import "../nhacungcap.scss";
function NhaCungCap() {
    const [loadpage, setLoadPage] = useState(false);
    const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const [HideCheck, setHideCheck] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const [listNhaCungCap, setlistNhaCungCap] = useState([]);
    const [userlogin, setUserLogin] = useState();

    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };

    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataNhaCungCap(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {

                    handleDelete(record.RowID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };
    const getAllNhaCungCap = async () => {
        try {
            const data = await nhacungcapAPI.getAll({ 'RowID': 0, 'Hide': HideCheck === false ? 0 : 1 });
            setlistNhaCungCap(data.data);
        } catch (err) { }
    };
    const handleUpdate = async (obj) => {
        const data = await nhacungcapAPI.update(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllNhaCungCap();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (RowID, EmployeeID) => {
        const data = await nhacungcapAPI.delete(RowID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllNhaCungCap();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleToggleHideCheckbox = async (record) => {
        const newHideValue = record.Hide === false ? true : false;
        const updatedRecord = { ...record, Hide: newHideValue,  EmployeeID: record.EmployeeID || userlogin.RowID };
        try {
            await handleUpdate(updatedRecord);
            successInfo("Cập nhật trạng thái thành công!");
            getAllNhaCungCap();
        } catch (err) {
            errorInfo("Cập nhật trạng thái thất bại!");

        }
    };
    useEffect(() => {
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        document.title = 'Nhà Cung Cấp - EmedLink';
        const getAllNhaCungCap = async () => {
            const data = await nhacungcapAPI.getAll({ 'RowID': 0, 'Hide': HideCheck === false ? 0 : 1 });
            setlistNhaCungCap(data.data);
        };
        getAllNhaCungCap();
        getAllUser();


    }, [HideCheck]);
    const status = [
        { value: null, text: "All" },
        { value: "true", text: "1" },
        { value: "false", text: "0" }
    ]
    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];

    if (loadpage === true) {
        return (
            <>

                <div className="text-muted styleNhaCungCap">
                    <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                        <div className="d-flex align-items-center ">
                            <div className="vienphi-danhmuc-title mx-2"><FontAwesomeIcon icon={faBars} /> Nhà cung cấp</div>
                        </div>
                        <div className="text-end">
                            <div className="nhomin-act-check">
                                <div className="col-n"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                                <div className="col-n">
                                    <Button className="form-btn bg" onClick={handleDataCreate}>
                                        <FontAwesomeIcon icon={faPlus} className="mx-1" />
                                        Thêm mới
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DetailNhaCungCap
                        open={open} setOpen={setOpen}
                        reLoad={getAllNhaCungCap}
                        handleUpdate={handleUpdate}
                        key="DetailNhaCungCap"
                    />
                    <hr className="w-100 my-1" />
                    <div className="m-1 danhmuc">
                        <DataGrid
                            data={listNhaCungCap}
                            style={{ height: '90vh' }}
                            filterable
                            columnResizing
                        >
                            <GridColumn field="VendorCode" title="Mã NCC" align="center" width={200} resizable />
                            <GridColumn field="VendorName" title="Tên nhà cung cấp" width={350} resizable />
                            <GridColumn field="Salesman" title="Người đại diện" width={150} resizable />
                            <GridColumn field="Address" title="Địa chỉ"  width={225} resizable />
                            <GridColumn field="Phone" title="Số điện thoại" align="center" width={150} resizable />
                            <GridColumn field="Email" title="Email"  width={200} resizable />
                            <GridColumn field="VendorTaxNo" title="Mã số thuế" align="center" width={200} resizable />
                            <GridColumn field="VendorFax" title="Số fax" align="center" width={200} resizable />
                            <GridColumn field="VendorBankName" title="TK ngân hàng" align="center" width={200} resizable />

                            <GridColumn
                                field="Hide"
                                title="Ẩn/Hiện"
                                align="center"
                                width={100}
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => (
                                        <Checkbox
                                            checked={row.Hide === true}
                                            onChange={() => handleToggleHideCheckbox(row)}
                                        />
                                )}
                            />
                            <GridColumn
                                field="actions"
                                title="Thao tác"
                                align="center"
                                width={100}
                                filterable={false}
                                render={({ row }) => (
                                    <div className="d-flex justify-content-center align-center">
                                        <Dropdown
                                            menu={{
                                                items,
                                                /**
                                                 *
                                                 */
                                                onClick: (e) => handleThaoTac(e, row),
                                            }}
                                            placement="left"
                                            arrow={{
                                                pointAtCenter: true,
                                            }}
                                        >
                                            <Button
                                                className="bg-light vienphi-danhmuc-icon-modify"
                                                onClick={() => {
                                                    setActiveModify(!activeModify);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    style={{ fontSize: "10px" }}
                                                    className="text-dark"
                                                />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </DataGrid>
                    </div>
                </div>
            </>
        );
    } else { return <LoadPage /> }
}

export default NhaCungCap;
