import React, { useEffect, useState } from 'react';
import { setDataNguoiGioiThieu } from '../../../../slices/dataTienIch';
import { Form, Input, Button, Modal, Checkbox, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import loginAPI from "../../../../services/loginApi";
import '../nguoigioithieu.scss';
const DetailNguoiGioiThieu = (props) => {
    const { open, setOpen, handleCreate_Update, listNghenghiep, listgioitinh,
        listTinhthanh, listQuanhuyen, listPhuongxa
    } = props;
    const { nguoigioithieu } = useSelector((state) => state.dataTienIch);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [filteredQuanhuyen, setFilteredQuanhuyen] = useState([]);
    const [filteredPhuongxa, setFilteredPhuongxa] = useState([]);
    const [localData, setLocalData] = useState({
        ProvincialID: null,
        DistrictID: null,
        WardID: null,
        Address_: "",
    });
    const [userlogin, setUserLogin] = useState();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
            throw new Error(err);
        }
    };

    const handleTinhthanhChange = (value) => {
        const filteredDistricts = listQuanhuyen.filter((district) => district.ProvincialID === value);
        setFilteredQuanhuyen(filteredDistricts);
        setFilteredPhuongxa([]);
        form.setFieldsValue({ DistrictID: null, WardID: null });

        const provinceName = listTinhthanh.find((item) => item.ProvincialID === value)?.ProvincialName || '';
        setLocalData({ ProvincialID: value, DistrictID: null, WardID: null, Address_: provinceName });
        form.setFieldsValue({ Address_: provinceName });
    };

    const handleQuanhuyenChange = (value) => {
        const filteredWards = listPhuongxa.filter((ward) => ward.DistrictID === value);
        setFilteredPhuongxa(filteredWards);
        form.setFieldsValue({ WardID: null });

        const districtName = listQuanhuyen.find((item) => item.DistrictID === value)?.DistrictName || '';
        const updatedAddress = `${districtName}, ${localData.Address_}`;
        setLocalData({ ...localData, DistrictID: value, Address_: updatedAddress });
        form.setFieldsValue({ Address_: updatedAddress });
    };

    const handlePhuongxaChange = (value) => {
        const wardName = listPhuongxa.find((item) => item.WardID === value)?.WardName || '';
        const updatedAddress = `${wardName}, ${localData.Address_}`;
        setLocalData({ ...localData, WardID: value, Address_: updatedAddress });
        form.setFieldsValue({ Address_: updatedAddress });
    };


    const handleSubmit = async (e) => {
        Swal.fire({
            title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
            confirmButtonText: "Đồng ý",
            showCancelButton: true,
            cancelButtonText: "Hủy",
            customClass: {
                title: "fs-5 text-dark",
                confirmButton: "bg-primary shadow-none",
                cancelButton: "bg-warning shadow-none",
            },
        }).then(async (result) => {
            if (result?.isConfirmed) {
                const Nguoigioithieu = {
                    RowID: e?.RowID || 0,
                    IntroCode: e?.IntroCode,
                    IntroName: e?.IntroName,
                    Sex: e?.Sex || 0,
                    Mobile: e?.Mobile ?? " ",
                    Address_: e?.Address_ ?? " ",
                    Birthday: e?.Birthday,
                    CareerID: e?.CareerID ?? 0,
                    Note: e?.Note || " ",
                    isNguoiGuiMau: e?.isNguoiGuiMau ?? 0,
                    Hide: nguoigioithieu?.Hide || 0,
                    EmployeeID: userlogin.RowID,
                };

                await handleCreate_Update(Nguoigioithieu);
                dispatch(setDataNguoiGioiThieu());
                form.resetFields();
                setOpen(false);
            }
        });
    };

    useEffect(() => {
        getAllUser();
        if (open) {
            
            form.setFieldsValue({
                RowID: nguoigioithieu?.RowID,
                IntroCode: nguoigioithieu?.IntroCode,
                IntroName: nguoigioithieu?.IntroName,
                Sex: nguoigioithieu?.Sex,
                Mobile: nguoigioithieu?.Mobile,
                Address_: nguoigioithieu?.Address_,
                Birthday: nguoigioithieu?.Birthday,
                CareerID: nguoigioithieu?.CareerID,
                Note: nguoigioithieu?.Note,
                isNguoiGuiMau: nguoigioithieu?.isNguoiGuiMau,
            });
        }
    }, [form, nguoigioithieu, open]);
    return (
        <Modal
            className="DetailNguoiGT"
            title={<div className="vienphi-danhmuc-add">Khai báo người giới thiệu</div>}
            centered
            open={open}
            okButtonProps={{
                style: {
                    display: "none",
                },
            }}
            cancelButtonProps={{
                style: {
                    display: "none",
                },
            }}
            onCancel={() => setOpen(false)}
            width={"80vw"}
            closable={false}
            forceRender
            maskClosable={false}
        >
            <div>
                <Form form={form} onFinish={handleSubmit}>
                    <div className="mb-2">
                        <div className="row">
                            <Form.Item
                                label=" (ID):"
                                name="RowID"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                                hidden
                            >
                                <Input className="form-control form-control-sm" />
                            </Form.Item>


                            <Form.Item
                                className="col-md-4"
                                label={<div className='form-input-label labelname'>Mã:</div>}
                                name="IntroCode"

                                rules={[{ required: true, message: 'Vui lòng chọn mã!' }]}
                            >
                                <Input className="form-control form-control-sm" />
                            </Form.Item>
                            <Form.Item
                                className="col-md-8"
                                label={<div className='form-input-label labelname'>Tên Người G.Thiệu:</div>}
                                name="IntroName"

                                rules={[{ required: true, message: 'Vui lòng nhập tên người giới thiệu!' }]}
                            >
                                <Input className="form-control form-control-sm" />
                            </Form.Item>
                        </div>
                        <div className="row">
                            <Form.Item label={<div className='form-input-label labelname'>Ngày sinh:</div>}
                                name="Birthday" className="col-md-4"
                                rules={[{ required: true, message: 'Vui lòng nhập ngày sinh!' }]}
                            >
                                <Input type="date" className="form-control form-control-sm"
                                />
                            </Form.Item>
                            <Form.Item label={<div className='form-input-label labelname'>Giới tính:</div>}
                                name="Sex" className="col-md-4"
                                rules={[{ required: true, message: 'Vui lòng nhập G.Tính!' }]}
                            >
                                <Select
                                    showSearch
                                    className="selectac"
                                    placeholder="Chọn"
                                    notFoundContent={"Chưa có dữ liệu"}
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={listgioitinh.map((item) => ({
                                        value: item.PatientGenderID,
                                        label: item.PatientGenderName
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item
                                className="col-md-4"
                                label={<div className='form-input-label labelname'>SĐT:</div>}
                                name="Mobile" >
                                <Input className="form-control form-control-sm"
                                />
                            </Form.Item>
                        </div>

                        <div className="row">
                            <Form.Item label={<div className='form-input-label labelname'>Tỉnh:</div>}
                                name="ProvincialID" className="col-md-4">
                                <Select
                                    showSearch
                                    placeholder="Chọn"
                                    className="selectac"
                                    notFoundContent={"Chưa có dữ liệu"}
                                    optionFilterProp="label"
                                    options={listTinhthanh.map((item) => ({
                                        value: item.ProvincialID,
                                        label: item.ProvincialName
                                    }))}

                                    onChange={handleTinhthanhChange}
                                />
                            </Form.Item>


                            <Form.Item label={<div className='form-input-label labelname'>Quận/Huyện:</div>}
                                name="DistrictID" className="col-md-4">
                                <Select
                                    showSearch
                                    className="selectac"
                                    placeholder="Chọn"
                                    notFoundContent={"Chưa có dữ liệu"}
                                    optionFilterProp="label"
                                    options={filteredQuanhuyen.map((item) => ({
                                        value: item.DistrictID,
                                        label: item.DistrictName
                                    }))}
                                    onChange={handleQuanhuyenChange}
                                />
                            </Form.Item>
                            <Form.Item label={<div className='form-input-label labelname'>Phường/Xã:</div>}
                                name="WardID" className="col-md-4" >
                                <Select
                                    showSearch
                                    className="selectac"
                                    placeholder="Chọn"
                                    notFoundContent={"Chưa có dữ liệu"}
                                    optionFilterProp="label"
                                    options={filteredPhuongxa.map((item) => ({
                                        value: item.WardID,
                                        label: item.WardName
                                    }))}
                                    onChange={handlePhuongxaChange}
                                />
                            </Form.Item>
                        </div>

                        <div className="row">
                            <Form.Item
                                label={<div className='form-input-label labelname'>Địa chỉ:</div>}
                                name="Address_"
                                className="col-md-12"
                            >
                                <Input className="form-control form-control-sm"
                                />
                            </Form.Item>
                        </div>

                        <div className="row">
                            <Form.Item label={<div className='form-input-label labelname'>Nghề nghiệp:</div>}
                                name="CareerID" className="col-md-4">
                                <Select
                                    showSearch
                                    className="selectac"
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={listNghenghiep.map((item) => ({
                                        value: item.CareerID,
                                        label: item.CareerName
                                    }))}
                                />
                            </Form.Item>



                            <Form.Item className="col-md-4"
                                label={<div className='form-input-label labelname'>Người gửi mẫu:</div>}
                                name="isNguoiGuiMau"
                                valuePropName="checked" initialValue={false}
                            >
                                <Checkbox />
                            </Form.Item>
                        </div>

                        <div className="row">
                            <Form.Item className="col-md-12"
                                label={<div className='form-input-label labelname'>Ghi chú:</div>}
                                name="Note"
                            >
                                <Input className="form-control form-control-sm"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="text-center">
                        <hr />
                        <Button type="primary" htmlType="submit" className="mx-2 vienphi-danhmuc-btn">
                            <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                        </Button>
                        <Button
                            onClick={() => {
                                form.resetFields();
                                setOpen(false);
                            }}
                            danger className="mx-2"
                        >
                            <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                        </Button>
                    </div>
                </Form>
            </div >
        </Modal >
    );
};

export default DetailNguoiGioiThieu;
