
import {  Button, Dropdown, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { DataGrid, GridColumn, ComboBox } from 'rc-easyui';
import {
    faEdit,
    faTrashCan,
    faPenToSquare,
    faPlus,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { errorInfo, successInfo } from "../../../../../components/Dialog/Dialog";
import donvitinhAPI from "../../../../../services/vienphi/danhmuc/donvitinhAPI";
import { setDataDonViTinhVienPhi } from "../../../../../slices/dataVienPhi";
import loginAPI from "../../../../../services/loginApi";
import "../../danhmuc.scss";
import DetailDonViTinh from "../Detail";
import LoadPage from "../../../../../loadpage";
function Khaibaodonvitinh() {


    const [HideCheck, setHideCheck] = useState(false);
    const [loadpage, setLoadPage] = useState(false);
    const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const [listDonViTinhVienPhi, setlistDonViTinhVienPhi] = useState([]);
    const [userlogin, setUserLogin] = useState();
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataDonViTinhVienPhi(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {

                    handleDelete(record.UnitID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };
    const getAllDonViTinhVienPhi = async () => {
        try {
            const data = await donvitinhAPI.getAll({ 'UnitID': 0, 'Hide': HideCheck === false ? 0 : 1 });
            setlistDonViTinhVienPhi(data.data);

        } catch (err) { }
    };

    const handleUpdate = async (obj) => {
        const data = await donvitinhAPI.update(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllDonViTinhVienPhi();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (UnitID, EmployeeID) => {
        const data = await donvitinhAPI.delete(UnitID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllDonViTinhVienPhi();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleToggleHideCheckbox = async (record) => {
        const newHideValue = record.Hide === false ? true : false;
        const updatedRecord = { ...record, Hide: newHideValue };

        try {
            await handleUpdate(updatedRecord);
            successInfo("Cập nhật trạng thái thành công!");
            getAllDonViTinhVienPhi();
        } catch (err) {
            errorInfo("Cập nhật trạng thái thất bại!");
            
        }
    };
    useEffect(() => {
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        const getAllDonViTinhVienPhi = async () => {
            const data = await donvitinhAPI.getAll({ 'UnitID': 0, 'Hide': HideCheck === false ? 0 : 1 });
            setlistDonViTinhVienPhi(data.data);
        };
        getAllDonViTinhVienPhi();
        getAllUser();
    }, [HideCheck]);
    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const status = [
        { value: null, text: "All" },
        { value: "true", text: "1" },
        { value: "false", text: "0" }
    ];
    if (loadpage === true) {
        return (
            <>

                <div className="text-muted StyleNhomIn">
                    <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                        <div className="d-flex align-items-center ">
                            <div className="vienphi-danhmuc-title mx-2"><FontAwesomeIcon icon={faBars} /> Đơn vị tính</div>
                        </div>
                        <div className="text-end">
                            <div className="nhomin-act-check">
                                <div className="col-n"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                                <div className="col-n">
                                    <Button className="form-btn bg" onClick={handleDataCreate}>
                                        <FontAwesomeIcon icon={faPlus} className="mx-1" />
                                        Thêm mới
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DetailDonViTinh
                        open={open} setOpen={setOpen}
                        reLoad={getAllDonViTinhVienPhi}
                        handleUpdate={handleUpdate}
                        key="DetailDonViTinh"
                    />
                    <hr className="w-100 my-1" />

                    <div className="m-1 danhmuc">
                        <DataGrid
                            data={listDonViTinhVienPhi}
                            style={{ height: '90vh' }}
                            filterable
                            columnResizing
                        >
                            <GridColumn field="UnitID" title="Mã ĐVT" filterable resizable width={100} />
                            <GridColumn field="UnitName" title="Tên ĐVT" filterable width={350} resizable />
                            <GridColumn field="STT" title="STT" filterable width={100} resizable />
                            <GridColumn field="Hide" title="Ẩn/Hiện" filterable width={50} resizable
                                filter={() => (
                                    <ComboBox
                                        data={status}
                                        editable={false}
                                    />
                                )}
                                render={({ row }) => (
                                    <Checkbox
                                        checked={row.Hide === true}
                                        onChange={() => handleToggleHideCheckbox(row)}
                                    />
                                )}
                            />
                            <GridColumn
                                field="actions"
                                title="Thao tác"
                                align="center"
                                width={100}
                                resizable
                                filterable={false}
                                render={({ row }) => (
                                    <div className="d-flex justify-content-center align-center">
                                        <Dropdown
                                            menu={{
                                                items,
                                                onClick: (e) => handleThaoTac(e, row),
                                            }}
                                            placement="left"
                                            arrow={{
                                                pointAtCenter: true,
                                            }}
                                        >
                                            <Button
                                                className="bg-light vienphi-danhmuc-icon-modify"
                                                onClick={() => {
                                                    setActiveModify(!activeModify);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    style={{ fontSize: "10px" }}
                                                    className="text-dark"
                                                />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </DataGrid>
                    </div>
                </div>

            </>
        );
    } else { return <LoadPage /> }
}

export default Khaibaodonvitinh;
