import React, { useEffect, useState } from 'react';
import khoaphongAPI from '../../../../services/tienich/khoaphongAPI';
import { Button, Checkbox, Dropdown } from "antd";
import { useDispatch } from 'react-redux';
import { setDataKhoaPhong } from '../../../../slices/dataTienIch';
import Swal from 'sweetalert2';
import { errorInfo, infoRes, successInfo } from "../../../../components/Dialog/Dialog";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPenToSquare, faPlus, faTrashCan, faBars } from '@fortawesome/free-solid-svg-icons';
import DetailKhoaPhong from '../Detail';
import { DataGrid, GridColumn } from 'rc-easyui';
import loginAPI from "../../../../services/loginApi";
import '../khoaphong.scss';

const KhoaPhong = () => {
    const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const [listKhoaphong, setListKhoaphong] = useState([]);
    const [listServiceModule, setlistServiceModule] = useState([]);
    const [listKhoaphong_ck, setListKhoaphong_ck] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [hide, setHide] = useState(0);
    // const [hide, setHide] = useState(0);


    const handleDataCreate = () => {
        setOpen(true);
    };

    const dispatch = useDispatch();
    const [userlogin, setUserLogin] = useState();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {}
    };


    useEffect(() => {
        const getAllKhoaphong = async () => {
            const data = await khoaphongAPI.fetchKhoaPhong({ 'Hide': hide });
            setListKhoaphong(data.data);
            setFilteredData(data.data);

        };
        const getAllKhoaphong_ck = async () => {
            const data = await khoaphongAPI.fetchKhoaPhong_ck({ 'Hide': hide });
            setListKhoaphong_ck(data.data);

        };

        getAllKhoaphong();
        getAllServiceModule();
        getAllKhoaphong_ck();
        getAllUser();
    }, [hide]);

    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataKhoaPhong(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa user này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleDelete(record.DepartmentID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };

    const getAllKhoaphong = async () => {
        try {
            const data = await khoaphongAPI.fetchKhoaPhong({ 'Hide': hide });
            setListKhoaphong(data.data);
            setFilteredData(data.data);
        } catch (err) {
            console.error("Lỗi khi lấy danh sách khoa phòng:", err);
        }
    };

    const getAllServiceModule = async () => {
        try {
            const data = await khoaphongAPI.getServiceModule('ServiceModuleCode');
            setlistServiceModule(data.data);
        } catch (err) {
            throw new Error(err);
        }
    };
    /* const getAllKhoaphong_ck = async () => {
         try {
             const data = await khoaphongAPI.fetchKhoaPhong_ck({ 'Hide': hide });
             setListKhoaphong_ck(data.data);
         } catch (err) {
             throw new Error(err);
         }
     };*/
    const handleCreate = async (obj) => {
        const data = await khoaphongAPI.addKhoaPhong(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        } else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllKhoaphong();
        }
    };
    const handleUpdate = async (obj) => {
        const data = await khoaphongAPI.updateKhoaPhong(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllKhoaphong();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (DepartmentID, EmployeeID) => {
        const data = await khoaphongAPI.deleteKhoaPhong(DepartmentID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            setListKhoaphong();
            reloadData();
        } else {
            errorInfo(data.data.Content);
        }
    };

    const reloadData = async () => {
        await Promise.all([getAllKhoaphong()]);
    };

    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];

    const handleToggleHide = () => {
        setHide(prev => (prev === 0 ? 1 : 0));
    };

    const handleToggleHideCheckbox = async (record) => {
        const newHideValue = record.Hide === false ? 1 : 0;
        const updatedRecord = { ...record, Hide: newHideValue };
        try {
            await handleUpdate(updatedRecord);
            successInfo("Cập nhật trạng thái thành công!");
            getAllKhoaphong();
        } catch (err) {
            errorInfo("Cập nhật trạng thái thất bại!");
        }
    };

    return (
        <div className="text-muted dmkhoaphong">
            <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                <h4 className="text-secondary mx-2">
                    <FontAwesomeIcon icon={faBars} />  Khai Báo Khoa Phòng
                </h4>
                <div className="textend">
                    <div className='dskhoa'>
                        <Checkbox onClick={handleToggleHide}>
                            DS khoá
                        </Checkbox>
                    </div>
                    <div>
                        <Button className="form-btn bg" onClick={handleDataCreate}>
                            <FontAwesomeIcon icon={faPlus} className="mx-1" />
                            Thêm mới
                        </Button>
                    </div>
                </div>


            </div>
            <DetailKhoaPhong
                open={open}
                setOpen={setOpen}
                listKhoaphong={listKhoaphong}
                listServiceModule={listServiceModule}
                listKhoaphong_ck={listKhoaphong_ck}
                refreshData={reloadData}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
                key="DetailKhoaPhong"
            />
            <div className='tablekhoaphong'>
                <DataGrid
                    data={filteredData}
                    style={{ height: '90vh' }}
                    filterable
                    columnResizing
                    locale={{
                        emptyText: "Không Dữ Liệu",
                    }}
                >
                    <GridColumn field="DepartmentCode" title="Mã KP" align="center" width={100} />
                    <GridColumn field="DepartmentName" title="Tên phòng thực hiện" width={250} />
                    <GridColumn field="ServiceModuleCode" title="Nhóm dịch vụ" width={250}
                        render={({ row }) => {
                            const matchedItem = listServiceModule.find(nhom => nhom.ServiceModuleCode === row.ServiceModuleCode);
                            return matchedItem ? matchedItem.ServiceModuleName : null;
                        }}
                    />
                    <GridColumn align="center" field="MaKhoa_CK" title="Mã khoa BHYT" width={100} />
                    <GridColumn field="MAKHOA_BH" title="Mã khoa QĐ 130" align="center" width={120} />
                    <GridColumn field="EmployeeID_CCHN" title="Mã BS CCHN" align="center" width={200} />
                    <GridColumn field="Note" title="Ghi chú" width={200} />

                    <GridColumn field="STT" title="STT" align="center" width={50} />
                    <GridColumn
                        field="Hide"
                        title="Ẩn/Hiện"
                        align="center"
                        width={80}
                        filterable={false}
                        render={({ row }) => (
                            <Checkbox
                                checked={row.Hide === true}
                                onChange={() => handleToggleHideCheckbox(row)}
                            />
                        )}
                    />
                    <GridColumn
                        field="actions"
                        title="Thao tác"
                        align="center"
                        filterable={false}
                        width={150}
                        render={({ row }) => (
                            <div className="d-flex justify-content-center align-center">
                                <Dropdown
                                    menu={{
                                        items,
                                        onClick: (e) => handleThaoTac(e, row),
                                    }}
                                    placement="left"
                                    arrow={{
                                        pointAtCenter: true,
                                    }}
                                >
                                    <Button
                                        className="bg-light vienphi-danhmuc-icon-modify"
                                        onClick={() => {
                                            setActiveModify(!activeModify);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            style={{ fontSize: "10px" }}
                                            className="text-dark"
                                        />
                                    </Button>
                                </Dropdown>
                            </div>
                        )}
                    />
                </DataGrid>
            </div>
        </div>
    );
};

export default KhoaPhong;