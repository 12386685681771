import { Button, Dropdown, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faTrashCan,
    faPenToSquare,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { DataGrid, GridColumn, ComboBox } from 'rc-easyui';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { errorInfo, successInfo } from "../../../../../components/Dialog/Dialog";
import nhominAPI from "../../../../../services/vienphi/danhmuc/nhominAPI";
import { setDataNhomInVienPhi } from "../../../../../slices/dataVienPhi";
import loginAPI from "../../../../../services/loginApi";
import "../../danhmuc.scss";
import DetailNhomInVienPhi from "../Detail";
import LoadPage from "../../../../../loadpage";
function NhomInVienPhi() {
    const [loadpage, setLoadPage] = useState(false);
    const [HideCheck, setHideCheck] = useState(false);
    const [open, setOpen] = useState(false);
    const [activeModify, setActiveModify] = useState(false);
    const handleDataCreate = () => {
        setOpen(true);
    };
    const [listNhomInVienPhi, setlistNhomInVienPhi] = useState([]);
    const [userlogin, setUserLogin] = useState();
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleThaoTac = (e, record) => {
        if (e.key === "update") {
            dispatch(setDataNhomInVienPhi(record));
            setOpen(true);
        } else if (e.key === "delete") {
            Swal.fire({
                text: "Bạn có muốn xóa thông tin này ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0067ac",
                cancelButtonColor: "#d33",
                cancelButtonText: "Hủy",
                confirmButtonText: "Đồng ý",
            }).then((result) => {
                if (result.isConfirmed) {

                    handleDelete(record.GroupPrinterID, userlogin.RowID);
                    successInfo("Đã xóa thành công !");
                }
            });
        }
    };
    const getAllNhomInVienPhi = async () => {
        try {
            const data = await nhominAPI.getAll({ 'GroupPrinterID': 0, 'Hide': HideCheck === false ? 0 : 1 });
            setlistNhomInVienPhi(data.data);

        } catch (err) {}
    };
    const handleUpdate = async (obj) => {
        const data = await nhominAPI.update(obj);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllNhomInVienPhi();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleDelete = async (GroupPrinterID, EmployeeID) => {
        const data = await nhominAPI.delete(GroupPrinterID, EmployeeID);
        if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            getAllNhomInVienPhi();
        } else {
            errorInfo(data.data.Content);
        }
    };
    const handleToggleHideCheckbox = async (record) => {
        const newHideValue = record.Hide === false ? true : false;
        const updatedRecord = { ...record, Hide: newHideValue };

        try {
            await handleUpdate(updatedRecord);
            successInfo("Cập nhật trạng thái thành công!");
            getAllNhomInVienPhi();
        } catch (err) {
            errorInfo("Cập nhật trạng thái thất bại!");
            
        }
    };
    useEffect(() => {
        document.title = 'Khai Báo Nhóm In - EmedLink';
        setTimeout(() => {
            setLoadPage(true);
        }, 500);
        const getAllNhomInVienPhi = async () => {
            const data = await nhominAPI.getAll({ 'GroupPrinterID': 0, 'Hide': HideCheck === false ? 0 : 1 });
            setlistNhomInVienPhi(data.data);
        };
        getAllNhomInVienPhi();
        getAllUser();
    }, [HideCheck]);

    const items = [
        {
            key: "delete",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "update",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const status = [
        { value: null, text: "All" },
        { value: "true", text: "1" },
        { value: "false", text: "0" }
    ]
    if (loadpage === true) {
        return (
            <>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { duration: 0.8 } }}
                >
                    <div className="text-muted StyleNhomIn">
                        <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                            <div className="d-flex align-items-center ">
                                <div className="vienphi-danhmuc-title mx-2">Khai báo nhóm in</div>
                            </div>
                            <div className="text-end">
                                <div className="nhomin-act-check">
                                    <div className="col-n"> <Checkbox onChange={() => setHideCheck(!HideCheck)} > DS Khoá </Checkbox></div>
                                    <div className="col-n">
                                        <Button className="form-btn bg" onClick={handleDataCreate}>
                                            <FontAwesomeIcon icon={faPlus} className="mx-1" />
                                            Thêm mới
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DetailNhomInVienPhi
                            open={open} setOpen={setOpen}
                            reLoad={getAllNhomInVienPhi}
                            handleUpdate={handleUpdate}
                            key="DetailNhomInVienPhi"
                        />

                        <hr className="w-100 my-1" />
                        <div className="m-1 danhmuc danhmuc_nhomvpht">
                            <DataGrid
                                data={listNhomInVienPhi}
                                style={{ height: '90vh' }}
                                filterable
                                columnResizing
                            >
                                <GridColumn field="GroupPrinterName" title="Tên nhóm in" filterable width={450} resizable />
                                <GridColumn field="STT" title="STT" filterable width={50} resizable />
                                <GridColumn field="Hide" title="Ẩn/Hiện" filterable width={50} resizable
                                    filter={() => (
                                        <ComboBox
                                            data={status}
                                            editable={false}
                                        />
                                    )}
                                    render={({ row }) => (
                                        <Checkbox
                                            checked={row.Hide === true}
                                            onChange={() => handleToggleHideCheckbox(row)}
                                        />
                                    )}
                                />
                                <GridColumn
                                    field="actions"
                                    title="Thao tác"
                                    align="center"
                                    width={150}
                                    resizable
                                    filterable={false}
                                    render={({ row }) => (
                                        <div className="d-flex justify-content-center align-center">
                                            <Dropdown
                                                menu={{
                                                    items,
                                                    onClick: (e) => handleThaoTac(e, row),
                                                }}
                                                placement="left"
                                                arrow={{
                                                    pointAtCenter: true,
                                                }}
                                            >
                                                <Button
                                                    className="bg-light vienphi-danhmuc-icon-modify"
                                                    onClick={() => {
                                                        setActiveModify(!activeModify);
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                        style={{ fontSize: "10px" }}
                                                        className="text-dark"
                                                    />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    )}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </motion.div>
            </>
        );
    } else { return <LoadPage /> }
}
export default NhomInVienPhi;
