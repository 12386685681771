import { createSlice } from "@reduxjs/toolkit";

export const dataUserSlices = createSlice({
    name: "dataUser",
    initialState: {
        employeelist: [],
    },
    reducers: {
        setDataEmployee: (state, action) => {
            state.employeelist = action.payload;
        },
    },
});
export const {
    setDataEmployee,
} = dataUserSlices.actions;
export default dataUserSlices.reducer;
