import { Modal, Button, Form, Input, Select, Checkbox,InputNumber } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import loginAPI from "../../../../../services/loginApi";
import { setDataThuocThauBHYT } from "../../../../../slices/dataDMDuoc";
import thuocthauBHYTAPI from "../../../../../services/duoc/danhmuc/thuocthauBHYTAPI";

import './thuocthauBHYT.scss';

function DetailThuocThauBHYT(props) {
    const { open, setOpen, handleUpdate, reLoad } = props;
    const { thuocthauBHYT } = useSelector((state) => state.dataDMDuoc);
    const [userlogin, setUserLogin] = useState();
    const [checkAnHien, setcheckAnHien] = useState(false);
    const [Hide_BV, setHideBV] = useState(false);
    const [is_Map, setIsMap] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    const handleCreate = async (obj) => {
        const data = await thuocthauBHYTAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);

        }
        else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }
    };
    const handleSubmit = async (e) => {
        Swal.fire({
            title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
            confirmButtonText: "Đồng ý",
            showCancelButton: true,
            cancelButtonText: "Hủy",
            customClass: {
                title: "fs-5 text-dark",
                confirmButton: "shadow-none",
                cancelButton: "shadow-none",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                if (thuocthauBHYT?.RowID) {
                    var arrayEdit = {
                        RowID: e?.RowID,
                        ItemCode_PK: e?.ItemCode_PK,
                        MaDauThau_PK: e?.MaDauThau_PK,
                        Hide: e?.Hide,
                        TenDonViThau_PK: e?.TenDonViThau_PK,
                        BHYTPrice: e?.BHYTPrice,
                        Ngay_Hieu_Luc: e?.Ngay_Hieu_Luc,
                        Nhom_TCKT_TCCN: e?.Nhom_TCKT_TCCN,
                        ItemName_BYT_XML: e?.ItemName_BYT_XML,
                        ActiceName_BYT_XML: e?.ActiceName_BYT_XML,
                        ActiceCode: e?.ActiceCode,
                        UsageCode_BYT_XML: e?.UsageCode_BYT_XML,
                        ItemContent_BYT_XML: e?.ItemContent_BYT_XML,
                        Rate_BHYT: e?.Rate_BHYT,
                        NgayNop_M16: e?.NgayNop_M16,
                        NgayDuyet_BHXH: e?.NgayDuyet_BHXH,
                        PheDuyet_BHXH: e?.PheDuyet_BHXH,
                        SODKGP: e?.SODKGP,
                        MaBYT_PK: e?.MaBYT_PK,
                        MA_GOITHAU: e?.MA_GOITHAU,
                        SO_CVGUI_BHXH: e?.SO_CVGUI_BHXH,
                        TTThau_BH: e?.TTThau_BH,
                        Hide_BV: e?.Hide_BV,
                        is_Map: e?.is_Map,
                        MaDonViThau: e?.MaDonViThau,
                        SoLuong: e?.SoLuong,
                        MaNhom_BH: e?.MaNhom_BH,
                        Nuoc_SX: e?.Nuoc_SX,
                        Hang_SX: e?.Hang_SX,
                        MaNhom_BC: e?.MaNhom_BC,
                        Du_Phong: e?.Du_Phong,
                        EmployeeID: userlogin.RowID,
                        IDate: new Date()

                    }
                    handleUpdate(arrayEdit);
                    dispatch(setDataThuocThauBHYT());
                    form.resetFields();
                    setOpen(false);
                } else {
                    var Thuocthaubhyt = {
                        RowID : moment(new Date()).format('YYYYMMDD')+ Math.floor(Math.random() * 10000),
                        ItemCode_PK: e?.ItemCode_PK ?? " ",
                        MaDauThau_PK: e?.MaDauThau_PK ?? " ",
                        Hide: checkAnHien === false ? 0 : 1,
                        TenDonViThau_PK: e?.TenDonViThau_PK ?? " ",
                        BHYTPrice: e?.BHYTPrice ?? 0,
                        Ngay_Hieu_Luc: e?.Ngay_Hieu_Luc ?? " ",
                        Nhom_TCKT_TCCN: e?.Nhom_TCKT_TCCN ?? " ",
                        ItemName_BYT_XML: e?.ItemName_BYT_XML ?? " ",
                        ActiceName_BYT_XML: e?.ActiceName_BYT_XML ?? " ",
                        ActiceCode: e?.ActiceCode ?? " ",
                        UsageCode_BYT_XML: e?.UsageCode_BYT_XML ?? " ",
                        ItemContent_BYT_XML: e?.ItemContent_BYT_XML ?? " ",
                        Rate_BHYT: e?.Rate_BHYT ?? " ",
                        NgayNop_M16: e?.NgayNop_M16 ?? " ",
                        NgayDuyet_BHXH: e?.NgayDuyet_BHXH ?? " ",
                        PheDuyet_BHXH: e?.PheDuyet_BHXH ?? " ",
                        SODKGP: e?.SODKGP ?? " ", 
                        MaBYT_PK: e?.MaBYT_PK ?? " ",
                        MA_GOITHAU: e?.MA_GOITHAU ?? " ",
                        SO_CVGUI_BHXH: e?.SO_CVGUI_BHXH ?? " ",
                        TTThau_BH: e?.TTThau_BH ?? " ",
                        Hide_BV: Hide_BV === false ? 0 : 1,
                        is_Map: is_Map === false ? 0 : 1,
                        MaDonViThau: e?.MaDonViThau ?? " ",
                        SoLuong: e?.SoLuong ?? 0,
                        MaNhom_BH: e?.MaNhom_BH ?? " ",
                        Nuoc_SX: e?.Nuoc_SX ?? " ",
                        Hang_SX: e?.Hang_SX ?? " ",
                        MaNhom_BC: e?.MaNhom_BC ?? " ",
                        Du_Phong: e?.Du_Phong ?? " ",
                        EmployeeID: userlogin.RowID,
                        IDate: new Date(),
                    }
                    handleCreate(Thuocthaubhyt);

                }
            }
        });

    }
    const onChangeAnHien = (e) => {
        setcheckAnHien(e.target.checked)
    };
    const onChangeHienBV = (e) => {
        setHideBV(e.target.checked)
    };
    const onChangeIsMap = (e) => {
        setIsMap(e.target.checked)
    };
    
    useEffect(() => {
        getAllUser();
        form.setFieldsValue({
            RowID: thuocthauBHYT?.RowID,
            ItemCode_PK: thuocthauBHYT?.ItemCode_PK,
            MaDauThau_PK: thuocthauBHYT?.MaDauThau_PK,
            Hide: thuocthauBHYT?.Hide,
            TenDonViThau_PK: thuocthauBHYT?.TenDonViThau_PK,
            BHYTPrice: thuocthauBHYT?.BHYTPrice,
            Ngay_Hieu_Luc: thuocthauBHYT?.Ngay_Hieu_Luc,
            Nhom_TCKT_TCCN: thuocthauBHYT?.Nhom_TCKT_TCCN,
            ItemName_BYT_XML: thuocthauBHYT?.ItemName_BYT_XML,
            ActiceName_BYT_XML: thuocthauBHYT?.ActiceName_BYT_XML,
            ActiceCode: thuocthauBHYT?.ActiceCode,
            UsageCode_BYT_XML: thuocthauBHYT?.UsageCode_BYT_XML,
            ItemContent_BYT_XML: thuocthauBHYT?.ItemContent_BYT_XML,
            Rate_BHYT: thuocthauBHYT?.Rate_BHYT,
            NgayNop_M16: thuocthauBHYT?.NgayNop_M16,
            NgayDuyet_BHXH: thuocthauBHYT?.NgayDuyet_BHXH,
            PheDuyet_BHXH: thuocthauBHYT?.PheDuyet_BHXH,
            IDate: thuocthauBHYT?.IDate,
            SODKGP: thuocthauBHYT?.SODKGP,
            MaBYT_PK: thuocthauBHYT?.MaBYT_PK,
            MA_GOITHAU: thuocthauBHYT?.MA_GOITHAU,
            SO_CVGUI_BHXH: thuocthauBHYT?.SO_CVGUI_BHXH,
            TTThau_BH: thuocthauBHYT?.TTThau_BH,
            Hide_BV: thuocthauBHYT?.Hide_BV,
            is_Map: thuocthauBHYT?.is_Map,
            MaDonViThau: thuocthauBHYT?.MaDonViThau,
            SoLuong: thuocthauBHYT?.SoLuong,
            MaNhom_BH: thuocthauBHYT?.MaNhom_BH,
            Nuoc_SX: thuocthauBHYT?.Nuoc_SX,
            Hang_SX: thuocthauBHYT?.Hang_SX,
            MaNhom_BC: thuocthauBHYT?.MaNhom_BC,
            Du_Phong: thuocthauBHYT?.Du_Phong
        });
    }, [thuocthauBHYT, form]);
    return (
        <>
            <Modal className="DMThuocThauBHYT"
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo thuốc thầu BHYT
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={"100vw"}
            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form}>
                        <div className="scroll">
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label input-label-khaibaouser "> ID: </div>}
                                    className="col-md-6" name="RowID" hidden={true}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên đơn vị thầu :</div>}
                                    className="col-md-6 m-0"
                                    name="TenDonViThau_PK"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên thuốc AX :</div>}
                                    className="col-md-6 m-0"
                                    name="ItemName_BYT_XML"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row dmdot">

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Hoạt chất AX :</div>}
                                    className="col-md-6 m-0"
                                    name="ActiceName_BYT_XML"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã hoạt chất :</div>}
                                    className="col-md-3 m-0"
                                    name="ActiceCode"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã hàng hoá :</div>}
                                    className="col-md-3 m-0"
                                    name="ItemCode_PK"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã theo KQ Đ.Thầu :</div>}
                                    className="col-md-3 m-0"
                                    name="MaDauThau_PK"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã ĐV thầu :</div>}
                                    className="col-md-3 m-0"
                                    name="MaDonViThau"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Hàm lượng AX :</div>}
                                    className="col-md-6 m-0"
                                    name="ItemContent_BYT_XML"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>

                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Số CV gửi BHXH :</div>}
                                    className="col-md-3 m-0"
                                    name="SO_CVGUI_BHXH"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã đường dùng AX :</div>}
                                    className="col-md-3 m-0"
                                    name="UsageCode_BYT_XML"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Số đăng ký :</div>}
                                    className="col-md-3 m-0"
                                    name="SODKGP"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Giá BHYT :</div>}
                                    className="col-md-3 m-0"
                                    name="BHYTPrice"
                                >
                                    <InputNumber className="form-control inputdm"
                                        formatter={(value) => priceSplitter(value)}
                                        parser={(value) => value.replace(/\D/g, "")} 
                                    />
                                </Form.Item>

                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã BHYT (Mã AX) :</div>}
                                    className="col-md-3 m-0"
                                    name="MaBYT_PK"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">% thưởng BHYT :</div>}
                                    className="col-md-3 m-0"
                                    name="Rate_BHYT"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Số QĐ trúng thầu :</div>}
                                    className="col-md-3 m-0"
                                    name="PheDuyet_BHXH"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Gói thầu :</div>}
                                    className="col-md-3 m-0"
                                    name="MA_GOITHAU"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>

                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Nhóm thầu :</div>}
                                    className="col-md-3 m-0"
                                    name="Nhom_TCKT_TCCN"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">TT thầu 4210 :</div>}
                                    className="col-md-3 m-0"
                                    name="TTThau_BH"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Hãng SX :</div>}
                                    className="col-md-6 m-0"
                                    name="Hang_SX"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Số lượng :</div>}
                                    className="col-md-3 m-0"
                                    name="SoLuong"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã nhóm 9324:</div>}
                                    className="col-md-3 m-0"
                                    name="MaNhom_BH"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Nước SX:</div>}
                                    className="col-md-6 m-0"
                                    name="Nuoc_SX"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                            </div>
                            <div className="row dmdot">

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Nhóm loại thuốc BC :</div>}
                                    className="col-md-3 m-0"
                                    name="MaNhom_BC"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ngày nộp M16 :</div>}
                                    className="col-md-3 m-0"
                                    name="NgayNop_M16"
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="label"
                                        options={[
                                            {
                                                value: 'jack',
                                                label: 'Jack',
                                            },
                                            {
                                                value: 'lucy',
                                                label: 'Lucy',
                                            },
                                            {
                                                value: 'tom',
                                                label: 'Tom',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ngày P.Duyệt BHXH :</div>}
                                    className="col-md-3 m-0"
                                    name="NgayDuyet_BHXH"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ngày hiệu lực :</div>}
                                    className="col-md-3 m-0"
                                    name="Ngay_Hieu_Luc"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                            </div>
                            <div className="row dmdot">

                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ẩn BV01 :</div>}
                                    className="col-md-1 m-0" valuePropName="checked"
                                    name="Hide_BV"
                                >
                                   <Checkbox onChange={onChangeHienBV} value={Hide_BV} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ẩn/Hiện :</div>}
                                    className="col-md-1 m-0"
                                    name="Hide" valuePropName="checked"
                                >
                                     <Checkbox onChange={onChangeAnHien} value={checkAnHien} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Map mã thầu qua DM thuốc :</div>}
                                    className="col-md-2 m-0" valuePropName="checked"
                                    name="is_Map"
                                >
                                    <Checkbox onChange={onChangeIsMap} value={is_Map} />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Dự phòng :</div>}
                                    className="col-md-8 m-0"
                                    name="Du_Phong"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>

                            </div>

                        </div>
                        <Form.Item className="text-center">
                            <hr />
                            <Button htmlType="submit" className="mx-2 vienphi-danhmuc-btn" >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default DetailThuocThauBHYT;
