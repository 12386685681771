import { createSlice } from "@reduxjs/toolkit";

export const dataTienIchSlice = createSlice({
    name: "dataTienIch",
    initialState: {
        khoaphong:[],
        nguoigioithieu:[]
    },
    reducers: {
        setDataKhoaPhong: (state, action) => {
            state.khoaphong = action.payload;
        },
        setDataNguoiGioiThieu: (state, action) => {
            state.nguoigioithieu = action.payload;
        },
    },
});
export const {
    setDataKhoaPhong,
    setDataNguoiGioiThieu
} = dataTienIchSlice.actions;
export default dataTienIchSlice.reducer;