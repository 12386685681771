import { Modal, Button, Form, Input, Select, Checkbox, InputNumber } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { errDialog, errorInfo, infoRes, successInfo } from "../../../../../components/Dialog/Dialog";
import loginAPI from "../../../../../services/loginApi";
import { setDataDuongDung } from "../../../../../slices/dataDMDuoc";
import duongdungAPI from "../../../../../services/duoc/danhmuc/duongdungAPI";
import "../duongdung.scss";
function DetailDuongDung(props) {
    const { open, setOpen, handleUpdate, listDuongDungBHYT, reLoad } = props;
    const { duongdung } = useSelector((state) => state.dataDMDuoc);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [userlogin, setUserLogin] = useState();
    const [checkAnHien, setcheckAnHien] = useState(false);
    const getAllUser = async () => {
        try {
            const data = await loginAPI.profileFetch();
            setUserLogin(data.data);
        } catch (err) {
            localStorage.removeItem("token");
        }
    };
    const handleCreate = async (obj) => {
        const data = await duongdungAPI.create(obj);
        if (data.data.Code === "err") {
            infoRes(data.data.Content);
        }
        else if (data.data.Code === 404) {
            errorInfo(data.data.Content);

        }
        else if (data.data.Code === "ok") {
            successInfo(data.data.Content);
            reLoad();
            form.resetFields();
        }

    };
    const handleSubmit = async (e) => {
        if (e?.UsageName === undefined || e?.UsageCode_BH === undefined) { errDialog(); } else {
            Swal.fire({
                title: "BẠN CÓ MUỐN LƯU THÔNG TIN?",
                confirmButtonText: "Đồng ý",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                customClass: {
                    title: "fs-5 text-dark",
                    confirmButton: "shadow-none",
                    cancelButton: "shadow-none",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    if (duongdung?.UsageID) {
                        var ArryDuongdungEdit = {
                            UsageID: e?.UsageID,
                            UsageName: e?.UsageName,
                            UsageCode_BH: e?.UsageCode_BH,
                            STT: e?.STT,
                            Hide: e?.Hide,
                            EmployeeID: userlogin.RowID,

                        }
                        handleUpdate(ArryDuongdungEdit);
                        dispatch(setDataDuongDung());
                        form.resetFields();
                        setOpen(false);
                    } else {
                        var ArryDuongdung = {
                            UsageName: e?.UsageName,
                            UsageCode_BH: e?.UsageCode_BH,
                            Hide: checkAnHien === false ? 0 : 1,
                            STT: e?.STT === undefined ? 1 : e?.STT,
                            EmployeeID: userlogin.RowID,
                        }
                        handleCreate(ArryDuongdung);
                    }
                }
            });
        }

    }
    const onChange = (e) => {
        setcheckAnHien(e.target.checked)
    };
    useEffect(() => {
        getAllUser();
        form.setFieldsValue({
            UsageID: duongdung?.UsageID,
            UsageName: duongdung?.UsageName,
            UsageCode_BH: duongdung?.UsageCode_BH,
            STT: duongdung?.STT,
            Hide: duongdung?.Hide
        });
    }, [duongdung, form]);
    return (
        <>
            <Modal className="DetailDuongDung"
                title={
                    <div className="vienphi-danhmuc-add">
                        Khai báo đường dùng
                    </div>
                }
                centered
                open={open}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setOpen(false)}
                width={600}
                closable={false}
                forceRender
                maskClosable={false}
            >
                <div className="text-center">
                    <Form onFinish={handleSubmit} form={form}>
                        <div className="scroll">
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input"></div>}
                                    className="col-md-8 m-0"
                                    name="UsageID"
                                    hidden={true}
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Tên đường dùng :</div>}
                                    className="col-md-9 m-0"
                                    name="UsageName"
                                >
                                    <Input className="form-control inputdm" />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">STT :</div>}
                                    className="col-md-3 m-0"
                                    name="STT"
                                >
                                    <InputNumber className="form-control inputdm" />
                                </Form.Item>
                               
                            </div>
                            <div className="row dmdot">
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Mã Đ.Dùng (BYT) :</div>}
                                    className="col-md-9 m-0"
                                    name="UsageCode_BH"
                                >
                                    <Select
                                        showSearch
                                        className="selectac"
                                        placeholder="Chọn"
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={listDuongDungBHYT.map((item) => ({
                                            value: item.UsageCode,
                                            label: item.UsageName
                                        }))}

                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="form-input-label lablename-input">Ẩn/Hiện:</div>}
                                    className="col-md-3 m-0"
                                    name="Hide" valuePropName="checked"
                                >
                                    <Checkbox onChange={onChange} value={checkAnHien} />
                                </Form.Item>
                            </div>
                        </div>
                        <Form.Item className="text-center">
                            <hr />
                            <Button
                                htmlType="submit"
                                className="mx-2 vienphi-danhmuc-btn"
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} className="mx-1" /> Lưu
                            </Button>
                            <Button danger className="mx-2" onClick={() => setOpen(false)}>
                                <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" /> Thoát
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default DetailDuongDung;
