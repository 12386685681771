import { Modal, Tabs, Button, Form, Input, Checkbox, Table, Dropdown, Radio, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendarCheck,
    faFileExcel,
    faPenToSquare,
    faReply,
    faSave,
    faFile,
    faAngleRight,
    faAngleLeft,
    faEdit,
    faTrashCan,
    faEye,
    faArrowsRotate,
    faBroom,
    faCapsules,
    faPills,
    faHouseChimneyMedical,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "../tiemchung.scss";
import ds from "../../../util/data";
function KhaiBaoTiemChung() {
    const [click, setClick] = useState(false);
    const [openModelToathuoctainha, setModelToathuoctainha] = useState(false);

    const items1 = [
        {
            label: <div className="form-input-label khambenh-form-input-label">F3 - Lưu</div>,
            key: "1",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faSave} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">F2 - Bỏ qua</div>,
            key: "2",
            icon: <FontAwesomeIcon icon={faReply} />,
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">F4 - Sửa</div>,
            key: "3",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faPenToSquare} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">Hủy</div>,
            key: "4",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFileExcel} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">F5 - In toa</div>,
            key: "5",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFileExcel} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">Toa trắng</div>,
            key: "6",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faFile} />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">CĐ hẹn TK</div>,
            key: "7",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faCalendarCheck} type="regular" />
                </div>
            ),
        },
        {
            label: <div className="form-input-label khambenh-form-input-label">Đóng HS</div>,
            key: "8",
            icon: (
                <div>
                    <FontAwesomeIcon icon={faBook} />
                </div>
            ),
        },
    ];
    const itemsl = [
        {
            key: "1",
            label: "Xem",
            icon: <FontAwesomeIcon icon={faEye} />,
        },
        {
            key: "2",
            label: "Xóa",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
        },
        {
            key: "3",
            label: "Sửa",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
        },
    ];
    const column0 = [
        {
            title: "STT",
            dataIndex: "TDV",
            fixed: "left",
            render: (TDV) => <div style={{ width: "200px" }}> {TDV} </div>,
        },
        {
            title: "Mã BN",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "600px" }}> {TDVVT} </div>,
        },
        {
            title: "Họ và tên",
            dataIndex: "MADVKT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "Năm sinh",
            dataIndex: "TDVVT",
            render: (TDVVT) => <div style={{ width: "200px" }}> {TDVVT} </div>,
        },
        {
            title: "Giới tính",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "Đối tượng",
            dataIndex: "MADVKT",
            align: "center",
        },
        {
            title: "Thao tác",
            dataIndex: "",
            align: "center",
            fixed: "right",
            render: (record) => (
                <div className="d-flex justify-content-center">
                    <Dropdown
                        menu={{
                            itemsl,
                        }}
                        placement="left"
                        arrow={{
                            pointAtCenter: true,
                        }}
                    >
                        <Button
                            className="bg-light vienphi-danhmuc-icon-modify"
                            onClick={() => {

                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: "10px" }}
                                className="text-dark"
                            />
                        </Button>
                    </Dropdown>
                </div>
            ),
        },
    ];

    return (
        <div>
            <div className="div-shadow v3">
                <div className="d-flex justify-content-end align-items-center p-1">
                    <Form>
                        <div className="kb-checktoa">
                            <div className=" kb-checkintoa checkintoa-name"> In toa A4 :</div>
                            <div className=" kb-checkintoa check-1"> <Checkbox />{""}</div>


                        </div>
                    </Form>

                    {items1.map((item) => (
                        <Button className="form-btn bg mx-1" key={item.key}>
                            {item.icon}
                            <div className="mx-1 fw-bold ">{item.label}</div>
                        </Button>
                    ))}
                </div>
            </div>
            <div className="d-flex h-max pt-2">
                <div className="khaibaotiemchung">
                    <div className="kbtiemchungleft">
                        <div className="title">Thông tin lịch tiêm chủng</div>
                        <Table
                            columns={column0}
                            dataSource={ds}
                            // loading={loading}
                            scroll={{ x: true, y: 600 }}
                            size="small"
                            pagination={false}

                        />{""}
                    </div>
                    <div className="kbtiemchungright">
                        <div className="title">Lịch tiêm chủng</div>
                        <div className="bototm-tiemchung">
                            <div className="rowitem-ltiemchung">
                                <div className="nameltc">Dịch vụ:</div>
                                <div className="inpultc"><Input className="form-control" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default KhaiBaoTiemChung;
